import { AllowedIcons, IconButton } from '@gnist/design-system';
import { useNavigate } from 'react-router-dom';
import { openWindowHref } from 'src/utils/navigation/openSystemHref';
import { styled } from 'styled-components';

const StyledIconButton = styled(IconButton)`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: var(--moller-spacing-m) var(--moller-spacing-base);
`;

interface MenuButtonProps {
    text: string;
    icon: AllowedIcons;
    route?: string;
    setIsOpen?: (value: boolean) => void;
}

const MenuButton = ({ text, icon, route, setIsOpen }: MenuButtonProps) => {
    const navigate = useNavigate();
    return (
        <StyledIconButton
            icon={icon}
            showLabel="left"
            label={text}
            onClick={() => {
                if (icon === 'external') {
                    openWindowHref(route);
                } else if (route) {
                    navigate(route);
                } else if (setIsOpen) {
                    setIsOpen(true);
                }
            }}
        />
    );
};

export default MenuButton;

import {
    formInputs,
    FormProvider,
    InputFieldValidity,
} from '@gnist/design-system';
import { LocalFormState } from '@gnist/design-system/utilities/forms/useLocalFormState.js';
import { useQuery } from '@tanstack/react-query';
import { useFetchersContext } from 'external-apis';
import {
    ExistingCarRelation,
    VehicleInfo,
} from 'external-apis/src/types/bilhold';
import { ApiError } from 'openapi-typescript-fetch';
import { useEffect } from 'react';
import { useUpdateDamageAppraisal } from 'src/features/damage/utils/useDamageAppraisals';
import { useSetStepDone } from 'src/utils/hooks/useSetStepDone';
import lang from 'src/utils/lang';
import { formatBrandName } from 'src/utils/string/formatBrandNames';
import { styled } from 'styled-components';

const useGetCarByRegistrationNumber = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.bilhold.fetcher
        .path('/v1/cars/regnr/{registrationNumber}')
        .method('get')
        .create();
};

function useCarByRegistrationNumber(registrationNumber?: string) {
    const getCarByRegNr = useGetCarByRegistrationNumber();
    return useQuery<VehicleInfo, ApiError>({
        queryKey: ['useCarByRegistrationNumber', registrationNumber],
        queryFn: () =>
            getCarByRegNr({
                registrationNumber: registrationNumber ?? '',
                countryCode: 'NOR',
            }).then((x) => x.data),
        enabled: !!registrationNumber,
    });
}

export function mapCarResponseToCar(
    response: VehicleInfo
): ExistingCarRelation {
    return {
        licensePlate: response.registrationNumber,
        vin: response.vin,
        brand: response.brand,
        name: response.modelName ?? '',
        mileage: 0,
        type: 'Undefined',
    };
}

type LicensePlateForm = {
    licensePlate: string;
};

const { TextField } = formInputs<LicensePlateForm>();

const StyledTextField = styled(TextField)`
    width: 100%;
    max-width: 300px;
`;

function getCarErrorText(
    httpStatusCode: number
): InputFieldValidity<{ errorMsgRequired: true }> | undefined {
    switch (httpStatusCode) {
        case 404:
            return {
                type: 'error',
                message: lang.damageFetchingCarError404,
            };

        case 409:
            return {
                type: 'error',
                message: lang.damageFetchingCarError409,
            };

        default:
            return;
    }
}

interface SearchOtherCarViewProps {
    setCar: (car: ExistingCarRelation) => void;
    damageId: number | null;
    form: LocalFormState<LicensePlateForm>;
    setVehicleStepDone: (value: boolean) => void;
}
const SearchOtherCarView = ({
    setCar,
    damageId,
    form,
    setVehicleStepDone,
}: SearchOtherCarViewProps) => {
    const { mutate: updateDamage, isSuccess: updateDamageSuccess } =
        useUpdateDamageAppraisal(damageId);

    const {
        data: car,
        error,
        isSuccess: carFetchSuccess,
    } = useCarByRegistrationNumber(form.state.validated.licensePlate);

    const invalid = error ? getCarErrorText(error.status) : undefined;

    const carData =
        form.state.isValid && carFetchSuccess
            ? mapCarResponseToCar(car)
            : undefined;

    const success = carData && {
        type: 'success' as const,
        message: `${formatBrandName(carData.brand)} ${carData.name}`,
    };

    useEffect(() => {
        if (car && form.state.isValid) {
            updateDamage({
                vin: car.vin,
                licensePlate: car.registrationNumber,
                title: 'Karosseri',
            });
            setCar(mapCarResponseToCar(car));
            return;
        }
    }, [car, form.state.isValid, setCar, updateDamage]);

    // Set vehicle step done when a car plate number is set
    useSetStepDone(updateDamageSuccess, setVehicleStepDone);

    return (
        <div>
            <p>{lang.damageCarNotRegistered}</p>
            <FormProvider
                hideNecessityText
                form={form}
                id="damageAppraisal-searchOtherCar"
            >
                <StyledTextField
                    field="licensePlate"
                    label={lang.damageCarInputLabel}
                    style={{ textTransform: 'uppercase' }}
                    validity={invalid || success}
                />
            </FormProvider>
        </div>
    );
};

export default SearchOtherCarView;

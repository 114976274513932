import { useQuery } from '@tanstack/react-query';
import { useFetchersContext } from 'external-apis';

export const carProfileQueryKey = 'carProfile';

export const useCarsFetcher = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.bilhold.fetcher.path('/v1/cars').method('get').create();
};

export const useCarProfile = () => {
    const getCars = useCarsFetcher();
    return useQuery({
        queryKey: [carProfileQueryKey],
        queryFn: () => getCars({}).then((res) => res.data),
    });
};

import { ExistingCarRelation } from 'external-apis/src/types/bilhold';
import moment from 'moment/moment';

const maximumMileage = 200000;
const maximumAgeInYears = 15;

export const isEligibleForValuation = (car: ExistingCarRelation) => {
    const carAgeInYears = Math.abs(
        moment(car.registrationDate).diff(moment(), 'years')
    );
    const validAge = carAgeInYears <= maximumAgeInYears;
    const validMileage = car.mileage ?? 0 <= maximumMileage;

    return validAge && validMileage;
};

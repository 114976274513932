import { datadogLogs } from '@datadog/browser-logs';
import { AlertBanner, InfoCard } from '@gnist/design-system';
import { useQueryClient } from '@tanstack/react-query';
import { AuthCallbacks, Config, WorkshopBooking } from 'booking';
import { getBookingParamValues } from 'booking/_api/urlSearchParameters';
import { BilholdLayout } from 'booking/components/bilhold/BilholdLayout';
import { PATH_HOME } from 'booking/lib/constants/routes';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppBundleUpdateContext } from 'src/utils/AppBundleUpdaterContext';
import { withErrorBoundary } from 'src/utils/ErrorBoundary';
import { getAccessToken, getPartyId } from 'src/utils/auth/authenticationUtils';
import getCurrentEnvironment from 'src/utils/environment/getCurrentEnvironment';
import { useProfile } from 'src/utils/hooks/useProfile';
import { refreshAccessTokenIfNeeded } from 'src/utils/http/utils/headers';
import onAuthError from 'src/utils/http/utils/onAuthError';
import lang from 'src/utils/lang';
import { styled } from 'styled-components';
import { appointmentsQueryKey } from '../appointment/api/useAppointments';
import { useHandleLegacyBookingParams } from './useHandleLegacyBookingParams';

export const localOverrideUrls = {
    PORT_BASE_URI: import.meta.env.VITE_BOOKING_API_URL_BOOKING,
    BILHOLD_BASE_URI: import.meta.env.VITE_BILHOLD_API_URL_BOOKING,
    DAMAGE_APPRAISAL_URI: import.meta.env.VITE_DAMAGE_APPRAISAL_URL_BOOKING,
    USER_SELF_SERVICE_URI: import.meta.env.VITE_USER_SELF_SERVICE,
};

export const authCallbacks: AuthCallbacks = {
    getFreshAuthToken: async () => {
        await refreshAccessTokenIfNeeded();
        return getAccessToken() || '';
    },
    onUnauthorized: onAuthError,
};

const StyledAlertBanner = styled(AlertBanner)`
    margin: var(--moller-spacing-l) var(--moller-spacing-s);
`;

const fallback = (retry: () => void) => (
    <StyledAlertBanner
        type="error"
        message="Vi greide dessverre ikke å vise siden for bestilling av verkstedtime."
        action={{ label: 'Prøv igjen', onClick: retry }}
    />
);

export const Booking = withErrorBoundary(
    'Booking',
    () => {
        useHandleLegacyBookingParams();
        const [searchParams] = useSearchParams();
        const bookingParameters = getBookingParamValues(searchParams);
        const userProfile = useProfile();
        const queryClient = useQueryClient();
        const navigate = useNavigate();
        const { setInterruptUpdateTimestamp } = useAppBundleUpdateContext();

        const environment = getCurrentEnvironment().name;

        if (userProfile.isLoading) {
            return (
                <BilholdLayout>
                    <InfoCard isLoading />
                </BilholdLayout>
            );
        }
        if (userProfile.isError || !userProfile.isSuccess) {
            return (
                <BilholdLayout>
                    <AlertBanner
                        type="error"
                        message={lang.errorTryAgainLater}
                        action={{
                            label: lang.back,
                            onClick: () => navigate(PATH_HOME),
                        }}
                    ></AlertBanner>
                </BilholdLayout>
            );
        }

        const config: Config = {
            apiLocaleId: 'no',
            searchableDealerNumbers: [],
            defaultApiLocale: 'no',
            bookingParameters,
        };
        const partyId = getPartyId();

        if (!partyId) {
            datadogLogs.logger.error(
                'Rendered NewWorkshopBooking without a partyId / valid access token. Should not be possible.'
            );
            return null;
        }

        function invalidateAppointmentsQuery() {
            void queryClient.invalidateQueries({
                queryKey: [appointmentsQueryKey],
            });
        }

        return (
            <WorkshopBooking
                authScheme={{
                    callbacks: authCallbacks,
                    user: {
                        partyId,
                        email: userProfile.data.email,
                        phone: userProfile.data.phoneNumber,
                    },
                }}
                {...{
                    environment:
                        environment === 'staging' ? 'stage' : environment,
                    localOverrideUrls,
                    config,
                }}
                languageId={'no'}
                invalidateAppointmentsQuery={invalidateAppointmentsQuery}
                setInterruptUpdateTimestamp={setInterruptUpdateTimestamp}
            />
        );
    },
    { fallback }
);

export default Booking;

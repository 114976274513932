import { datadogRum } from '@datadog/browser-rum';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import {
    Column,
    IconButton,
    PrimaryButton,
    TextArea,
    TextContainer,
} from '@gnist/design-system';
import { useToggle } from 'feature-toggle';
import { useState } from 'react';
import { styled } from 'styled-components';
import { useGetDealers } from '../../../_api/http/dealers';
import { useBookingSearchParameters } from '../../../_api/urlSearchParameters';
import { BoldText } from '../../../components/BoldText';
import { FlexRow } from '../../../components/FlexRow';
import { useLanguageContext } from '../../../lib/languages/languageContext';
import { NpsScale } from './NpsScale';

const InputBox = styled.div`
    position: fixed;
    padding: 12px;
    margin-bottom: env(safe-area-inset-bottom);
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: #fff;
    animation-duration: 2s;
    animation-name: NPSInput-slidein;
    z-index: 10000;
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.16);

    @keyframes NPSInput-slidein {
        from {
            bottom: -100%;
        }

        to {
            bottom: 0px;
        }
    }
`;

const InnerText = styled.div`
    width: 100%;
    max-width: 1000px;
    margin: 12px auto;
    text-align: center;
`;

const CloseButton = styled(IconButton)`
    float: right;
`;

const RightAlignedFlexRow = styled(FlexRow)`
    margin-left: auto;
`;

const TextAreaLeft = styled(TextArea)`
    text-align: left;
`;

const NPS_DETRACTOR_THRESHOLD = 6;

export const NpsInput = ({ dealerNumber }: { dealerNumber?: string }) => {
    const [dismissed, setDismissed] = useState(false);
    const [score, setScore] = useState<number | null>(null);
    const [feedback, setFeedback] = useState('');
    const [sent, setSent] = useState(false);
    const [animationParent] = useAutoAnimate();
    const [lc] = useLanguageContext();

    const multipleDealersToggle = useToggle<string>(
        'booking-experiment-multiple-dealers-bergen'
    );

    const dealerGroup = useBookingSearchParameters().dealerGroup;
    const dealer = useGetDealers(dealerGroup).data?.find(
        (dealer) => dealer.id === dealerNumber
    );
    const dealerName = dealer?.name;
    const adapterId = dealer?.adapterId;

    const multipleDealersBergen = !!Number(
        multipleDealersToggle?.variation.value
    );

    const dismissNps = () => {
        if (!score) {
            datadogRum.addAction('User dismissed NPS modal', {
                dealerName,
                dealerNumber,
                adapterId,
                multipleDealersBergen,
            });
        }
        setDismissed(true);
    };

    const submitScore = (value: number) => {
        setScore(value);
        datadogRum.addAction('User submitted NPS', {
            dealerName,
            dealerNumber,
            adapterId,
            score: value,
            multipleDealersBergen,
        });
    };

    const submitFeedback = (value: string) => {
        setSent(true);
        if (feedback.length > 0) {
            datadogRum.addAction('User submitted text feedback in NPS', {
                dealerName,
                dealerNumber,
                adapterId,
                feedback: value,
                multipleDealersBergen,
            });
        }
    };

    if (dismissed) {
        return null;
    }

    return (
        <InputBox>
            <CloseButton
                icon="close"
                label={lc.confirmBooking.net_promoter_score.close}
                onClick={() => dismissNps()}
            />
            <InnerText>
                {(score && score > NPS_DETRACTOR_THRESHOLD) || sent ? (
                    <TextContainer>
                        <p>{lc.confirmBooking.net_promoter_score.thank_you}</p>
                    </TextContainer>
                ) : (
                    <Column gap="xxs" ref={animationParent}>
                        <TextContainer>
                            {lc.confirmBooking.net_promoter_score.question}
                        </TextContainer>
                        <NpsScale submitScore={submitScore} score={score} />
                        {score !== null && (
                            <>
                                <BoldText>
                                    {
                                        lc.confirmBooking.net_promoter_score
                                            .we_wish_your_feedback
                                    }
                                </BoldText>
                                <TextAreaLeft
                                    label={
                                        lc.confirmBooking.net_promoter_score
                                            .your_feedback
                                    }
                                    helperText={
                                        lc.confirmBooking.net_promoter_score
                                            .no_personal_information
                                    }
                                    onChange={(e) =>
                                        setFeedback(e.target.value)
                                    }
                                />
                                <RightAlignedFlexRow>
                                    <PrimaryButton
                                        onClick={() => {
                                            submitFeedback(feedback);
                                        }}
                                    >
                                        {feedback.length === 0
                                            ? lc.components.button_skip
                                            : lc.confirmBooking
                                                  .net_promoter_score
                                                  .send_feedback}
                                    </PrimaryButton>
                                </RightAlignedFlexRow>
                            </>
                        )}
                    </Column>
                )}
            </InnerText>
        </InputBox>
    );
};

import { DealerServiceCertifications } from 'external-apis/.typescript/src/types/port';
import { Brand as BrandType } from 'external-apis/.typescript/src/types/bilhold';

export function isBrandMatch(
    certificationBrand: DealerServiceCertifications,
    bilholdBrand: BrandType
) {
    return certificationBrandToBilholdBrand(certificationBrand) == bilholdBrand;
}

function certificationBrandToBilholdBrand(
    certificationBrand: DealerServiceCertifications
): BrandType {
    switch (certificationBrand) {
        case 'Vw':
            return 'Volkswagen';
        case 'Skoda':
            return 'Skoda';
        case 'Seat':
            return 'Seat';
        case 'Audi':
            return 'Audi';
        case 'Nytte':
            return 'VolkswagenCommercialVehicles';
        case 'Cupra':
            return 'Cupra';
        case 'Other':
        case 'Chrysler':
        case 'Jeep':
        case 'Dodge':
        case 'Unknown':
            return 'Other';
    }
}

import {
    Column,
    Link,
    Logo,
    Row,
    SecondaryButton,
    TextContainer,
} from '@gnist/design-system';
import { createPortal } from 'react-dom';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
    PATH_APP,
    PATH_COOKIES,
    PATH_MY_PROFILE,
    PATH_PRIVACY,
    PATH_TERMS_OF_SERVICE,
} from 'src/routing/routes';
import { getIsLoggedIn } from 'src/utils/auth/authenticationUtils';
import { useProfileConsent } from 'src/utils/hooks/useProfile';
import lang from 'src/utils/lang';
import { styled } from 'styled-components';

const FooterContainer = styled(Row)`
    flex-wrap: wrap;
    flex-shrink: 0;
    justify-content: space-around;
    margin-top: var(--moller-spacing-xl);
    padding: var(--moller-spacing-l) var(--moller-spacing-s);
    background-color: var(--moller-color-inverse-background);
    color: var(--moller-color-on-inverse-background);
    @media screen and (max-width: 1000px) {
        flex-direction: column;
        gap: var(--moller-spacing-m);
    }
`;

const FooterColumn = styled(Column)`
    max-width: 20%;
    min-width: 200px;
    align-items: flex-start;
    a {
        color: var(--moller-color-on-inverse-background);
    }
    @media screen and (max-width: 1000px) {
        max-width: 500px;
    }
`;

const LogoContainer = styled.div`
    margin-top: 16px;
    padding-bottom: 10px;
    @media screen and (max-width: 600px) {
        padding-bottom: 0;
        button {
            width: 100%;
        }
    }
`;

const FooterSectionHeadline = styled.p`
    font-weight: bold;
    padding-bottom: var(--moller-spacing-xl);
    @media screen and (max-width: 600px) {
        padding-bottom: var(--moller-spacing-s);
    }
`;

const FooterContactUs = styled(Column)`
    padding-bottom: var(--moller-spacing-s);
`;

const FooterDescription = styled(TextContainer)`
    padding-bottom: var(--moller-spacing-s);
`;

const FooterLink = styled(Link)`
    padding-bottom: var(--moller-spacing-s);
`;

const ChangeConsents = () => {
    const { data: consentUrl } = useProfileConsent();

    return (
        <FooterLink href={consentUrl ?? PATH_MY_PROFILE}>
            {lang.changeConsent}
        </FooterLink>
    );
};

export const Footer = () => {
    const navigate = useNavigate();
    const year = new Date().getFullYear();
    const footerElement = document.getElementById('footer');

    if (!footerElement) {
        throw new Error(`Portal target with id footer does not exist in DOM`);
    }
    return createPortal(
        <FooterContainer className="footer">
            <FooterColumn>
                <LogoContainer>
                    <Logo brand="bilhold" type="wordmark" />
                </LogoContainer>
                <FooterDescription>
                    <p>{lang.footerDescription}</p>
                    <p>
                        © {year} {lang.allRights}
                    </p>
                </FooterDescription>
            </FooterColumn>
            <FooterColumn>
                <TextContainer>
                    <FooterSectionHeadline>
                        {lang.usefulInformation}
                    </FooterSectionHeadline>
                </TextContainer>
                <FooterLink forwardedAs={RouterLink} to={PATH_PRIVACY}>
                    {lang.privacy}
                </FooterLink>
                <FooterLink forwardedAs={RouterLink} to={PATH_COOKIES}>
                    {lang.cookies}
                </FooterLink>
                {getIsLoggedIn() && <ChangeConsents />}
                <FooterLink forwardedAs={RouterLink} to={PATH_TERMS_OF_SERVICE}>
                    {lang.termsOfService}
                </FooterLink>
            </FooterColumn>
            <FooterColumn>
                <TextContainer>
                    <FooterContactUs>
                        <FooterSectionHeadline>
                            {lang.contactUs}
                        </FooterSectionHeadline>
                        {lang.haraldAMollerOrgNr}
                        <p>
                            <a href="mailto:kundeservice@moller.no">
                                {lang.customerServiceMail}
                            </a>
                        </p>
                        <p>
                            {lang.phoneToCustomerService}{' '}
                            <a href="tel:+4724033570">24 03 35 70</a>
                        </p>
                    </FooterContactUs>
                </TextContainer>
            </FooterColumn>
            <FooterColumn>
                <TextContainer>
                    <FooterSectionHeadline>{lang.brands}</FooterSectionHeadline>
                </TextContainer>
                <FooterLink href="https://www.audi.no/">{lang.audi}</FooterLink>
                <FooterLink href="https://www.cupraofficial.no/">
                    {lang.cupra}
                </FooterLink>
                <FooterLink href="https://www.seat.no/">{lang.seat}</FooterLink>
                <FooterLink href="https://www.skoda.no/">
                    {lang.skoda}
                </FooterLink>
                <FooterLink href="https://www.volkswagen.no/">
                    {lang.vw}
                </FooterLink>
                <FooterLink href="https://www.volkswagen.no/">
                    {lang.vwnytte}
                </FooterLink>
            </FooterColumn>
            <LogoContainer>
                <SecondaryButton onClick={() => navigate(PATH_APP)}>
                    {lang.downloadApp}
                </SecondaryButton>
            </LogoContainer>
        </FooterContainer>,
        footerElement
    );
};

import { ExistingCarRelation } from 'external-apis/src/types/bilhold';
import { DealerViewModel } from 'external-apis/src/types/port';
import { isDateLessThanXMonthsAgo } from 'src/features/home/utils/isDateWithinRange';

export const getFilteredDealers = (
    dealers?: DealerViewModel[],
    car?: ExistingCarRelation
): DealerViewModel[] => {
    if (!dealers || !car) return [];

    const isNewVehicle = isDateLessThanXMonthsAgo(car?.registrationDate, 60);
    // TODO: should this logic be part of DealerPicker instead?
    return dealers.filter(
        (dealer) => !(isEconomyDealer(dealer.id) && isNewVehicle)
    );
};

export const ECONOMY_GUMPENS = '487';
function isEconomyDealer(dealerId: string) {
    return dealerId === ECONOMY_GUMPENS;
}

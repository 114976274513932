import { CheckboxCard } from '@gnist/design-system';
import { ServiceViewModel } from 'external-apis/src/types/port';
import { Price, parseServicePrices } from '../../../shared/prices/Price';
import { ServiceDescription } from '../../../shared/services/ServiceDescription';
import { useStandardServicesForm } from '../../useSectionForm';
import { useStandardServiceListValidity } from '../../useStandardServiceListValidity';
import { toggleFeature } from '../toggleFeature';
import { AdditionalServiceInfo } from '../../additionalInfo/AdditionalServiceInfo';

type Props = {
    serviceForm: ReturnType<typeof useStandardServicesForm>;
    dealerNumber?: string;
    service: ServiceViewModel;
};

export function Service({ serviceForm, service, dealerNumber }: Props) {
    const setSelectedServiceIds = serviceForm.setValue('selectedIds');
    const selectedServiceIds = serviceForm.state.raw.selectedIds;

    const validity = serviceForm.inputProps('selectedIds').validity;
    const serviceListValidity = useStandardServiceListValidity(validity);

    return (
        <CheckboxCard
            onChange={() =>
                toggleFeature({
                    selectedServiceIds,
                    id: service.id,
                    setSelectedServiceIds,
                })
            }
            density="compact"
            label={service.name}
            value={selectedServiceIds.includes(service.id)}
            description={
                <ServiceDescription
                    selected={selectedServiceIds.includes(service.id)}
                    description={service.description}
                >
                    <Price values={parseServicePrices(service, dealerNumber)} />
                    <AdditionalServiceInfo
                        serviceForm={serviceForm}
                        service={service}
                    />
                </ServiceDescription>
            }
            {...serviceListValidity}
        />
    );
}

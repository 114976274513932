import { Product } from 'src/features/home/utils/productType';
import VWSkiposeImage from '../product-images/vw/vw-Skipose.webp';
import VWHjemmeladerImage from '../product-images/vw/vw-hjemmelader.png';
import VWSykkelholderImage from '../product-images/vw/vw-sykkelholder-3-sykler.webp';
import VWTakstativImage from '../product-images/vw/vw-takstativ.webp';
import VWUnderlag from '../product-images/vw/vw-underlag-barnesete.webp';
import VWUSBCharger from '../product-images/vw/vw-usbc-lader-miljøbilde.webp';

export const VwProducts: Product[] = [
    {
        articleNumber: '3C0071105C',
        packageNumber: 'G311-03',
        image: VWSykkelholderImage,
        title: 'Sykkelholder',
        descriptionShort: 'Original sykkelholder for tilhengerfeste.',
        descriptionLong:
            'Volkswagen Compact III er et lite, lett og kompakt sykkelstativ for inntil tre sykler.',
        text: 'Takket være det sammenleggbare systemet kan baklyktene, sykkelrailene og støtterammen legges sammen og oppbevares i bagasjerommet. Stativet er spesielt enkelt å vippe ned mens det er festet på kjøretøyet - selv med sykler - takket være fotpedalen, slik at tilgang til bagasjerommet forblir tilgjengelig. Det låses automatisk når stativet er vippet opp. Praktisk bæreveske er inkludert.',
        price: 7990,
        carBrand: 'Volkswagen',
        carModel: ['ID.3', 'ID.4', 'ID.5', 'ID.7'],
    },
    {
        articleNumber: '00V061202',
        packageNumber: 'G301',
        image: VWSkiposeImage,
        title: 'Skipose',
        descriptionShort: 'Original skibag kan romme fire par ski, og staver.',
        descriptionLong:
            'Volkswagen Original skibag kan romme opptil fire par ski, inkludert staver.',
        text: 'Bagen er spesielt egnet for transport inni bilen, der den kan festes ved hjelp av ett av baksetebeltene til baksetet. Lengde på posen er 195cm.',
        price: 790,
        carBrand: 'Volkswagen',
        carModel: ['ID.3', 'ID.4', 'ID.5', 'ID.7'],
    },
    {
        articleNumber: 'VW DEFAPOWER + DEFABALANCER',
        packageNumber: 'A030-04',
        image: VWHjemmeladerImage,
        title: 'Hjemmelader',
        descriptionShort: 'DEFA Power med Balancer. Smarteste måten å lade.',
        descriptionLong:
            'Den smarteste måten å kontrollere strømforbruket ditt, slik at du kan lade til lavest mulig kostnad med maksimal effekt.',
        text: 'DEFA Power har et intuitivt display og 6 meter integrert Type 2-ladekabel som forenkler din daglige bruk og gir deg full kontroll over ladingen. DEFA Power optimaliserer tilgjengelig strøm, og med PowerSmart kan du unngå strømtopper og dermed unngå å lade når strømmen er dyrest.',
        specList: [
            'Maksimal effekt – opp til 22kW',
            'DEFA Power leverer lovet effekt, uansett om du kan motta 7,4kW, 11kW eller 22kW.',
            'Gjennom en intelligent konstruksjon og avanserte simuleringer er den designet for å sikre raskest mulig lading.',
            'PowerSmart™ med Power Appen',
            'Forhåndsinnstill at du vil lade når strømprisene er lave, basert på daglige priser fra Nord Pool.',
            'Montering er inkludert',
        ],
        price: 17990,
        carBrand: 'Volkswagen',
        carModel: ['ID.3', 'ID.4', 'ID.5', 'ID.7'],
    },
    {
        articleNumber: 'ID.4: 11A071151A - ID.7 (coupe): 14A071126',
        packageNumber: 'G200',
        image: VWTakstativImage,
        title: 'Takstativ',
        descriptionShort: 'Volkswagen originalt takstativ.',
        descriptionLong:
            'Volkswagen originalt takstativ er et praktisk tilbehør som lar deg enkelt transportere større gjenstander som ikke får plass inne i bilen.',
        text: 'Den aerodynamiske formen på takstativet bidrar til å redusere luftmotstanden og støyen inne i bilen. Den stilige, flate designen passer perfekt til bilens uttrykk. Takstativet er enkelt å montere. Det er også låsbart, slik at du ikke trenger å bekymre deg for tyveri. Ved hjelp av T-spor kan ulike transportsystemer som sykkelstativer, takbokser og takbokser monteres på dette stativet.',
        price: 3590,
        carBrand: 'Volkswagen',
        carModel: ['ID.4', 'ID.7'],
    },
    {
        articleNumber: '000 019 819 C',
        packageNumber: 'C900',
        image: VWUnderlag,
        title: 'Underlag',
        descriptionShort: 'Underlag for barnesete. Beskytter setene i bilen.',
        descriptionLong:
            'Volkswagen originalt underlag til barnestol. Et praktisk tilbehør som forebygger mot skitne og slitte seter og ryggstøtter.',
        text: 'Med antiskli belegg kombinert med at det er vaskbart er det lett å rengjøre underlaget og det bidrar til å holde bilen din ren og opprettholde verdien. Underlaget passer til alle barnestoler, inkludert de med ISOFIX- eller i-Size-tilkoblinger. Settet består av to deler, et setetrekk og et ryggstøttetrekk som kan festes ved nakkestøttene. Kombinasjonen er spesielt praktisk når du kjører med barn i en bakovervendt stilling. Tre integrerte nettlommer gir også lagringsplass for små gjenstander.',
        price: 455,
        carBrand: 'Volkswagen',
        carModel: ['ID.3', 'ID.4', 'ID.5', 'ID.7'],
    },
    {
        articleNumber: '000051446AD',
        packageNumber: 'A015-03',
        image: VWUSBCharger,
        title: 'USB C kabel 30cm',
        descriptionShort: 'Premium USB-kabel (USB-C til USB-C plugg).',
        descriptionLong:
            'Denne Premium USB-kabelen (USB-C til USB-C plugg) er spesielt utviklet for å koble en smarttelefon eller andre mobile enheter til Volkswagens Infotainment-system.',
        text: 'Kabelen er 30 cm lang og har en høykvalitets tekstiloverflate og krombelagte metallkontakter, hver med Volkswagen-logo. Den korte lengden gjør at kabelen egner seg spesielt godt til bruk i bilen. Funksjoner som GoogleAuto og MirrorLink i AppConnect kan praktisk brukes med denne kabelen, og den kan selvfølgelig også brukes til å lade den tilkoblede enheten. Laderen passer til Android-mobiler, i tillegg til iPhone 15 og nyere.',
        price: 49,
        carBrand: 'Volkswagen',
        carModel: ['ID.3', 'ID.4', 'ID.5', 'ID.7'],
    },
];

import { Capacitor } from '@capacitor/core';
import {
    ActionChip,
    AlertBanner,
    breakpointMobile,
    Column,
    DetailText,
    Heading2Text,
} from '@gnist/design-system';
import { sendBookingFormProgressToAdobe } from 'adobe-utils/formInteraction';
import { isSameDay } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import {
    DealerViewModel,
    TimeSlotViewModel,
} from 'external-apis/src/types/port';
import { pipe } from 'fp-ts/function';
import { groupBy } from 'fp-ts/lib/NonEmptyArray';
import { Dictionary } from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import { styled } from 'styled-components';
import { useGetApiLocaleConfig } from '../../_api/apiLocale';
import { useReserveTimeSlot } from '../../_api/http/timeslots';
import { BoldText } from '../../components/BoldText';
import { FlexRow } from '../../components/FlexRow';
import { useLanguageContext } from '../../lib/languages/languageContext';
import { StandardServicesData } from '../standard-services/useStandardServices';
import { ChooseTimeSlotState } from './ChooseTimeslotSection';

export function DealersAndSlots({
    selectedDate,
    vin,
    selectedServices,
    ...props
}: {
    vin: string;
    selectedDealerId: string;
    selectedDealerNumbers: string[];
    selectedServices: StandardServicesData['selectedServices'];
    selectedDate: Date;
    dealers: DealerViewModel[];
    timeSlots: TimeSlotViewModel[];
    setReservedTimeSlotError: (x: Error | null) => void;
    setSectionState: (x: ChooseTimeSlotState) => void;
}) {
    const timeSlotsForSelectedDate = props.timeSlots.filter(
        (x) => selectedDate && isSameDay(new Date(x.startTime), selectedDate)
    );

    const timeSlotsForDealer = pipe(
        timeSlotsForSelectedDate,
        groupBy((x) => x.dealerNumber)
    ) as Dictionary<TimeSlotViewModel[] | undefined>; // need casting because groupBy return type lie.

    const selectedDealers = props.dealers.filter((x) =>
        props.selectedDealerNumbers.includes(x.id)
    );

    // DO NOT REMOVE ?? [] FROM TIMESLOTS BEFORE FIXING TIME_SLOTS_FOR_DEALER TYPING
    // IE. RETURN TYPE OF PIPE
    return (
        <Column gap="l">
            {selectedDealers.map((dealer) => (
                <Fragment key={dealer.id}>
                    {1 < selectedDealers.length && (
                        <BoldText>{dealer.name}</BoldText>
                    )}
                    <DealerAndSlots
                        timeSlots={timeSlotsForDealer[dealer.id] ?? []}
                        selectedDate={selectedDate}
                        selectedServices={selectedServices}
                        dealer={dealer}
                        vin={vin}
                        setSectionState={props.setSectionState}
                        setReservedTimeSlotError={
                            props.setReservedTimeSlotError
                        }
                    />
                </Fragment>
            ))}
        </Column>
    );
}

const TimeSlotsContainer = styled(FlexRow)`
    flex: 1;
    flex-wrap: wrap;
    justify-content: start;
    column-gap: var(--moller-spacing-m);
    row-gap: var(--moller-spacing-s);
    margin: var(--moller-spacing-s) 0;

    @media (max-width: ${breakpointMobile}) {
        column-gap: var(--moller-spacing-xs);
    }
`;

function DealerAndSlots({
    setSectionState,
    setReservedTimeSlotError,
    selectedServices,
    ...props
}: {
    selectedServices: StandardServicesData['selectedServices'];
    timeSlots: TimeSlotViewModel[];
    vin: string;
    dealer: DealerViewModel;
    selectedDate: Date;
    setReservedTimeSlotError: (x: Error | null) => void;
    setSectionState: (x: ChooseTimeSlotState) => void;
}) {
    const [lc] = useLanguageContext();
    const [selectedTimeSlot, setSelectedTimeSlot] =
        useState<TimeSlotViewModel>();

    const reserveTimeSlot = useReserveTimeSlot({
        vin: props.vin,
        serviceIds: selectedServices.map((x) => x.serviceId),
        dealerMail: props.dealer.email,
    });

    useEffect(() => {
        if (reserveTimeSlot.isError) {
            setReservedTimeSlotError(reserveTimeSlot.error);
        }
        if (reserveTimeSlot.isSuccess) {
            setSectionState({
                viewMode: 'done',
                data: {
                    reservedTimeSlot: reserveTimeSlot.data,
                    isRequest: false, //if you set a timeslot, it is not a request
                },
            });
            setReservedTimeSlotError(null);
            sendBookingFormProgressToAdobe(
                'chooseTimeSlot',
                'transportServices',
                Capacitor.getPlatform()
            );
        }
    }, [
        reserveTimeSlot.data,
        reserveTimeSlot.error,
        reserveTimeSlot.isError,
        reserveTimeSlot.isSuccess,
        setReservedTimeSlotError,
        setSectionState,
    ]);

    const formatString = 'HH:mm';
    const { timeZone } = useGetApiLocaleConfig();

    const servicesNotOfferedByDealer = getServicesNotOfferedByDealer(
        props.dealer,
        selectedServices
    );

    // some selected services are not offered by the dealer.
    if (0 < servicesNotOfferedByDealer.length) {
        const message = lc.chooseTimeslot.service_not_offered_dealer.replace(
            '{services}',
            servicesNotOfferedByDealer.map((x) => x.name).join(', ')
        );

        return (
            <AlertBanner type="warning" message={message} density="compact" />
        );
    }

    // the dealer offer all services but has no available dates.
    if (props.timeSlots.length < 1) {
        return (
            <DetailText>
                {lc.chooseTimeslot.no_available_slots_for_date}
            </DetailText>
        );
    }

    return (
        <>
            <Heading2Text>{lc.chooseTimeslot.select_time_of_day}</Heading2Text>
            <p>{lc.chooseTimeslot.select_time_of_day_description}</p>
            <TimeSlotsContainer>
                {props.timeSlots.map((x) => (
                    <ActionChip
                        style={{ minWidth: '70px' }}
                        key={x.startTime}
                        onClick={() => {
                            setSelectedTimeSlot(x);
                            reserveTimeSlot.mutate(x, {
                                onError: () => setSelectedTimeSlot(undefined),
                            });
                        }}
                        density={'compact'}
                        disabled={reserveTimeSlot.isPending}
                        loading={
                            selectedTimeSlot &&
                            reserveTimeSlot.isPending &&
                            isSameTimeSlot(selectedTimeSlot, x)
                                ? {
                                      isLoading: true,
                                      loadingText:
                                          lc.chooseTimeslot.reserving_slot,
                                  }
                                : undefined
                        }
                        label={formatInTimeZone(
                            x.startTime,
                            timeZone,
                            formatString
                        )}
                    />
                ))}
            </TimeSlotsContainer>
        </>
    );
}

function getServicesNotOfferedByDealer(
    dealer: DealerViewModel,
    selectedServices: StandardServicesData['selectedServices']
) {
    const servicesNotOfferedByDealer = selectedServices.filter(
        (x) =>
            !x.dealerSpecificInformation.some(
                (y) => y.dealerNumber === dealer.id
            )
    );
    return servicesNotOfferedByDealer;
}

function isSameTimeSlot(a: TimeSlotViewModel, b: TimeSlotViewModel) {
    return JSON.stringify(a) === JSON.stringify(b);
}

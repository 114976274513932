import { Heading1Text, LeadText, TextContainer } from '@gnist/design-system';
import { isMobileApp } from 'src/utils/mobile';
import service from '../../eu-control/eu-control.png';
import {
    FlexContainer,
    RecallServiceContentProps,
} from '../RecallServiceContent';

// Tilbakekalling tannstangsstyring
export const RackAndPinionIssue = ({
    car,
    recall,
}: RecallServiceContentProps) => {
    return (
        <TextContainer>
            <FlexContainer reverseOrder={isMobileApp()}>
                {!isMobileApp() && <img src={service} alt="Tilbakekalling" />}
                <Heading1Text>{`Tilbakekallingsoppgradering ${recall.recallCode}`}</Heading1Text>
            </FlexContainer>
            <LeadText>
                Av hensyn til sikkerheten er det nødvendig å bytte ut
                tannstangstyringen på bilen din.
            </LeadText>
            <p>
                {`
                Fabrikk har registrert at det på ${car.name} fra et bestemt
                produksjonstidsrom kan være montert en tannstangstyring med
                utilstrekkelig korrosjonsbeskyttelse. Dette medfører en risiko
                for at det etter hvert kan oppstå kraftig korrosjon på hele
                tannstangstyringen.`}
            </p>
            <p>
                Avhengig av korrosjonsgraden kan det oppstå sprekker og
                materialbrudd på huset til tannstangstyringen. Dette medfører en
                risiko for at tannstangen mister grepet på tannhjulet. Dette kan
                føre til at styringen ikke virker. Det er fare for ulykker.
                Bestill time for oppgradring så fort som mulig. Vi håper du har
                forståelse for at dette verkstedbesøket er nødvendig og beklager
                de ulemper dette eventuelt medfører.
            </p>
        </TextContainer>
    );
};

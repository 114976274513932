import { DetailText } from '@gnist/design-system';
import { useFlag } from 'feature-toggle';
import { useRef } from 'react';
import { useConfirmBooking } from '../../../_api/http/booking';
import { useGetDealers } from '../../../_api/http/dealers';
import { useGetVehicle } from '../../../_api/http/vehicle';
import { BilholdViewLayout } from '../../../components/bilhold/BilholdView';
import { CancelButton } from '../../../components/bilhold/CancelButton';
import { QueryError } from '../../../components/QueryError';
import { useLanguageContext } from '../../../lib/languages/languageContext';
import { Done } from '../../sectionUtils';
import { parseServicesPrices, SumPrice } from '../../shared/prices/SumPrice';
import { BookingProgress } from '../confirm-booking-loading/BookingProgress';
import {
    ConfirmBookingDependencies,
    ConfirmButton,
} from '../ConfirmBookingSection';
import { ConfirmBookingEditProps } from './ConfirmBookingEdit';
import { FlexRowConfirmButtons } from './ConfirmBookingWithoutDoneDependencies';
import {
    createBookingBody,
    getBusinessOperation,
    toBookedService,
} from './createBookingBody';
import { createGUID } from './createGuid';

type ConfirmBookingWithDependenciesProps = ConfirmBookingEditProps & {
    dependencies: Done<ConfirmBookingDependencies>;
};

export function ConfirmBookingWithDoneDependencies({
    dependencies,
    invalidateAppointmentsQuery,
    ...props
}: ConfirmBookingWithDependenciesProps) {
    const confirmButtonRef = useRef<HTMLButtonElement | null>(null);
    const shouldShowWaitingAnimationsToggle = useFlag(
        'ShowWaitingAnimationsForBooking'
    );

    const registrationNumber =
        dependencies.chooseVehicle.data.registrationNumber;
    const mileage = dependencies.standardServices.data.mileage;
    const timeslot = dependencies.chooseTimeSlot.data.reservedTimeSlot;
    const dealerNumber = dependencies.chooseDealer.data.dealerNumber;
    const selectedServices = [
        ...dependencies.standardServices.data.selectedServices,
        ...dependencies.additionalServices.data.selectedServices,
        ...dependencies.transportServices.data.selectedServices,
    ];

    function onSuccessBooking() {
        invalidateAppointmentsQuery();

        props.setSectionState({
            viewMode: 'done',
            data: null,
        });
    }

    const dealerResponse = useGetDealers();
    const dealerMail = dealerResponse.data?.find(
        (x) => x.id === dealerNumber
    )?.email;

    const confirmBooking = useConfirmBooking(onSuccessBooking, dealerMail);
    const [lc] = useLanguageContext();

    const vehicleResponse = useGetVehicle(registrationNumber);

    //  This should not be true due to caching
    if (vehicleResponse.isPending) {
        return (
            <FlexRowConfirmButtons>
                <CancelButton />
                <ConfirmButton isLoading={true} hidden />
            </FlexRowConfirmButtons>
        );
    }

    if (vehicleResponse.isError) {
        return (
            <QueryError
                isError={vehicleResponse.isError}
                error={vehicleResponse.error}
            />
        );
    }
    const vin = vehicleResponse.data?.vin;

    const bookingId = timeslot?.bookingId ?? createGUID();
    const adapterId = dependencies.chooseDealer.data.adapterId;
    const bookingType = props.isBookingRequest ? 'Request' : 'Scheduled';

    return (
        <BilholdViewLayout>
            {confirmBooking.isPending && shouldShowWaitingAnimationsToggle && (
                <BookingProgress />
            )}
            {selectedServices && (
                <SumPrice
                    values={parseServicesPrices(selectedServices, dealerNumber)}
                    finalSummary
                />
            )}
            <DetailText>{lc.prices.disclaimer_price_material}</DetailText>
            <FlexRowConfirmButtons>
                <CancelButton />
                <ConfirmButton
                    onClick={() => {
                        confirmBooking.mutate(
                            createBookingBody({
                                vin,
                                bookingId,
                                adapterId,
                                dealerNumber,
                                bookedServices:
                                    selectedServices.map(toBookedService),
                                businessOperation: getBusinessOperation(
                                    timeslot,
                                    dealerNumber,
                                    selectedServices
                                ),
                                isWaiting:
                                    dependencies.transportServices.data
                                        .isWaiting,
                                startTime: timeslot?.startTime,
                                mileage,
                                bookingType: bookingType,
                            })
                        );
                    }}
                    isLoading={confirmBooking.isPending}
                    ref={confirmButtonRef}
                />
            </FlexRowConfirmButtons>
            <QueryError
                isError={confirmBooking.isError}
                error={confirmBooking.error}
            />
        </BilholdViewLayout>
    );
}

import { datadogRum } from '@datadog/browser-rum';
import { useMutation } from '@tanstack/react-query';
import getCurrentEnvironment from 'src/utils/environment/getCurrentEnvironment';
import { ORDER_ACCESSORIES } from 'src/utils/http/endpoints';
import post from 'src/utils/http/post';

interface OrderAccessoriesData {
    dealerEmail: string;
    customerEmail: string;
    contractId: string;
    brand: string;
    model: string;
    firstName: string;
    lastName: string;
    dealerNumber: string;
    sellerName: string;
    vin: string;
    partyId: number;
    accessories: {
        accessoryName: string;
        price: string;
        nuPackage: string;
    }[];
    contractDate: string;
}

export const useOrderAccessories = (onSuccess: () => void) => {
    const { bilholdApiUrl, name: env } = getCurrentEnvironment();
    return useMutation({
        mutationFn: (accessoriesData: OrderAccessoriesData) =>
            post(`${bilholdApiUrl}/${ORDER_ACCESSORIES}`, {
                dealerEmail:
                    env === 'prod'
                        ? accessoriesData.dealerEmail
                        : 'jonas.lochsen@moller.no',
                customerEmail:
                    env === 'prod'
                        ? accessoriesData.customerEmail
                        : 'jonas.lochsen@moller.no',
                contractId: accessoriesData.contractId,
                brand: accessoriesData.brand,
                model: accessoriesData.model,
                firstName: accessoriesData.firstName,
                lastName: accessoriesData.lastName,
                dealerNumber: accessoriesData.dealerNumber,
                sellerName: accessoriesData.sellerName,
                vin: accessoriesData.vin,
                partyId: accessoriesData.partyId,
                accessories: accessoriesData.accessories,
            }),

        onSuccess: (_, accessoriesData) => {
            datadogRum.addAction('Accessory ordered from Bilhold.', {
                WPcontractId: accessoriesData.contractId,
                WPcarBrand: accessoriesData.brand,
                WPdealer: accessoriesData.dealerNumber,
                WPproduct: accessoriesData.accessories[0].accessoryName, //TODO handle multiple products when possible
                WPprice: accessoriesData.accessories[0].price,
                WPcontractDate: accessoriesData.contractDate,
            });
            onSuccess();
        },
        onError: () => {
            return true;
        },
    });
};

import { datadogRum } from '@datadog/browser-rum';
import {
    Accordion,
    AlertBanner,
    breakpointMobile,
    Column,
    DetailText,
    Heading1Text,
    Heading3Text,
    IconButton,
    Row,
    TextContainer,
} from '@gnist/design-system';
import { useNavigate } from 'react-router-dom';

import { Divider } from '@mui/material';
import { useToggle } from 'feature-toggle';
import { PropsWithChildren } from 'react';
import { OTHER_SERVICES } from 'src/constants';
import {
    getMessageWithAppointmentId,
    partialAppointmentGuidToSafeNumberId,
} from 'src/features/contact/utils/messagesUtils';
import { EuControlCampaignAccordion } from 'src/features/home/current-actions/eu-control/EuControlCampaignAccordion';
import { useCarInformation } from 'src/features/home/queries/useCarInformation';
import { ContactItem } from 'src/shared/contact-item/ContactItem';
import addToCalendar from 'src/utils/calendar/addToCalendar';
import { dealerIsUpdatingSystems } from 'src/utils/dealerIsUpdatingSystems';
import useFeatureToggle from 'src/utils/dev-tools/useFeatureToggle';
import FuelType from 'src/utils/enums/FuelType';
import { formatDealerName } from 'src/utils/formatDealerName';
import { useMessageThreads } from 'src/utils/hooks/useMessageThreads';
import lang from 'src/utils/lang';
import { formatTime, getFormatDateWithDay } from 'src/utils/time/timeUtils';
import { styled } from 'styled-components';
import { BookingViewModel } from '../../api/useAppointments';

const InformationContainer = styled(Column)`
    width: 70%;
    @media screen and (max-width: ${breakpointMobile}) {
        width: 100%;
    }
`;

const StyledInformationBlock = styled(Column)`
    width: 100%;
    padding: var(--moller-spacing-s) 0px;
    p {
        margin-top: 0px;
    }
`;

const StyledAccordionsContainer = styled(Column)`
    margin-top: var(--moller-spacing-l);
`;

const StyledDescription = styled(DetailText)`
    color: var(--moller-color-on-surface-variant);
`;

const TimeInformation = styled(Row)`
    width: 100%;
    ${Column.selector} {
        width: 50%;
    }
`;

const CalendarButton = styled(IconButton)`
    margin-left: -4px;
    margin-top: 8px;
`;

const StyledAccordion = styled(Accordion)`
    summary {
        padding-left: 0;
        &:focus {
            outline: none;
        }
    }
    div {
        padding: 0;
    }
    p {
        margin-top: 0;
    }
`;

const StyledList = styled.ul`
    padding-left: var(--moller-spacing-l);
`;

interface InformationBlockProps {
    headline: string;
}

const InformationBlock = ({
    headline,
    children,
}: PropsWithChildren<InformationBlockProps>) => {
    return (
        <StyledInformationBlock>
            <Heading3Text>{headline}</Heading3Text>
            {children}
        </StyledInformationBlock>
    );
};

const chargeableFuelTypes =
    FuelType.ELECTRIC || FuelType.DIESEL_HYBRID || FuelType.PETROL_HYBRID;

interface InformationProps {
    appointment: BookingViewModel;
}

export const Information = ({ appointment }: InformationProps) => {
    const ShowSystemUpgradeInfo = useFeatureToggle('ShowSystemUpgradeInfo');
    const navigate = useNavigate();

    const messageFunctionality = useToggle<string[]>(
        'DealersWithMessageFunctionality'
    );
    const dealersWithMessageFunctionality =
        messageFunctionality?.variation.value ?? ([] as string[]);

    const appointmentDate =
        appointment.startTime && getFormatDateWithDay(appointment.startTime);

    const isEuControl = appointment.bookedServices?.find((service) =>
        service.name.includes('EU-kontroll')
    );
    const isOtherService = appointment.bookedServices?.find(
        (service) => service.id === OTHER_SERVICES
    );
    const { data: technicalDetails } = useCarInformation(appointment.vin);

    const { data: messages } = useMessageThreads();

    const appointmentIdAsNumber = partialAppointmentGuidToSafeNumberId(
        appointment.id
    );

    const earlierSentMessage = getMessageWithAppointmentId(
        messages,
        appointmentIdAsNumber
    );

    const isServiceRequiresCharger = appointment.bookedServices?.some(
        (service) =>
            // Names and ids in service catalog are not consistent yet, so checking name instead of id
            service.name.includes('service') && !service.name.includes('klima')
    );

    const hasCarUsesCharger =
        technicalDetails?.fuelType === chargeableFuelTypes;

    const shouldShowChargerBanner =
        hasCarUsesCharger && (isServiceRequiresCharger || isEuControl);

    /*
        Even though we have turned off message in unleash for certain dealers, 
        we want to allow users who have started messages to easily see shortcut to started threads
    */
    const shouldShowSendMessage =
        !!earlierSentMessage ||
        dealersWithMessageFunctionality.includes(appointment.dealer.id);

    function handleNavigateToMessage() {
        datadogRum.addAction('bilhold-send-message-information');

        if (earlierSentMessage) {
            navigate(
                `/messages/messages-contact/thread/${earlierSentMessage.threadId}`
            );
        } else {
            navigate(`/messages/messages-contact/new/${appointment.id}`);
        }
    }
    const isScheduledAppointment = appointment.type === 'Scheduled';

    const appointmentHeader = isScheduledAppointment
        ? lang.future_appointment
        : lang.requestedAppointment;

    const bookedServicesTitle = isScheduledAppointment
        ? lang.services
        : lang.requestedServices;

    return (
        <InformationContainer>
            <Heading1Text>{appointmentHeader}</Heading1Text>
            <InformationBlock headline={lang.workshop}>
                <p>{formatDealerName(appointment.dealer?.name ?? '')}</p>
                <Column gap="xxs">
                    <StyledDescription>
                        {appointment.dealer?.streetAddress ?? ''},{' '}
                        {appointment.dealer?.zipCode ?? ''}{' '}
                        {appointment.dealer?.city ?? ''}
                    </StyledDescription>
                    <Row gap="xxs" flexWrap="wrap">
                        {shouldShowSendMessage && (
                            <IconButton
                                icon="chat"
                                label={
                                    earlierSentMessage
                                        ? 'Fortsett samtale'
                                        : 'Send melding'
                                }
                                showLabel="right"
                                onClick={handleNavigateToMessage}
                            />
                        )}
                        {appointment.dealer?.phoneNumber && (
                            <ContactItem
                                phoneLabel="Ring verksted"
                                phoneNumber={appointment.dealer.phoneNumber}
                            />
                        )}
                        {appointment.dealer?.email && (
                            <IconButton
                                $as="a"
                                icon="mail"
                                href={`mailto:${appointment.dealer.email}`}
                                label={appointment.dealer.email}
                                showLabel="right"
                            />
                        )}
                    </Row>
                </Column>
            </InformationBlock>
            {isScheduledAppointment && appointmentDate && (
                <>
                    <Divider />
                    <InformationBlock headline={lang.date}>
                        <p>
                            {appointmentDate.substring(
                                0,
                                appointmentDate.lastIndexOf(' ')
                            )}
                        </p>
                        <CalendarButton
                            icon="calendar"
                            label="Legg i kalender"
                            showLabel="right"
                            onClick={() => void addToCalendar(appointment)}
                        />
                    </InformationBlock>
                    <Divider />
                </>
            )}
            {isScheduledAppointment && appointment.startTime && (
                <TimeInformation>
                    <InformationBlock headline={lang.appointmentTime}>
                        <p>{formatTime(appointment.startTime)}</p>
                    </InformationBlock>
                    {appointment.estimatedFinished && (
                        <>
                            <Divider
                                orientation="vertical"
                                variant="middle"
                                style={{
                                    height: 'auto',
                                    marginRight: 'var(--moller-spacing-s)',
                                }}
                            />
                            {!isOtherService && (
                                <InformationBlock
                                    headline={lang.estimatedWorkshopTimeTitle}
                                >
                                    <p>
                                        {formatTime(
                                            appointment.estimatedFinished
                                        )}
                                    </p>
                                </InformationBlock>
                            )}
                        </>
                    )}
                </TimeInformation>
            )}
            <Divider />
            {appointment.bookedServices &&
                appointment.bookedServices.length > 0 && (
                    <StyledInformationBlock>
                        <Heading3Text>{bookedServicesTitle}</Heading3Text>
                        {appointment.bookedServices.map(
                            (service) =>
                                service && (
                                    <StyledList key={service.name}>
                                        <li>
                                            {service.name.replace(
                                                /[^a-zA-ZÆØÅæøå0-9+.,/:\-() ]/g,
                                                ''
                                            )}
                                        </li>
                                    </StyledList>
                                )
                        )}
                    </StyledInformationBlock>
                )}
            <StyledAccordionsContainer gap="xs">
                {!isScheduledAppointment && (
                    <>
                        <WhatIsRequestedBookingAccordion />
                        <RequestedBookingConfirmationAccordion />
                    </>
                )}
                {dealerIsUpdatingSystems(appointment.dealer?.name) &&
                    ShowSystemUpgradeInfo && (
                        <AlertBanner
                            type="info"
                            message={lang.delaysDueToNewSystems}
                        />
                    )}
                {shouldShowChargerBanner && (
                    <AlertBanner
                        type="info"
                        heading={lang.rememberChargingCableHeading}
                        message={lang.rememberChargingCableDescription}
                    />
                )}
                {isEuControl && <EuControlCampaignAccordion />}
            </StyledAccordionsContainer>
        </InformationContainer>
    );
};

const WhatIsRequestedBookingAccordion = () => {
    return (
        <StyledAccordion head={lang.bookingRequestExplanationTitle}>
            <TextContainer>
                <p>{lang.bookingRequestExplanation}</p>
            </TextContainer>
        </StyledAccordion>
    );
};

const RequestedBookingConfirmationAccordion = () => {
    return (
        <StyledAccordion head={lang.bookingRequestConfirmationInfoTitle}>
            <TextContainer>
                <p>{lang.bookingRequestConfirmationInfo}</p>
            </TextContainer>
        </StyledAccordion>
    );
};

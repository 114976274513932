import { styled } from 'styled-components';

// TODO: change to design-system component when it is done
// TODO: Do this todo^^^
export const CarAlert = styled.p`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    width: 100%;
    background-color: var(--moller-color-info-container);
    color: var(--moller-color-on-info-container);
    border-top: 1px solid var(--moller-color-info);
    padding: var(--moller-spacing-base) var(--moller-spacing-xxs);
    align-items: center;
    gap: var(--moller-spacing-base);
    font-size: var(--moller-type-medium-detail-font-size);
    line-height: 1.5;
`;

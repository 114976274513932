import { Camera, CameraResultType } from '@capacitor/camera';
import { datadogRum } from '@datadog/browser-rum';
import {
    AlertBanner,
    Column,
    Heading2Text,
    Icon,
    IconButton,
    InfoCard,
    Modal,
    Row,
    SecondaryButton,
    TextArea,
} from '@gnist/design-system';
import { ExistingCarRelation } from 'external-apis/src/types/bilhold';
import { DamageAppraisal } from 'external-apis/src/types/damageappraisal';
import { RefObject, useEffect, useRef, useState } from 'react';
import { useUpdateDamageAppraisal } from 'src/features/damage/utils/useDamageAppraisals';
import { ddRumPrivacySetting } from 'src/utils/datadog';
import lang from 'src/utils/lang';
import { css, styled } from 'styled-components';
import { NextStepButton, SectionTitle } from '../Damage';
import { hasValidImages } from '../utils/damageValidation';
import {
    ImageType,
    useDeleteMedia,
    useGetMedia,
    useUploadMedia,
} from '../utils/useMedia';

export const StyledImage = styled.img`
    height: 200px;
    width: 200px;
    object-fit: cover;
    ${({ onClick }) =>
        onClick &&
        css`
            cursor: pointer;
        `}
`;

const StyledParagraph = styled.p`
    margin-bottom: var(--moller-spacing-base);
`;

const UploadImage = ({
    tag,
    images,
    damageId,
}: {
    tag: string;
    images?: ImageType[];
    damageId: number;
}) => {
    const { mutate: uploadMedia, isPending: isSubmitting } =
        useUploadMedia(damageId);

    const takePicture = async () => {
        const image = await Camera.getPhoto({
            resultType: CameraResultType.Base64,
            saveToGallery: true,
            width: 1280,
            height: 1280,
            promptLabelHeader: lang.photoUploadImage,
            promptLabelCancel: lang.photoPromptLabelCancel,
            promptLabelPhoto: lang.photoPromptLabelPhoto,
            promptLabelPicture: lang.photoPromptLabelPicture,
        });
        if (!image.base64String) {
            throw new Error(
                'File upload using @capacitor/camera failed because image data base64 string was empty.'
            );
        }
        uploadMedia({ tag: tag, imageData: image.base64String });
    };

    return (
        <>
            <Column gap="xxs">
                <SecondaryButton
                    onClick={takePicture}
                    loading={{
                        isLoading: isSubmitting,
                        loadingText: 'laster bilde',
                    }}
                >
                    <Icon icon="file_upload" />
                    {lang.photoUploadImage}
                </SecondaryButton>
                <Row gap={'m'} flexWrap="wrap">
                    {images?.map((image) => (
                        <ImageWithDelete
                            key={image.id}
                            tag={tag}
                            damageId={damageId}
                            image={image}
                        />
                    ))}
                </Row>
            </Column>
        </>
    );
};

interface DetailsProps {
    damage: DamageAppraisal;
    detailsStepDone: boolean;
    setDetailsStepDone: (value: boolean) => void;
    car?: ExistingCarRelation;
    headingRef: RefObject<HTMLHeadingElement>;
}

const DamageDetails = ({
    damage,
    detailsStepDone,
    setDetailsStepDone,
    car,
    headingRef,
}: DetailsProps) => {
    const { mutate: updateDamage, isError } = useUpdateDamageAppraisal(
        damage.id
    );
    const datadogSent = useRef(false);
    const [changedDesc, setChangedDesc] = useState(damage?.description ?? '');
    const { data: initialImages } = useGetMedia(damage?.id);
    const isStepInvalid = !damage.description || !hasValidImages(initialImages);
    useEffect(() => {
        if (isStepInvalid) {
            setDetailsStepDone(false);
        }
    }, [isStepInvalid, setDetailsStepDone]);

    if (car && !datadogSent.current) {
        datadogRum.addAction('Damage details');
        datadogSent.current = true;
    }

    return (
        <div {...ddRumPrivacySetting('allow')}>
            <Heading2Text ref={headingRef}>
                {lang.damageDetailsTitle}
            </Heading2Text>
            {damage && damage.id ? (
                <Column gap="m">
                    <div>
                        <div>{lang.damageDetailsDescription}</div>
                        <TextArea
                            label={lang.damageDescription}
                            helperText={lang.damageDescriptionPlaceholder}
                            value={changedDesc}
                            onChange={(e) =>
                                setChangedDesc(e.target.value.trimStart())
                            }
                            maxLength={4000}
                            onBlur={() => {
                                if (damage.description !== changedDesc) {
                                    updateDamage({
                                        description: changedDesc,
                                    });
                                }
                            }}
                        />
                    </div>
                    <Column gap="base">
                        <SectionTitle>{lang.imagesOfDamage}</SectionTitle>
                        <StyledParagraph>
                            {lang.closeupImagesDescription}
                        </StyledParagraph>
                        <UploadImage
                            tag="car-closeup"
                            images={initialImages?.filter(
                                (img) => img.tag === 'car-closeup'
                            )}
                            damageId={damage?.id}
                        />
                    </Column>
                    <Column gap="base">
                        <SectionTitle>{lang.imageOverview}</SectionTitle>
                        <StyledParagraph>
                            {lang.overviewImagesDescription}
                        </StyledParagraph>
                        <UploadImage
                            tag="car-overview"
                            images={initialImages?.filter(
                                (img) => img.tag === 'car-overview'
                            )}
                            damageId={damage?.id}
                        />
                    </Column>
                    <Column gap="base">
                        <SectionTitle>{lang.mileageImagesTitle}</SectionTitle>
                        <StyledParagraph>
                            {lang.mileageImagesDescription}
                        </StyledParagraph>
                        <UploadImage
                            tag="car-mileage"
                            images={initialImages?.filter(
                                (img) => img.tag === 'car-mileage'
                            )}
                            damageId={damage?.id}
                        />
                    </Column>
                    {!detailsStepDone && (
                        <NextStepButton
                            disabled={isStepInvalid}
                            setStepDone={setDetailsStepDone}
                        />
                    )}
                </Column>
            ) : car && !damage?.id ? (
                <InfoCard isLoading />
            ) : (
                <p>Du må velge bil før du kan beskrive skaden</p>
            )}
            {isError && (
                <AlertBanner type="error" message={lang.damageGeneralError} />
            )}
        </div>
    );
};

const AccessibleFullscreenButton = styled(IconButton)`
    position: absolute;
    top: 0;
    right: 0;
    background: var(--moller-color-background);
    opacity: 0;
    &:focus {
        opacity: 1;
    }
`;
const ImgContainer = styled.div`
    width: 100%;
`;

const BigImage = styled.img`
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: calc(100% - 12px);
`;

const RelativeColumn = styled(Column)`
    position: relative;
`;

export default DamageDetails;

const ImageWithDelete = ({
    image,
    tag,
    damageId,
}: {
    image: ImageType;
    tag: string;
    damageId: number;
}) => {
    const [isFullscreen, setIsFullscreen] = useState(false);
    const { mutate: deleteMedia, isPending: isDeleting } =
        useDeleteMedia(damageId);
    return (
        <InfoCard density="compact">
            <RelativeColumn key={image.id} gap="xxs">
                <StyledImage
                    src={image.dataUrl}
                    alt={tag}
                    onClick={
                        // This onClick handler is not accessible for screen readers or keyboard
                        // navigation, but this is handled by AccessibleFullscreenButton below.
                        isDeleting ? undefined : () => setIsFullscreen(true)
                    }
                />
                <AccessibleFullscreenButton
                    icon="open"
                    label="se i fullskjerm"
                    disabled={isDeleting}
                    onClick={() => setIsFullscreen(true)}
                />
                <IconButton
                    icon="trash"
                    label="Slett"
                    showLabel="right"
                    disabled={isDeleting}
                    onClick={() => deleteMedia({ id: image.id })}
                />
                <Modal
                    isOpen={isFullscreen}
                    size="large"
                    handleClose={() => setIsFullscreen(false)}
                >
                    <ImgContainer>
                        <BigImage src={image.dataUrl} alt={tag} />
                    </ImgContainer>
                </Modal>
            </RelativeColumn>
        </InfoCard>
    );
};

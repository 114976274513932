import { datadogRum } from '@datadog/browser-rum';
import { useMutation } from '@tanstack/react-query';
import getCurrentEnvironment from 'src/utils/environment/getCurrentEnvironment';
import { SEND_PRODUCT_REQUEST } from 'src/utils/http/endpoints';
import post from 'src/utils/http/post';

export interface ProductRequestData {
    dealerEmail: string;
    contractId: string;
    brand: string;
    model: string;
    firstName: string;
    dealerNumber: string;
    vin: string;
    partyId: number;
    customProductRequest: string;
}

export const useSendProductRequest = () => {
    const { bilholdApiUrl, name: env } = getCurrentEnvironment();
    return useMutation({
        mutationFn: (productRequestData: ProductRequestData) =>
            post(`${bilholdApiUrl}/${SEND_PRODUCT_REQUEST}`, {
                dealerEmail:
                    env === 'prod'
                        ? productRequestData.dealerEmail
                        : 'jonas.lochsen@moller.no',
                contractId: productRequestData.contractId,
                brand: productRequestData.brand,
                model: productRequestData.model,
                firstName: productRequestData.firstName,
                dealerNumber: productRequestData.dealerNumber,
                vin: productRequestData.vin,
                partyId: productRequestData.partyId,
                customProductRequest: productRequestData.customProductRequest,
            }),

        onSuccess: (_, productRequestData) => {
            datadogRum.addAction('Product request sent from Bilhold.', {
                WPCcontractId: productRequestData.contractId,
                WPCcarBrand: productRequestData.brand,
                WPCdealer: productRequestData.dealerNumber,
                WPCproduct: productRequestData.customProductRequest,
            });
        },

        onError: () => {
            return true;
        },
    });
};

import { InputFieldValidity, InputHelperText } from '@gnist/design-system';
import { styled } from 'styled-components';
import { FlexRow } from '../FlexRow';

type BilholdErrorTextProps = {
    id: string;
    validity?: InputFieldValidity<{ errorMsgRequired: false }>;
};

const BilholdErrorTextComponent = styled.div`
    color: var(--moller-color-error);
`;

const CustomFlexRow = styled(FlexRow)`
    color: var(--moller-color-error);
    justify-content: start;
    align-items: center;
`;

export const BilholdErrorText: React.FC<BilholdErrorTextProps> = ({
    id,
    validity,
}) => {
    return (
        <BilholdErrorTextComponent>
            {validity?.type === 'error' && validity.message && (
                <CustomFlexRow>
                    <InputHelperText
                        {...{ id }}
                        validity={{
                            type: validity.type,
                            message: validity.message,
                        }}
                    />
                </CustomFlexRow>
            )}
        </BilholdErrorTextComponent>
    );
};

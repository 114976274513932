import {
    BodyText,
    Column,
    InfoCard,
    DetailText,
    Link,
    Row,
    Heading2Text,
} from '@gnist/design-system';
import { TDFTextLarge } from 'src/features/competition/textComponents/TDFText';
import { styled } from 'styled-components';
import { CompetitionFinishedContent } from './CompetitionFinishedContent';
import { StageProgressBar } from './StageProgressBar';
import { Stages } from './Stages';

const WrapperCol = styled(Column)<{ disable: boolean }>`
    width: 100%;
    opacity: ${(props) => (props.disable ? 0.4 : 1)};
`;

const Divider = styled.hr`
    border-width: 0.5px;
    border-color: #5a5b5c;
    margin-top: var(--moller-spacing-l);
    margin-bottom: var(--moller-spacing-s);
`;

const CustomAccordion = styled.details`
    summary {
        list-style: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 var(--moller-spacing-base);
        border-bottom: none;
    }

    &:last-child summary {
        border: none;
    }

    summary::after {
        content: '';
        width: 0;
        height: 0;
        border-top: 10px solid #ffffff;
        border-inline: 7px solid transparent;
        transition: 0.2s;
    }
    &[open] summary::after {
        transform: rotate(180deg);
    }
`;

interface Props {
    isDisabled: boolean;
    currentKm: number;
    maxKm: number;
    stravaAthleteId: number | undefined;
    currentStage: number;
    end: string;
    start: string;
    totalKmOfAllStages: number;
    totalKm: number;
}

export const CompetitionStageCard = ({
    isDisabled,
    currentKm,
    maxKm,
    stravaAthleteId,
    currentStage,
    end,
    start,
    totalKmOfAllStages,
    totalKm,
}: Props) => {
    const hasFinishedTour = totalKm >= totalKmOfAllStages;
    return (
        <WrapperCol disable={isDisabled} verticalAlign={'start'} gap={'base'}>
            <TDFTextLarge>Din tour</TDFTextLarge>
            <InfoCard>
                {hasFinishedTour ? (
                    <CompetitionFinishedContent />
                ) : (
                    <Column gap={'base'}>
                        <Row horizontalAlign="spaceBetween"></Row>
                        <Column gap={'base'}>
                            <Row
                                horizontalAlign="spaceBetween"
                                style={{
                                    marginBottom: 'var(--moller-spacing-s)',
                                }}
                            >
                                <Heading2Text>{`${start} til ${end}`}</Heading2Text>
                                <DetailText>{`${
                                    currentStage + 1
                                }. etappe`}</DetailText>
                            </Row>
                        </Column>
                    </Column>
                )}

                <StageProgressBar
                    currentKm={currentKm}
                    maxKm={maxKm}
                    end={end}
                    start={start}
                    currentStage={currentStage}
                />
                <Divider />
                <CustomAccordion>
                    <summary>Etappeoversikt</summary>
                    <Stages currentStage={currentStage} totalKm={totalKm} />
                </CustomAccordion>
            </InfoCard>
            <BodyText style={{ color: '#fff', textAlign: 'right' }}>
                {stravaAthleteId && (
                    <Link
                        href={`https://www.strava.com/athletes/${stravaAthleteId}`}
                        target="_blank"
                    >
                        Se data på Strava
                    </Link>
                )}
            </BodyText>
        </WrapperCol>
    );
};

import { Heading2Text } from '@gnist/design-system';
import { Divider } from '@mui/material';
import {
    CarContractDealer,
    CarContractSeller,
} from 'external-apis/src/types/bilhold';
import DealerContact from 'src/features/my-ordered-car/components/DealerContact';
import { ddRumPrivacySetting } from 'src/utils/datadog';
import lang from 'src/utils/lang';
import { styled } from 'styled-components';

interface DealerSectionProps {
    readonly dealer: CarContractDealer;
    readonly seller?: CarContractSeller;
}

const FutureCarPageContainer = styled.div`
    margin-top: var(--moller-spacing-m);
`;
const SubTitleWrapper = styled.div`
    margin-top: var(--moller-spacing-s);
`;
const StyledDivider = styled(Divider)`
    padding-top: var(--moller-spacing-s);
    border-color: var(--moller-color-outline) !important;
`;

const DealerSection = ({ seller, dealer }: DealerSectionProps) => {
    return (
        <FutureCarPageContainer>
            <Heading2Text>
                <b>{lang.myDealer}</b>
            </Heading2Text>
            <div>
                {seller && (
                    <div {...ddRumPrivacySetting('mask')}>
                        <SubTitleWrapper>
                            <b>{seller.fullName}</b>
                        </SubTitleWrapper>
                        <DealerContact
                            phoneNumber={seller.phoneNumber}
                            email={seller.email}
                        />
                        <StyledDivider />
                    </div>
                )}
                {dealer && (
                    <>
                        <SubTitleWrapper>
                            <b>{dealer.name}</b>
                        </SubTitleWrapper>
                        <DealerContact
                            dealerName={dealer.name}
                            address={dealer.streetAddress}
                            postalArea={dealer.city}
                            phoneNumber={dealer.phoneNumber}
                            email={dealer.email}
                        />
                    </>
                )}
            </div>
        </FutureCarPageContainer>
    );
};

export default DealerSection;

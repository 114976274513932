import {
    runValidation,
    validRegistrationNumber,
} from '@gnist/design-system/utilities/validation';
import { useEffect, useState } from 'react';
import { isAnnataAdapter } from './_api/adapterUtils';
import { useGetRegisteredVehicles } from './_api/http/vehicle';
import { useAppConfig } from './lib/config/configContext';
import { useFastTrack } from './lib/useFastTrack';
import { useAdditionalServices } from './sections/additional-services/AdditionalServicesSection';
import { useChooseDealer } from './sections/choose-dealer/ChooseDealerSection';
import { useChooseTimeSlot } from './sections/choose-timeslot/ChooseTimeslotSection';
import { useChooseVehicle } from './sections/choose-vehicle/ChooseVehicleSection';
import { useConfirmBooking } from './sections/confirm-booking/ConfirmBookingSection';
import {
    StandardServicesData,
    useStandardServices,
} from './sections/standard-services/useStandardServices';
import { useTransportServices } from './sections/transport-services/TransportServicesSection';

export type SetIsBookingRequest = {
    services: StandardServicesData['selectedServices'];
};

function hasSelectedRequestServices(
    selectedServices: StandardServicesData['selectedServices']
) {
    return selectedServices.some((x) => x.bookingType === 'Request');
}

function hasOnlySelectedRecommendedServices(
    selectedServices: StandardServicesData['selectedServices']
) {
    const onlyRecommendedServices = selectedServices.every(
        (x) =>
            x.serviceCategory === 'RecommendedService' &&
            x.bookingType === 'Scheduled'
    );

    if (onlyRecommendedServices) {
        return true;
    }

    return false;
}

function hasSelectedMultipleScheduledServices(
    selectedServices: StandardServicesData['selectedServices']
) {
    const onlySelectedRecommendedServices =
        hasOnlySelectedRecommendedServices(selectedServices);
    if (onlySelectedRecommendedServices) {
        return false;
    }

    const scheduledServices = selectedServices.filter(
        (x) => x.bookingType === 'Scheduled'
    );

    return scheduledServices.length > 1;
}

function isBookingRequest(
    selectedServices: StandardServicesData['selectedServices']
) {
    return (
        hasSelectedRequestServices(selectedServices) ||
        hasSelectedMultipleScheduledServices(selectedServices)
    );
}

function determineIsBookingRequest({ services }: SetIsBookingRequest) {
    const isAnnataBooking = services.some((x) => isAnnataAdapter(x?.adapterId));

    return isAnnataBooking && isBookingRequest(services);
}

if (import.meta.vitest) {
    const { it, expect } = import.meta.vitest;

    const scheduledService: StandardServicesData['selectedServices'][number] = {
        serviceId: '1',
        adapterId: 'annata-adapter',
        serviceCategory: 'EuTest',
        serviceType: 'Standard',
        dealerSpecificInformation: [],
        name: 'EU-kontroll',
        bookingType: 'Scheduled',
    };

    const requestService: StandardServicesData['selectedServices'][number] = {
        serviceId: '1',
        adapterId: 'annata-adapter',
        serviceCategory: 'GlassDamage',
        serviceType: 'Standard',
        dealerSpecificInformation: [],
        name: 'Glasskade',
        bookingType: 'Request',
    };

    const scheduledServices: StandardServicesData['selectedServices'] = [
        {
            serviceId: '1',
            adapterId: 'annata-adapter',
            serviceCategory: 'EuTest',
            serviceType: 'Standard',
            dealerSpecificInformation: [],
            name: 'EU-kontroll',
            bookingType: 'Scheduled',
        },
        {
            serviceId: '1',
            adapterId: 'annata-adapter',
            serviceCategory: 'RecommendedService',
            name: 'Recommended service',
            serviceType: 'Standard',
            dealerSpecificInformation: [],
            bookingType: 'Scheduled',
        },
    ];

    const recommendedServices: StandardServicesData['selectedServices'] = [
        {
            serviceId: '1',
            adapterId: 'annata-adapter',
            serviceCategory: 'RecommendedService',
            name: 'Recommended service',
            serviceType: 'Standard',
            dealerSpecificInformation: [],
            bookingType: 'Scheduled',
        },
        {
            serviceId: '1',
            adapterId: 'annata-adapter',
            serviceCategory: 'RecommendedService',
            name: 'Recommended service',
            serviceType: 'Standard',
            dealerSpecificInformation: [],
            bookingType: 'Scheduled',
        },
    ];

    it.concurrent(
        'hasSelectedRequestServices - standard scheduled service',
        () => {
            const actual = hasSelectedRequestServices([scheduledService]);
            expect(actual).toBe(false);
        }
    );
    it.concurrent(
        'hasSelectedRequestServices - standard request service',
        () => {
            const actual = hasSelectedRequestServices([requestService]);
            expect(actual).toBe(true);
        }
    );
    it.concurrent(
        'hasSelectedMultipleScheduledServices - scheduled recommended service and standard scheduled service',
        () => {
            const actual =
                hasSelectedMultipleScheduledServices(scheduledServices);
            expect(actual).toBe(true);
        }
    );
    it.concurrent(
        'hasSelectedMultipleScheduledServices - standard scheduled service',
        () => {
            const actual = hasSelectedMultipleScheduledServices([
                scheduledService,
            ]);
            expect(actual).toBe(false);
        }
    );
    it.concurrent(
        'hasOnlySelectedRecommendedServices - scheduled recommended services',
        () => {
            const actual =
                hasOnlySelectedRecommendedServices(recommendedServices);
            expect(actual).toBe(true);
        }
    );
    it.concurrent(
        'hasOnlySelectedRecommendedServices - scheduled recommended service and standard scheduled service',
        () => {
            const actual =
                hasOnlySelectedRecommendedServices(scheduledServices);
            expect(actual).toBe(false);
        }
    );
    it.concurrent('isBookingRequest - request service', () => {
        const actual = isBookingRequest([requestService]);
        expect(actual).toBe(true);
    });
    it.concurrent('isBookingRequest - scheduled recommended services', () => {
        const actual = isBookingRequest(recommendedServices);
        expect(actual).toBe(false);
    });
    it.concurrent(
        'isBookingRequest - scheduled recommended services and standard scheduled service',
        () => {
            const actual = isBookingRequest(scheduledServices);
            expect(actual).toBe(true);
        }
    );

    const annataService: StandardServicesData['selectedServices'][number] = {
        serviceId: '1',
        adapterId: 'annata-adapter',
        serviceCategory: 'Tire',
        serviceType: 'Standard',
        dealerSpecificInformation: [],
        name: 'Tire service',
        bookingType: 'Request',
    };
    const recommendedService: StandardServicesData['selectedServices'][number] =
        {
            serviceId: '1',
            adapterId: 'annata-adapter',
            serviceCategory: 'RecommendedService',
            name: 'Recommended service',
            serviceType: 'Standard',
            dealerSpecificInformation: [],
            bookingType: 'Scheduled',
        };
    const mnetService: StandardServicesData['selectedServices'][number] = {
        serviceId: '1',
        adapterId: 'mnet-adapter',
        name: 'Eu test',
        serviceCategory: 'EuTest',
        serviceType: 'Standard',
        dealerSpecificInformation: [],
        bookingType: 'Scheduled',
    };
    it.concurrent(
        'determineIsBookingRequest - standard service with annata dealer',
        () => {
            const actual = determineIsBookingRequest({
                services: [annataService],
            });
            expect(actual).toBe(true);
        }
    );
    it.concurrent(
        'determineIsBookingRequest - recommended service with annata dealer',
        () => {
            const actual = determineIsBookingRequest({
                services: [recommendedService],
            });
            expect(actual).toBe(false);
        }
    );
    it.concurrent(
        'determineIsBookingRequest - standard service with mnet dealer',
        () => {
            const actual = determineIsBookingRequest({
                services: [mnetService],
            });
            expect(actual).toBe(false);
        }
    );
}

export function useBookingState(
    setInterruptUpdateTimestamp: React.Dispatch<React.SetStateAction<number>>
) {
    const config = useAppConfig();
    const { registrationNumber, vin } = config.bookingParameters;
    const vehicles = useGetRegisteredVehicles();
    const vehicleFromVin = vehicles.data?.find((c) => c.vin === vin);
    const fastTrackType = useFastTrack();
    const isFastTrack = !!fastTrackType.fastTrack;

    const [isBookingRequest, setIsBookingRequestState] = useState(false);
    const setIsBookingRequest = (
        services: StandardServicesData['selectedServices']
    ) => {
        setIsBookingRequestState(
            determineIsBookingRequest({
                services,
            })
        );
    };

    const validation = runValidation(
        registrationNumber ?? '',
        undefined,
        validRegistrationNumber
    );

    const chooseVehicle = useChooseVehicle({
        initialState:
            validation.isValid && validation.value
                ? {
                      viewMode: 'done',
                      data: { registrationNumber: validation.value },
                  }
                : { viewMode: 'edit' },
    });

    const setVehicle = chooseVehicle.props.setSectionState;
    useEffect(() => {
        if (vehicleFromVin) {
            setVehicle({
                viewMode: 'done',
                data: { registrationNumber: vehicleFromVin.licensePlate },
            });
        }
    }, [setVehicle, vehicleFromVin]);

    const chooseDealer = useChooseDealer({
        initialState: {
            viewMode: 'hidden',
        },
        dependencies: { chooseVehicle: chooseVehicle.result },
    });

    const standardServices = useStandardServices({
        initialState: {
            viewMode: 'hidden',
        },
        dependencies: {
            chooseVehicle: chooseVehicle.result,
            chooseDealer: chooseDealer.result,
        },
        setInterruptUpdateTimestamp: setInterruptUpdateTimestamp,
    });

    const chooseTimeSlot = useChooseTimeSlot({
        initialState: {
            viewMode: 'hidden',
        },
        dependencies: {
            chooseVehicle: chooseVehicle.result,
            chooseDealer: chooseDealer.result,
            standardServices: standardServices.result,
        },
        isBookingRequest,
    });

    const transportServices = useTransportServices({
        initialState: {
            viewMode: 'hidden',
        },
        dependencies: {
            chooseVehicle: chooseVehicle.result,
            chooseTimeSlot: chooseTimeSlot.result,
            chooseStandardServices: standardServices.result,
            chooseDealer: chooseDealer.result,
        },
        isFastTrack,
    });

    const additionalServices = useAdditionalServices({
        initialState: {
            viewMode: 'hidden',
        },
        dependencies: {
            chooseVehicle: chooseVehicle.result,
            chooseTimeSlot: chooseTimeSlot.result,
            transportServices: transportServices.result,
            chooseDealer: chooseDealer.result,
        },
        isFastTrack,
    });

    const confirmBooking = useConfirmBooking({
        initialState: {
            viewMode: 'edit',
        },
        dependencies: {
            chooseVehicle: chooseVehicle.result,
            chooseDealer: chooseDealer.result,
            standardServices: standardServices.result,
            chooseTimeSlot: chooseTimeSlot.result,
            transportServices: transportServices.result,
            additionalServices: additionalServices.result,
        },
        isBookingRequest,
    });

    return {
        chooseVehicle,
        chooseDealer,
        standardServices,
        chooseTimeSlot,
        additionalServices,
        transportServices,
        confirmBooking,
        isBookingRequest,
        setIsBookingRequest,
        isFastrack: !!fastTrackType.fastTrack,
    };
}

import { DetailText, InfoCard } from '@gnist/design-system';
import { styled } from 'styled-components';
import { useGetVehicle } from '../../_api/http/vehicle';
import { BilholdViewLayout } from '../../components/bilhold/BilholdView';
import { BoldText } from '../../components/BoldText';
import { QueryError } from '../../components/QueryError';
import { useBookingStepAction } from '../../lib/datadog/hooks';
import { ChooseVehicleLoader } from './ChooseVehicleLoader';
import { Brand } from 'external-apis/.typescript/src/types/bilhold';

const UpperCaseText = styled(DetailText)`
    text-transform: uppercase;
`;

interface ChooseVehicleDoneProps {
    registrationNumber: string;
}

function formatBrand(brand: Brand) {
    switch (brand) {
        case 'VolkswagenCommercialVehicles':
            return 'Volkswagen';
        default:
            return brand.toString();
    }
}
export const ChooseVehicleDone: React.FC<ChooseVehicleDoneProps> = ({
    registrationNumber,
}) => {
    useBookingStepAction('Search car');
    const vehicle = useGetVehicle(registrationNumber);

    if (vehicle.isError) {
        return <QueryError error={vehicle.error} isError={true} />;
    }
    if (!vehicle.isSuccess) {
        return <ChooseVehicleLoader />;
    }

    return (
        <BilholdViewLayout>
            <InfoCard density="compact">
                <BoldText>
                    {formatBrand(vehicle.data.brand) +
                        ' ' +
                        vehicle.data.modelName}
                </BoldText>
                <UpperCaseText>{registrationNumber}</UpperCaseText>
            </InfoCard>
        </BilholdViewLayout>
    );
};

import { Column, DetailText } from '@gnist/design-system';
import { styled } from 'styled-components';

export type TimelineContentProps = {
    tasks: string[];
    date: Date;
    dealerName: string;
};

const Tasks = styled.ul`
    padding-left: 0;
    list-style: none;
`;

export function TimelineContent({
    tasks,
    date,
    dealerName,
}: TimelineContentProps) {
    const formattedDate = date.toLocaleDateString(undefined, {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
    });
    return (
        <Column gap="xxs">
            <Column gap="none">
                <DetailText>{formattedDate}</DetailText>
                <DetailText>{dealerName}</DetailText>
            </Column>
            <Column gap="none">
                <Tasks>
                    {tasks.map((x, i) => (
                        <li key={i + x}>{x}</li>
                    ))}
                </Tasks>
            </Column>
        </Column>
    );
}

import { LocalFormState } from '@gnist/design-system/utilities/forms/useLocalFormState.js';
import { DealerViewModel } from 'external-apis/src/types/port';
import { useEffect } from 'react';
import { useBookingStepAction } from '../../lib/datadog/hooks';
import { formatDealerName } from '../../lib/util/datadogFormatDealerName';
import { DealerForm } from './ChooseDealerSection';
import { ChosenDealer } from './dealer-picker/ChosenDealer';

type ChooseDealerDoneProps = {
    form: LocalFormState<DealerForm>;
    selectedDealerNumber: string;
    dealers: DealerViewModel[];
};
export const ChooseDealerDone: React.FC<ChooseDealerDoneProps> = ({
    form,
    selectedDealerNumber,
    dealers,
}) => {
    const selectedDealers = dealers.filter(
        (x) => selectedDealerNumber === x.id
    );

    const actionContext = {
        dealerName: formatDealerName(selectedDealers[0].name),
        dealerNumber: selectedDealers[0].id,
        adapterId: selectedDealers[0].adapterId,
    };

    useBookingStepAction('Choose dealer', {
        actionContext,
        additionalActions: [`Choose dealer ${actionContext.dealerName}`],
    });

    const inputProps = form.inputProps('selectedDealer');
    const dealerNumberInForm = form.state.validated.selectedDealer;
    useEffect(() => {
        if (selectedDealerNumber !== dealerNumberInForm) {
            inputProps.setValue(selectedDealerNumber);
        }
    }, [dealerNumberInForm, inputProps, selectedDealerNumber]);

    return (
        <>
            {selectedDealers.map((dealer) => (
                <ChosenDealer dealer={dealer} key={dealer.id} />
            ))}
        </>
    );
};

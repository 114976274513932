import {
    AlertBanner,
    LoadingSpinner,
    TextContainer,
    breakpointTablet,
} from '@gnist/design-system';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useCarProfile } from 'src/features/home/queries/useCarProfile';
import lang from 'src/utils/lang';
import { selectTheme } from 'src/utils/themes/selectTheme';
import { styled } from 'styled-components';
import { CarVideoContainer } from './CarVideoContainer';
import {
    getBrandForVin,
    getTypeNumberForVin,
    useGetSanityVideosByTypeNumber,
} from './useGetSanityVideosByTypeNumber';

const Container = styled(TextContainer)`
    width: 90vw;
    padding-top: var(--moller-spacing-xs);
    padding-bottom: var(--moller-spacing-xxl);
    margin: auto;
    max-width: 1200px;
    @media screen and (min-width: ${breakpointTablet}) {
        width: 80vw;
    }
`;

export const ExistingCarVideos = () => {
    const vin = useParams().vin;

    const { data: cars } = useCarProfile();

    const typeNumber = getTypeNumberForVin(cars?.existingCars, vin);
    const brand = getBrandForVin(cars?.existingCars, vin);

    const {
        data: videos,
        isLoading,
        isError,
    } = useGetSanityVideosByTypeNumber(typeNumber, !!typeNumber);

    useEffect(() => {
        if (localStorage.getItem('showRatingPrompt') === null) {
            const storageItem = {
                showRatingPrompt: 'true',
                expiry: null,
                trigger: 'Tilbake fra videoer på min bil',
            };

            localStorage.setItem(
                'showRatingPrompt',
                JSON.stringify(storageItem)
            );
        }
    }, []);

    if (isLoading) {
        return (
            <Container>
                <LoadingSpinner isFullScreen />
            </Container>
        );
    }

    if (isError) {
        return (
            <AlertBanner
                type={'error'}
                message={lang.general_error_contact_support}
            />
        );
    }

    return <CarVideoContainer theme={selectTheme(brand)} videos={videos} />;
};

import { datadogRum } from '@datadog/browser-rum';
import {
    breakpointTablet,
    LoadingSpinner,
    TextContainer,
} from '@gnist/design-system';
import { OrderedCarRelation } from 'external-apis/src/types/bilhold';
import { useEffect } from 'react';
import { styled } from 'styled-components';
import { SanityCarVideos } from './SanityCarVideos';
import { useGetSanityVideosByTypeNumber } from './useGetSanityVideosByTypeNumber';

interface OrderedCarVideoContainerProps {
    car: OrderedCarRelation;
}

const Container = styled(TextContainer)`
    width: 90vw;
    padding-top: var(--moller-spacing-xs);
    padding-bottom: var(--moller-spacing-xxl);
    margin: auto;
    max-width: 1200px;
    @media screen and (min-width: ${breakpointTablet}) {
        width: 80vw;
    }
`;
export const OrderedCarVideoContainer = ({
    car,
}: OrderedCarVideoContainerProps) => {
    const {
        data: videos,
        isPending: isLoading,
        error,
    } = useGetSanityVideosByTypeNumber(car.model.typeNumber);

    useEffect(() => {
        if (!videos || videos.length === 0) {
            return;
        }

        const videoTitles = videos.map((v) => v.name);

        datadogRum.addAction('bilhold-user-sees-sanity-videos', {
            modelType: car.model.type,
            carBrand: car.brand.toString(),
            modelYear: car.model.year,
            videoTitles,
        });
    }, [car.brand, car.model.type, car.model.year, videos]);

    if (isLoading) {
        return (
            <Container>
                <LoadingSpinner isFullScreen />
            </Container>
        );
    }
    if (error) {
        datadogRum.addError(
            `Error received when trying to fetch Sanity data from ${car.brand} ${car.model.text}, typeNumber ${car.model.typeNumber}}`
        );
    }

    if (!videos || videos.length < 1) {
        return null;
    }
    return <SanityCarVideos car={car} videos={videos} />;
};

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useFetchersContext } from 'external-apis';
import { loadImageUrls } from './loadImageUrl';
import { getDamageQueryKey } from './useDamageAppraisals';

export interface ImageType {
    id: number;
    tag: string;
    dataUrl: string;
}

const useGetDamageMedia = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.damageappraisal.fetcher
        .path('/v3/media')
        .method('get')
        .create();
};

const getMediaQueryKey = (damageId?: number) =>
    ['damageMedia', damageId] as const;

export const useGetMedia = (damageId?: number) => {
    const getDamageMedia = useGetDamageMedia();
    return useQuery({
        queryKey: getMediaQueryKey(damageId),
        queryFn: async () => {
            const res = await getDamageMedia({
                relatedId: damageId,
            });
            if (res.data) {
                return await loadImageUrls(res.data);
            }
            return [];
        },
        enabled: !!damageId,
    });
};

const usePostDamageMedia = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.damageappraisal.fetcher
        .path('/v3/media')
        .method('post')
        .create();
};

export const useUploadMedia = (damageId: number) => {
    const qc = useQueryClient();
    const postMediaDamage = usePostDamageMedia();
    return useMutation({
        mutationFn: ({
            tag,
            imageData,
        }: {
            tag: string;
            imageData: string;
        }) => {
            return postMediaDamage({
                relatedId: damageId,
                tag: tag,
                data: imageData,
            });
        },
        onSuccess: async (result, newImage) => {
            const queryKey = getMediaQueryKey(damageId);
            await qc.cancelQueries({ queryKey: queryKey });
            qc.setQueryData<ImageType[]>(queryKey, (existingImages) => {
                return [
                    ...(existingImages ?? []),
                    {
                        id: result.data.id,
                        tag: newImage.tag,
                        dataUrl: `data:image/jpeg;base64,${newImage.imageData}`,
                    },
                ];
            });
            await qc.invalidateQueries({
                queryKey: getDamageQueryKey(damageId),
            });
        },
    });
};

const useDeleteDamageMedia = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.damageappraisal.fetcher
        .path('/v3/deleteMedia/{id}')
        .method('delete')
        .create();
};

export const useDeleteMedia = (relatedId: number) => {
    const qc = useQueryClient();
    const deleteDamageMedia = useDeleteDamageMedia();
    return useMutation({
        onSuccess: async (_, deleteImg) => {
            const queryKey = getMediaQueryKey(relatedId);
            await qc.cancelQueries({ queryKey: queryKey });
            qc.setQueryData<ImageType[]>(queryKey, (existingImages) => {
                return existingImages?.filter((img) => deleteImg.id !== img.id);
            });
        },
        mutationFn: ({ id }: { id: number }) =>
            deleteDamageMedia({ id: id.toString() }),
    });
};

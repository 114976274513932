import { useNavigate, useParams } from 'react-router-dom';
import { useCarInformation } from 'src/features/home/queries/useCarInformation';
import { themeOverride } from 'src/utils/dev-tools/devToolsUtils';
import { ChildThemeProvider, getThemeFromBrand } from 'theming';
import { PageLayout } from '../components/PageLayout';
import { Heading1Text, IconButton } from '@gnist/design-system';
import FeedbackPrompt from 'src/shared/feedback-card/FeedbackPrompt';
import { Timeline, TimelineIcon } from './Timeline';
import { useFlag } from 'feature-toggle';
import lang from 'src/utils/lang';

export function VehicleHistory({ path }: { path: string }) {
    const { vin } = useParams();
    const { data, isPending, isError } = useCarInformation(vin);
    const navigate = useNavigate();

    const shouldShowVehicleHistory = useFlag('ShouldShowServiceBooklet');
    if (!shouldShowVehicleHistory) return null;

    if (isPending || isError) return null;
    const { brand, model } = data;
    const theme = getThemeFromBrand(brand || '');

    // TODO: replace with real data
    const testData = [
        {
            icon: 'build' as TimelineIcon,
            date: new Date(2025, 2, 27),
            dealerName: 'Dahles Auto Fredrikstad',
            tasks: ['Bremsevæskeskift 24 måneder'],
        },
        {
            icon: 'car_crash' as TimelineIcon,
            date: new Date(2024, 3, 15),
            dealerName: 'Møller Bil Haugesund Skadesenter',
            tasks: ['Glasskade reperasjon'],
        },
    ];

    //TODO: use langfile
    return (
        <ChildThemeProvider theme={theme} overrideTheme={themeOverride}>
            <PageLayout>
                <IconButton
                    icon={'chevron_left'}
                    label={model}
                    showLabel="right"
                    onClick={() => navigate(-1)}
                />
                <Heading1Text>{lang.vehicle_service_heading}</Heading1Text>
                <Timeline
                    steps={testData.map(({ icon, ...content }) => ({
                        icon,
                        content,
                    }))}
                />
                <FeedbackPrompt
                    customTitle={lang.feedbackTitle}
                    customContent={lang.feedbackContent}
                    path={path}
                />
            </PageLayout>
        </ChildThemeProvider>
    );
}

import { CarRelationsModel } from 'external-apis/src/types/bilhold';
import { CarStatusType, CarWithStatus } from 'src/types/CarWithStatus';

export const getCarsWithStatus = (
    cars: CarRelationsModel | undefined
): CarWithStatus[] => {
    const carList: CarWithStatus[] = [];

    cars?.existingCars.forEach((car) =>
        carList.push({
            car,
            statusType: CarStatusType.EXISTING_CAR,
        })
    );
    cars?.orderedCars.forEach((car) =>
        carList.push({
            car,
            statusType: CarStatusType.ORDERED_CAR,
        })
    );
    cars?.pendingCars.forEach((car) =>
        carList.push({
            car,
            statusType: CarStatusType.PENDING_CAR,
        })
    );
    return carList;
};

import { CheckboxCard, Column, RadioButton, Row } from '@gnist/design-system';
import { ServiceViewModel } from 'external-apis/src/types/port';
import { styled } from 'styled-components';
import { useLanguageContext } from '../../../../lib/languages/languageContext';
import {
    Price,
    parseServicePrices,
    parseServicesPrices,
} from '../../../shared/prices/Price';
import { ServiceDescription } from '../../../shared/services/ServiceDescription';
import { tireHotelService, tireService } from '../../../testData';
import { useStandardServicesForm } from '../../useSectionForm';
import { useStandardServiceListValidity } from '../../useStandardServiceListValidity';
import { selectService } from '../setSelectedServiceId';
import { toggleFeature } from '../toggleFeature';
import { Service } from '../service/Service';

const IndentedDescription = styled.div`
    display: grid;
    padding-left: 28px;
    grid-template-columns: 1fr fit-content(50px);
    gap: var(--moller-spacing-xs);
`;

type TireChangeProps = {
    serviceForm: ReturnType<typeof useStandardServicesForm>;
    services: ServiceViewModel[];
    dealerNumber?: string;
};

export function TireChange({
    serviceForm,
    services,
    dealerNumber,
}: TireChangeProps) {
    const tireServices = services.filter((x) => x.category === 'Tire');
    const setSelectedServiceIds = serviceForm.setValue('selectedIds');
    const selectedServiceIds = serviceForm.state.raw.selectedIds;
    const selectedTireServiceId = selectedServiceIds.find((x) =>
        tireServices.some((y) => x === y.id)
    );

    const validity = serviceForm.inputProps('selectedIds').validity;
    const serviceListValidity = useStandardServiceListValidity(validity);

    const [lc] = useLanguageContext();

    if (tireServices.length === 0) return null;

    if (tireServices.length === 1)
        return (
            <Service
                serviceForm={serviceForm}
                service={tireServices[0]}
                dealerNumber={dealerNumber}
            />
        );

    const mostExpensiveService =
        findMostExpensiveService(tireServices) ?? tireServices[0];

    return (
        <CheckboxCard
            onChange={() =>
                toggleFeature({
                    selectedServiceIds,
                    id: selectedTireServiceId ?? mostExpensiveService.id,
                    setSelectedServiceIds,
                })
            }
            density="compact"
            label={lc.standardServices.custom_service_groups.tire_group_title}
            value={!!selectedTireServiceId}
            description={
                <ServiceDescription
                    selected={!!selectedTireServiceId}
                    description={mostExpensiveService.description}
                >
                    {!selectedTireServiceId && (
                        <Price
                            values={parseServicesPrices(
                                tireServices,
                                dealerNumber
                            )}
                        />
                    )}
                </ServiceDescription>
            }
            {...serviceListValidity}
        >
            {selectedTireServiceId && (
                <Column gap="xxs">
                    {tireServices.map((x) => (
                        <div key={x.id}>
                            <Row>
                                <RadioButton
                                    id={x.id}
                                    label={x.name}
                                    onChange={() =>
                                        selectService({
                                            id: x.id,
                                            selectedServiceIds,
                                            setSelectedServiceIds,
                                            services: tireServices,
                                        })
                                    }
                                    value={x.id === selectedTireServiceId}
                                />
                            </Row>
                            <IndentedDescription>
                                {x.description}
                                <Price values={parseServicePrices(x)} />
                            </IndentedDescription>
                        </div>
                    ))}
                </Column>
            )}
        </CheckboxCard>
    );
}

function findMostExpensiveService(services: ServiceViewModel[]) {
    if (services.length < 0) return undefined;
    return services.reduce((x, y) =>
        (findMostExpensivePrice(x) ?? 0) > (findMostExpensivePrice(y) ?? 0)
            ? x
            : y
    );
}

if (import.meta.vitest) {
    const { it, expect } = import.meta.vitest;
    it.concurrent('findMostExpenciveService', () => {
        const expected = tireHotelService;
        const actual = findMostExpensiveService([
            tireService,
            tireHotelService,
        ]);
        expect(actual).toBe(expected);
    });
}

function findMostExpensivePrice(service: ServiceViewModel) {
    return service.dealerSpecificInformation.reduce((x, y) =>
        (x?.price?.fixedPrice ?? 0) > (y.price?.fixedPrice ?? 0) ? x : y
    ).price?.fixedPrice;
}

if (import.meta.vitest) {
    const { it, expect } = import.meta.vitest;
    it.concurrent('findMostExpencivePrice', () => {
        const expected = 300;
        const actual = findMostExpensivePrice(tireService);
        expect(actual).toBe(expected);
    });
}

import {
    ActionCard,
    DetailText,
    LeadText,
    Heading2Text,
} from '@gnist/design-system';

import { datadogRum } from '@datadog/browser-rum';
import { useState } from 'react';
import { EnvironmentName } from 'src/utils/environment/environments';
import { getCurrentEnvironmentName } from 'src/utils/environment/getCurrentEnvironment';
import { useUserHasSeenElement } from 'src/utils/hooks/useInView';
import { isMobileApp } from 'src/utils/mobile';
import { styled } from 'styled-components';
import { CampaignDrawer } from '../../components/CampaignDrawer';
import { CampaignModal } from '../../components/CampaignModal';
import { useCarInformation } from '../../queries/useCarInformation';
import { useGetCarPriceEstimate } from '../../queries/useGetCarPriceEstimate';
import { UsedCarValuation } from '../used-car-valuation/UsedCarValuation';
import { PriceEstimateContent } from './PriceEstimateContent';
import ValuationCardSVG from './svgs/valuation-card.svg?react';

const StyledActionCard = styled(ActionCard)`
    background-color: var(--moller-color-primary);
    color: var(--moller-color-on-primary);
    display: flex;
    justify-content: space-between;
    padding-right: 0;
    padding-bottom: 0;
`;

const StyledSubtitle = styled(Heading2Text)`
    font-weight: 400;
`;

const StyledCardContent = styled.div`
    padding-bottom: var(--moller-spacing-l);
`;

const StyledLeadText = styled(LeadText)`
    font-size: var(--moller-typeface-size-xl);
    font-weight: 500;
    white-space: nowrap;
    margin: var(--moller-spacing-base) 0;
`;

const StyledValuationCardSVG = styled(ValuationCardSVG)`
    border-radius: 0 0 var(--moller-radius-card) 0;
`;

const SVGContainer = styled.div`
    align-self: flex-end;
    width: 80px;
    height: 86px;
`;

interface CarValuationCardProps {
    vin: string;
    mileage: number;
}

export const CarPriceEstimateCard = ({
    vin,
    mileage,
}: CarValuationCardProps) => {
    const { data } = useGetCarPriceEstimate(vin, mileage);
    const [campaignModalIsOpen, setCampaignModalIsOpen] = useState(false);
    const carInformation = useCarInformation(vin);

    const ref = useUserHasSeenElement<HTMLButtonElement>(() => {
        datadogRum.addAction('User sees price estimate card', {
            data: carInformation.data?.modelYear?.toString(),
        });
    });

    const env = getCurrentEnvironmentName();

    const parseEnv = (env?: EnvironmentName) => {
        switch (env) {
            case 'local':
                return 'dev';
            case 'staging':
                return 'stage';
            default:
                return env;
        }
    };

    const valuationLink =
        env === 'prod'
            ? `https://verdivurdering.moller.no?src=bilholdPriceEstimate&registrationNumber=${carInformation.data?.registrationNumber}`
            : `https://verdivurdering.${parseEnv(
                  env
              )}.mollercloud.no?src=bilholdPriceEstimate&registrationNumber=${
                  carInformation.data?.registrationNumber
              }`;

    if (!data) {
        return null;
    }
    if (data.length === 0) {
        return <UsedCarValuation />;
    }

    return (
        <>
            <StyledActionCard
                ref={ref}
                onClick={() => {
                    setCampaignModalIsOpen(true);
                    datadogRum.addAction('User clicks on price estimate card', {
                        data: carInformation.data?.modelYear?.toString(),
                    });
                }}
            >
                <StyledCardContent>
                    <StyledSubtitle>
                        Lurer du på hvor mye du kan få for bilen din?
                    </StyledSubtitle>
                    <StyledLeadText>
                        {data[data.length - 1].marketMinPrice.toLocaleString()}{' '}
                        -{' '}
                        {data[data.length - 1].marketMaxPrice.toLocaleString()}
                        kr
                    </StyledLeadText>
                    <DetailText>
                        *Estimatet er basert på at bilen har kjørt {mileage} km.
                        Oppdater km.stand hvis det ikke stemmer.
                    </DetailText>
                </StyledCardContent>
                <SVGContainer>
                    <StyledValuationCardSVG />
                </SVGContainer>
            </StyledActionCard>
            {isMobileApp() ? (
                <CampaignDrawer
                    drawerIsOpen={campaignModalIsOpen}
                    setDrawerIsOpen={setCampaignModalIsOpen}
                    title="Lurer du på hvor mye du kan få for bilen din?"
                    buttonText="Gi meg et konkret tilbud"
                    buttonLink={valuationLink}
                    buttonClick={() =>
                        datadogRum.addAction(
                            'User clicks on concrete offer button',
                            {
                                data: carInformation.data?.modelYear?.toString(),
                            }
                        )
                    }
                >
                    {/* Need to wrap the content in a div to properly align on big mobile screens */}
                    <div>
                        <PriceEstimateContent
                            priceEstimate={data[data.length - 1]}
                            modelYear={carInformation.data?.modelYear?.toString()}
                        />
                    </div>
                </CampaignDrawer>
            ) : (
                <CampaignModal
                    campaignModalIsOpen={campaignModalIsOpen}
                    setCampaignModalIsOpen={setCampaignModalIsOpen}
                    buttonText="Gi meg et konkret tilbud"
                    buttonLink={valuationLink}
                    buttonClick={() =>
                        datadogRum.addAction(
                            'User clicks on concrete offer button',
                            {
                                data: carInformation.data?.modelYear?.toString(),
                            }
                        )
                    }
                >
                    <PriceEstimateContent
                        priceEstimate={data[data.length - 1]}
                        modelYear={carInformation.data?.modelYear?.toString()}
                    />
                </CampaignModal>
            )}
        </>
    );
};

import { InputFieldValidity } from '@gnist/design-system';
import lang from 'src/utils/lang';
import {
    VALID_ADDRESS,
    VALID_EMAIL,
    VALID_NAME,
    VALID_ZIP_INPUT,
} from 'src/utils/string/regex';

export const checkTextFieldValidity = (
    field: 'name' | 'email' | 'address' | 'zip',
    value: string
): InputFieldValidity<{ errorMsgRequired: true }> | undefined => {
    switch (field) {
        case 'name':
            if (!VALID_NAME.test(value) && value.length >= 1)
                return {
                    type: 'error',
                    message: lang.invalidName,
                };
            return;
        case 'email':
            if (!VALID_EMAIL.test(value) && value.length >= 1)
                return {
                    type: 'error',
                    message: lang.invalid_email,
                };
            return;
        case 'address':
            if (!VALID_ADDRESS.test(value))
                return {
                    type: 'error',
                    message: lang.invalidAddress,
                };
            return;
        case 'zip':
            if (!VALID_ZIP_INPUT.test(value))
                return {
                    type: 'error',
                    message: lang.invalid_zip_code,
                };
            return;
        default:
            return;
    }
};

export const checkValidForm = (
    firstname: string,
    surname: string,
    address: string,
    postcode: string,
    city: string,
    email: string
) => {
    return (
        firstname &&
        surname &&
        address &&
        postcode &&
        city &&
        email &&
        VALID_NAME.test(firstname) &&
        VALID_NAME.test(surname) &&
        VALID_ADDRESS.test(address) &&
        VALID_EMAIL.test(email) &&
        VALID_ZIP_INPUT.test(postcode)
    );
};

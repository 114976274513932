import {
    Column,
    Heading1Text,
    Icon,
    LinkCard,
    Row,
    Heading2Text,
} from '@gnist/design-system';
import { ddRumPrivacySetting } from 'booking/components/DDRumPrivacySetting';
import BackButton from 'src/shared/BackButton';
import { CenteredPageContent } from 'src/shared/CenteredPageContent';
import FeedbackPrompt from 'src/shared/feedback-card/FeedbackPrompt';
import { themeOverride } from 'src/utils/dev-tools/devToolsUtils';
import { styled } from 'styled-components';
import { ChildThemeProvider } from 'theming';
import { SkodaCompetitionCardBranded } from '../components/SkodaCompetitionCardBranded';

const MainHeadline = styled(Heading1Text)`
    margin: var(--moller-spacing-m) 0;
`;

export function ExploreSkodaPage() {
    return (
        <ChildThemeProvider theme="skoda" overrideTheme={themeOverride}>
            <CenteredPageContent {...ddRumPrivacySetting('allow')}>
                <BackButton />
                <MainHeadline>Velkommen til Škoda</MainHeadline>
                <Column gap="l">
                    <LinkCard
                        as={'a'}
                        href={'https://www.skoda-auto.no/'}
                        target="__blank"
                    >
                        <Row horizontalAlign="spaceBetween" gap="base">
                            <Heading2Text>Škoda</Heading2Text>
                            <Icon icon="external" />
                        </Row>
                        <p>Besøk Škoda Norge</p>
                    </LinkCard>

                    <SkodaCompetitionCardBranded />
                </Column>

                <FeedbackPrompt />
            </CenteredPageContent>
        </ChildThemeProvider>
    );
}

import { Heading1Text, TextContainer } from '@gnist/design-system';
import { CenteredPageContent } from 'src/shared/CenteredPageContent';

const Maintenance = () => {
    return (
        <CenteredPageContent>
            <TextContainer>
                <Heading1Text>
                    Bilhold er nede for vedlikehold og vil straks være tilbake
                </Heading1Text>
            </TextContainer>
        </CenteredPageContent>
    );
};

export default Maintenance;

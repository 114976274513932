import { Capacitor } from '@capacitor/core';
import {
    ActionCard,
    DetailText,
    Heading3Text,
    LeadText,
    LinkCard,
} from '@gnist/design-system';
import { adobeEvent } from 'adobe-utils';
import { OrderedCarRelation } from 'external-apis/src/types/bilhold';
import { Link, generatePath, useNavigate } from 'react-router-dom';
import { PATH_PRODUCT_WITH_ID } from 'src/routing/routes';
import { styled } from 'styled-components';
import { pocProducts } from '../../current-actions/waiting-program/pocProducts';
import { customProduct } from '../../current-actions/waiting-program/product-data/custom-product';
import { Product } from '../../utils/productType';
import { CardLayout } from '../CardLayout';

const StyledActionCard = styled(ActionCard)`
    min-width: 160px;
    padding: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

const ProductImage = styled.img`
    min-width: 160px;
    width: 100%;
    height: 100%;
    max-height: 80px;
    object-fit: cover;
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: var(--moller-spacing-xs);
    gap: var(--moller-spacing-xs);
    height: 100%;
`;

const ProductTag = styled.div`
    margin-top: auto;
    background-color: var(--moller-color-secondary-container);
    color: var(--moller-color-on-secondary-container);
    padding: var(--moller-spacing-xxs) var(--moller-spacing-s);
    border-radius: var(--moller-border-radius-full);
    max-width: fit-content;
`;

interface ProductSliderCardProps {
    product: Product;
    car: OrderedCarRelation;
}

export const ProductSliderCard = ({ product, car }: ProductSliderCardProps) => {
    const navigate = useNavigate();

    const priceTag =
        product.price > 0
            ? product.price.toLocaleString('NO-nb') + ' kr'
            : 'Forespørsel';

    const handleCardAction = () => {
        adobeEvent.push(
            'clickProduct',
            {
                productID: `${product.articleNumber}`,
                productName: `${product.title}`,
                price: product.price,
            },
            Capacitor.getPlatform()
        );
        navigate(
            generatePath(PATH_PRODUCT_WITH_ID, {
                carId: car.contractId.toString(),
                articleNumber: product.articleNumber,
            })
        );
    };

    return (
        <StyledActionCard title={product.title} onClick={handleCardAction}>
            <ProductImage src={product.image} alt={product.title} />
            <ContentContainer>
                <LeadText>{product.title}</LeadText>
                <p>{product.descriptionShort}</p>
                <ProductTag>
                    <DetailText>{priceTag}</DetailText>
                </ProductTag>
            </ContentContainer>
        </StyledActionCard>
    );
};

const SliderContainer = styled.div`
    display: flex;
    gap: var(--moller-spacing-s);
    max-width: 100%;
    height: 100%;
    overflow: auto;
    margin-top: var(--moller-spacing-s);
    margin-bottom: var(--moller-spacing-s);
    padding: 0 2px; // Just enough so corners aren't cut off
`;

const StyledLinkCard = styled(LinkCard)`
    margin-top: var(--moller-spacing-s);
    margin-bottom: 0;
`;

interface ProductSliderProps {
    car: OrderedCarRelation;
}

export const ProductSlider = ({ car }: ProductSliderProps) => {
    // certain products should only be shown for certain brands and models
    const filteredPocProducts = pocProducts.filter(
        (product) =>
            product.carBrand === car.brand &&
            product.carModel.some((modelName) =>
                car.model.text.toLowerCase().includes(modelName.toLowerCase())
            )
    );

    if (filteredPocProducts.length === 0) {
        return (
            <>
                <Heading3Text>Tilleggsutstyr</Heading3Text>
                <StyledLinkCard
                    forwardedAs={Link}
                    to={generatePath(PATH_PRODUCT_WITH_ID, {
                        carId: car.contractId.toString(),
                        articleNumber: 'customer-request',
                    })}
                >
                    <CardLayout
                        title="Send forespørsel om tilleggsutstyr"
                        body="Send en forespørsel her, så hjelper din forhandler deg med å finne riktige produkter til din nye bil."
                        ImageSrc={customProduct.image}
                    />
                </StyledLinkCard>
            </>
        );
    }

    return (
        <>
            <Heading3Text>Tilleggsutstyr</Heading3Text>
            <SliderContainer>
                {filteredPocProducts.map((product, index) => (
                    <ProductSliderCard
                        key={index}
                        product={product}
                        car={car}
                    />
                ))}
                <ProductSliderCard product={customProduct} car={car} />
            </SliderContainer>
        </>
    );
};

import { Checkbox, Column } from '@gnist/design-system';
import { ServiceViewModel } from 'external-apis/src/types/port';
import { styled } from 'styled-components';
import { FlexRow } from '../../../../components/FlexRow';
import { useLanguageContext } from '../../../../lib/languages/languageContext';
import { parseServicePrices, Price } from '../../../shared/prices/Price';
import { parseServicesPrices, SumPrice } from '../../../shared/prices/SumPrice';
import { useStandardServiceListValidity } from '../../useStandardServiceListValidity';

type RecommendedServicesProps = {
    recommendedServices: ServiceViewModel[];
    selectedServiceIds: string[];
    setSelectedServices: (x: string[]) => void;
    selectedDealerNumber: string;
    serviceListValidity: ReturnType<typeof useStandardServiceListValidity>;
};

const Container = styled(Column)`
    padding-top: 8px;
`;

function onChangeRecommendedService(
    serviceId: string,
    selectedIds: string[],
    setSelectedServices: (x: string[]) => void
) {
    if (selectedIds.includes(serviceId)) {
        setSelectedServices(selectedIds.filter((x) => x !== serviceId));
    } else {
        setSelectedServices([...selectedIds, serviceId]);
    }
}

export function RecommendedServices({
    recommendedServices,
    selectedServiceIds,
    setSelectedServices,
    selectedDealerNumber,
    serviceListValidity,
}: RecommendedServicesProps) {
    const [lc] = useLanguageContext();

    const selectedRecommendedServices = recommendedServices.filter((x) =>
        selectedServiceIds.includes(x.id)
    );

    const shouldShowSumPrice = recommendedServices.length > 1;
    return (
        <Container gap="xxs">
            {recommendedServices.length > 0 && (
                <p>{lc.standardServices.recommended_work}</p>
            )}
            {recommendedServices.map((x) => (
                <FlexRow key={x.id}>
                    <Checkbox
                        key={x.id}
                        label={x.name}
                        onChange={() => {
                            onChangeRecommendedService(
                                x.id,
                                selectedServiceIds,
                                setSelectedServices
                            );
                        }}
                        value={selectedServiceIds.includes(x.id)}
                        {...serviceListValidity}
                    />
                    <Price
                        values={parseServicePrices(x, selectedDealerNumber)}
                    ></Price>
                </FlexRow>
            ))}
            {shouldShowSumPrice && (
                <SumPrice
                    values={parseServicesPrices(
                        selectedRecommendedServices,
                        selectedDealerNumber
                    )}
                    finalSummary={false}
                />
            )}
        </Container>
    );
}

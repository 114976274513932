import { datadogRum } from '@datadog/browser-rum';
import {
    AlertBanner,
    Column,
    DetailText,
    InfoCard,
    Lozenge,
    Row,
    breakpointDesktop,
} from '@gnist/design-system';
import { CarRelationsModel } from 'external-apis/src/types/bilhold';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { generatePath } from 'react-router-dom';
import { normalizeETron } from 'src/features/my-car/car-brands/audi/audiUtils';
import { PATH_MY_CAR, PATH_MY_ORDERED_CAR } from 'src/routing/routes';
import { CarStatusType, CarWithStatus } from 'src/types/CarWithStatus';
import { withErrorBoundary } from 'src/utils/ErrorBoundary';
import { ddRumPrivacySetting } from 'src/utils/datadog';
import { formatBrandName } from 'src/utils/string/formatBrandNames';
import formatLicensePlate from 'src/utils/string/formatLicensePlate';
import { styled } from 'styled-components';
import { Swiper as SwiperType } from 'swiper';
import 'swiper/css/navigation';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import 'swiper/scss/pagination';
import { useCarProfile } from '../../queries/useCarProfile';
import { useCarSwiperIndex } from '../../store/useCarSwiperIndex';
import { getCarsWithStatus } from '../../utils/getCarsWithStatus';
import { Container } from '../HomeStyledComponents';
import { AddCar } from './AddCar';
import { CarCard } from './CarCard';
import { CarHeadline } from './CarHeadline';
import { CarSkeleton } from './CarSkeleton';

const StyledSwiper = styled(Swiper)`
    z-index: 0;
    --swiper-navigation-size: 12px;
    margin-bottom: var(--moller-spacing-s);
    .swiper-pagination {
        bottom: -4px;
    }
    .swiper-slide {
        height: auto;
    }
    .swiper-button-next,
    .swiper-button-prev {
        border-radius: 50%;
        width: 30px;
        height: 30px;
        padding: 5px;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        margin: -4% -5px 0 -5px;

        background-color: var(--moller-color-primary);
        color: var(--moller-color-on-primary);
        :hover {
            width: 40px;
            height: 40px;
            margin: -4.5% -7px 0 -7px;
        }
    }
    .swiper-pagination-bullet {
        background-color: var(--moller-color-on-background);
    }
    .swiper-button-disabled {
        display: none !important;
    }
`;

const SwiperContainer = styled.div`
    position: relative;
    width: 100%;
    padding: 0 0;
`;

const AlertBannerWithMargin = styled(AlertBanner)`
    margin: var(--moller-spacing-s);
    margin-bottom: var(--moller-spacing-l);
`;

const PendingCarCard = styled(InfoCard)`
    position: relative;
    box-sizing: content-box;
    padding: var(--moller-spacing-s) var(--moller-spacing-l);
    padding-bottom: var(--moller-spacing-s);
    min-height: 118px;
    overflow: hidden;

    @media screen and (min-width: ${breakpointDesktop}) {
        padding: var(--moller-spacing-3xl) var(--moller-spacing-xl);
    }
`;

const fallback = (retry: () => void) => (
    <AlertBannerWithMargin
        type="warning"
        message="Vi greide dessverre ikke å laste inn dine biler. Du kan likevel bruke menyen under for å bestille verkstedtimer eller få digital skadetakst."
        action={{ label: 'Prøv igjen', onClick: retry }}
    />
);

interface MyCarSwiperProps {
    readonly setActiveCar: React.Dispatch<
        React.SetStateAction<CarWithStatus | undefined>
    >;
}

export const MyCarSwiper = withErrorBoundary(
    'MyCarSwiper',
    (props: MyCarSwiperProps) => {
        const cars = useCarProfile();
        const hasLoggedNumberOfCars = !!sessionStorage.getItem(
            'hasLoggedNumberOfCars'
        );

        const carList = useMemo(() => {
            const sortedCars: CarRelationsModel | undefined = cars.data
                ? {
                      ...cars.data,
                      existingCars: cars.data.existingCars,
                      orderedCars: cars.data.orderedCars.filter(
                          (car) => !car.status || car.status === 'SIGNED'
                      ),
                  }
                : undefined;
            return getCarsWithStatus(sortedCars);
        }, [cars.data]);

        // Note: We don't check appointments.isError here, as appointments are only used to sort the cars.
        //       It's better to return cars in the wrong order than to show no cars.
        if (cars.isError) {
            datadogRum.addError(new Error('MyCarSwiper failed to load data'), {
                error: cars.error,
            });
            return fallback(() => {
                void cars.refetch();
            });
        }
        if (cars.isSuccess && cars.data && !hasLoggedNumberOfCars) {
            datadogRum.addAction('number-of-cars', {
                numberOfExistingCars: cars.data.existingCars.length,
                numberOfOrderedCars: cars.data.orderedCars.length,
            });
            sessionStorage.setItem('hasLoggedNumberOfCars', 'true');
        }
        return (
            <MyCarSwiperView
                {...props}
                carList={carList}
                isLoading={cars.isLoading}
            />
        );
    },
    { fallback }
);

interface MyCarSwiperViewProps extends MyCarSwiperProps {
    readonly carList: CarWithStatus[];
    readonly isLoading: boolean;
}

const MyCarSwiperView = ({
    carList,
    isLoading,
    setActiveCar,
}: MyCarSwiperViewProps) => {
    const hasCorrectedSwiperIndex = useRef(false);

    const [swiperRef, setSwiperRef] = useState<SwiperType | null>(null);
    const carSwiperIndex = useCarSwiperIndex((state) => state.carSwiperIndex);
    const setCarSwiperIndex = useCarSwiperIndex(
        (state) => state.setCarSwiperIndex
    );

    const slideTo = useCallback(
        (index: number) => {
            swiperRef?.slideTo(index, 0);
        },
        [swiperRef]
    );

    useEffect(() => {
        const car = carList[carSwiperIndex];
        setCarSwiperIndex(carSwiperIndex);
        setActiveCar(car);
        slideTo(carSwiperIndex);
    }, [carList, setActiveCar, carSwiperIndex, slideTo, setCarSwiperIndex]);

    useEffect(() => {
        /* 
            Index on "Add car" on startup may lead to users not finding their car,
            so setting to last car instead
        */
        if (
            isLoading ||
            hasCorrectedSwiperIndex.current ||
            carSwiperIndex === 0 ||
            carList.length === 0
        ) {
            return;
        }

        if (carList.length === carSwiperIndex) {
            hasCorrectedSwiperIndex.current = true; // Only want to adjust for index once on startup
            setCarSwiperIndex(carList.length - 1);
        }
    }, [carList, carSwiperIndex, isLoading, setCarSwiperIndex]);

    const swiperCards = carList.map(toCarCard);

    return (
        <SwiperContainer>
            <StyledSwiper
                onSwiper={setSwiperRef}
                onSlideChange={(swiper) => setCarSwiperIndex(swiper.realIndex)}
                pagination={true}
                navigation={true}
                modules={[Pagination, Navigation]}
                spaceBetween={20}
            >
                {!isLoading && carList ? (
                    <>
                        {swiperCards}
                        <SwiperSlide key={'addCar'}>
                            <AddCar />
                        </SwiperSlide>
                    </>
                ) : (
                    <SwiperSlide key={'skeleton'}>
                        <Container>
                            <CarSkeleton />
                        </Container>
                    </SwiperSlide>
                )}
            </StyledSwiper>
        </SwiperContainer>
    );
};

function toCarCard(
    { car, statusType }: CarWithStatus,
    index: number
): JSX.Element {
    switch (statusType) {
        case CarStatusType.EXISTING_CAR:
            return (
                <SwiperSlide key={`${car.vin}-${index}`}>
                    <CarCard
                        lozengeText={formatLicensePlate(car.licensePlate)}
                        lozengeVariant="neutral"
                        headlineText={`${formatBrandName(
                            car.brand
                        )} ${normalizeETron(car.name)}`}
                        hrefString={generatePath(PATH_MY_CAR, {
                            vin: car.vin,
                        })}
                        carType="MYCAR"
                        myCar={car}
                    />
                </SwiperSlide>
            );
        case CarStatusType.ORDERED_CAR:
            return (
                <SwiperSlide key={`${car.orderNumber}-${index}`}>
                    <CarCard
                        lozengeText={'snart din'}
                        lozengeVariant="secondary"
                        headlineText={`${formatBrandName(car.brand)} ${
                            car.model.text
                        }`}
                        hrefString={`${PATH_MY_ORDERED_CAR}/${car.contractId}`}
                        carType="ORDERED"
                        orderedCar={car}
                    />
                </SwiperSlide>
            );
        case CarStatusType.PENDING_CAR:
            return (
                <SwiperSlide key={`${car.licensePlate}-${index}`}>
                    <Container>
                        <PendingCarCard title="Behandles">
                            <Row horizontalAlign="spaceBetween">
                                <Column>
                                    <div>
                                        <Lozenge colorVariant={'warning'}>
                                            {'behandles'}
                                        </Lozenge>
                                        <CarHeadline
                                            {...ddRumPrivacySetting('mask')}
                                            headlineText={formatLicensePlate(
                                                car.licensePlate
                                            )}
                                        />
                                    </div>
                                    <DetailText
                                        {...ddRumPrivacySetting('mask')}
                                    >
                                        Vi behandler forespørselen din om å
                                        legge til bil med registreringsnummer{' '}
                                        {formatLicensePlate(car.licensePlate)}.
                                        Takk for tålmodigheten.
                                    </DetailText>
                                </Column>
                            </Row>
                        </PendingCarCard>
                    </Container>
                </SwiperSlide>
            );
        default:
            return <></>;
    }
}

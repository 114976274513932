import { Video } from '@bilhold/sanity';
import { OrderedCarRelation } from 'external-apis/src/types/bilhold';
import { useEffect } from 'react';
import { selectTheme } from 'src/utils/themes/selectTheme';
import { CarVideoContainer } from './CarVideoContainer';

interface Props {
    car: OrderedCarRelation;
    videos: Video[];
}

export const SanityCarVideos = ({ car, videos }: Props) => {
    useEffect(() => {
        if (localStorage.getItem('showRatingPrompt') === null) {
            const storageItem = {
                showRatingPrompt: 'true',
                expiry: null,
                trigger: 'Tilbake fra videoer i venteprogram',
            };

            localStorage.setItem(
                'showRatingPrompt',
                JSON.stringify(storageItem)
            );
        }
    }, []);

    return <CarVideoContainer theme={selectTheme(car.brand)} videos={videos} />;
};

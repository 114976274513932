export function getIfExistFormat<T>(
    variable: T | undefined,
    format: (value: T) => string
) {
    if (!variable) return null;
    return format(variable);
}

export function getIfExist(variable?: string | null) {
    if (!variable) return null;
    return variable;
}

import { Column } from '@gnist/design-system';
import { TDFShirt } from 'src/features/competition/assets/TDFShirt';
import { ShirtCard } from 'src/features/competition/components/shirtCards/ShirtCard';
import { ShirtProgressBar } from 'src/features/competition/components/shirtCards/ShirtProgressBar';
import { TDSTextXSmall } from 'src/features/competition/textComponents/TDFText';
import { styled } from 'styled-components';

const ShirtWrapper = styled(Column)`
    width: fit-content;
    align-self: end;
`;

const MAX_HEIGHT = 2469;

interface Props {
    currentElevation: number;
    isDisabled: boolean;
}

export const WhiteElevationShirt = ({
    currentElevation,
    isDisabled,
}: Props) => {
    const shownElevation = Math.min(currentElevation, MAX_HEIGHT);
    const isCompleted = currentElevation >= MAX_HEIGHT;
    return (
        <ShirtCard
            isCompleted={isCompleted}
            isDisabled={isDisabled}
            headline={'Klatretrøya'}
            modalText={
                'For å oppnå klatretrøya må du sykle tilsvarende opp Galdhøpiggen i løpet av konkurranseperioden.'
            }
            prizesRemaining={250}
            shirtColor={'white'}
        >
            <>
                <ShirtProgressBar
                    currentValue={shownElevation}
                    maxValue={MAX_HEIGHT}
                    progressHeader={`${MAX_HEIGHT} m`}
                    shirtColor={'white'}
                    textUnderProgress={'m'}
                />
                <ShirtWrapper
                    horizontalAlign={'center'}
                    style={{ marginTop: '1rem' }}
                >
                    <TDFShirt
                        fill={'white'}
                        width={'48'}
                        height={'40'}
                        opacity={isCompleted ? '1' : '0.2'}
                        isElevationShirt
                    />
                    {!isCompleted && (
                        <TDSTextXSmall>
                            {Math.ceil(MAX_HEIGHT - currentElevation)} meter
                            igjen
                        </TDSTextXSmall>
                    )}
                </ShirtWrapper>
            </>
        </ShirtCard>
    );
};

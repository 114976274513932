import { Column } from '@gnist/design-system';
import { TDFShirt } from 'src/features/competition/assets/TDFShirt';
import { ShirtCard } from 'src/features/competition/components/shirtCards/ShirtCard';
import { ShirtProgressBar } from 'src/features/competition/components/shirtCards/ShirtProgressBar';
import { TDSTextXSmall } from 'src/features/competition/textComponents/TDFText';
import { styled } from 'styled-components';

const ShirtWrapper = styled(Column)`
    width: fit-content;
    align-self: end;
`;

const TOUR_LENGTH = 349.2;

interface Props {
    totalKm: number;
    isDisabled: boolean;
}

export const YellowTotalShirt = ({ totalKm, isDisabled }: Props) => {
    const km = totalKm <= TOUR_LENGTH ? totalKm : TOUR_LENGTH;
    const isCompleted = totalKm >= TOUR_LENGTH;
    return (
        <ShirtCard
            isCompleted={isCompleted}
            isDisabled={isDisabled}
            headline={'Den gule trøya'}
            modalText={
                'For å oppnå den gule trøya må du sykle alle etappene i hele touren!'
            }
            prizesRemaining={250}
            shirtColor={'#FAEB67'}
        >
            <>
                <ShirtProgressBar
                    currentValue={km}
                    maxValue={TOUR_LENGTH}
                    progressHeader={`${TOUR_LENGTH} km`}
                    shirtColor={'#FAEB67'}
                    startLabel={'Florence'}
                    endLabel={'Nice'}
                    textUnderProgress={'km'}
                />
                <ShirtWrapper horizontalAlign={'center'}>
                    <TDFShirt
                        fill="#FAEB67"
                        width={'48'}
                        height={'40'}
                        opacity={isCompleted ? '1' : '0.2'}
                    />
                    {!isCompleted && (
                        <TDSTextXSmall>
                            {(TOUR_LENGTH - km).toFixed(1)} km igjen
                        </TDSTextXSmall>
                    )}
                </ShirtWrapper>
            </>
        </ShirtCard>
    );
};

import { datadogRum } from '@datadog/browser-rum';
import {
    ExistingCarRelation,
    RecallAppointment,
} from 'external-apis/src/types/bilhold';
import { useEffect } from 'react';
import { styled } from 'styled-components';
import { AudiBatteryIssue } from './recall-issues/AudiBatteryIssue';
import { AudiSoftwareUpdateBreakingSystem } from './recall-issues/AudiSoftwareUpdateBreakingSystem';
import { RackAndPinionIssue } from './recall-issues/RackAndPinionIssue';

export const FlexContainer = styled.div<{ reverseOrder: boolean }>`
    display: flex;
    flex-direction: ${({ reverseOrder }) =>
        reverseOrder ? 'column-reverse' : 'column'};

    h2 {
        margin-top: var(--moller-spacing-s) !important;
    }
`;

export interface RecallServiceContentProps {
    car: ExistingCarRelation;
    recall: RecallAppointment;
}

export const RecallServiceContent = ({
    car,
    recall,
}: RecallServiceContentProps) => {
    useEffect(() => {
        datadogRum.addAction('User sees recall article');
    }, []);

    switch (recall.recallCode) {
        case '93V2':
            return <AudiBatteryIssue recall={recall} />;
        case '93U9':
            return <AudiBatteryIssue recall={recall} />;
        case '48R7':
            return <RackAndPinionIssue car={car} recall={recall} />;
        case '48R8':
            return <RackAndPinionIssue car={car} recall={recall} />;
        case '48S2':
            return <RackAndPinionIssue car={car} recall={recall} />;
        case '454R':
            return (
                <AudiSoftwareUpdateBreakingSystem car={car} recall={recall} />
            );
        default:
            return null;
    }
};

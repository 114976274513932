import { Column, InfoCard, Row } from '@gnist/design-system';
import {
    TDSThinHeadline,
    TextThin,
} from 'src/features/competition/textComponents/TDFText';
import { styled } from 'styled-components';

const StyledInfoCard = styled(InfoCard)`
    align-items: center;
    border: 0;
    margin-bottom: 0;
    position: relative;
`;
const TimeCard = styled(InfoCard)`
    padding: var(--moller-spacing-xxs) var(--moller-spacing-m);
    background-color: black;
    border-width: var(--moller-stroke-medium);
`;

const TimeCardText = styled.p`
    color: var(--moller-color-primary);
    font-size: var(--moller-type-medium-heading2-font-size);
`;

export const TimeToCompetition = ({ TDFStart }: { TDFStart: Date }) => {
    const today = new Date();
    const diffInMilliSeconds = Math.abs(TDFStart.getTime() - today.getTime());
    const daysToStart = Math.floor(diffInMilliSeconds / 1000 / 60 / 60 / 24);
    const hoursToStart = Math.floor(diffInMilliSeconds / 1000 / 60 / 60) % 24;
    const minutesToStart = Math.floor(diffInMilliSeconds / 1000 / 60) % 60;
    return (
        <StyledInfoCard>
            <Column
                gap={'base'}
                verticalAlign={'center'}
                horizontalAlign={'center'}
            >
                <TDSThinHeadline>
                    Tid igjen til konkurransestart:
                </TDSThinHeadline>
                <Row gap={'s'}>
                    <Column gap={'xxs'} horizontalAlign={'center'}>
                        <TimeCard>
                            <TimeCardText>{daysToStart}</TimeCardText>
                        </TimeCard>
                        <TextThin>Dager</TextThin>
                    </Column>
                    <Column gap={'xxs'} horizontalAlign={'center'}>
                        <TimeCard>
                            <TimeCardText>{hoursToStart}</TimeCardText>
                        </TimeCard>
                        <TextThin>Timer</TextThin>
                    </Column>
                    <Column gap={'xxs'} horizontalAlign={'center'}>
                        <TimeCard>
                            <TimeCardText>{minutesToStart}</TimeCardText>
                        </TimeCard>
                        <TextThin>Minutter</TextThin>
                    </Column>
                </Row>
            </Column>
        </StyledInfoCard>
    );
};

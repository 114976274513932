import { InputFieldValidity, RadioButton, Row } from '@gnist/design-system';
import { AdditionalInfoResourceGroup } from 'external-apis/src/types/port';
import {
    LanguageContextType,
    useLanguageContext,
} from '../../../lib/languages/languageContext';

type ResourceGroupProps = {
    groups: AdditionalInfoResourceGroup[] | undefined;
    selectedGroup: AdditionalInfoResourceGroup | undefined;
    setResourceGroup: (group: AdditionalInfoResourceGroup) => void;
    validity: InputFieldValidity | undefined;
};

function textForResourceGroup(
    group: AdditionalInfoResourceGroup,
    lc: LanguageContextType
) {
    switch (group) {
        case 'Noise':
            return lc.standardServices.resourceGroupSound;
        case 'ProblemWithCharging':
            return lc.standardServices.resourceGroupCharging;
        case 'IlluminatedWarningLamp':
            return lc.standardServices.resourceGroupWarningLamp;
        case 'HatchThatWontOpen':
            return lc.standardServices.resourceGroupHatch;
        case 'ProblemWithClimateSystem':
            return lc.standardServices.resourceGroupAC;
    }
}

export function ResourceGroups({
    groups,
    selectedGroup,
    setResourceGroup,
    validity,
}: ResourceGroupProps) {
    const [lc] = useLanguageContext();
    if (!groups) return null;
    return (
        <Row horizontalAlign="start" gap="s">
            {groups.map((x) => {
                return (
                    <RadioButton
                        value={x === selectedGroup}
                        key={x}
                        onChange={(e) => {
                            e.preventDefault();
                            setResourceGroup(x);
                        }}
                        label={textForResourceGroup(x, lc)}
                        validity={validity}
                    />
                );
            })}
        </Row>
    );
}

import { damageAppraisalBaseUrl } from 'external-apis/src/fetchers/damageappraisal';
import { environmentOverride } from 'src/utils/dev-tools/devToolsUtils';
import Environment, { Auth0Config } from 'src/utils/environment/Environment';
import { isMobileApp } from 'utils/mobile';

const mollerEnvironments = ['prod', 'staging', 'test', 'dev'] as const;
type MollerEnvironment = (typeof mollerEnvironments)[number] | 'local';
const BILHOLD_ENVIRONMENTS = [...mollerEnvironments, 'local'] as const;
export type EnvironmentName = (typeof BILHOLD_ENVIRONMENTS)[number];

// TODO: delete this once we no longer use the doRequest function to fetch data
export function getServerUrl(env: MollerEnvironment | 'local'): string {
    if (env === 'local') {
        return isMobileApp()
            ? 'http://10.0.2.2:8080/bilhold'
            : `http://localhost:8080/bilhold`;
    }

    switch (env) {
        case 'prod':
            return 'https://api.mittbilhold.no/bilhold';
        case 'staging':
            return 'https://api-staging.mittbilhold.no/bilhold';
        case 'test':
            return 'https://api-test.mittbilhold.no/bilhold';
        case 'dev':
            return 'https://api-dev.mittbilhold.no/bilhold';
    }
}

const loginServerUrl: Record<MollerEnvironment, string> = {
    prod: 'https://userselfservice.prod.mollercloud.no',
    staging: 'https://userselfservice.stage.mollercloud.no',
    test: 'https://userselfservice.test.mollercloud.no',
    dev: 'https://userselfservice.dev.mollercloud.no',
    local: 'https://userselfservice.test.mollercloud.no',
};

const redirectUrl = `${window.location.origin}/callback`;

const getAuth0Config = (env: EnvironmentName): Auth0Config => {
    switch (env) {
        case 'prod':
            return {
                audience: 'bilhold-prod',
                clientId: 'kr2bllLHLMseDzMpqwBm7ION4MH8yRgv',
                domain: 'id.mollermobilitygroup.com',
                scope: 'openid profile partyId entryUUID offline_access mmg',
            };
        case 'staging':
            return {
                audience: 'bilhold-stage',
                clientId: 'c9NAVTTywelhGgDVfDTLnBTMzEM1RRe2',
                domain: 'id-stage.mollermobilitygroup.com',
                scope: 'openid profile partyId entryUUID offline_access mmg',
            };
        case 'test':
        case 'local':
            return {
                audience: 'bilhold-test',
                clientId: '0I47YBnzLufNx7I0ST5K2WjiQ28qqhv0',
                domain: 'id-test.mollermobilitygroup.com',
                scope: 'openid profile partyId entryUUID offline_access mmg',
            };
        case 'dev':
            return {
                audience: 'bilhold-dev',
                clientId: '5kyx0VkuocqalLEOZK0zfLAMijDozJC1',
                domain: 'id-dev.mollermobilitygroup.com',
                scope: 'openid profile partyId entryUUID offline_access mmg',
            };
    }
};

const messageCenterUrl: Record<MollerEnvironment, string> = {
    prod: 'https://message-center.prod.mollercloud.no',
    staging: 'https://message-center.stage.mollercloud.no',
    test: 'https://message-center.test.mollercloud.no',
    dev: 'https://message-center.dev.mollercloud.no',
    local: 'https://message-center.test.mollercloud.no',
};

const carInformationUrl: Record<MollerEnvironment, string> = {
    prod: 'https://car-information.prod.mollercloud.no',
    staging: 'https://car-information.stage.mollercloud.no',
    test: 'https://car-information.test.mollercloud.no',
    dev: 'https://car-information.dev.mollercloud.no',
    local: 'https://car-information.test.mollercloud.no',
};

const bilholdApiUrl: Record<MollerEnvironment, string> = {
    prod: 'https://bilhold-api.prod.mollercloud.no',
    staging: 'https://bilhold-api.stage.mollercloud.no',
    test: 'https://bilhold-api.test.mollercloud.no',
    dev: 'https://bilhold-api.dev.mollercloud.no',
    local: 'https://localhost:7269',
};

const notificationsUrl: Record<MollerEnvironment, string> = {
    prod: 'https://bilhold-notification.prod.mollercloud.no',
    staging: 'https://bilhold-notification.stage.mollercloud.no',
    test: 'https://bilhold-notification.test.mollercloud.no',
    dev: 'https://bilhold-notification.dev.mollercloud.no',
    local: 'https://bilhold-notification.test.mollercloud.no',
};

const getDamageAppraisalUrl = (environment: MollerEnvironment): string => {
    if (environment === 'staging') {
        return damageAppraisalBaseUrl('stage');
    }
    return damageAppraisalBaseUrl(
        environment,
        import.meta.env.VITE_DAMAGE_APPRAISAL_URL_BOOKING
    );
};
const appRedirectURL = 'carcare://auth/callback';

export function parseEnvironmentName(
    env: string
): MollerEnvironment | 'local' | undefined {
    switch (env) {
        case 'prod':
        case 'staging':
        case 'test':
        case 'dev':
            return env;
        case 'stage':
            return 'staging';
        case 'local':
            return 'local';
        default:
            return undefined;
    }
}

export function environmentNameFromIndex(
    index: number
): EnvironmentName | undefined {
    switch (index) {
        case 0:
            return 'prod';
        case 1:
            return 'staging';
        case 2:
            return 'test';
        case 3:
            return 'dev';
        case 4:
            return 'local';
        default:
            return undefined;
    }
}

export function environmentNameFromLocation(
    location: Location
): EnvironmentName {
    if (
        location.origin?.includes('staging') ||
        location.origin?.includes('stage')
    ) {
        return 'staging';
    }
    if (location.origin?.includes('test')) {
        return 'test';
    }
    if (location.origin?.includes('dev')) {
        return 'dev';
    }
    if (
        [
            'https://www.mittbilhold.no',
            'https://mittbilhold.no',
            'https://bilhold-frontend.prod.mollercloud.no',
        ].some((url) => location.origin?.startsWith(url))
    ) {
        return 'prod';
    }
    if (
        isMobileApp() &&
        [
            'http://localhost', // Android
            'ionic://localhost', // iOS
        ].some((url) => location.origin === url)
    ) {
        return environmentOverride ?? 'prod';
    }
    return import.meta.env.VITE_BACKEND_ENV;
}

export function getEnvironment(name: EnvironmentName): Environment {
    if (name === 'local') {
        const backend = parseEnvironmentName(import.meta.env.VITE_BACKEND_ENV);
        if (!backend) {
            throw new Error(
                'Missing or invalid environment variable VITE_BACKEND_ENV. Check .env / .env.local'
            );
        }
        return {
            name,
            appRedirectURL,
            loginServerUrl: loginServerUrl[backend],
            messageCenterUrl: messageCenterUrl[backend],
            carInformationUrl: carInformationUrl[backend],
            notificationUrl:
                (import.meta.env.VITE_NOTIFICATION_URL as string) ||
                notificationsUrl[backend],
            redirectURL: redirectUrl,
            serverUrl: getServerUrl(backend),
            damageAppraisalApiUrl: getDamageAppraisalUrl(backend),
            auth0: getAuth0Config(backend),
            bilholdApiUrl:
                import.meta.env.VITE_BILHOLD_API_URL_BOOKING ||
                bilholdApiUrl[backend],
        };
    }
    return {
        name,
        appRedirectURL,
        loginServerUrl: loginServerUrl[name],
        messageCenterUrl: messageCenterUrl[name],
        carInformationUrl: carInformationUrl[name],
        notificationUrl: notificationsUrl[name],
        redirectURL: redirectUrl,
        serverUrl: getServerUrl(name),
        damageAppraisalApiUrl: getDamageAppraisalUrl(name),
        auth0: getAuth0Config(name),
        bilholdApiUrl: bilholdApiUrl[name],
    };
}

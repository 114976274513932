import { nextMonth } from '@gnist/design-system';
import {
    QueryClient,
    useMutation,
    useQuery,
    useQueryClient,
} from '@tanstack/react-query';
import { format, formatISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { useFetchersContext } from 'external-apis';
import {
    AvailableTimeSlotsQuery,
    CountryCode,
    ReservedTimeSlotViewModel,
    TimeSlotViewModel,
    TimeslotReserveModelQuery,
} from 'external-apis/src/types/port';
import {
    FriendlyQueryError,
    FriendlyQueryWarning,
} from '../../lib/errors/PortError';
import {
    LanguageContextType,
    useLanguageContext,
} from '../../lib/languages/languageContext';
import { STALE_TIME } from '../../lib/query-client/config';
import { useGetApiLocaleConfig } from '../apiLocale';
import { useFlag } from 'feature-toggle';

const useFindTimeSlots = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.port.fetcher
        .path('/time-slots/search')
        .method('post')
        .create();
};

export const queryPrefix = 'getTimeSlots';

const toGetTimeslotKey = (
    query: AvailableTimeSlotsQuery,
    timeZone: string
) => ({
    ...query,
    startTime: formatInTimeZone(query.startTime, timeZone, 'yyyy-MM'),
});

const getPrefetchBody = (query: AvailableTimeSlotsQuery, offset: number) => ({
    ...query,
    startTime: formatISO(nextMonth(new Date(), offset).setHours(5, 0, 0)),
    endTime: formatISO(
        new Date(
            new Date().getFullYear(),
            new Date().getMonth() + 1 + offset,
            0
        ).setHours(23, 59, 59)
    ),
});

export const usePrefetchTimeslots = (
    query: AvailableTimeSlotsQuery,
    dealerMail: string | undefined
) => {
    const findTimeSlots = useFindTimeSlots();
    const qc = useQueryClient();
    const [lc] = useLanguageContext();
    const { timeZone } = useGetApiLocaleConfig();

    const prefetchSlots = (prefetchBodyoffset: AvailableTimeSlotsQuery) => {
        void qc.prefetchQuery({
            queryKey: [
                queryPrefix,
                toGetTimeslotKey(prefetchBodyoffset, timeZone),
            ],
            queryFn: () =>
                getTimeSlots(prefetchBodyoffset, lc, dealerMail, findTimeSlots),
            staleTime: STALE_TIME.minute * 2,
        });
    };

    prefetchSlots(getPrefetchBody(query, 1));
    prefetchSlots(getPrefetchBody(query, 2));
};

export function useGetTimeSlots(
    body: AvailableTimeSlotsQuery & { startTime: string },
    dealerMail: string | undefined
) {
    const [lc] = useLanguageContext();

    const { timeZone } = useGetApiLocaleConfig();
    const findTimeSlots = useFindTimeSlots();

    // If the key includes startTime with seconds we get 100% cache miss.
    return useQuery<TimeSlotViewModel[], Error>({
        queryKey: [queryPrefix, toGetTimeslotKey(body, timeZone)],
        queryFn: () => getTimeSlots(body, lc, dealerMail, findTimeSlots),
        enabled: !!body,
        staleTime: STALE_TIME.minute * 2,
        refetchOnWindowFocus: false,
    });
}

export async function getTimeSlots(
    body: AvailableTimeSlotsQuery,
    lc: LanguageContextType,
    dealerMail: string | undefined,
    findTimeSlots: ReturnType<typeof useFindTimeSlots>
) {
    try {
        const result = await findTimeSlots(body);
        return result.data;
    } catch (e) {
        if (e instanceof findTimeSlots.Error) {
            throw new FriendlyQueryError(
                lc.errors.port_timeslots_find_default.replace(
                    '{mail}',
                    dealerMail ?? 'kundeservice@moller.no'
                ),
                e,
                e.status
            );
        }
        throw e;
    }
}

const usePostTimeStot = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.port.fetcher
        .path('/time-slots/{timeSlotId}/reserve')
        .method('post')
        .create();
};

export function useReserveTimeSlot({
    vin,
    serviceIds,
    dealerMail,
}: {
    vin: string;
    serviceIds: string[];
    dealerMail: string;
}) {
    const [lc] = useLanguageContext();
    const qc = useQueryClient();

    const { countryCode } = useGetApiLocaleConfig();
    const postTimeslot = usePostTimeStot();

    const shouldUseTireHotelForRecall = useFlag('use-tirehotel-for-recall');
    const TIRE_HOTEL_SERVICE_ID = '4';
    const RECALL_454R_MBOV_SERVICE_ID = 'AS-IAD-2297-011';

    return useMutation<ReservedTimeSlotViewModel, Error, TimeSlotViewModel>({
        mutationFn: (timeSlot: TimeSlotViewModel) =>
            reserveTimeSlot(
                timeSlot,
                vin,
                serviceIds.map((id) => {
                    if (
                        // TODO: do this properly, preferably in backend, with materialNumber
                        id === RECALL_454R_MBOV_SERVICE_ID &&
                        shouldUseTireHotelForRecall
                    )
                        return TIRE_HOTEL_SERVICE_ID;
                    return id;
                }),
                countryCode,
                lc,
                qc,
                postTimeslot,
                dealerMail
            ),
    });
}

async function reserveTimeSlot(
    timeSlot: TimeSlotViewModel,
    vin: string,
    serviceIds: string[],
    countryCode: CountryCode,
    lc: LanguageContextType,
    qc: QueryClient,
    postTimeSlot: ReturnType<typeof usePostTimeStot>,
    dealerMail: string
) {
    const body: TimeslotReserveModelQuery = {
        vin,
        dealerNumber: timeSlot.dealerNumber,
        startTime: timeSlot.startTime,
        estimatedFinished: timeSlot.estimatedFinished,
        resourceId: timeSlot.timeSlotId,
        adapterId: timeSlot.adapterId,
        businessOperation: timeSlot.businessOperation,
        serviceIds,
        countryCode,
    };

    try {
        const result = await postTimeSlot({
            ...body,
            timeSlotId: body.resourceId,
        });
        return result.data;
    } catch (e) {
        if (e instanceof postTimeSlot.Error) {
            const response = e.getActualType();
            if (response) {
                switch (response.status) {
                    case 409:
                        if (
                            response.data.errorType ===
                            'TimeSlotReservationExpired'
                        ) {
                            const warningMsg =
                                lc.errors.port_timeslots_reserve_conflict.replace(
                                    '{time}',
                                    format(new Date(body.startTime), 'HH:mm')
                                );

                            qc.setQueriesData<TimeSlotViewModel[]>(
                                { queryKey: [queryPrefix] },
                                (oldData) => {
                                    return (oldData ?? []).filter(
                                        (x) =>
                                            !(
                                                x.adapterId ===
                                                    body.adapterId &&
                                                x.dealerNumber ===
                                                    body.dealerNumber &&
                                                x.timeSlotId ===
                                                    body.resourceId &&
                                                x.startTime ===
                                                    body.startTime &&
                                                x.estimatedFinished ===
                                                    body.estimatedFinished
                                            )
                                    );
                                }
                            );

                            void qc.invalidateQueries({
                                queryKey: [queryPrefix],
                            });
                            throw new FriendlyQueryWarning(
                                warningMsg,
                                e,
                                e.status
                            );
                        }
                        throw new FriendlyQueryError(
                            lc.errors.default,
                            e,
                            response.status
                        );
                    default:
                        throw new FriendlyQueryError(
                            lc.errors.port_timeslots_reserve_default.replace(
                                '{mail}',
                                dealerMail
                            ),
                            e,
                            e.status
                        );
                }
            }
        }
        throw e;
    }
}

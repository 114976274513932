import { useEffect } from 'react';
import { getCurrentEnvironmentName } from 'src/utils/environment/getCurrentEnvironment';
import { isMobileApp } from 'utils/mobile';

function getOneTrustScriptId() {
    const id = '94f02282-ce75-4a01-9921-11661218aa90';
    return getCurrentEnvironmentName() === 'prod' ? id : `${id}-test`;
}

function initializeCookieBanner() {
    const wrapperScriptTag = document.createElement('script');
    const nonce =
        document.head.querySelector<HTMLMetaElement>('meta[name="nonce"]')
            ?.content ?? '';
    wrapperScriptTag.setAttribute('nonce', nonce);
    wrapperScriptTag.type = 'text/javascript';
    const inlineScript = document.createTextNode(
        'function OptanonWrapper() { }'
    );
    wrapperScriptTag.appendChild(inlineScript);
    document.head.insertBefore(
        wrapperScriptTag,
        document.head.firstElementChild
    );
    const scriptTag = document.createElement('script');
    scriptTag.type = 'text/javascript';
    scriptTag.setAttribute('charset', 'UTF-8');
    scriptTag.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
    scriptTag.setAttribute('data-domain-script', getOneTrustScriptId());
    scriptTag.setAttribute('data-document-language', 'true');
    scriptTag.setAttribute('nonce', nonce);
    scriptTag.setAttribute('crossorigin', 'anonymous');
    document.head.insertBefore(scriptTag, document.head.firstElementChild);
}

function useInitializeCookieBanner() {
    useEffect(() => {
        if (isMobileApp() || window.location.pathname.includes('cookies')) {
            return;
        }

        setTimeout(initializeCookieBanner, 1000);
    }, []);
}

export default useInitializeCookieBanner;

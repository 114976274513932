import {
    AllowedIcons,
    breakpointDesktop,
    Icon,
    DetailText,
} from '@gnist/design-system';
import { styled } from 'styled-components';

const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    ${Icon.selector} {
        margin-right: var(--moller-spacing-xs);

        @media screen and (min-width: ${breakpointDesktop}) {
            margin-right: var(--moller-spacing-m);
        }
    }
`;

export const CardLayout = ({
    icon,
    title,
    description,
}: {
    icon?: AllowedIcons;
    title: string;
    description: string;
}) => {
    return (
        <Container>
            {icon ? <Icon icon={icon} size={'xl'} /> : null}
            <Column>
                {description && <DetailText>{description}</DetailText>}
                <p>{title}</p>
            </Column>
        </Container>
    );
};

import { ExternalArticleQueryResult } from '@bilhold/sanity';
import { datadogRum } from '@datadog/browser-rum';
import { LoadingSpinner } from '@gnist/design-system';
import groq from 'groq';
import { Variables } from 'src/sanity/components/RichText';
import { useSanityData } from 'src/sanity/useSanityData';
import SanityCard from './SanityCard';

interface Props {
    cardTitle: string;
    variables?: Variables;
}

export const ExternalArticleSanity = (props: Props) => {
    const externalArticleQuery = groq`*[_type == "externalArticle" && cardTitle == $cardTitle][0]`;

    const {
        data,
        isPending: isLoading,
        error,
    } = useSanityData<ExternalArticleQueryResult>(externalArticleQuery, {
        cardTitle: props.cardTitle,
    });

    if (isLoading) return <LoadingSpinner isFullScreen />;

    if (error || !data) {
        datadogRum.addError(
            `Error received when trying to fetch external article Sanity data from ${props.cardTitle}`
        );
        return null;
    }

    return (
        <>
            <SanityCard
                isExternalArticle
                cardTitle={data.cardTitle || ''}
                cardDescription={data.cardDescription}
                variables={props.variables}
                image={data.image}
                buttonText={data.linkText}
                buttonLink={data.link || ''}
            />
        </>
    );
};

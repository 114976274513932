import { AlertBanner } from '@gnist/design-system';
import { BaseFriendlyQueryError } from 'external-apis/src/fetchers/errorUtils';
import { FriendlyQueryWarning } from '../lib/errors/PortError';
import { useLanguageContext } from '../lib/languages/languageContext';
import { DDRumPrivacySetting } from './DDRumPrivacySetting';

type QueryErrorProps = {
    isError: boolean;
    error: Error | null;
};
export function QueryError({ isError, error }: QueryErrorProps) {
    const [lc] = useLanguageContext();
    if (!isError || !error) {
        return null;
    }

    if (error instanceof BaseFriendlyQueryError) {
        return (
            <DDRumPrivacySetting privacyLevel="allow">
                <AlertBanner
                    type={
                        error instanceof FriendlyQueryWarning
                            ? 'warning'
                            : 'error'
                    }
                    message={error.message}
                    density={'compact'}
                />
            </DDRumPrivacySetting>
        );
    }
    return (
        <DDRumPrivacySetting privacyLevel="allow">
            <AlertBanner
                type={'error'}
                message={error.message ?? lc.errors.default}
                density={'compact'}
            />
        </DDRumPrivacySetting>
    );
}

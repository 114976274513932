import { breakpointMobile, Column, TextField } from '@gnist/design-system';
import { ServiceViewModel } from 'external-apis/src/types/port';
import { styled } from 'styled-components';
import { useLanguageContext } from '../../../../lib/languages/languageContext';
import { useStandardServicesForm } from '../../useSectionForm';
import { setAdditionalGlassDamageInfo } from './glassDamageFormState';

const DamageFieldGrid = styled.div`
    gap: var(--moller-spacing-s);
    display: grid;
    width: 100%;
    @media (min-width: ${breakpointMobile}) {
        grid-template-columns: 1fr 1fr;
    }
`;
type GlassDamageDetailsProps = {
    setIncidentLocation: (x: string) => void;
    setIncidentDate: (x: string) => void;
    incidentLocation: string;
    incidentDate: string;
    incidentLocationValidity:
        | {
              type: 'error';
              message: string;
          }
        | undefined;
    incidentDateValidity:
        | {
              type: 'error';
              message: string;
          }
        | undefined;
    service: ServiceViewModel;
    serviceForm: ReturnType<typeof useStandardServicesForm>;
};

export function GlassDamageDetails({
    setIncidentLocation,
    setIncidentDate,
    incidentLocation,
    incidentDate,
    incidentDateValidity,
    incidentLocationValidity,
    serviceForm,
    service,
}: GlassDamageDetailsProps) {
    const [lc] = useLanguageContext();
    return (
        <DamageFieldGrid>
            <Column>
                <p>{lc.standardServices.glass_damage_when}?</p>
                <TextField
                    label={'DD.MM.YYYY'}
                    onChange={(e) => setIncidentDate(e.target.value)}
                    value={incidentDate}
                    validity={incidentDateValidity}
                    onBlur={() => {
                        setAdditionalGlassDamageInfo({
                            serviceForm,
                            service,
                            lc,
                        });
                    }}
                />
            </Column>
            <Column>
                <p>{lc.standardServices.glass_damage_where}?</p>
                <TextField
                    label={lc.standardServices.glass_damage_location_label}
                    onChange={(e) => setIncidentLocation(e.target.value)}
                    value={incidentLocation}
                    validity={incidentLocationValidity}
                    onBlur={() => {
                        setAdditionalGlassDamageInfo({
                            serviceForm,
                            service,
                            lc,
                        });
                    }}
                />
            </Column>
        </DamageFieldGrid>
    );
}

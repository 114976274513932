import { CheckboxCard, Column } from '@gnist/design-system';
import { ServiceViewModel } from 'external-apis/src/types/port';
import { Price, parseServicePrices } from '../../../shared/prices/Price';
import { ServiceDescription } from '../../../shared/services/ServiceDescription';
import { useStandardServicesForm } from '../../useSectionForm';
import { useStandardServiceListValidity } from '../../useStandardServiceListValidity';
import { toggleFeature } from '../toggleFeature';
import { GlassDamageDetails } from './GlassDamageDetails';
import { GlassDamageInfoBox } from './GlassDamageInfoBox';
import { GlassDamageOptions } from './GlassDamageOptions';

type GlassDamageProps = {
    serviceForm: ReturnType<typeof useStandardServicesForm>;
    services: ServiceViewModel[];
    dealerNumber: string;
};

export function GlassDamage({
    serviceForm,
    services,
    dealerNumber,
}: GlassDamageProps) {
    const glassDamageService = services.find(
        (x) => x.category === 'GlassDamage'
    );
    const selectedIds = serviceForm.state.raw.selectedIds;
    const selectedGlassDamageServiceId = selectedIds.find(
        (x) => x === glassDamageService?.id
    );
    const selectedDamageExtent = serviceForm.state.raw.glassDamageExtent;
    const isNearEdge = serviceForm.state.raw.glassDamageNearEdge;
    const setSelectedIds = serviceForm.setValue('selectedIds');
    const setIncidentDate = serviceForm.setValue('glassDamageIncidentDate');
    const setIncidentLocation = serviceForm.setValue(
        'glassDamageIncidentLocation'
    );
    const validity = serviceForm.inputProps('selectedIds').validity;
    const serviceListValidity = useStandardServiceListValidity(validity);
    const incidentLocation = serviceForm.state.raw.glassDamageIncidentLocation;
    const incidentDate = serviceForm.state.raw.glassDamageIncidentDate;
    const incidentLocationValidity = serviceForm.inputProps(
        'glassDamageIncidentLocation'
    ).validity;
    const incidentDateValidity = serviceForm.inputProps(
        'glassDamageIncidentDate'
    ).validity;

    if (!glassDamageService) {
        return null;
    }

    return (
        <CheckboxCard
            onChange={() =>
                toggleFeature({
                    id: glassDamageService.id,
                    selectedServiceIds: selectedIds,
                    setSelectedServiceIds: setSelectedIds,
                })
            }
            density="compact"
            value={!!selectedGlassDamageServiceId}
            description={ServiceDescription({
                selected: !!selectedGlassDamageServiceId,
                description: glassDamageService.description,
                children: (
                    <Price
                        values={parseServicePrices(
                            glassDamageService,
                            dealerNumber
                        )}
                    />
                ),
            })}
            label={glassDamageService.name}
            {...serviceListValidity}
        >
            {selectedGlassDamageServiceId && (
                <Column gap={'xxs'}>
                    <GlassDamageOptions
                        service={glassDamageService}
                        serviceForm={serviceForm}
                    />
                    <GlassDamageInfoBox
                        changeScreen={
                            isNearEdge || selectedDamageExtent === 'large'
                        }
                    />
                    <GlassDamageDetails
                        setIncidentDate={setIncidentDate}
                        setIncidentLocation={setIncidentLocation}
                        incidentLocation={incidentLocation}
                        incidentDate={incidentDate}
                        incidentDateValidity={incidentDateValidity}
                        incidentLocationValidity={incidentLocationValidity}
                        service={glassDamageService}
                        serviceForm={serviceForm}
                    />
                </Column>
            )}
        </CheckboxCard>
    );
}

import { Middleware } from 'openapi-typescript-fetch';

export function userSelfServiceMiddleware(): Middleware {
    return async (url, init, next) => {
        init.headers.append(
            'X-Moller-UserSelfService-Api-Client-Id',
            'MITT_BILHOLD'
        );

        return await next(url, init);
    };
}

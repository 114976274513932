import { LocalFormState } from '@gnist/design-system/utilities/forms/useLocalFormState.js';
import { VehicleInfo } from 'external-apis/src/types/bilhold';
import {
    DEALER_TYPE_DAMAGE,
    DEALER_TYPE_SERVICE,
    DealerType,
    DealerViewModel,
} from 'external-apis/src/types/port';
import { getThemeFromDealerName, useThemeContext } from 'theming';
import { GUMPENS_ECONOMY_SERVICE } from '../../lib/constants/dealerConstants';
import { useFastTrack } from '../../lib/useFastTrack';
import { isBrandMatch } from '../../lib/util/brandUtils';
import { vehicleOlderThanXYears } from '../../lib/util/vehicleOlderThanXYears';
import { DealerForm } from './ChooseDealerSection';
import { DealerPicker } from './dealer-picker';

function getFilteredDealers(
    dealers: DealerViewModel[],
    selectedVehicle: VehicleInfo,
    activeDealerTypes: DealerType[],
    filterByBrand: boolean
): DealerViewModel[] {
    const isOldVehicle = vehicleOlderThanXYears(selectedVehicle.modelYear, 10);
    const filteredDealers = filterByBrand
        ? dealers.filter((dealer) =>
              dealer.serviceCertifications.some((cert) =>
                  isBrandMatch(cert, selectedVehicle.brand)
              )
          )
        : dealers;
    return (
        filteredDealers
            // TODO: should this logic be a part of DealerPicker instead?
            .filter((dealer) => !(isEconomyDealer(dealer.id) && !isOldVehicle))
            .filter((dealer) =>
                activeDealerTypes.some((type) =>
                    dealer.dealerTypes.includes(type)
                )
            )
    );
}

function isEconomyDealer(dealerId: string) {
    return dealerId === GUMPENS_ECONOMY_SERVICE;
}

type ChooseDealerEditProps = {
    dealers: DealerViewModel[];
    dealerForm: LocalFormState<DealerForm>;
    vehicle: VehicleInfo;
    preferredDealerId: string | undefined;
};

export function ChooseDealerEdit({
    dealers,
    vehicle,
    dealerForm,
    ...props
}: ChooseDealerEditProps) {
    const { setTheme } = useThemeContext();

    const fastTrackType = useFastTrack();
    const getDealerTypes: DealerType[] = [
        DEALER_TYPE_SERVICE,
        ...(fastTrackType.fastTrack === 'glass-damage'
            ? [DEALER_TYPE_DAMAGE]
            : []),
    ];

    const filteredDealers = getFilteredDealers(
        dealers,
        vehicle,
        getDealerTypes,
        !fastTrackType.fastTrack
    );

    const inputProps = dealerForm.inputProps('selectedDealer');

    function handleChange(dealer: DealerViewModel) {
        inputProps.setValue(dealer.id);
        const theme = getThemeFromDealerName(dealer.name);
        setTheme(theme);
    }

    return (
        <DealerPicker
            dealers={filteredDealers}
            onChange={handleChange}
            selectedDealerId={inputProps.value}
            validity={inputProps.validity}
            {...props}
        />
    );
}

import { ServiceViewModel } from 'external-apis/src/types/port';
import { useStandardServicesForm } from '../useSectionForm';
import { CommentField } from './CommentField';
import { ResourceGroups } from './ResourceGroups';
import { existingInfo, IncludesInfoId } from './AdditionalInfoHelpers';
import { useFlag } from 'feature-toggle';
import { getAdditionalInfoSetter } from './AdditionalInfoFormState';
import { InterviewQuestions } from './InterviewQuestions';
import { textFieldValidity } from '../../../lib/form/validationUtils';

type AdditionalServiceInfoProps = {
    serviceForm: ReturnType<typeof useStandardServicesForm>;
    service: ServiceViewModel;
};

export function AdditionalServiceInfo({
    serviceForm,
    service,
}: AdditionalServiceInfoProps) {
    const info = service.additionalInfo;
    const noAdditionalInfo = !info || info.length === 0;
    if (noAdditionalInfo) {
        return null;
    }
    return info.map((x, i) => (
        <AdditionalInfoContent
            key={`${x.infoType}=${i}`}
            serviceForm={serviceForm}
            service={service}
            info={x}
        />
    ));
}

type AdditionalInfoContentProps = {
    serviceForm: ReturnType<typeof useStandardServicesForm>;
    info: NonNullable<ServiceViewModel['additionalInfo']>[number];
    service: ServiceViewModel;
};

function AdditionalInfoContent({
    service,
    serviceForm,
    info,
}: AdditionalInfoContentProps) {
    const inputProps = serviceForm.inputProps;
    const selectedServices = inputProps('selectedIds').value;
    const selectedValues = inputProps('additionalInfo').value;
    const setValues = inputProps('additionalInfo').setValue;
    const requiredFieldValidity = inputProps('selectedIds').validity;

    // we might want to remove this and replace it with the requiredField validity as for comment and interview
    const additionalInfoValidity = inputProps('additionalInfo').validity;
    const serviceInfoId: IncludesInfoId = {
        adapterId: service.adapterId,
        serviceId: service.id,
    };

    const showCustomerInterview = useFlag('enable-show-forkontroll');
    const showResourceGroup = useFlag('enable-show-resource-group');
    if (
        info.infoType === 'CustomerInterview' &&
        info.customerInterviewQuestions &&
        selectedServices.includes(service.id) &&
        showCustomerInterview
    ) {
        return (
            <InterviewQuestions
                questions={info.customerInterviewQuestions}
                questionsAndAnswers={
                    selectedValues.find(existingInfo(serviceInfoId))
                        ?.interviewQuestions
                }
                setQuestionsAndAnswers={getAdditionalInfoSetter({
                    service: serviceInfoId,
                    field: 'interviewQuestions',
                    selectedValues,
                    setValues,
                })}
                textFieldValidity={textFieldValidity}
                requiredFieldValidity={requiredFieldValidity}
            />
        );
    }

    if (info.infoType === 'Comment' && selectedServices.includes(service.id)) {
        return (
            <CommentField
                comment={
                    selectedValues.find(existingInfo(serviceInfoId))?.comment ??
                    ''
                }
                setComment={getAdditionalInfoSetter({
                    service: serviceInfoId,
                    field: 'comment',
                    selectedValues,
                    setValues,
                })}
                textFieldValidity={textFieldValidity}
                requiredFieldValidity={requiredFieldValidity}
            />
        );
    }

    if (
        info.infoType === 'ResourceGroup' &&
        selectedServices.includes(service.id) &&
        showResourceGroup
    ) {
        return (
            <ResourceGroups
                groups={info.resourceGroups}
                setResourceGroup={getAdditionalInfoSetter({
                    service: serviceInfoId,
                    field: 'resourceGroup',
                    selectedValues,
                    setValues,
                })}
                selectedGroup={
                    selectedValues.find(existingInfo(serviceInfoId))
                        ?.resourceGroup
                }
                validity={additionalInfoValidity}
            />
        );
    }
}

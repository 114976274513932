import {
    Accordion,
    Column,
    InfoCard,
    LeadText,
    Link,
} from '@gnist/design-system';
import lang from 'src/utils/lang';
import { styled } from 'styled-components';

const StyledInfoCard = styled(InfoCard)`
    border-style: none;
`;

const StyledAccordion = styled(Accordion)`
    summary {
        padding-left: 0;
        &:focus {
            outline: none;
        }
    }
    div {
        padding-left: 0;
    }
    p {
        font-size: var(--moller-type-medium-body-font-size);
    }
`;

export const WorkshopHistoryGuide = () => {
    return (
        <StyledInfoCard>
            <Column gap="s">
                <LeadText>{lang.wsHistoryGuideTitle}</LeadText>
                <p>{lang.wsHistoryGuideDescription}</p>
            </Column>
            <StyledAccordion
                head={<p>{lang.wsHistoryGuideAccordionTitle}</p>}
                withBackground
            >
                <Column gap="s">
                    <p>
                        {`${lang.wsHistoryGuideAccordionTextA}`}{' '}
                        <Link href="https://www.vegvesen.no/en/vehicles/buy-and-sell/vehicle-information/finn-eier/">
                            Finn eier
                        </Link>
                    </p>
                    <p>{lang.wsHistoryGuideAccordionTextB}</p>
                    <p>{lang.wsHistoryGuideAccordionTextC}</p>
                </Column>
            </StyledAccordion>
        </StyledInfoCard>
    );
};

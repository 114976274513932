import { Column, Heading3Text } from '@gnist/design-system';
import { ExistingCarRelation } from 'external-apis/src/types/bilhold';
import { useMemo } from 'react';
import { BookingViewModel } from 'src/features/appointment/api/useAppointments';
import { useShowCompetitionForBetaUsers } from 'src/features/competition/hooks/useShowCompetitionForBetaUsers';
import { BuyUsedCar } from 'src/features/home/current-actions/buy-used-car/BuyUsedCar';
import { useWorkshopHistory } from 'src/features/my-car/query/useWorkshopHistory';
import { CompetitionCard } from 'src/shared/competition-card/CompetitionCard';
import { VwCompetitionContent } from 'src/shared/competition-card/VwCompetitionContent';
import VwGenserImage from 'src/shared/competition-card/vw-genser.png';
import { dealerIsUpdatingSystems } from 'src/utils/dealerIsUpdatingSystems';
import useFeatureToggle from 'src/utils/dev-tools/useFeatureToggle';
import { normalizeDealerName } from 'src/utils/normalizeDealerName';
import { styled } from 'styled-components';
import { CarPriceEstimateCard } from '../../current-actions/car-price-estimate/CarPriceEstimateCard';
import { isEligibleForValuation } from '../../current-actions/car-price-estimate/utils';
import { EuControl } from '../../current-actions/eu-control/EuControl';
import { InspectionService } from '../../current-actions/inspection-service/InspectionService';
import { RecallService } from '../../current-actions/recalls/RecallService';
import { TireChangeHotel } from '../../current-actions/tire-change-hotel/TireChangeHotel';
import { WarningLightsCard } from '../../current-actions/warning-lights/WarningLightsCards';
import { useCarInformation } from '../../queries/useCarInformation';
import { usePreferredAffiliationDealers } from '../../queries/usePreferredDealer';
import { useRecalls } from '../../queries/useRecalls';
import {
    hasUpcomingEuControl,
    useEuControlCard,
    useInspectionServiceCard,
    useRecallCard,
    useTireChangeCard,
} from '../../utils/useUpcomingActions';
import { SystemUpgradeCard } from './system-upgrade-info/SystemUpgradeCard';

const StyledContainer = styled.div`
    margin-top: var(--moller-spacing-xl);
    margin-bottom: var(--moller-spacing-s);
`;

const StyledOverline = styled(Heading3Text)`
    margin-top: var(--moller-spacing-xl);
    margin-bottom: var(--moller-spacing-xxs);
`;

const StyledColumn = styled(Column)`
    gap: var(--moller-spacing-xs);
`;

interface RecommendedProps {
    activeCar: ExistingCarRelation;
    appointments?: BookingViewModel[];
}

export const Recommended = ({ activeCar, appointments }: RecommendedProps) => {
    const { data: preferredDealerData } = usePreferredAffiliationDealers();

    const showBuyUsedCarCardFlag = useFeatureToggle('ShowBuyUsedCarCard');

    const carInformation = useCarInformation(activeCar.vin);

    const workshopHistory = useWorkshopHistory(activeCar.vin);

    const showForBetaUsers = useShowCompetitionForBetaUsers();
    const showCompetitionCard = useFeatureToggle('ShowCompetitionCard');

    const { data: recalls } = useRecalls(activeCar.vin);

    const ShowVolkswagenCompetitionHome = useFeatureToggle(
        'ShowVolkswagenCompetitionHome'
    );

    const debug_showEuControlCard = useFeatureToggle('Debug_ShowEuControlCard');
    const debug_showInspectionServiceCard = useFeatureToggle(
        'Debug_ShowInspectionServiceCard'
    );
    const debug_showTireServiceCard = useFeatureToggle(
        'Debug_ShowTireServiceCard'
    );

    const showCarValuationCards =
        useFeatureToggle('ShowCarValuation') &&
        isEligibleForValuation(activeCar);

    const nextEuControlDate =
        carInformation.data?.periodicalVehicleControlData?.nextControlDate;

    const carAppointments = useMemo(() => {
        if (appointments) {
            return appointments.filter((ap) => ap.vin === activeCar?.vin);
        }
        return [];
    }, [activeCar, appointments]);

    const carPreferredDealer = Array.isArray(preferredDealerData)
        ? preferredDealerData?.find(
              (p) => p.vin === activeCar.vin && p.preferredDealerName != null
          )
        : undefined;

    const showEuControlCard =
        (useEuControlCard(carAppointments, nextEuControlDate) &&
            !!nextEuControlDate) ||
        debug_showEuControlCard;

    const showInspectionServiceCard =
        useInspectionServiceCard(
            activeCar,
            carAppointments,
            carInformation.data,
            workshopHistory.data
        ) || debug_showInspectionServiceCard;

    const showTireServiceCard =
        useTireChangeCard(
            activeCar.vin,
            carPreferredDealer,
            carAppointments,
            workshopHistory.data
        ) || debug_showTireServiceCard;

    const showRecallCard =
        useRecallCard(carAppointments, workshopHistory.data) &&
        recalls &&
        recalls?.length > 0;

    const ShowSystemUpgradeInfo = useFeatureToggle('ShowSystemUpgradeInfo');

    const hasMollerPreferredDealer =
        carPreferredDealer &&
        normalizeDealerName(carPreferredDealer?.preferredDealerName) ===
            'mollerbil';

    const showBuyUsedCarCard = !!(
        hasMollerPreferredDealer &&
        hasUpcomingEuControl(nextEuControlDate) &&
        showBuyUsedCarCardFlag
    );

    const ShowVWCompetition =
        activeCar.brand === 'Volkswagen' && ShowVolkswagenCompetitionHome;

    const ShowDahleSystemUpgrade =
        dealerIsUpdatingSystems(carPreferredDealer?.preferredDealerName) &&
        ShowSystemUpgradeInfo;

    const showCompetitionSkoda =
        activeCar.brand === 'Skoda' &&
        (showCompetitionCard || showForBetaUsers);

    const showRecommendedSection =
        showBuyUsedCarCard ||
        showCarValuationCards ||
        hasMollerPreferredDealer ||
        showCompetitionSkoda ||
        ShowVWCompetition ||
        ShowDahleSystemUpgrade ||
        showRecallCard ||
        showEuControlCard ||
        showInspectionServiceCard ||
        showTireServiceCard;

    if (!showRecommendedSection) return null;

    return (
        <StyledContainer>
            <StyledOverline>For deg</StyledOverline>
            <StyledColumn>
                {showEuControlCard && nextEuControlDate && (
                    <EuControl nextDate={nextEuControlDate} car={activeCar} />
                )}
                {ShowDahleSystemUpgrade && <SystemUpgradeCard />}
                {showRecallCard && (
                    <RecallService recalls={recalls} car={activeCar} />
                )}
                {showInspectionServiceCard && (
                    <InspectionService car={activeCar} />
                )}
                {showTireServiceCard && carPreferredDealer && (
                    <TireChangeHotel
                        car={activeCar}
                        preferredDealer={carPreferredDealer}
                    />
                )}
                {ShowVWCompetition && (
                    <CompetitionCard
                        image={VwGenserImage}
                        link="https://www.volkswagen.no/no/vinn-en-id7.html"
                    >
                        <VwCompetitionContent />
                    </CompetitionCard>
                )}
                {showBuyUsedCarCard && <BuyUsedCar />}

                {hasMollerPreferredDealer && (
                    <>
                        <WarningLightsCard />
                        {showCarValuationCards && (
                            <CarPriceEstimateCard
                                vin={activeCar.vin}
                                mileage={activeCar.mileage ?? 0}
                            />
                        )}
                    </>
                )}
            </StyledColumn>
        </StyledContainer>
    );
};

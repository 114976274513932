import { InfoCard } from '@gnist/design-system';
import { useQueryClient } from '@tanstack/react-query';
import { ReservedTimeSlotViewModel } from 'external-apis/src/types/port';
import { useEffect, useRef, useState } from 'react';
import { useGetDealers } from '../../_api/http/dealers';
import { queryPrefix } from '../../_api/http/timeslots';
import { DDRumPrivacySetting } from '../../components/DDRumPrivacySetting';
import { FlexRow } from '../../components/FlexRow';
import { QueryError } from '../../components/QueryError';
import {
    BilholdViewHidden,
    BilholdViewLayout,
} from '../../components/bilhold/BilholdView';
import { BilholdViewTitle } from '../../components/bilhold/BilholdViewTitle';
import { useLanguageContext } from '../../lib/languages/languageContext';
import { useScrollRefIntoView } from '../../lib/util/scroll';
import { ChooseDealerResult } from '../choose-dealer/ChooseDealerSection';
import { ChooseVehicleResult } from '../choose-vehicle/ChooseVehicleSection';
import { SectionState, checkDoneDependencies } from '../sectionUtils';
import { StandardServicesResult } from '../standard-services/StandardServicesSection';
import { ChooseTimeslotDone as ChooseTimeSlotDone } from './ChooseTimeslotDone';
import { ChooseTimeSlotEdit } from './ChooseTimeslotEdit';

export type ChooseTimeSlotData =
    | {
          isRequest: false;
          reservedTimeSlot: ReservedTimeSlotViewModel;
      }
    | {
          isRequest: true;
          reservedTimeSlot: undefined;
      };

export type ChooseTimeSlotState = SectionState<ChooseTimeSlotData>;
export type ChooseTimeSlotDependencies = {
    chooseVehicle: ChooseVehicleResult;
    chooseDealer: ChooseDealerResult;
    standardServices: StandardServicesResult;
};

export function useChooseTimeSlot({
    initialState,
    dependencies,
    isBookingRequest,
}: {
    initialState: ChooseTimeSlotState;
    dependencies: ChooseTimeSlotDependencies;
    isBookingRequest: boolean;
}) {
    const [state, setState] = useState(initialState);
    const depsAreDone = checkDoneDependencies(dependencies);
    useEffect(() => {
        if (depsAreDone) {
            setState({ viewMode: 'edit' });
        } else {
            setState({ viewMode: 'hidden' });
        }
    }, [depsAreDone]);

    if (depsAreDone && state.viewMode === 'done') {
        const done = {
            result: {
                viewMode: state.viewMode,
                data: state.data,
            },
            props: {
                viewMode: state.viewMode,
                dependencies,
                setSectionState: setState,
                data: state.data,
            },
        };
        return done;
    }

    if (depsAreDone && state.viewMode === 'edit' && isBookingRequest) {
        const edit = {
            result: {
                viewMode: 'done' as const,
                data: {
                    isRequest: true as const,
                    reservedTimeSlot: undefined,
                },
            },
            props: {
                viewMode: 'done' as const,
                dependencies,
                data: {
                    isRequest: true as const,
                    reservedTimeSlot: undefined,
                },
                setSectionState: setState,
            },
        };
        return edit;
    }

    if (depsAreDone && state.viewMode === 'edit') {
        const edit = {
            result: {
                viewMode: state.viewMode,
            },
            props: {
                viewMode: state.viewMode,
                dependencies,
                setSectionState: setState,
            },
        };
        return edit;
    }

    return {
        result: {
            viewMode: 'hidden' as const,
        },
        props: {
            viewMode: 'hidden' as const,
            setSectionState: setState,
        },
    };
}

type ChooseTimeSlotProps = ReturnType<typeof useChooseTimeSlot>['props'] & {
    isBookingRequest: boolean;
};
export type ChooseTimeSlotResult = ReturnType<
    typeof useChooseTimeSlot
>['result'];

export function ChooseTimeSlot({
    isBookingRequest,
    setSectionState,
    ...props
}: ChooseTimeSlotProps) {
    const [lc] = useLanguageContext();
    const qc = useQueryClient();

    const sectionRef = useRef(null);
    useScrollRefIntoView(props.viewMode === 'edit', sectionRef);

    if (isBookingRequest) {
        return null;
    }

    return (
        <DDRumPrivacySetting privacyLevel="allow">
            <BilholdViewLayout>
                <FlexRow ref={sectionRef}>
                    <BilholdViewTitle
                        title={lc.chooseTimeslot.title}
                        isEditable={props.viewMode === 'done'}
                        onClick={() => {
                            void qc.resetQueries({ queryKey: [queryPrefix] });
                            setSectionState({
                                viewMode: 'edit',
                            });
                        }}
                    />
                </FlexRow>
                <ChooseTimeSlotSection
                    {...props}
                    setSectionState={setSectionState}
                    isBookingRequest={isBookingRequest}
                />
            </BilholdViewLayout>
        </DDRumPrivacySetting>
    );
}

export function ChooseTimeSlotSection(props: ChooseTimeSlotProps) {
    const dealerResponse = useGetDealers();

    if (props.viewMode === 'hidden') {
        return <ChooseTimeSlotHidden />;
    }

    if (dealerResponse.isPending) {
        return <InfoCard isLoading />;
    }

    if (dealerResponse.isError) {
        return <QueryError error={dealerResponse.error} isError />;
    }
    if (props.viewMode === 'edit') {
        return (
            <ChooseTimeSlotEdit
                dependencies={props.dependencies}
                setSectionState={props.setSectionState}
            />
        );
    }

    if (props.viewMode === 'done' && !props.data.isRequest) {
        return (
            <ChooseTimeSlotDone
                reservedTimeSlot={props.data.reservedTimeSlot}
                selectedServices={
                    props.dependencies.standardServices.data.selectedServices
                }
                dealers={dealerResponse.data}
            />
        );
    }

    return (
        <QueryError
            error={
                new Error(
                    'Frontend error: The app entered an invalid state: TimeSlot section.'
                )
            }
            isError
        />
    );
}

function ChooseTimeSlotHidden() {
    const [lc] = useLanguageContext();
    return <BilholdViewHidden desc={lc.chooseTimeslot.description} />;
}

import loginRedirect from 'src/utils/auth/loginRedirect';
import { Section, SectionTextLayout } from '../components';

export const ChangeTire = () => {
    return (
        <Section withBackgroundColor={true}>
            <SectionTextLayout
                title={'Gjør hjulskiftet enkelt og stressfritt med Bilhold'}
                cta={{
                    label: 'Bestill time for hjulskift',
                    onClick: () => loginRedirect(),
                }}
                wideText={true}
            >
                <p>
                    Bestill tid hos våre profesjonelle verksteder som skifter
                    hjulene mens du venter, og lagre hjulene dine på vårt
                    dekkhotell for å spare plass hjemme. Vi sørger for at
                    hjulene dine alltid er trygge og rene, ved å vaske og
                    kvalitetssjekke dem før hver sesong{' '}
                </p>
            </SectionTextLayout>
        </Section>
    );
};

import {
    Column,
    InfoCard,
    Row,
    TextContainer,
    breakpointMobile,
} from '@gnist/design-system';
import {
    HeadingPrizeCard,
    TDFTextMedium,
    TextSmallThin,
} from 'src/features/competition/textComponents/TDFText';
import { styled } from 'styled-components';
import thuleTent from '../../images/Thule_tent.jpg';

const PrizesCard = styled(InfoCard)`
    background-color: white;
    color: black;
    margin: var(--moller-spacing-s) 0;
    border: 0;
    padding-bottom: 0;
    padding-right: 0;
`;

const TDSPicture = styled.img`
    @media screen and (min-width: ${breakpointMobile}) {
        height: 35%;
        width: 35%;
    }
    object-fit: scale-down;
    border-bottom-right-radius: var(--moller-radius-card);
    width: 60%;
    align-self: center;
`;

const StyledList = styled.ul`
    padding: 0 var(--moller-spacing-s);
    width: 95%;
`;
const PaddingRow = styled(Row)`
    padding-right: var(--moller-spacing-m);
`;

export const FirstPrize = () => {
    return (
        <PrizesCard>
            <Column gap={'m'}>
                <PaddingRow verticalAlign={'start'}>
                    <TextContainer>
                        <HeadingPrizeCard>Hovedpremie</HeadingPrizeCard>
                        <TextSmallThin>
                            Samle lodd til trekningen ved å sykle etapper i
                            touren. Jo flere lodd, jo større blir vinnersjansene
                            dine!
                        </TextSmallThin>
                        <TextSmallThin>
                            Vinneren kan velge blant følgende premier:
                        </TextSmallThin>
                        <StyledList>
                            <li>
                                <TDFTextMedium>
                                    Låne en Škoda i to valgfrie uker (ila 2024)
                                </TDFTextMedium>
                            </li>
                            <li>
                                <TDFTextMedium>
                                    Få et taktelt til bil fra Thule
                                </TDFTextMedium>
                            </li>
                        </StyledList>
                    </TextContainer>
                </PaddingRow>
                <Row
                    horizontalAlign={'spaceBetween'}
                    verticalAlign={'center'}
                    style={{ width: '100%' }}
                >
                    <TextSmallThin>
                        Vinneren av hovedpremien trekkes 9. august!
                    </TextSmallThin>
                    <TDSPicture src={thuleTent} alt={'thule taktelt'} />
                </Row>
            </Column>
        </PrizesCard>
    );
};

import { Accordion, TextContainer } from '@gnist/design-system';
import { styled } from 'styled-components';

const StyledTextContainer = styled(TextContainer)`
    padding-top: 0 !important;

    > *:first-child {
        margin-top: 0 !important;
    }
`;

export const EuControlCampaignAccordion = () => {
    return (
        <div>
            <Accordion head="Hva sjekkes på EU-kontroll?" withDivider>
                <StyledTextContainer>
                    <p>
                        Våre eksperter gjør en grundig sjekk etter
                        retningslinjer som er lovpålagt fra Statens Vegvesen. Vi
                        sjekker blant annet:
                    </p>
                    <ul>
                        <li>Det elektroniske stabilitetssystemet</li>
                        <li>Bilens airbager </li>
                        <li>Bremseskiver, -klosser og -bånd</li>
                        <li>Eventuelle lekkasjer</li>
                        <li>Lykter</li>
                        <li>Karosseri</li>
                        <li>Hjuloppheng</li>
                    </ul>
                    <p>
                        Når kontrollen er ferdig vil du motta en kontrollseddel
                        med resultatet. Her vil du blant annet se registrert
                        kilometerstand, neste frist og eventuelle feil og
                        mangler.
                    </p>
                </StyledTextContainer>
            </Accordion>
            <Accordion head="Må alle feil på bilen rettes?" withDivider>
                <StyledTextContainer>
                    <p>
                        Dersom kjøretøyet har feil eller mangler vil du få
                        beskjed om hvilke større feil og mangler som må rettes
                        og godkjennes i en etterkontroll innen fristen. Du vil
                        også få opplyst om mindre feil eller mangler som bør
                        rettes, men som ikke har betydning for om kjøretøyet kan
                        godkjennes.
                    </p>
                </StyledTextContainer>
            </Accordion>
            <Accordion
                head="Hva skjer hvis jeg glemmer EU-kontroll?"
                withDivider
            >
                <StyledTextContainer>
                    <p>
                        Er ikke kjøretøyet ditt godkjent innen fristen, kjører
                        du ulovlig og kjøretøyet kan bli avskiltet. Hvis du har
                        glemt fristen kan du bestille time hos oss og få
                        kjøretøyet godkjent. Du kan kjøre til verkstedet hvis du
                        leverer skiltene på en trafikkstasjon og kjøper
                        prøveskilt.
                    </p>
                </StyledTextContainer>
            </Accordion>
        </div>
    );
};

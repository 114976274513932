import { Section } from '../booking';
import { adobeEvent } from './src';

export interface BookingFormProgressEventDataType {
    action: 'formProgress';
    name: FormNames;
    type: FormType;
    fromStepName: Section;
    toStepName: Section;
}

export const sendBookingFormProgressToAdobe = (
    fromStepName: Section,
    toStepName: Section,
    platform: string
) => {
    adobeEvent.push(
        'formInteraction',
        {
            name: 'Verkstedtjenester',
            type: 'booking',
            action: 'formProgress',
            fromStepName: fromStepName,
            toStepName: toStepName,
        },
        platform
    );
};

export const formInteractionEventData = (
    eventData: FormInteractionEventDataType
) => ({
    form: eventData,
});

type FormNames =
    | 'Verkstedtjenester'
    | 'Glasskade'
    | 'Hjulskift'
    | 'Gratis skadetakst';

type FormType = 'booking' | 'fasttrack booking';

export type FormInteractionEventDataType = BookingFormProgressEventDataType;

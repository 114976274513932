import { datadogRum } from '@datadog/browser-rum';
import { AlertBanner, Column } from '@gnist/design-system';
import { ExistingCarRelation } from 'external-apis/src/types/bilhold';
import {
    BookingViewModel,
    useAppointments,
} from 'src/features/appointment/api/useAppointments';
import { withErrorBoundary } from 'src/utils/ErrorBoundary';
import lang from 'src/utils/lang';
import { styled } from 'styled-components';
import UpcomingActions from './UpcomingActions';

const StyledColumn = styled(Column)`
    margin-top: var(--moller-spacing-s);
    margin-bottom: var(--moller-spacing-s);
`;

interface UpcomingProps {
    activeCar: ExistingCarRelation;
}

// TODO: A bit tedious to have to do this to all elements on the front page.
// Layout/spacing should be handled by a container surrounding all elements.
const AlertBannerWithHorizMargin = styled(AlertBanner)`
    margin-left: var(--moller-spacing-s);
    margin-right: var(--moller-spacing-s);
`;

const fallback = (retry: () => void) => (
    <AlertBannerWithHorizMargin
        type="warning"
        message="Vi greide dessverre ikke å vise kommende avtaler eller oppgaver for denne bilen. Prøv igjen senere!"
        action={{ label: 'Prøv igjen', onClick: retry }}
    />
);

export const Upcoming = withErrorBoundary(
    'Upcoming',
    (props: UpcomingProps) => {
        const appointments = useAppointments();
        if (appointments.isError) {
            datadogRum.addError(new Error('Upcoming failed to load data'), {
                error: appointments.error,
            });
            return fallback(() => void appointments.refetch());
        }
        return (
            <UpcomingView
                {...props}
                appointments={appointments.data}
                isLoading={appointments.isLoading}
            />
        );
    },
    { fallback }
);

interface UpcomingViewProps extends UpcomingProps {
    appointments?: BookingViewModel[];
    isLoading: boolean;
}

const UpcomingView = ({
    activeCar,
    appointments,
    isLoading,
}: UpcomingViewProps) => {
    const carAppointments = appointments?.filter(
        (ap) => ap.vin === activeCar?.vin && ap.type === 'Scheduled'
    );

    const carRequestAppointments = appointments?.filter(
        (ap) => ap.vin === activeCar?.vin && ap.type === 'Request'
    );

    const showAppointmentSection =
        carAppointments && carAppointments.length > 0;

    const showRequestAppointmentSection =
        carRequestAppointments && carRequestAppointments.length > 0;

    return (
        <StyledColumn>
            {showAppointmentSection && (
                <UpcomingActions
                    appointments={carAppointments}
                    title={lang.futureAppointments}
                    isLoading={isLoading}
                />
            )}
            {showRequestAppointmentSection && (
                <UpcomingActions
                    appointments={carRequestAppointments}
                    title={lang.requestedAppointments}
                    isLoading={isLoading}
                />
            )}
        </StyledColumn>
    );
};

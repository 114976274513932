import { AlertBanner, Column, Heading1Text } from '@gnist/design-system';
import { LogoBrand } from '@gnist/design-system/foundation/logos/Logo.js';
import { useParams } from 'react-router-dom';
import { useCarProfile } from 'src/features/home/queries/useCarProfile';
import { CenteredPageContent } from 'src/shared/CenteredPageContent';
import { themeOverride } from 'src/utils/dev-tools/devToolsUtils';
import { selectTheme } from 'src/utils/themes/selectTheme';
import { styled } from 'styled-components';
import { ChildThemeProvider } from 'theming';
import { Header } from '../sticky-header/StickyHeader';
import { EmissionsCalculator } from './EmissionsCalculator';
import { References } from './References';
import { UsefulContentInput } from './UsefulContentInput';

const TopHeadline = styled(Heading1Text)`
    margin-top: 49px;
`;

const TopDescription = styled.p`
    margin: var(--moller-spacing-base) 0 var(--moller-spacing-m) 0;
`;

export const Emissions = () => {
    const { vin } = useParams<{ vin: string }>();
    const { data } = useCarProfile();
    const car = data?.existingCars.find((c) => c.vin === vin);

    const carTheme = selectTheme(car?.brand);

    const formatCarName = (name: string | undefined) => {
        if (name === 'Enyaq Coupe Rs') {
            return 'Enyaq Coupé RS iV';
        }

        return name;
    };

    if (!car) {
        return (
            <AlertBanner
                type={'error'}
                message={
                    'Vi klarte ikke hente informasjon om bilen din. Vennlist prøv igjen senere.'
                }
            />
        );
    }

    return (
        <ChildThemeProvider
            theme={carTheme.toLocaleLowerCase() as LogoBrand}
            overrideTheme={themeOverride}
        >
            <CenteredPageContent>
                <Header
                    car={car}
                    isLoading={false}
                    isScrollActive={true}
                    carName={formatCarName(car.name)}
                />
                <TopHeadline>Klimautslipp</TopHeadline>
                <TopDescription>
                    Her kan du se hvor mye du har spart miljøet ved å kjøre
                    elbil!
                </TopDescription>
                <EmissionsCalculator car={car} />
                <Column gap="m">
                    <UsefulContentInput
                        usefulText={'User finds co2-calc useful'}
                        notUsefulText={'User finds co2-calc not useful'}
                    />
                    <References />
                </Column>
            </CenteredPageContent>
        </ChildThemeProvider>
    );
};

import { AlertBanner, InfoCard, Link as LinkText } from '@gnist/design-system';
import { Skeleton } from '@mui/material';
import { useEffect, useLayoutEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Upcoming } from 'src/features/home/components/upcoming/Upcoming';
import { usePreferredAffiliationDealers } from 'src/features/home/queries/usePreferredDealer';
import RateAppOrNpsInput from 'src/shared/RateAppOrNpsInput';
import FeedbackPrompt from 'src/shared/feedback-card/FeedbackPrompt';
import { CarStatusType, CarWithStatus } from 'src/types/CarWithStatus';
import { useCarTransitionContext } from 'src/utils/CarTransistionContext';
import { withErrorBoundary } from 'src/utils/ErrorBoundary';
import { ddRumPrivacySetting } from 'src/utils/datadog';
import useFeatureToggle from 'src/utils/dev-tools/useFeatureToggle';
import { hideSplashScreen } from 'src/utils/hideSplashScreen';
import { styled } from 'styled-components';
import { useThemeContext } from 'theming';
import { CarInfo } from './CarInfo';
import { CarSelect } from './CarSelect';
import { ActionBar } from './components/action/ActionBar';
import { MyCarSwiper } from './components/car/MyCarSwiper';
import { PendingCarInformation } from './components/car/PendingCar';
import { DamageAppraisalSection } from './components/damage-appraiser/DamageAppraisalSection';
import { Recommended } from './components/recommended/Recommended';
import { WaitingPeriodContent } from './current-actions/waiting-program';
import { useCarProfile } from './queries/useCarProfile';
import { selectHomeTheme } from './utils/selectHomeTheme';

const StyledDiv = styled.div`
    padding-bottom: calc(
        var(--moller-spacing-xl) + env(safe-area-inset-bottom)
    );
    max-width: 800px;
    margin: 0 auto;
    box-sizing: border-box;
`;

const PaddedContainer = styled.div`
    padding-left: var(--moller-spacing-s);
    padding-right: var(--moller-spacing-s);
`;

const ActiveCarCard = styled(InfoCard)`
    margin-bottom: var(--moller-spacing-l);
    border: none;
`;

const HomeView = () => {
    const { setTheme } = useThemeContext();
    const showDropdownForCarSelectInsteadOfSwiper = useFeatureToggle(
        'ShowDropdownForCarSelectInsteadOfSwiper'
    );

    const { setShouldRunCarTransition } = useCarTransitionContext();

    const { isLoading: isCarsLoading } = useCarProfile();
    const { data: preferredDealers, isPending: isPreferredDealersLoading } =
        usePreferredAffiliationDealers();

    const [activeCar, setActiveCar] = useState<CarWithStatus | undefined>(
        undefined
    );

    useEffect(() => {
        setShouldRunCarTransition(true);
    }, [setShouldRunCarTransition]);

    const isExistingCar = activeCar?.statusType === CarStatusType.EXISTING_CAR;
    // Should show action bar if there is no car (can still order by reg.nr)
    const showActionBar =
        !activeCar ||
        activeCar.statusType === CarStatusType.EXISTING_CAR ||
        activeCar.statusType === CarStatusType.PENDING_CAR;

    useLayoutEffect(() => {
        if (isCarsLoading) {
            return;
        }

        const activeDealer = Array.isArray(preferredDealers)
            ? preferredDealers.find(
                  (car) =>
                      isExistingCar &&
                      car.vin == activeCar.car.vin &&
                      car.preferredDealerName != null
              )
            : undefined;

        const homeTheme = selectHomeTheme(
            activeCar,
            activeDealer?.preferredDealerName
        );

        if (homeTheme) {
            setTheme(homeTheme);
            localStorage.setItem('preferredDealerName', homeTheme);
        }
    }, [activeCar, isExistingCar, preferredDealers, setTheme, isCarsLoading]);

    useEffect(() => {
        if (!isPreferredDealersLoading && !isCarsLoading) {
            hideSplashScreen();
        }
    }, [isPreferredDealersLoading, isCarsLoading]);

    const vin = isExistingCar ? activeCar.car.vin : undefined;
    const licensePlate =
        isExistingCar || activeCar?.statusType === CarStatusType.PENDING_CAR
            ? activeCar.car.licensePlate
            : undefined;

    const storageItem = localStorage.getItem('showRatingPrompt');
    const parsedItem = storageItem
        ? (JSON.parse(storageItem) as {
              showRatingPrompt: string;
              expiry?: string;
              trigger?: string;
          })
        : { trigger: 'Annet' };

    return (
        <StyledDiv {...ddRumPrivacySetting('allow')}>
            {showDropdownForCarSelectInsteadOfSwiper ? (
                <PaddedContainer>
                    {isCarsLoading ? (
                        <>
                            <Skeleton height={'60px'} width={'160px'} />
                            <Skeleton variant="rectangular" height={'120px'} />
                        </>
                    ) : (
                        <>
                            <CarSelect
                                activeCar={activeCar}
                                setActiveCar={setActiveCar}
                            />
                            {activeCar && (
                                <ActiveCarCard>
                                    <CarInfo car={activeCar} />
                                </ActiveCarCard>
                            )}
                        </>
                    )}
                </PaddedContainer>
            ) : (
                <>
                    {/* Swiper is not in paddedcontainer, as margins are used to create distance from swiper arrows */}
                    <MyCarSwiper setActiveCar={setActiveCar} />
                </>
            )}
            {!isCarsLoading && (
                <PaddedContainer>
                    {activeCar?.statusType === CarStatusType.PENDING_CAR && (
                        <PendingCarInformation {...activeCar.car} />
                    )}
                    {isExistingCar && (
                        <>
                            <Upcoming activeCar={activeCar.car} />
                            <DamageAppraisalSection vin={activeCar.car.vin} />
                        </>
                    )}
                    <ActionBar
                        vin={vin}
                        licensePlate={licensePlate}
                        isHidden={!showActionBar}
                    />
                    {isExistingCar && <Recommended activeCar={activeCar.car} />}
                    {activeCar?.statusType === CarStatusType.ORDERED_CAR && (
                        <WaitingPeriodContent car={activeCar.car} />
                    )}
                    <FeedbackPrompt />
                    <RateAppOrNpsInput
                        trigger={
                            parsedItem.trigger ? parsedItem.trigger : 'Annet'
                        }
                    />
                </PaddedContainer>
            )}
        </StyledDiv>
    );
};

const StyledAlertBanner = styled(AlertBanner)`
    margin: var(--moller-spacing-l) var(--moller-spacing-s);
`;

const fallbackMessage = (
    <>
        Vi greide dessverre ikke å vise forsiden. For å få gjort det du skal,
        kan du prøve å gå til{' '}
        <LinkText $as={Link} to="/booking">
            verkstedbestilling
        </LinkText>{' '}
        eller{' '}
        <LinkText $as={Link} to="/damage">
            skadetakst
        </LinkText>{' '}
        og taste inn registreringsnummer.
    </>
);
const fallback = (retry: () => void) => (
    <StyledAlertBanner
        type="error"
        message={fallbackMessage as unknown as string} // Ugly hack because message must be string
        action={{ label: 'Prøv igjen', onClick: retry }}
    />
);

export const Home = withErrorBoundary('Home', HomeView, { fallback });

import {
    Column,
    DetailText,
    Heading1Text,
    IconButton,
    Lozenge,
    Row,
} from '@gnist/design-system';
import { Link as RouterLink, generatePath } from 'react-router-dom';
import { PATH_MY_CAR, PATH_MY_ORDERED_CAR } from 'src/routing/routes';
import { CarStatusType, CarWithStatus } from 'src/types/CarWithStatus';
import { formatBrandName } from 'src/utils/string/formatBrandNames';
import formatLicensePlate from 'src/utils/string/formatLicensePlate';
import { styled } from 'styled-components';
import { normalizeETron } from '../my-car/car-brands/audi/audiUtils';

interface Props {
    car: CarWithStatus;
}

const StyledHeadline = styled(Heading1Text)`
    margin-bottom: var(--moller-spacing-xs);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

const StyledLozenge = styled(Lozenge)`
    width: fit-content;
    text-transform: uppercase;
    margin-bottom: var(--moller-spacing-xxs);
`;

function mapStatus(carStatus: CarStatusType) {
    switch (carStatus) {
        case CarStatusType.EXISTING_CAR:
            return null;
        case CarStatusType.ORDERED_CAR:
            return (
                <StyledLozenge colorVariant="success">bestilt</StyledLozenge>
            );
        case CarStatusType.PENDING_CAR:
            return (
                <StyledLozenge colorVariant="warning">behandles</StyledLozenge>
            );
        default:
            return null;
    }
}

function mapHeadline(car: CarWithStatus) {
    switch (car.statusType) {
        case CarStatusType.EXISTING_CAR:
            return `${formatBrandName(car.car.brand)} ${normalizeETron(
                car.car.name
            )}`;
        case CarStatusType.ORDERED_CAR:
            return `${formatBrandName(car.car.brand)} ${car.car.model.text}`;
        case CarStatusType.PENDING_CAR:
            return formatLicensePlate(car.car.licensePlate);
        default:
            return '';
    }
}

export function CarDetails({ car }: Props) {
    switch (car.statusType) {
        case CarStatusType.EXISTING_CAR:
            return (
                <>
                    <Row gap="s">
                        <IconButton
                            icon="contract"
                            as={RouterLink}
                            to={generatePath(PATH_MY_CAR, {
                                vin: car.car.vin,
                            })}
                            label="Om din bil"
                            showLabel="right"
                        />
                        <IconButton
                            icon="assignment"
                            as={RouterLink}
                            to={`/my-car/vin/${car.car.vin}/appointments`}
                            label="Verkstedhistorikk"
                            showLabel="right"
                        />
                    </Row>
                </>
            );
        case CarStatusType.ORDERED_CAR:
            return (
                <>
                    <IconButton
                        icon="contract"
                        as={RouterLink}
                        to={`${PATH_MY_ORDERED_CAR}/${car.car.contractId}`}
                        label="Om din bil"
                        showLabel="right"
                    />
                </>
            );
        case CarStatusType.PENDING_CAR:
            return (
                <>
                    <DetailText>
                        Vi behandler forespørselen din om å legge til bil med
                        registreringsnummer{' '}
                        {formatLicensePlate(car.car.licensePlate)}. Takk for
                        tålmodigheten.
                    </DetailText>
                </>
            );
        default:
            return <></>;
    }
}

export function CarInfo({ car }: Props) {
    return (
        <Column>
            {mapStatus(car.statusType)}
            <StyledHeadline>{mapHeadline(car)}</StyledHeadline>
            <CarDetails car={car} />
        </Column>
    );
}

import { Row, TextContainer } from '@gnist/design-system';
import { LoadingSpan } from 'src/features/my-car/technical-details/TechnicalDetails';
import { VehicleData } from 'src/types/generated/CarInformation/CarInformationTypes';
import { styled } from 'styled-components';

const StyledRow = styled(Row)`
    margin-top: var(--moller-spacing-xs);
    :last-child {
        padding-bottom: var(--moller-spacing-xs);
    }
`;

const List = styled.ul`
    list-style: none;
    padding: 0;
`;

const ListElement = styled.li`
    margin-top: var(--moller-spacing-xs);
`;

interface TechnicalData {
    readonly label?: string;
    readonly value?: string;
}

interface EngineContentProps {
    readonly technicalDetails: VehicleData | undefined;
    readonly tdError: boolean;
    readonly tdLoading: boolean;
}

interface EngineRow {
    readonly engine?: string;
    readonly technicalData?: TechnicalData[];
}

const EngineContent = ({
    technicalDetails,
    tdError,
    tdLoading,
}: EngineContentProps) => {
    const engineList: EngineRow[] = [];

    technicalDetails?.engines?.forEach((engine, index) => {
        const engineObject = {
            engine: `Motor ${index + 1}`,
            technicalData: [
                {
                    label: engine?.engineVolume != 0 ? 'Slagvolum:' : '',
                    value:
                        engine?.engineVolume != 0
                            ? ` ${engine?.engineVolume}`
                            : '',
                },
                {
                    label:
                        engine?.engineEffect != 0 ? 'Motorytelse/effekt:' : '',
                    value:
                        engine?.engineEffect != 0
                            ? ` ${engine?.engineEffect}KW`
                            : '',
                },
            ],
        };

        engineList.push(engineObject);
    });

    const hasMultipleMotors = engineList?.length > 1;

    const engineWithEmptyValues = engineList.filter((p) =>
        p.technicalData?.every((p) => p.label === '' && p.value === '')
    );

    if (engineWithEmptyValues.length > 0) {
        return (
            <StyledRow>
                <List>
                    <span>Ikke oppgitt</span>
                </List>
            </StyledRow>
        );
    }

    return (
        <List>
            {engineList?.map((row, index) => {
                return (
                    <ListElement key={index}>
                        {hasMultipleMotors && (
                            <TextContainer>
                                <b>{row?.engine}</b>
                            </TextContainer>
                        )}
                        {row.technicalData?.map((row, index) => {
                            return (
                                <StyledRow
                                    key={index}
                                    horizontalAlign="spaceBetween"
                                >
                                    <TextContainer>{row.label}</TextContainer>
                                    <TextContainer>
                                        <LoadingSpan
                                            content={row.value}
                                            isLoading={tdLoading}
                                            isError={tdError}
                                        />
                                        {row.label === 'Slagvolum:' &&
                                        !tdLoading &&
                                        !tdError ? (
                                            <span>
                                                cm<sup>3</sup>
                                            </span>
                                        ) : null}
                                    </TextContainer>
                                </StyledRow>
                            );
                        })}
                    </ListElement>
                );
            })}
            {hasMultipleMotors && (
                <ListElement>
                    <StyledRow horizontalAlign="spaceBetween">
                        <TextContainer>Full effekt</TextContainer>
                        <TextContainer>
                            {technicalDetails?.engineEffect}KW
                        </TextContainer>
                    </StyledRow>
                </ListElement>
            )}
        </List>
    );
};

export default EngineContent;

import {
    AlertBanner,
    Column,
    InfoCard,
    Row,
    TextContainer,
} from '@gnist/design-system';
import { breakpointTablet } from '@gnist/design-system/utilities/layout/breakpoints.js';
import { SelectChangeEvent } from '@mui/material';
import { useEffect, useState } from 'react';
import { TDFShirt } from 'src/features/competition/assets/TDFShirt';
import { PickDealer } from 'src/features/competition/components/PickDealer';
import { PrizePicked } from 'src/features/competition/components/PrizePicked';
import {
    HeadingPrizeCard,
    TextSmallThin,
    TextThin,
} from 'src/features/competition/textComponents/TDFText';
import { UpdatePrizeDataPayload } from 'src/features/competition/utils/useUpdatePrizeData';
import { styled } from 'styled-components';

const PrizesCard = styled(InfoCard)`
    background-color: white;
    color: black;
    margin: var(--moller-spacing-s) 0;
    border: 0;
`;

const TDTPicture = styled.img`
    @media screen and (min-width: ${breakpointTablet}) {
        height: 30%;
        width: 30%;
    }
    height: 40%;
    width: 40%;
    align-content: center;
`;

interface Props {
    hasWon: boolean | undefined;
    dealer: string | undefined;
    hasNotWonText: string;
    hasWonText: string;
    heading: string;
    color: string;
    prizeName: string;
    isUpdating: boolean;
    isError: boolean;
    updatePrize: (data: UpdatePrizeDataPayload) => void;
    image: string;
}
const orderBeforeDate = new Date(2024, 7, 12);

export const PrizeComponentBase = ({
    hasNotWonText,
    hasWonText,
    heading,
    dealer,
    hasWon,
    color,
    prizeName,
    isUpdating,
    isError,
    updatePrize,
    image,
}: Props) => {
    const today = new Date();
    const canOrder = today < orderBeforeDate;
    const [newPreferredDealerId, setNewPreferredDealerId] = useState<
        string | undefined
    >(undefined);

    const onUpdate = (event: SelectChangeEvent) => {
        setNewPreferredDealerId(event.target.value);
    };

    useEffect(() => {
        if (dealer) {
            setNewPreferredDealerId(dealer);
        }
    }, [dealer]);

    const onOrder = () => {
        const changedPrizeData: UpdatePrizeDataPayload = {
            dealer: newPreferredDealerId,
        };
        updatePrize(changedPrizeData);
    };

    const disableButton = isUpdating;
    const disableOrderButton =
        (!newPreferredDealerId && newPreferredDealerId !== '0') ||
        disableButton;

    return (
        <PrizesCard>
            <Column gap={'m'}>
                <Row verticalAlign={'start'} horizontalAlign={'spaceBetween'}>
                    <TextContainer>
                        <HeadingPrizeCard>{heading}</HeadingPrizeCard>
                        {!hasWon && (
                            <TextSmallThin>{hasNotWonText}</TextSmallThin>
                        )}
                        {hasWon && !dealer && canOrder && (
                            <TextSmallThin>{hasWonText}</TextSmallThin>
                        )}
                        {hasWon && !dealer && !canOrder && (
                            <TextSmallThin>
                                Beklager, du har gått glipp av fristen for å
                                bestille premie.
                            </TextSmallThin>
                        )}
                    </TextContainer>
                    <div>
                        <TDFShirt
                            fill={color}
                            width={'50'}
                            height={'100%'}
                            stroke={'black'}
                            isElevationShirt={color === 'white'}
                        />
                    </div>
                </Row>
                <Row
                    horizontalAlign={'spaceBetween'}
                    verticalAlign={'center'}
                    style={{ width: '100%' }}
                >
                    <TextThin>{prizeName}</TextThin>
                    <TDTPicture src={image} alt={prizeName} />
                </Row>
                {hasWon && !dealer && (
                    <>
                        {isError && (
                            <AlertBanner
                                type="error"
                                message={
                                    'Det oppstod en feil når vi prøvde å lagre valget ditt. Prøv igjen senere.'
                                }
                            />
                        )}
                        <PickDealer
                            disableButton={disableButton}
                            disableOrderButton={disableOrderButton}
                            isUpdating={isUpdating}
                            newPreferredDealerId={newPreferredDealerId}
                            onOrder={onOrder}
                            onUpdate={onUpdate}
                        />
                    </>
                )}
                {hasWon && dealer && (
                    <PrizePicked dealer={newPreferredDealerId} />
                )}
                {!hasWon && (
                    <TextSmallThin>
                        Følg med i appen for å se om du vinner premie!
                    </TextSmallThin>
                )}
            </Column>
        </PrizesCard>
    );
};

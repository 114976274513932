import {
    Column,
    PrimaryButton,
    TextButton,
    TextContainer,
} from '@gnist/design-system';
import { Dispatch } from 'react';
import { styled } from 'styled-components';
import { TDFTextLarge, TDFTextMedium } from '../textComponents/TDFText';

interface Props {
    isOpen: boolean;
    setIsConfirmPrizeModalOpen: Dispatch<React.SetStateAction<boolean>>;
    onOrder: () => void;
    isUpdating: boolean;
}

const ModalWrapper = styled(Column)`
    inset: 0;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    z-index: 1000;
    isolation: isolate;
    position: fixed;
`;
const GrayBackground = styled.div`
    z-index: -1;
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
`;
const DivWrapper = styled.div`
    width: 70%;
    color: black;
    height: fit-content;
    border-radius: 1rem;
    max-height: 656px;
    max-width: 600px;
    box-sizing: border-box;
    overflow: auto;
    display: flex;
    gap: var(--moller-spacing-m);
    position: fixed;
    flex-direction: column;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: var(--moller-spacing-l);
    z-index: 1100;
    background-color: #78faae;
`;

const ModalDiv = styled.div`
    display: block;
    unicode-bidi: isolate;
`;
export const ConfirmModal = ({
    isOpen,
    setIsConfirmPrizeModalOpen,
    onOrder,
    isUpdating,
}: Props) => {
    return (
        <>
            {isOpen && (
                <ModalWrapper onClick={() => null}>
                    <GrayBackground />
                    <DivWrapper>
                        <TextContainer>
                            <TDFTextLarge style={{ textAlign: 'center' }}>
                                Er du sikker?
                            </TDFTextLarge>
                        </TextContainer>
                        <TextContainer>
                            <TDFTextMedium style={{ textAlign: 'center' }}>
                                Du kan ikke endre ditt valg etter du har lagret.
                            </TDFTextMedium>
                            <TDFTextMedium style={{ textAlign: 'center' }}>
                                Sikker på at du vil lagre?
                            </TDFTextMedium>
                        </TextContainer>
                        <PrimaryButton
                            onClick={onOrder}
                            loading={{
                                isLoading: isUpdating,
                                loadingText: 'Laster...',
                            }}
                            style={{
                                alignSelf: 'center',
                                backgroundColor: '#FFF',
                            }}
                        >
                            <TDFTextMedium>Lagre</TDFTextMedium>
                        </PrimaryButton>
                        <TextButton
                            type="button"
                            style={{
                                alignSelf: 'center',
                                textDecoration: 'underline',
                            }}
                            onClick={() => setIsConfirmPrizeModalOpen(false)}
                        >
                            <TDFTextMedium>Avbryt</TDFTextMedium>
                        </TextButton>
                    </DivWrapper>
                    <ModalDiv />
                </ModalWrapper>
            )}
        </>
    );
};

import { useMutation } from '@tanstack/react-query';
import { useFetchersContext } from 'external-apis';

const useUnsubscribeEmailFetcher = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.bilhold.fetcher
        .path('/v1/competition/email-unsubscribe')
        .method('patch')
        .create();
};

export const useUnsubscribeEmail = (onSuccess: () => void) => {
    const unsubscribe = useUnsubscribeEmailFetcher();
    return useMutation({
        mutationFn: () => unsubscribe({}),
        onSuccess: () => {
            onSuccess();
        },
    });
};

import { DamageMedia } from 'external-apis/.typescript/src/types/damageappraisal';
import { loadImageWithAuth } from 'src/utils/auth/authUtils';
import getCurrentEnvironment from 'src/utils/environment/getCurrentEnvironment';
import { ImageType } from './useMedia';

function createMediaPath(mediaId: string): string {
    if (!mediaId) {
        throw new Error('mediaId cannot be empty string');
    }
    const { damageAppraisalApiUrl } = getCurrentEnvironment();
    const path = `${damageAppraisalApiUrl}/v3/media/${mediaId}`;

    return path;
}

export const loadImageUrl = async (img: DamageMedia): Promise<ImageType> => {
    const mediaPath = createMediaPath(img.id.toString());
    const dataUrl = await loadImageWithAuth(mediaPath);
    return { id: img.id, tag: img.tag, dataUrl: dataUrl };
};

export const loadImageUrls = async (images: DamageMedia[]) => {
    return Promise.all(images.map(loadImageUrl));
};

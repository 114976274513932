import { CheckboxCard, Column } from '@gnist/design-system';
import { DelayedRender } from '../../lib/DelayedRender';

export function StandardServicesLoader() {
    return (
        <DelayedRender>
            <Column gap="xxs">
                {[1, 2, 3, 4, 5].map((v) => (
                    <CheckboxCard
                        key={v}
                        id={`standardserviceslist-selectedId-${v}`}
                        onChange={() => {
                            return;
                        }}
                        isLoading
                        label=""
                    />
                ))}
            </Column>
        </DelayedRender>
    );
}

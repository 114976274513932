import { Heading1Text, Link, TextContainer } from '@gnist/design-system';
import { RecallAppointment } from 'external-apis/src/types/bilhold';
import { isMobileApp } from 'src/utils/mobile';
import service from '../../eu-control/eu-control.png';
import { FlexContainer } from '../RecallServiceContent';

export const AudiBatteryIssue = ({ recall }: { recall: RecallAppointment }) => {
    const selectAudiBatteryIssueLink = () => {
        if (recall.recallCode === '93V2') {
            return 'https://m.dm.audi.no/rest/head/mirrorPage/@-ytSCxbVXQPiszlFP2cSPYCHB3VGi5Ko5I8Y5yyHI5yFbaMVhRvRvBQRxclz8Q3t94KoULq8SJ62_P8ZWG-yVCKhGS57df2orpAMEVwTSIRR6Xd3.html';
        } else if (recall.recallCode === '93U9') {
            return 'https://m.dm.audi.no/rest/head/mirrorPage/@-ytSCxbVXQPiszlFP2cSPWk5ru5ghZ1gYkaONjxTply0bJrOiQlB4u7xaI8_JZO7e77ZjTwbJMbGgTPHxdBFIR8faL5WnsmUShAI2Mb3Zu2fPqOI.html';
        } else return null;
    };
    const link = selectAudiBatteryIssueLink();

    return (
        <TextContainer>
            <FlexContainer reverseOrder={isMobileApp()}>
                {!isMobileApp() && <img src={service} alt="Tilbakekalling" />}
                <Heading1Text>{`Tilbakekallingsoppgradering ${recall.recallCode} for kontroll av høyenergibatteri`}</Heading1Text>
            </FlexContainer>
            <p>
                Din bil er berørt av denne tilbakekallingen. Bestill time i
                Bilhold, eller kontakt en Audi-forhandler så raskt som mulig for
                å avtale en verkstedtime. Du kan kjøre bilen frem til du får
                time på verksted.
            </p>
            {link && (
                <p>
                    <Link href={link} target="_blank" rel="noopener noreferrer">
                        Vennligst les dette skrivet nøye
                    </Link>
                </p>
            )}
            <p>
                Oppgraderingen er kostnadsfri. Kontrollen tar en dag og hvis noe
                må byttes tar det to til tre dager.
            </p>
            <p>
                <b>
                    Vi ber deg om å begrense batteriladingen til maksimalt 80
                    prosent frem til bilen har vært på verksted.
                </b>
            </p>
            <p>
                Har du solgt bilen, eller om den ikke lenger er i bruk, ber vi
                deg ta kontakt med vårt{' '}
                <Link href="mailto:kundeservice@moller.no">
                    kundesenter på e-post.
                </Link>
            </p>
        </TextContainer>
    );
};

import { Heading1Text, Row } from '@gnist/design-system';
import { styled } from 'styled-components';

const HeadlineRow = styled(Row)`
    align-items: center;
    margin-top: var(--moller-spacing-xxs);
`;

const StyledHeadline = styled(Heading1Text)`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

export const CarHeadline = ({ headlineText }: { headlineText: string }) => {
    return (
        <HeadlineRow>
            <StyledHeadline>{headlineText}</StyledHeadline>
        </HeadlineRow>
    );
};

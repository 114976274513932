import {
    Heading1Text,
    Heading2Text,
    TextContainer,
} from '@gnist/design-system';
import { CenteredPageContent } from 'src/shared/CenteredPageContent';

const WarrantySeat = () => {
    return (
        <CenteredPageContent showBackButton>
            <TextContainer>
                <Heading1Text>Garantier på bilen</Heading1Text>
                <p>
                    Det skal være trygt å kjøre og eie en SEAT og våre biler
                    leveres med en omfattende garantipakke. SEAT kjennetegnes av
                    kvalitet, som bidrar til lavere servicekostnader og en høy
                    andrehåndsverdi.
                </p>
                <Heading2Text>5 års Norgesgaranti</Heading2Text>
                <p>
                    Alle nye SEAT personbiler som er registrert etter 01.01.2011
                    og importert av Harald A. Møller AS har en Norgesgaranti som
                    gjelder i 5 år/100.000 km, avhengig av hva som først
                    inntreffer.
                </p>
                <p>
                    For at garantien skal være gjeldende må service, vedlikehold
                    og reparasjoner til enhver tid utføres i henhold til SEAT
                    sine fastsatte normer.
                </p>

                <Heading2Text>
                    5 års Norgesgaranti på SEAT originaldeler®
                </Heading2Text>
                <p>
                    Garantien gjelder i 5 år eller 100.000 km, avhengig av hva
                    som først inntreffer og gjelder for deler importert av
                    Harald A. Møller AS. Direkte slitasjedeler er unntatt fra
                    garantien.
                </p>

                <Heading2Text>Lakkgaranti</Heading2Text>
                <p>
                    Alle nye SEAT personbiler garanteres i 3 år fra
                    leveringsdato mot skader i lakken som kan føres tilbake til
                    feil i produksjonen. Skader som er oppstått på grunn av ytre
                    påvirkning, faller naturlig nok utenfor garantien.
                    Vedlikehold av lakken i henhold til bilens instruksjonsbok
                    forutsettes.
                </p>

                <Heading2Text>Batterigaranti ladbare biler</Heading2Text>
                <p>
                    Alle nye ladbare biler fra SEAT leveres fra fabrikk med 8
                    år/160.000 km garanti på batteripakken. SEAT garanterer at
                    batteriet skal holde minst 70% kapasitet i inntil 8
                    år/160.000 km. Garantien forutsetter lading i henhold til
                    bilens instruksjonsbok.
                </p>

                <Heading2Text>12 års garanti mot gjennomrusting</Heading2Text>
                <p>
                    Alle nye biler fra SEAT leveres med 12 års garanti mot
                    gjennomrusting av karosseriet. Med gjennomrusting menes her
                    at det er oppstått hull i karosseriplatene som har utviklet
                    seg innenfra (fra hulrom) som ikke kan forventes å bli
                    oppdaget tidligere i forbindelse med lakkvedlikehold i
                    henhold til anbefalinger i bilens instruksjonsbok. Skadene
                    må ikke være forårsaket av ytre påvirkning.
                </p>

                <Heading2Text>Reklamasjonsrettigheter</Heading2Text>
                <p>
                    Garantien begrenser ikke garantitakerens lovfestede
                    rettigheter i henhold til norsk lov (Lov om kjøp, Lov om
                    Forbrukerkjøp) ovenfor selger ved mangler, og heller ikke
                    eventuelle krav i hht. Lov om produktansvar.
                </p>
                <p>
                    Direkte slitasjedeler og justeringsarbeider er unntatt fra
                    garantien.
                </p>
            </TextContainer>
        </CenteredPageContent>
    );
};

export default WarrantySeat;

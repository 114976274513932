import {
    AlertBanner,
    Column,
    Heading1Text,
    IconButton,
    LoadingSpinner,
    Row,
} from '@gnist/design-system';
import { Divider } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { CenteredPageContent } from 'src/shared/CenteredPageContent';
import scrollToTopOfPage from 'src/utils/browser/scrollToTopOfPage';
import {
    temporaryMessageId,
    useMessageThread,
    useMessagesInThread,
} from 'src/utils/hooks/useMessageThreads';
import { useProfile } from 'src/utils/hooks/useProfile';
import lang from 'src/utils/lang';
import { sortByTime } from 'src/utils/time/timeUtils';
import { styled } from 'styled-components';
import { CHAT_LIST } from '..';
import Message from './Message';
import { SendMessageInExistingThread } from './SendMessageInExistingThread';

const MessageThreadContainer = styled(Column)`
    width: 100%;
    gap: var(--moller-spacing-l);
    margin-top: var(--moller-spacing-l);
`;

const StyledContainer = styled(Row)`
    margin-bottom: var(--moller-spacing-l);
`;

const ConversationIsClosedParagraph = styled.p`
    margin-left: auto;
`;

export const IconContainer = styled.div`
    display: flex;
    gap: var(--moller-spacing-base);
    align-items: center;
    margin-bottom: 0.5rem;
`;

export const MessageThreadsChat = () => {
    const { threadId } = useParams<{ threadId: string }>();
    const { data: profile } = useProfile();
    const threadIdFromParam = threadId ?? '';

    const navigate = useNavigate();

    const {
        data: thread,
        isPending: isLoadingThread,
        isError: isErrorThread,
    } = useMessageThread(threadId);

    const { data: messages, isPending: isLoadingMessages } =
        useMessagesInThread(threadId);

    const navigateToChat = () => {
        navigate(`/messages/?category=${CHAT_LIST}`);
    };

    const sortedMessages =
        messages &&
        [...messages].sort((a, b) => sortByTime(a.createdAt, b.createdAt));

    return (
        <CenteredPageContent>
            <StyledContainer>
                <IconButton
                    icon="arrow_backward"
                    label={lang.back}
                    showLabel="right"
                    onClick={navigateToChat}
                />
            </StyledContainer>
            {isLoadingMessages ||
                (isLoadingThread && <LoadingSpinner isFullScreen />)}
            {isErrorThread ? (
                <AlertBanner
                    type="error"
                    message={lang.general_error_try_again}
                />
            ) : (
                <Column gap={'m'}>
                    <Column gap={'m'}>
                        <Heading1Text>{thread?.title}</Heading1Text>
                        <p>
                            Lurer du på noe angående din verkstedtime? Vi svarer
                            deg innen 1 virkedag (åpningstid 09-15). Ring oss om
                            det er noe som haster.
                        </p>
                        <Divider />
                        {isLoadingMessages && <LoadingSpinner isFullScreen />}
                        <MessageThreadContainer>
                            {sortedMessages?.map((m) => (
                                <Message
                                    messageText={m.content}
                                    sentTime={m.createdAt ?? ''}
                                    isSentByMe={!m.sender.internal}
                                    key={m.messageId}
                                    senderName={
                                        m.sender.internal
                                            ? m.sender.name?.split(' ')[0]
                                            : ''
                                    }
                                    isOptimistic={
                                        m.messageId === temporaryMessageId
                                    }
                                />
                            ))}
                        </MessageThreadContainer>
                        <Divider />
                        {thread?.status === 'CLOSED' ? (
                            <ConversationIsClosedParagraph>
                                <strong>Samtalen er avsluttet.</strong> Vi gjør
                                oppmerksom på at meldingstjenesten dessverre er
                                midlertidig stengt. Har du spørsmål? Du kan nå
                                oss på e-post eller telefon - vi hjelper deg
                                gjerne!
                            </ConversationIsClosedParagraph>
                        ) : (
                            <SendMessageInExistingThread
                                threadId={threadIdFromParam}
                                senderName={`${profile?.firstname} ${profile?.surname}`}
                            />
                        )}
                    </Column>
                    <Divider />
                    <Row horizontalAlign="end" verticalAlign="center">
                        <IconButton
                            icon="arrow_upward"
                            label={lang.toTop}
                            showLabel="left"
                            onClick={() => scrollToTopOfPage()}
                        />
                    </Row>
                </Column>
            )}
        </CenteredPageContent>
    );
};

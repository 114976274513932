import { PrimaryButton, SecondaryButton } from '@gnist/design-system';
import { useEffect, useState } from 'react';
import { swiperIndexLocalStorageKey } from 'src/features/home/store/useCarSwiperIndex';
import Brand from 'src/types/Brand';
import FuelType from 'src/utils/enums/FuelType';
import { BaseData, CarStatus, PreferredDealer } from '../data';
import CarComponent, { BaseCarWithId } from './CarComponent';

const updateMockData = (cars: BaseCarWithId[]) => {
    localStorage.setItem('mockData', JSON.stringify({ cars }));
};

const clearTempData = () => {
    localStorage.removeItem('preferredDealerName');
    localStorage.removeItem(swiperIndexLocalStorageKey);
    localStorage.removeItem('REACT_QUERY_OFFLINE_CACHE');
};

const DevToolsMockData = () => {
    const [hasMockData, setHasMockData] = useState(false);
    const [cars, setCars] = useState<BaseCarWithId[]>([]);

    useEffect(() => {
        const mockDataJson = localStorage.getItem('mockData');
        if (mockDataJson) {
            const baseData = JSON.parse(mockDataJson) as BaseData;
            setCars(
                baseData.cars.map((c, index) => ({
                    ...c,
                    id: index.toString() + new Date().getTime().toString(),
                }))
            );
            setHasMockData(true);
        }
    }, []);

    const addCar = () => {
        const newCar = {
            CarStatus: CarStatus.EXISTING,
            vin: '',
            brand: Brand.AUDI,
            model: '',
            registrationYear: new Date().getFullYear(),
            fuelType: FuelType.PETROL,
            futureAppointments: 0,
            pastAppointments: 0,
            hasUpdatedEuControl: true,
            hasUpdatedMileage: true,
            mobilityGuarantee: false,
            hasTireHotel: false,
            preferredDealer: PreferredDealer.BILHOLD,
            id: new Date().getTime().toString(),
            originBilholdApi: true,
        };
        const updatedCars = [...cars, newCar];
        setCars(updatedCars);
        updateMockData(updatedCars);
        setHasMockData(true);
    };

    const onCarUpdate = (car: BaseCarWithId) => {
        const index = cars.findIndex((c) => c.id === car.id);
        const updatedCars = [...cars];
        updatedCars[index] = car;
        setCars(updatedCars);
        updateMockData(updatedCars);
    };

    const onCarRemove = (car: BaseCarWithId) => {
        const index = cars.findIndex((c) => c.id === car.id);
        const updatedCars = [...cars];
        updatedCars.splice(index, 1);
        setCars(updatedCars);
        updateMockData(updatedCars);
    };

    const goToFrontpage = () => {
        clearTempData();
        window.location.href = '/';
    };

    const resetMockData = () => {
        localStorage.removeItem('mockData');
        clearTempData();
        window.location.href = '/';
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            {cars.map((car) => (
                <CarComponent
                    key={car.id}
                    car={car}
                    onCarUpdate={onCarUpdate}
                    onCarRemove={onCarRemove}
                />
            ))}
            {hasMockData && cars.length == 0 && (
                <div>Du har 0 biler aktive.</div>
            )}
            <SecondaryButton onClick={addCar}>Legg til bil</SecondaryButton>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '5px',
                    flexWrap: 'wrap',
                }}
            >
                <PrimaryButton onClick={goToFrontpage}>Lagre</PrimaryButton>
                {hasMockData && (
                    <PrimaryButton onClick={resetMockData}>
                        Nullstill
                    </PrimaryButton>
                )}
            </div>
        </div>
    );
};

export default DevToolsMockData;

import { AllowedIcons, DetailText, Icon } from '@gnist/design-system';
import { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import { useLanguageContext } from '../../../lib/languages/languageContext';

interface AnimationContainerProps {
    className?: string;
    icon: AllowedIcons;
    text: string;
}
const AnimationContainer = ({
    className,
    icon,
    text,
}: AnimationContainerProps) => {
    const [lc] = useLanguageContext();
    return (
        <Container>
            <div className={className}>
                <Icon icon={icon} size="3xl" />
                {text}
                <WaitingTimeDisclaimerText>
                    {
                        lc.confirmBooking
                            .loading_animation_waiting_time_disclaimer
                    }
                </WaitingTimeDisclaimerText>
            </div>
        </Container>
    );
};

const Animation = styled(AnimationContainer)<AnimationProps>`
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--moller-color-background);
    * svg {
        color: var(--moller-color-primary);

        animation-duration: ${({ duration }) => duration};
        animation-name: ${({ animation }) => animation};

        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
    }

    @keyframes bounce {
        0%,
        20%,
        50%,
        80%,
        100% {
            transform: translateY(0);
        }
        40% {
            transform: translateY(-10px);
        }
        60% {
            transform: translateY(-5px);
        }
    }

    @keyframes swing {
        20% {
            transform: rotate(15deg);
        }
        40% {
            transform: rotate(-10deg);
        }
        60% {
            transform: rotate(5deg);
        }
        80% {
            transform: rotate(-5deg);
        }
        100% {
            transform: rotate(0deg);
        }
    }

    @keyframes wroom {
        0% {
            transform: translateX(-60vw);
        }
        60% {
            transform: translateX(0);
        }

        100% {
            transform: translateX(60vw);
        }
    }
`;

interface AnimationProps {
    readonly animation: 'swing' | 'bounce' | 'wroom';
    readonly duration: string;
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--moller-spacing-xs);
`;
const animationDurationMillis = 5000;

export const BookingProgress = () => {
    const [lc] = useLanguageContext();

    const [animationIndex, setAnimationIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setAnimationIndex((animationIndex) => animationIndex + 1);
        }, animationDurationMillis);
        return () => clearInterval(interval);
    }, []);

    const animations: Array<
        AnimationProps & { icon: AllowedIcons; text: string }
    > = [
        {
            animation: 'swing',
            duration: '1.5s',
            icon: 'edit',
            text: lc.confirmBooking.loading_animation_1,
        },
        {
            animation: 'wroom',
            duration: '2.5s',
            icon: 'car',
            text: lc.confirmBooking.loading_animation_2,
        },
        {
            animation: 'bounce',
            duration: '1.5s',
            icon: 'calendar',
            text: lc.confirmBooking.loading_animation_3,
        },
        {
            animation: 'swing',
            duration: '1.5s',
            icon: 'build',
            text: lc.confirmBooking.loading_animation_4,
        },
        {
            animation: 'bounce',
            duration: '1.5s',
            icon: 'favorite_border',
            text: lc.confirmBooking.loading_animation_5,
        },
    ];

    const animation =
        animations[Math.min(animationIndex, animations.length - 1)];

    return (
        <Animation
            animation={animation.animation}
            duration={animation.duration}
            icon={animation.icon}
            text={animation.text}
        />
    );
};

const WaitingTimeDisclaimerText = styled(DetailText)`
    color: var(--moller-color-text-variant);
`;

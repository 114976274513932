import { useMutation, useQuery } from '@tanstack/react-query';
import { useFetchersContext } from 'external-apis';
import {
    AvailableServicesQuery,
    ServiceType,
    ServiceViewModel,
} from 'external-apis/src/types/port';
import { pipe } from 'fp-ts/function';
import { groupBy } from 'fp-ts/lib/NonEmptyArray';
import { FriendlyQueryError } from '../../lib/errors/PortError';
import {
    LanguageContextType,
    useLanguageContext,
} from '../../lib/languages/languageContext';
import { STALE_TIME } from '../../lib/query-client/config';
import { useGetApiLocaleConfig } from '../apiLocale';

const useFindServices = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.port.fetcher
        .path('/services/search')
        .method('post')
        .create();
};

type UseGetServices = {
    vin?: string;
    dealerNumbers?: string[];
};

export function useGetServices(props: UseGetServices, enabled = true) {
    const [lc] = useLanguageContext();
    const { vin, dealerNumbers } = props;
    const { countryCode } = useGetApiLocaleConfig();
    const findServices = useFindServices();
    const body: AvailableServicesQuery = {
        vin: vin ?? '',
        dealerFilter: {
            selectedDealerNumbers: dealerNumbers,
        },
        countryCode,
    };

    return useQuery<Services, Error>({
        queryKey: [body],
        queryFn: () =>
            getServices(body, lc, findServices).then((x) => ({
                ...x,
                Recommended: x.Recommended,
            })),
        staleTime: STALE_TIME.hour,
        gcTime: STALE_TIME.hour,
        enabled: !!(vin && dealerNumbers) && enabled,
    });
}

export type Services = {
    [x in ServiceType]: ServiceViewModel[];
};

async function getServices(
    body: AvailableServicesQuery,
    lc: LanguageContextType,
    findServices: ReturnType<typeof useFindServices>
) {
    try {
        const result = await findServices(body);

        if (result.data.length === 0) {
            throw new Error(lc.errors.port_services_default);
        }

        const groupedServices = pipe<
            ServiceViewModel[],
            Record<ServiceType, ServiceViewModel[]>
        >(
            result.data,
            groupBy((x) => x.serviceType)
        );
        // groupBy can return undefined for a ServiceType
        return {
            Standard: groupedServices.Standard ?? [],
            Additional: groupedServices.Additional ?? [],
            Transport: groupedServices.Transport ?? [],
            Delivery: groupedServices.Delivery ?? [],
            Recommended: groupedServices.Recommended ?? [],
            Waiting: groupedServices.Waiting ?? [],
            None: groupedServices.None ?? [],
        };
    } catch (e) {
        if (e instanceof findServices.Error) {
            throw new FriendlyQueryError(
                lc.errors.port_services_default,
                e,
                e.status
            );
        }
        throw e;
    }
}

type GetRecommendedServicesBody = {
    body: AvailableServicesQuery;
    preselectRecommendedServices: (fetchedIds: string[]) => void;
};

export function useGetRecommendedServices() {
    const [lc] = useLanguageContext();
    const findRecommendedServices = useFindServices();

    return useMutation({
        mutationKey: ['recommendedServices'],
        mutationFn: async (request: GetRecommendedServicesBody) => {
            const data = await getRecommendedServices(
                request.body,
                lc,
                findRecommendedServices
            );
            request.preselectRecommendedServices(data.map((x) => x.id));
            return data;
        },
    });
}

async function getRecommendedServices(
    body: AvailableServicesQuery,
    lc: LanguageContextType,
    findRecommendedServices: ReturnType<typeof useFindServices>
) {
    try {
        const result = await findRecommendedServices(body);
        return result.data.filter((x) => x.category === 'RecommendedService');
    } catch (e) {
        if (e instanceof findRecommendedServices.Error) {
            throw new FriendlyQueryError(
                lc.errors.port_services_default,
                e,
                e.status
            );
        }
        throw e;
    }
}

import { AllowedIcons } from '@gnist/design-system';
import { useQuery } from '@tanstack/react-query';
import { useFetchersContext } from 'external-apis';
import {
    ExistingCarRelation,
    OrderedCarRelation,
} from 'external-apis/src/types/bilhold';
import lang from 'src/utils/lang';
import { formatMileage } from 'src/utils/string/formatMilage';
import { useCarInformation } from './useCarInformation';
import { useContract } from './useContract';

const useFindCarMileage = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.bilhold.fetcher
        .path('/v1/cars/{Vin}/mileage')
        .method('get')
        .create();
};

export function useGetCarMileage(vin: string | undefined) {
    const getCarMilage = useFindCarMileage();
    return useQuery({
        queryKey: ['getCarMilage', vin ?? ''],
        queryFn: () =>
            getCarMilage({ Vin: vin ?? '' }).then((x) => {
                return x.data;
            }),
        enabled: !!vin,
    });
}

interface DetailProps {
    readonly carType: string;
    readonly myCar?: ExistingCarRelation;
    readonly orderedCar?: OrderedCarRelation;
}

const calculateDays = (date?: string) => {
    if (!date) return '';
    const now = new Date();
    const then = new Date(date);
    const difference = now.getTime() - then.getTime();
    return Math.ceil(difference / (1000 * 3600 * 24));
};

export const useCorrectDetails = ({
    carType,
    myCar,
    orderedCar,
}: DetailProps) => {
    let technicalDetailsIcons: AllowedIcons[] = ['speed', 'build', 'verified'];
    let technicalDetailsLabels: string[] = [
        'Kilometer',
        'Neste service',
        'Mobilitetsgaranti',
    ];
    let technicalDetailsValues: string[] = ['---', '---', '---'];
    let lastUpdatedMilageValue = 0;

    const { data: details } = useCarInformation(myCar?.vin);
    const { data: orderedContract } = useContract(orderedCar?.contractId);
    const { data: mileageInfo } = useGetCarMileage(myCar?.vin);
    const lastUpdatedMileage =
        mileageInfo?.lastUpdatedMileage != null
            ? calculateDays(mileageInfo.lastUpdatedMileage)
            : '';
    const fuelType: string =
        orderedContract?.carContractCar.fuelType != undefined
            ? lang[
                  `contract_fuel_type_${orderedContract?.carContractCar.fuelType}`
              ]
            : '---';
    const fuel_icon =
        details?.fuelType === 'Elektrisk' ? 'ev_station' : 'gas_station';
    switch (carType) {
        case 'MYCAR':
            lastUpdatedMilageValue = +lastUpdatedMileage;
            technicalDetailsIcons = ['speed', 'assignment', fuel_icon];
            technicalDetailsLabels = ['Kilometer', 'Verksted', 'Drivstoff'];
            technicalDetailsValues = [
                `${formatMileage(`${myCar?.mileage}`)} km`,
                'Historikk',
                details?.fuelType ?? '---',
            ];
            return {
                technicalDetailsIcons: technicalDetailsIcons,
                technicalDetailsLabels: technicalDetailsLabels,
                technicalDetailsValues: technicalDetailsValues,
                lastUpdatedMilage: lastUpdatedMilageValue,
            };

        case 'ORDERED':
            technicalDetailsIcons = ['car', 'ev_station', 'assignment'];
            technicalDetailsLabels = ['Årsmodell', 'Drivstoff', 'Ordrenummer'];
            technicalDetailsValues = [
                `${orderedCar?.model?.year}`,
                `${fuelType}`,
                `${orderedCar?.orderNumber}`,
            ];
            return {
                technicalDetailsIcons: technicalDetailsIcons,
                technicalDetailsLabels: technicalDetailsLabels,
                technicalDetailsValues: technicalDetailsValues,
                lastUpdatedMilage: lastUpdatedMilageValue,
            };
        default:
            return {
                technicalDetailsIcons: technicalDetailsIcons,
                technicalDetailsLabels: technicalDetailsLabels,
                technicalDetailsValues: technicalDetailsValues,
                lastUpdatedMilage: lastUpdatedMilageValue,
            };
    }
};

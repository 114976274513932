import {
    Accordion,
    AlertBanner,
    GhostButton,
    Heading1Text,
    LoadingSpinner,
    PrimaryButton,
    TextArea,
    TextContainer,
} from '@gnist/design-system';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageLayout } from 'src/features/my-car/components/PageLayout';
import { Header } from 'src/features/my-car/sticky-header/StickyHeader';
import { useGetContract } from 'src/features/my-ordered-car/http/useGetContract';
import { getPartyId } from 'src/utils/auth/authenticationUtils';
import { useStickyScroll } from 'src/utils/browser/useStickyScroll';
import { ddRumPrivacySetting } from 'src/utils/datadog';
import { themeOverride } from 'src/utils/dev-tools/devToolsUtils';
import { useProfile } from 'src/utils/hooks/useProfile';
import { getThemeForCarBrand } from 'src/utils/themes/selectTheme';
import { styled } from 'styled-components';
import { ChildThemeProvider } from 'theming';
import { ManualProductRequest } from '../utils/sendProductRequestManually';
import { useSendProductRequest } from '../utils/useSendProductRequest';
import { ServiceTable } from './AboutServicesTable';
import { getModelPrice } from './getModelPrice';

const StyledHeadline = styled(Heading1Text)`
    margin-top: calc(var(--moller-spacing-3xl) + var(--moller-spacing-m));
`;

const StyledTextContainer = styled(TextContainer)`
    margin-bottom: var(--moller-spacing-xs);
`;

const StyledTextArea = styled(TextArea)`
    width: 100%;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
    margin-bottom: var(--moller-spacing-xxl);
`;

const StyledGhostButton = styled(GhostButton)`
    margin-bottom: var(--moller-spacing-xl);
`;

const StyledFieldset = styled.fieldset`
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    margin-bottom: var(--moller-spacing-xs);
    border: 0;

    legend {
        margin-bottom: var(--moller-spacing-xxs);
        font-weight: bold;
    }

    label {
        margin-bottom: var(--moller-spacing-xxs);
    }
`;

function formatNumberToNorwegianFormat(numberToFormat?: number | null) {
    if (!numberToFormat) {
        return '';
    }

    if (numberToFormat % 1 === 0) {
        // Don't force decimals if no decimals in number to format.
        return numberToFormat.toLocaleString('nb-NO');
    }

    return numberToFormat.toLocaleString('nb-NO', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
}

export const SvaProductRequest = () => {
    const params = useParams<{ carId?: string }>();
    const { data: contractDetails, isError } = useGetContract(params.carId);

    const model = contractDetails?.carContractCar.model;
    const brand = contractDetails?.carContractCar.brand;
    const logoBrand = getThemeForCarBrand(brand);
    const isElectric = contractDetails?.carContractCar.fuelType === 'Electric';

    // We don't have a full car model catalog and most of the consumers are electric, so only checking electric
    const servicePriceFrom = isElectric ? getModelPrice(model, brand) : null;
    const formattedPriceFrom = formatNumberToNorwegianFormat(servicePriceFrom);

    const [isTableShowing, setIsTableShowing] = useState(false);
    const [isScrollActive, setIsScrollActive] = useState(false);
    const [manualCustomerResponse, setManualCustomerResponse] = useState('');

    function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        const formData = new FormData(e.currentTarget);
        const selectedOption = formData.get('serviceType');
        const customMessage = formData.get('customMessage')?.toString();

        const formattedDeal =
            selectedOption === 'sva'
                ? 'Service- og vedlikeholdsavtale'
                : 'Serviceavtale';

        const customerResponse = `Jeg ønsker ${formattedDeal}. ${customMessage}`;

        setManualCustomerResponse(customerResponse);

        handleSendProductRequest(customerResponse);
    }

    const navigate = useNavigate();

    const { carId } = useParams<{
        carId: string;
    }>();

    const isStickyScrollActive = useStickyScroll(
        isScrollActive,
        setIsScrollActive
    );

    const {
        mutate: sendProductRequest,
        isPending,
        isError: isSendProductRequestError,
        isSuccess: hasBeenSubmitted,
    } = useSendProductRequest();

    const { data: user } = useProfile();
    const partyId = getPartyId();

    if (isError) {
        return (
            <AlertBanner
                type={'error'}
                message={
                    'Vi klarte dessverre ikke å hente kontrakten din. Vennligst prøv igjen senere eller kontakt din forhandler for å legge til produkter på din kontrakt.'
                }
            />
        );
    }

    const isAllDataReady = !!contractDetails && !!user && !!partyId && !!carId;

    if (!isAllDataReady) {
        return <LoadingSpinner isFullScreen />;
    }

    const { carContractCar, carContractDealer } = contractDetails;

    // 532 dealer does not have the same nybil-mail as others
    const dealerEmail =
        contractDetails.carContractDealer.number === '532'
            ? 'nybil.532@mobile-larvik.no'
            : `nybil.${contractDetails.carContractDealer.number}@moller.no`;

    const handleSendProductRequest = (customerResponse: string) => {
        sendProductRequest({
            dealerEmail: dealerEmail,
            contractId: `${contractDetails.contractNumber}/${contractDetails.version}`,
            brand: carContractCar?.brand,
            model: carContractCar.model || 'Ukjent',
            firstName: user.firstname,
            dealerNumber: carContractDealer.number,
            vin: carId,
            partyId: parseInt(partyId),
            customProductRequest: customerResponse,
        });
    };

    const renderSubmittedState = () => {
        return (
            <PageLayout>
                <StyledHeadline>Takk for din henvendelse!</StyledHeadline>
                <StyledTextContainer>
                    <p>
                        Vi har mottatt forespørselen og {carContractDealer.name}{' '}
                        vil kontakte deg på e-post så fort som mulig.
                    </p>
                </StyledTextContainer>
                <StyledPrimaryButton
                    onClick={() =>
                        navigate('/,', {
                            replace: true,
                        })
                    }
                >
                    Tilbake til forsiden
                </StyledPrimaryButton>
            </PageLayout>
        );
    };

    const renderFormState = () => {
        return (
            <PageLayout>
                <StyledHeadline>
                    Send forespørsel om serviceavtale
                </StyledHeadline>

                <StyledTextContainer>
                    <p>
                        Serviceavtale eller Service- og vedlikeholdsavtale (SVA)
                        kan tegnes på flere kombinasjoner av tid/km. Du får
                        tilbud om en avtale tilpasset ditt bruksområde og
                        kjøremønster, samt utfyllende informasjon om hva avtalen
                        dekker.
                    </p>

                    {!!formattedPriceFrom && (
                        <p>Pris fra {formattedPriceFrom} kr</p>
                    )}
                </StyledTextContainer>

                <StyledGhostButton
                    onClick={() => setIsTableShowing((prev) => !prev)}
                >
                    {isTableShowing
                        ? 'Skjul informasjon om avtaler'
                        : 'Les mer om avtalene'}
                </StyledGhostButton>
                {isTableShowing && <ServiceTable isElectric={isElectric} />}

                <form onSubmit={handleSubmit}>
                    <StyledFieldset>
                        <legend>Velg en avtale</legend>{' '}
                        <label>
                            <input
                                type="radio"
                                name="serviceType"
                                value="serviceavtale"
                                required
                            />
                            Serviceavtale
                        </label>
                        <label>
                            <input
                                type="radio"
                                name="serviceType"
                                value="sva"
                                required
                            />
                            Service- og vedlikeholdsavtale (SVA)
                        </label>
                    </StyledFieldset>
                    <StyledTextArea
                        label="Noe annet du lurer på?"
                        name="customMessage"
                    />
                    <StyledPrimaryButton
                        loading={{
                            isLoading: isPending,
                            loadingText: 'Sender forespørsel',
                        }}
                        type="submit"
                    >
                        Send forespørsel
                    </StyledPrimaryButton>
                </form>

                <Accordion
                    head="Forhandlerinformasjon"
                    withBackground
                    withDivider
                >
                    <TextContainer>
                        <p>Forhandler: {carContractDealer.name}</p>
                        <p>Adresse: {carContractDealer.streetAddress}</p>
                        <p>
                            Postnummer: {carContractDealer.postalCode}{' '}
                            {carContractDealer.city}
                        </p>
                        <p>Telefonnummer: {carContractDealer.phoneNumber}</p>
                    </TextContainer>
                </Accordion>
                {isSendProductRequestError && (
                    <div
                        {...ddRumPrivacySetting('mask')}
                        style={{ marginTop: 'var(--moller-spacing-m' }}
                    >
                        <ManualProductRequest
                            orderedCarDealerEmail={dealerEmail}
                            customProductRequest={manualCustomerResponse}
                            contract={contractDetails}
                            userFirstName={user.firstname}
                        />
                    </div>
                )}
            </PageLayout>
        );
    };

    return (
        <ChildThemeProvider
            {...ddRumPrivacySetting('allow')}
            theme={logoBrand}
            overrideTheme={themeOverride}
        >
            <Header
                brand={carContractCar?.brand}
                isLoading={!isAllDataReady}
                carName={carContractCar?.model}
                isScrollActive={isStickyScrollActive}
            />
            {hasBeenSubmitted ? renderSubmittedState() : renderFormState()}
        </ChildThemeProvider>
    );
};

import { Capacitor } from '@capacitor/core';
import { Modal } from '@gnist/design-system';
import { adobeEvent } from 'adobe-utils';
import { useState } from 'react';
import { PATH_HOME } from 'src/routing/routes';
import lang from 'src/utils/lang';
import { OneTimePasswordForm } from './OneTimePasswordForm';

interface EnrollUserProps {
    phoneNumber: string;
    setPhoneNumber: (phoneNumber: string) => void;
    setToken: (token: string) => void;
}

export const EnrollUser = ({
    phoneNumber,
    setPhoneNumber,
    setToken,
}: EnrollUserProps): JSX.Element => {
    const [userExistsModalShowing, showUserExistsModal] = useState(false);

    const pushOtpConfirmedEvent = () =>
        adobeEvent.push(
            'formStep',
            {
                formName: 'Create new user',
                stepName: 'OTP confirmed',
                stepNumber: 1,
                formId: phoneNumber,
            },
            Capacitor.getPlatform()
        );

    const onTokenSuccess = (token: string) => {
        setToken(token);
        pushOtpConfirmedEvent();
    };

    return (
        <>
            <OneTimePasswordForm
                onTokenSuccess={onTokenSuccess}
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                showUserExistsModal={showUserExistsModal}
            />
            <Modal
                isOpen={userExistsModalShowing}
                title={lang.userAlreadyExistsTitle}
                handleClose={() => showUserExistsModal(false)}
                primaryAction={{
                    onClick: () => (window.location.href = PATH_HOME),
                    label: lang.login,
                }}
            >
                {lang.userAlreadyExistsDescription}
            </Modal>
        </>
    );
};

import { breakpointTablet } from '@gnist/design-system';
import { styled } from 'styled-components';

export const BilholdLayout = styled.div`
    display: flex;
    flex-direction: column;
    padding: var(--moller-spacing-xs);
    gap: var(--moller-spacing-l);
    @media (min-width: ${breakpointTablet}) {
        width: 800px;
        margin: auto;
    }
`;

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useFetchersContext } from 'external-apis';
import { carProfileQueryKey } from 'src/features/home/queries/useCarProfile';

const useUpdateMileageFetcher = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.bilhold.fetcher
        .path('/v1/cars/{Vin}/mileage')
        .method('put')
        .create();
};

export const useUpdateMileage = (vin: string, onSuccess: () => void) => {
    const queryClient = useQueryClient();
    const updateMileage = useUpdateMileageFetcher();

    return useMutation({
        mutationFn: (mileage: string) =>
            updateMileage({ Vin: vin, mileage: parseInt(mileage) }),
        onSuccess: () => {
            void queryClient.invalidateQueries({
                queryKey: ['getCarMilage', vin],
            });
            void queryClient.invalidateQueries({
                queryKey: [carProfileQueryKey],
            });
            void queryClient.invalidateQueries({
                queryKey: ['getServiceAgreement', vin],
            });
            onSuccess();
        },
    });
};

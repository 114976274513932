import { LogoBrand } from '@gnist/design-system/foundation/logos/Logo.js';
import { useParams } from 'react-router-dom';
import NoContent from 'src/routing/NoContent';
import Brand, { brandMapper } from 'src/types/Brand';
import { themeOverride } from 'src/utils/dev-tools/devToolsUtils';
import { ChildThemeProvider } from 'theming';
import WarrantyAudi from './WarrantyAudi';
import WarrantyCupra from './WarrantyCupra';
import WarrantySeat from './WarrantySeat';
import WarrantySkoda from './WarrantySkoda';
import WarrantyVw from './WarrantyVw';

const getBrandTheme = (brand: Brand): LogoBrand => {
    switch (brand) {
        case Brand.VW:
            return 'vw';
        case Brand.AUDI:
            return 'audi';
        case Brand.SKODA:
            return 'skoda';
        case Brand.CUPRA:
            return 'cupra';
        case Brand.NYTTE:
            return 'vwnytte';
        default:
            return 'bilhold';
    }
};

const getWarrantyComponent = (brand: Brand) => {
    switch (brand) {
        case Brand.AUDI:
            return <WarrantyAudi />;
        case Brand.SKODA:
            return <WarrantySkoda />;
        case Brand.SEAT:
            return <WarrantySeat />;
        case Brand.VW:
            return <WarrantyVw />;
        case Brand.CUPRA:
            return <WarrantyCupra />;
        case Brand.NYTTE:
            return <WarrantyVw />;
        default:
            return <NoContent />;
    }
};

const WarrantyContainer = () => {
    const { brand: brandFromUrl } = useParams();
    const brand = brandMapper(brandFromUrl);
    const brandTheme = getBrandTheme(brand);

    return (
        <ChildThemeProvider theme={brandTheme} overrideTheme={themeOverride}>
            {getWarrantyComponent(brand)}
        </ChildThemeProvider>
    );
};

export default WarrantyContainer;

import { Product } from 'src/features/home/utils/productType';
import ProductRequestImage from '../product-images/custom/product-request.jpg';

export const customProduct: Product = {
    articleNumber: 'customer-request', // Decides where to route
    packageNumber: 'custom',
    image: ProductRequestImage,
    title: 'Flere produkter',
    descriptionShort: 'Her kan du sende oss en forespørsel om andre produkter.',
    descriptionLong: 'Vi kan skaffe det meste til din bil.',
    text: 'Vi kan skaffe det meste til din bil.',
    price: 0,
    carBrand: 'Other',
    carModel: ['OTHER'],
};

import { datadogRum } from '@datadog/browser-rum';
import { ActionCard, Icon, Row, Heading2Text } from '@gnist/design-system';
import { useLocation, useNavigate } from 'react-router-dom';
import { TDTStart } from 'src/features/competition/pages/CompetitionPage';
import { useCompetitionData } from 'src/features/competition/utils/useCompetitionData';
import { PATH_COMPETITION } from 'src/routing/routes';

export function TourDeSkodaShortcut() {
    const hasCompetitionStarted = TDTStart.getTime() < new Date().getTime();

    /* 
        We only want to know if some data is registered. 
    */
    const { data: hasRegisteredCompetitionData } = useCompetitionData(
        hasCompetitionStarted,
        true // If not authenticated, will redirect to about-competition
    );

    const { pathname } = useLocation();
    const navigate = useNavigate();

    function handleNavigate() {
        datadogRum.addAction('bilhold-tourdeskoda-shortcut', {
            pathname: pathname,
        });

        navigate(PATH_COMPETITION);
    }

    return hasRegisteredCompetitionData ? (
        <ActionCard as="button" type="button" onClick={handleNavigate}>
            <Row horizontalAlign="spaceBetween" gap="base">
                <Heading2Text>Avsluttet: Tour de Škoda</Heading2Text>
                <Icon icon="chevron_right" />
            </Row>
            <p>Se konkurransesiden</p>
        </ActionCard>
    ) : null;
}

import { ActionCard, AllowedIcons, Icon, Row } from '@gnist/design-system';
import { useNavigate } from 'react-router-dom';
import { useCarInformation } from '../home/queries/useCarInformation';
import { useGetSanityVideosByTypeNumber } from './videos/useGetSanityVideosByTypeNumber';
import { useCarProfile } from '../home/queries/useCarProfile';
import { useDamageAppraisers } from './query/useDamageAppraisers';
import { useFlag } from 'feature-toggle';
import lang from 'src/utils/lang';
import { PATH_WARRANTY } from 'src/routing/routes';

type ActionCardContent = {
    toggle: boolean;
    path: string;
    icon: AllowedIcons;
    title: string;
};

function usePayloadCalculator(vin: string) {
    const { data: technicalDetails } = useCarInformation(vin);
    return !!(
        technicalDetails?.minimumWeight &&
        technicalDetails?.maxWeight &&
        technicalDetails.minimumWeight > 0 &&
        technicalDetails.maxWeight > 0
    );
}

function useEnergyConsumptionCalculator(vin: string) {
    const { data: technicalDetails } = useCarInformation(vin);
    return (
        !!technicalDetails?.fuelType &&
        technicalDetails?.energyConsumption != null
    );
}

function useShowSanityVideos(car: Car) {
    const { data: sanityVideos } = useGetSanityVideosByTypeNumber(
        car.typeNumber
    );
    return !!sanityVideos && sanityVideos.length > 0;
}

type Car = NonNullable<
    ReturnType<typeof useCarProfile>['data']
>['existingCars'][number];
export function CarMenu({ car }: { car: Car }) {
    const navigate = useNavigate();
    const vin = car.vin;
    const appraisers = useDamageAppraisers();
    const showDamageAppraiser = !!appraisers?.data?.find((x) => x.vin === vin);
    const showVehicleHistory = useFlag('ShouldShowServiceBooklet');
    const showPayloadCalculator = usePayloadCalculator(vin);
    const showEnergyConsumption = useEnergyConsumptionCalculator(vin);
    const showSanityVideos = useShowSanityVideos(car);

    const paths: ActionCardContent[] = [
        {
            toggle: true,
            path: `/booking?registrationNumber=${car.licensePlate}`,
            icon: 'build',
            title: lang.workshopLinkTitle,
        },
        {
            toggle: showVehicleHistory,
            path: `/my-car/vin/${vin}/vehicle-history`,
            icon: 'assignment',
            title: lang.car_history,
        },
        {
            toggle: true,
            path: `/my-car/vin/${vin}/appointments`,
            icon: 'assignment',
            title: lang.see_workshop_history,
        },
        {
            toggle: true,
            path: `${PATH_WARRANTY}/${car.brand?.toLowerCase()}`,
            icon: 'calendar',
            title: lang.car_guarantees,
        },
        {
            toggle: showPayloadCalculator,
            path: `/my-car/vin/${vin}/payload-calculator`,
            icon: 'car',
            title: lang.payloadCalculatorTitle,
        },
        {
            toggle: showEnergyConsumption,
            path: `/my-car/vin/${vin}/emissions`,
            icon: 'savings',
            title: lang.co2CardTitle,
        },
        {
            toggle: showSanityVideos,
            path: `/my-car/vin/${vin}/videos`,
            icon: 'play_circle',
            title: lang.videos,
        },
        {
            toggle: showDamageAppraiser,
            path: `/my-car/vin/${vin}/damageAppraiser`,
            icon: 'car_crash',
            title: lang.damage_appraiser,
        },
    ];

    return paths.map(({ toggle, path, icon, title }) => {
        if (!toggle) return null;
        return (
            <ActionCard key={path} onClick={() => navigate(path)}>
                <Row gap="xxs">
                    <Icon icon={icon} size={'l'} />
                    {title}
                </Row>
            </ActionCard>
        );
    });
}

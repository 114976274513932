import { Heading1Text, LeadText, TextContainer } from '@gnist/design-system';
import { isMobileApp } from 'src/utils/mobile';
import { styled } from 'styled-components';
import service from '../../eu-control/eu-control.png';
import { RecallServiceContentProps } from '../RecallServiceContent';

const FlexContainer = styled.div<{ reverseOrder: boolean }>`
    display: flex;
    flex-direction: ${({ reverseOrder }) =>
        reverseOrder ? 'column-reverse' : 'column'};

    h2 {
        margin-top: var(--moller-spacing-s) !important;
    }
`;

export const AudiSoftwareUpdateBreakingSystem = ({
    car,
    recall,
}: RecallServiceContentProps) => {
    return (
        <TextContainer>
            <FlexContainer reverseOrder={isMobileApp()}>
                {!isMobileApp() && <img src={service} alt="Tilbakekalling" />}
                <Heading1Text>{`Tilbakekallingsoppgradering ${recall?.recallCode}`}</Heading1Text>
            </FlexContainer>
            <LeadText>
                Av konformitetshensyn er det nødvendig å installere ny
                programvare for bremsestyreenheten på bilen din.
            </LeadText>
            <p>
                {`
                Fabrikken har registrert at parkeringsbremsen på ${car.name} fra et bestemt produksjonstidsrom under visse forhold kan risikere å ikke fungere korrekt.`}
            </p>
            <p>
                Når bilen er sikret med parkeringsbremsen, lyser den røde
                kontrollampen for parkeringsbremsen i kombiinstrumentet. Før du
                forlater bilen, må du forsikre deg om at denne røde
                kontrollampen vises i flere sekunder.
            </p>
            <p>
                Bestill time for oppgradering så raskt som mulig. Du kan kjøre
                bilen frem til du får time på verkstedet.
            </p>
            <p>
                Dette tiltaket vil ta inntil en time, og du vil selvsagt ikke
                belastes for kostnaden. Vi håper du har forståelse for at dette
                verkstedbesøket er nødvendig og beklager de ulemper dette
                eventuelt medfører.
            </p>
        </TextContainer>
    );
};

import {
    AllowedIcons,
    breakpointTablet,
    Column,
    Icon,
    Row,
} from '@gnist/design-system';
import { styled } from 'styled-components';

const StyledRow = styled(Row)`
    gap: 0 var(--moller-spacing-base);
    align-items: center;
    flex-wrap: wrap;
    margin-right: var(--moller-spacing-xs);

    @media screen and (min-width: ${breakpointTablet}) {
        gap: var(--moller-spacing-xs);
    }
`;
interface TechnicalDetailsProps {
    value: string | number;
    icon: AllowedIcons;
}
export const TechnicalDetails = ({ value, icon }: TechnicalDetailsProps) => {
    return (
        <StyledRow>
            <Icon icon={icon} size={'l'} />
            <Column>
                <p>{value}</p>
            </Column>
        </StyledRow>
    );
};

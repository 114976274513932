import { AllowedIcons, Icon, Row } from '@gnist/design-system';
import {
    ServiceCategory,
    ServiceViewModel,
} from 'external-apis/src/types/port';
import React from 'react';
import { styled } from 'styled-components';

type WaitingServiceIconsProps = {
    waitingServices: ServiceViewModel[];
};

type WorkshopAmenity = Extract<
    ServiceCategory,
    | 'WorkshopAmenityBeverage'
    | 'WorkshopAmenityWifi'
    | 'WorkshopAmenityRecreation'
    | 'WorkshopAmenityEntertainment'
>;

const workshopAmenities: { [key in WorkshopAmenity]: AllowedIcons } = {
    WorkshopAmenityBeverage: 'coffee',
    WorkshopAmenityEntertainment: 'live_tv',
    WorkshopAmenityWifi: 'wifi',
    WorkshopAmenityRecreation: 'forest',
};

function isWorkshopAmenity(
    category: ServiceCategory | undefined
): category is WorkshopAmenity {
    return !!(category && category in workshopAmenities);
}

export const WaitingServiceIcons: React.FC<WaitingServiceIconsProps> = ({
    waitingServices,
}) => {
    return (
        <Row style={{ gap: `0 var(--moller-spacing-l)` }} flexWrap="wrap">
            {waitingServices.map((service) => (
                <SingleWaitingService key={service.id} service={service} />
            ))}
        </Row>
    );
};

const Wrapper = styled.span`
    display: grid;
    grid-template-columns: auto auto;
    gap: var(--moller-spacing-base);
`;

const SingleWaitingService: React.FC<{ service: ServiceViewModel }> = ({
    service: { category, ...service },
}) => {
    if (!category) return null;
    let amenity: AllowedIcons = 'info';
    if (isWorkshopAmenity(category)) {
        amenity = workshopAmenities[category];
    }
    return (
        <Wrapper>
            <Icon icon={amenity} />
            <span>{service.name}</span>
        </Wrapper>
    );
};

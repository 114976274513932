import { Capacitor } from '@capacitor/core';
import { datadogRum } from '@datadog/browser-rum';
import {
    Accordion,
    AlertBanner,
    DetailText,
    Heading1Text,
    LeadText,
    LoadingSpinner,
    PrimaryButton,
    TextButton,
    TextContainer,
} from '@gnist/design-system';
import { adobeEvent } from 'adobe-utils';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageLayout } from 'src/features/my-car/components/PageLayout';
import { Header } from 'src/features/my-car/sticky-header/StickyHeader';
import { useGetContract } from 'src/features/my-ordered-car/http/useGetContract';
import { getPartyId } from 'src/utils/auth/authenticationUtils';
import { useStickyScroll } from 'src/utils/browser/useStickyScroll';
import { themeOverride } from 'src/utils/dev-tools/devToolsUtils';
import { useProfile } from 'src/utils/hooks/useProfile';
import { getThemeForCarBrand } from 'src/utils/themes/selectTheme';
import { formatDate } from 'src/utils/time/timeUtils';
import { styled } from 'styled-components';
import { ChildThemeProvider } from 'theming';
import { Product } from '../../utils/productType';
import { pocProducts } from './pocProducts';
import { ErrorMessageWithManualEmail } from './utils/sendOrderEmailManually';
import { useOrderAccessories } from './utils/useSendPurchaseConfirmation';

const ProductImage = styled.img`
    width: 100%;
    height: auto;
    object-fit: cover;
    margin: var(--moller-size-s) 0;
    border-radius: var(--moller-radius-card);
    margin-top: calc(var(--moller-spacing-3xl) + var(--moller-spacing-s));
`;

const ActionContainer = styled.div`
    display: flex;
    gap: var(--moller-spacing-s);
    margin: var(--moller-size-s) 0;
`;

const ProductTag = styled.div`
    background-color: var(--moller-color-secondary-container);
    color: var(--moller-color-on-secondary-container);
    padding: var(--moller-spacing-xxs) var(--moller-spacing-s);
    border-radius: var(--moller-border-radius-full);
    max-width: fit-content;
`;

const StyledDescription = styled(DetailText)`
    margin-bottom: var(--moller-spacing-s);
`;

const StyledLeadText = styled(LeadText)`
    margin: var(--moller-spacing-3xl) 0 var(--moller-spacing-xl);
`;

const StyledList = styled.ul`
    display: flex;
    flex-direction: column;
    gap: var(--moller-spacing-xs);
    list-style: disc;
    margin: var(--moller-spacing-s) 0;
    padding-left: var(--moller-spacing-m);
`;

const StyledInfoSection = styled.div`
    padding-top: var(--moller-spacing-xxl);
`;

export const ProductPage = () => {
    const [isScrollActive, setIsScrollActive] = useState(false);
    const [isClickedReadMore, setIsClickedReadMore] = useState(false);
    const [product, setProduct] = useState<Product | undefined | null>(
        undefined
    );
    const { carId, articleNumber } = useParams<{
        carId: string;
        articleNumber: string;
    }>();
    const { data, isError: isContractError } = useGetContract(carId);

    useEffect(() => {
        const product = pocProducts.find(
            (product) => product.articleNumber === articleNumber
        );

        if (!product) {
            setProduct(null);

            datadogRum.addAction('productPageError', {
                articleNumber: articleNumber,
            });
        } else {
            setProduct(product);

            datadogRum.addAction('productPageLoaded', {
                articleNumber: product.articleNumber,
                title: product.title,
                price: product.price,
            });
        }
    }, [articleNumber]);

    const isStickyScrollActive = useStickyScroll(
        isScrollActive,
        setIsScrollActive
    );

    const navigate = useNavigate();

    const redirectToConfirmationPage = () => {
        navigate('confirmation', { replace: true });
    };

    const {
        mutate: orderAccessories,
        isPending: isLoading,
        isError: isPurchaseError,
    } = useOrderAccessories(redirectToConfirmationPage);

    // Get user data and party id.
    const { data: user } = useProfile();
    const partyId = getPartyId();

    if (isContractError) {
        return (
            <AlertBanner
                type={'error'}
                message={
                    'Vi klarte dessverre ikke å hente kontrakten din. Vennligst prøv igjen senere eller kontakt din forhandler for å legge til produkter på din kontrakt.'
                }
            />
        );
    }

    // Check if all data is ready to handle a transaction.
    // TODO: Add error handling to avoid infinite loading.
    const isAllDataReady =
        !isLoading &&
        !!data &&
        !!user &&
        !!partyId &&
        !!carId &&
        !!articleNumber &&
        !!product;

    if (!isAllDataReady) {
        return <LoadingSpinner isFullScreen />;
    }

    const { carContractCar, carContractDealer, seller } = data;

    const carTheme = getThemeForCarBrand(carContractCar.brand);

    // 532 dealer does not have the same nybil-mail as others
    const dealerEmail =
        carContractDealer.number === '532'
            ? 'nybil.532@mobile-larvik.no'
            : `nybil.${carContractDealer.number}@moller.no`;

    const sendConfirmation = () => {
        adobeEvent.push(
            'purchase',
            {
                productID: `${product.articleNumber}`,
                productName: `${product.title}`,
                price: product.price,
            },
            Capacitor.getPlatform()
        );
        orderAccessories({
            dealerEmail: dealerEmail,
            customerEmail: user.email,
            contractId: `${data.contractNumber}/${data.version}`,
            brand: carContractCar.brand,
            model: carContractCar.model || 'Ukjent',
            firstName: user.firstname,
            lastName: user.surname || '',
            dealerNumber: carContractDealer.number,
            sellerName: seller?.fullName || '',
            vin: carId,
            partyId: parseInt(partyId),
            accessories: [
                {
                    accessoryName: `${product.title} ${product.articleNumber}`,
                    price: product.price.toString(),
                    nuPackage: product.packageNumber,
                },
            ],
            contractDate: formatDate(data.createdAt),
        });
    };

    const renderErrorMessageWithManualEmail = () => {
        return (
            <ErrorMessageWithManualEmail
                orderedCarDealerEmail={dealerEmail}
                product={product}
                contract={data}
                articleNumber={articleNumber}
                userFirstName={user.firstname}
            />
        );
    };

    const handleReadMoreClick = () => {
        setIsClickedReadMore(true);

        const infoSection = document.getElementById('info-section');

        if (infoSection) {
            infoSection.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest',
            });
        }
    };

    return (
        <ChildThemeProvider theme={carTheme} overrideTheme={themeOverride}>
            <Header
                brand={carContractCar.brand}
                isLoading={isLoading}
                carName={carContractCar.model}
                isScrollActive={isStickyScrollActive}
            />
            <PageLayout>
                <ProductImage src={product.image} />
                <Heading1Text>{product.title}</Heading1Text>
                <ActionContainer>
                    <ProductTag>
                        {product.price.toLocaleString('NO-nb')} kr
                    </ProductTag>
                    <PrimaryButton
                        loading={{
                            isLoading: isLoading,
                            loadingText: 'Sender bestilling',
                        }}
                        onClick={() => {
                            sendConfirmation();
                        }}
                    >
                        Send bestilling
                    </PrimaryButton>
                </ActionContainer>
                <StyledDescription>
                    Produktet legges til på kontrakten din.{' '}
                    <TextButton onClick={handleReadMoreClick}>
                        Les mer
                    </TextButton>
                </StyledDescription>
                {isPurchaseError && renderErrorMessageWithManualEmail()}
                <StyledLeadText>{product.descriptionLong}</StyledLeadText>
                <TextContainer>
                    <p>{product.text}</p>
                </TextContainer>
                <TextContainer>
                    <StyledList>
                        {product.specList?.map((item) => (
                            <li key={item}>{item}</li>
                        ))}
                    </StyledList>
                </TextContainer>
                <StyledInfoSection id="info-section">
                    <Accordion
                        head="Informasjon om selger"
                        withBackground
                        expanded={isClickedReadMore}
                        withDivider
                    >
                        <TextContainer>
                            <p>Forhandler: {carContractDealer.name}</p>
                            <p>Adresse: {carContractDealer.streetAddress}</p>
                            <p>
                                Postnummer: {carContractDealer.postalCode}{' '}
                                {carContractDealer.city}
                            </p>
                            <p>
                                Telefonnummer: {carContractDealer.phoneNumber}
                            </p>
                        </TextContainer>
                    </Accordion>
                    <Accordion
                        head="Hva skjer ved bestilling?"
                        withBackground
                        expanded={isClickedReadMore}
                    >
                        <TextContainer>
                            <p>
                                Når du har sendt bestillingen vil forhandleren
                                din få en e-post med informasjon om hvilket
                                produkt du ønsker å kjøpe. Du vil også få en
                                e-post med bekreftelse på at bestillingen er
                                sendt.
                            </p>
                            <p>
                                Forhandleren vil sende bekreftelse på
                                bestillingen i løpet av noen virkedager.
                            </p>
                            <p>
                                I tråd med lov om angrerett har du 14 dagers
                                angrefrist fra du har mottatt bestillingen.
                                Skjema for angrerett vil bli sendt til deg
                                sammen med ordrebekreftelsen.
                            </p>
                        </TextContainer>
                    </Accordion>
                </StyledInfoSection>
            </PageLayout>
        </ChildThemeProvider>
    );
};

import { InfoCard } from '@gnist/design-system';
import { GreenStreakShirtPrize } from 'src/features/competition/components/myPrizes/GreenStreakShirtPrize';
import { StartPrize } from 'src/features/competition/components/myPrizes/StartPrize';
import { WhiteElevationShirtPrize } from 'src/features/competition/components/myPrizes/WhiteElevationShirtPrize';
import { YellowTourShirtPrize } from 'src/features/competition/components/myPrizes/YellowTourShirtPrize';
import { PrizeData } from 'src/features/competition/utils/useCompetitionPrizeData';
import { StartPrizeInterface } from 'src/features/competition/utils/useCompetitionStartPrize';
import { styled } from 'styled-components';
import { FirstPrize } from './FirstPrize';

const CustomAccordion = styled.details`
    background-color: var(--moller-color-primary);

    summary {
        list-style: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: var(--moller-spacing-xxs);
    }

    &:last-child summary {
        border: none;
    }

    summary::after {
        content: '';
        width: 0;
        height: 0;
        border-top: 10px solid black;
        border-inline: 7px solid transparent;
        transition: 0.2s;
    }
    &[open] summary::after {
        transform: rotate(180deg);
    }
`;

const PrizesCardWrapper = styled(InfoCard)`
    background-color: var(--moller-color-primary);
    color: black;
    margin: var(--moller-spacing-s) 0;
    padding: var(--moller-spacing-xs) var(--moller-spacing-m);
    border: 0;
`;

interface Props {
    competitionPrizeData: PrizeData | undefined;
    startPrizeData: StartPrizeInterface | undefined;
}

export const PrizesAccordion = ({
    competitionPrizeData,
    startPrizeData,
}: Props) => {
    return (
        <PrizesCardWrapper>
            <CustomAccordion>
                <summary>Premier</summary>
                <StartPrize
                    hasWon={startPrizeData?.hasWon}
                    dealer={startPrizeData?.dealer}
                    prize={startPrizeData?.prize}
                />
                <YellowTourShirtPrize
                    hasWon={competitionPrizeData?.hasWonDistancePrize}
                    dealer={competitionPrizeData?.distancePrizeDealer}
                />
                <GreenStreakShirtPrize
                    hasWon={competitionPrizeData?.hasWonStreakPrize}
                    dealer={competitionPrizeData?.streakPrizeDealer}
                />
                <WhiteElevationShirtPrize
                    hasWon={competitionPrizeData?.hasWonElevationPrize}
                    dealer={competitionPrizeData?.elevationPrizeDealer}
                />
                <FirstPrize />
            </CustomAccordion>
        </PrizesCardWrapper>
    );
};

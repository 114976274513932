import { App as AppApi } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { AxiosError } from 'axios';
import { createContext, ReactNode, useEffect } from 'react';
import { isIOS, isMobileApp } from 'src/utils/mobile';
import { useHasAuthenticatedToStrava } from '../utils/useHasAuthenticatedToStrava';
import { useHasCompetitionUser } from '../utils/useHasCompetitionUser';
import { useUpdateAccessToken } from '../utils/useUpdateAccessToken';

interface ErrorResponseData {
    message?: string;
}

interface AuthContextProps {
    hasCompetitionUser: boolean | undefined;
    isHasCompetitionUserLoading: boolean;
    hasStravaAuthenticated: boolean | undefined;
    isStravaAuthLoading: boolean;
    isStravaCodeLoading: boolean;
    error: AxiosError<ErrorResponseData>;
}

export const AuthContext = createContext<AuthContextProps>(
    {} as AuthContextProps
);

export function AuthProvider({
    children,
}: {
    children: ReactNode;
}): JSX.Element {
    const { data: hasStravaAuthenticated, isPending: isStravaAuthLoading } =
        useHasAuthenticatedToStrava();
    const { data: hasCompetitionUser, isPending: isHasCompetitionUserLoading } =
        useHasCompetitionUser();
    const {
        mutate: saveAccessToken,
        isPending: isStravaCodeLoading,
        error,
    } = useUpdateAccessToken();

    useEffect(() => {
        function getCodeAndSendToBackend() {
            if (hasStravaAuthenticated || isStravaAuthLoading) {
                return;
            }

            if (isMobileApp()) {
                void AppApi.addListener('appUrlOpen', (event) => {
                    const url = new URL(event.url);
                    const code = url.searchParams.get('code');
                    if (isIOS()) {
                        Browser.close().catch((err) => console.error(err));
                    }
                    if (code) {
                        saveAccessToken(code);
                    }
                });
            } else {
                const urlParams = new URLSearchParams(window.location.search);
                const code = urlParams.get('code');

                if (code) {
                    saveAccessToken(code);
                }
            }
        }

        getCodeAndSendToBackend();

        return () => {
            if (isMobileApp()) {
                AppApi.removeAllListeners().catch((err) => console.error(err));
            }
        };
    }, [hasStravaAuthenticated, saveAccessToken, isStravaAuthLoading]);

    return (
        <AuthContext.Provider
            value={{
                hasCompetitionUser,
                isHasCompetitionUserLoading,
                hasStravaAuthenticated,
                isStravaAuthLoading,
                isStravaCodeLoading,
                error: error as AxiosError<ErrorResponseData>,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

import { Capacitor } from '@capacitor/core';
import {
    DetailText,
    FormProvider,
    InputHelperText,
    useInputHelperText,
} from '@gnist/design-system';
import { QueryObserverSuccessResult } from '@tanstack/react-query';
import { sendBookingFormProgressToAdobe } from 'adobe-utils/formInteraction';
import {
    ServiceCategory,
    ServiceViewModel,
} from 'external-apis/src/types/port';
import { Services, useGetRecommendedServices } from '../../_api/http/services';
import { BilholdNextButton } from '../../components/bilhold/BilholdNextButton';
import { BilholdInnerViewLayout } from '../../components/bilhold/BilholdView';
import { useLanguageContext } from '../../lib/languages/languageContext';
import { SectionState } from '../sectionUtils';
import { toSelectedService } from '../shared/toSelectedService';
import { GlassDamage } from './features/glassDamage/GlassDamage';
import {
    RecommendedServicesContainer,
    removeRecommendedServices,
} from './features/recommendedServices/RecommendedServicesContainer';
import { Service } from './features/service/Service';
import { TireChange } from './features/tireChange/TireChange';
import { useStandardServicesForm } from './useSectionForm';
import { standardServicesHelperTextId } from './useStandardServiceListValidity';
import { StandardServicesData } from './useStandardServices';

type StandardServicesEditProps = {
    servicesForm: ReturnType<typeof useStandardServicesForm>;
    services: ServiceViewModel[];
    setSectionState: (x: SectionState<StandardServicesData>) => void;
    recommendedServices: ReturnType<typeof useGetRecommendedServices>;
    vin: string;
    dealerNumber: string;
    servicesResponse: QueryObserverSuccessResult<Services>;
    setIsBookingRequest: (x: StandardServicesData['selectedServices']) => void;
};

type StandardServiceType = Exclude<
    ServiceCategory,
    'Tire' | 'RecommendedService' | 'GlassDamage'
>;

type GroupedService =
    | {
          type: StandardServiceType;
          service: ServiceViewModel;
      }
    | { type: 'Tire'; tireServices: ServiceViewModel[] }
    | { type: 'RecommendedService'; recommendedServices: ServiceViewModel[] }
    | { type: 'GlassDamage'; glassDamageServices: ServiceViewModel[] };

const groupServices = (services: ServiceViewModel[]): GroupedService[] => {
    const tireServices = services.filter(
        (service) => service.category === 'Tire'
    );
    const recommendedServices = services.filter(
        (service) => service.category === 'RecommendedService'
    );

    const glassDamageServices = services.filter(
        (service) => service.category === 'GlassDamage'
    );

    const remainingServices = services
        .map((service) =>
            service.category !== 'Tire' &&
            service.category !== 'RecommendedService' &&
            service.category !== 'GlassDamage'
                ? { type: service.category ?? 'None', service }
                : null
        )
        .filter((service) => !!service);

    return [
        { type: 'Tire', tireServices },
        { type: 'RecommendedService', recommendedServices },
        { type: 'GlassDamage', glassDamageServices },
        ...remainingServices,
    ];
};

const sortServices = (services: GroupedService[]) => {
    return [...services].sort(
        (a, b) =>
            groupedServiceOrderPriority(b) - groupedServiceOrderPriority(a)
    );
};

const groupedServiceOrderPriority = (groupServices: GroupedService) => {
    switch (groupServices.type) {
        case 'RecommendedService':
            return 10;
        case 'Service':
            return 9;
        case 'EuTest':
            return 8;
        case 'GlassDamage':
            return 7;
        case 'Tire':
            return 6;
        case 'Diagnosis':
            return -10;
        default:
            return 0;
    }
};

export function StandardServicesEdit({
    servicesForm,
    services,
    recommendedServices,
    setIsBookingRequest,
    setSectionState,
    dealerNumber,
    vin,
}: StandardServicesEditProps) {
    const mileage = servicesForm.state.raw.recommendedService.mileage;

    const [lc] = useLanguageContext();

    const groupedServices = sortServices(groupServices(services));

    const validity = servicesForm.inputProps('selectedIds').validity;
    const { helperTextProps } = useInputHelperText<{
        errorMsgRequired: true;
    }>({
        id: standardServicesHelperTextId,
        validity,
        reserveSpaceForMessage: false,
    });

    return (
        <FormProvider
            id={'standardserviceslist-formprovider'}
            form={servicesForm}
            hideNecessityText={true}
            onSubmit={(e) => {
                const allServices = [
                    ...removeRecommendedServices(services),
                    ...(recommendedServices.data || []),
                ];
                const selectedServices = allServices
                    .filter((x) => e.selectedIds.includes(x.id))
                    .filter((x) =>
                        x.dealerSpecificInformation.some(
                            (x) => x.dealerNumber === dealerNumber
                        )
                    )
                    .map((x) => toSelectedService(x, e.additionalInfo));
                setIsBookingRequest(selectedServices);
                setSectionState({
                    viewMode: 'done',
                    data: {
                        selectedServices,
                        mileage,
                        fetchedData: {
                            availableRecommendedServices:
                                recommendedServices.data ?? [],
                        },
                    },
                });
                sendBookingFormProgressToAdobe(
                    'standardServices',
                    'chooseTimeSlot',
                    Capacitor.getPlatform()
                );
            }}
        >
            <BilholdInnerViewLayout id={'standardserviceslist-selectedIds'}>
                {groupedServices.map((service) => {
                    switch (service.type) {
                        case 'Tire':
                            return (
                                <TireChange
                                    serviceForm={servicesForm}
                                    services={service.tireServices}
                                    dealerNumber={dealerNumber}
                                />
                            );
                        case 'RecommendedService':
                            return (
                                <RecommendedServicesContainer
                                    serviceForm={servicesForm}
                                    services={service.recommendedServices}
                                    vin={vin}
                                    recommendedServices={recommendedServices}
                                    dealerNumber={dealerNumber}
                                />
                            );
                        case 'GlassDamage':
                            return (
                                <GlassDamage
                                    serviceForm={servicesForm}
                                    services={service.glassDamageServices}
                                    dealerNumber={dealerNumber}
                                />
                            );
                        default:
                            return (
                                <Service
                                    serviceForm={servicesForm}
                                    service={service.service}
                                    dealerNumber={dealerNumber}
                                />
                            );
                    }
                })}
                <InputHelperText {...helperTextProps} />
                <DetailText>{lc.prices.disclaimer_price}</DetailText>
                <BilholdNextButton />
            </BilholdInnerViewLayout>
        </FormProvider>
    );
}

type SelectServiceId = {
    id: string;
    selectedIds: string[];
    setSelectedIds: (x: string[]) => void;
};

function selectServiceId({ id, selectedIds, setSelectedIds }: SelectServiceId) {
    if (selectedIds.includes(id)) {
        setSelectedIds(selectedIds.filter((x) => x !== id));
    } else {
        setSelectedIds([...selectedIds, id]);
    }
}

if (import.meta.vitest) {
    const { it, expect } = import.meta.vitest;
    let selectedIds = ['1'];
    const fakeSelectServiceIds = (x: string[]) => {
        selectedIds = x;
    };
    it('selectServiceId - select service with given id', () => {
        selectServiceId({
            id: '2',
            selectedIds,
            setSelectedIds: fakeSelectServiceIds,
        });
        expect(selectedIds).toEqual(['1', '2']);
    });
    it('selectServiceId - unselect service with given id', () => {
        selectServiceId({
            id: '1',
            selectedIds,
            setSelectedIds: fakeSelectServiceIds,
        });
        expect(selectedIds).toEqual(['2']);
    });
}

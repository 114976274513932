import { Column, IconButton, InfoCard, Row } from '@gnist/design-system';
import { TDFModal } from 'src/features/competition/components/TDFModal';
import useModal from 'src/features/competition/hooks/useModal';
import { TDSThinHeadline } from 'src/features/competition/textComponents/TDFText';
import { styled } from 'styled-components';

const WrapperCol = styled(Column)<{ disable: boolean }>`
    width: 100%;
    opacity: ${(props) => (props.disable ? 0.4 : 1)};
`;

const InfoCardWrapper = styled(InfoCard)<{ completed: boolean }>`
    border: ${(props) =>
        props.completed ? 'var(--moller-stroke-medium) solid' : 0};
    border-color: ${(props) =>
        props.completed && 'var(--moller-color-primary)'};
`;

const CompletedDiv = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: var(--moller-color-primary);
    color: black;
    padding: var(--moller-spacing-xxs);
    border-radius: 0 var(--moller-border-radius-medium);
    font-size: 0.75rem;
    font-weight: 400;
`;

interface Props {
    isCompleted: boolean;
    headline: string;
    isDisabled: boolean;
    modalText: string;
    prizesRemaining: number;
    shirtColor: string;
    children: React.JSX.Element;
}

export const ShirtCard = ({
    isCompleted,
    headline,
    modalText,
    shirtColor,
    isDisabled,
    children,
}: Props) => {
    const { isOpen, toggle } = useModal();
    return (
        <WrapperCol disable={isDisabled} verticalAlign={'start'} gap={'xs'}>
            <InfoCardWrapper completed={isCompleted}>
                <Column gap={'base'}>
                    <Row verticalAlign={'center'} gap={'s'}>
                        <TDSThinHeadline>{headline}</TDSThinHeadline>
                        <IconButton
                            icon={'info'}
                            label={`info om ${headline}`}
                            onClick={() => toggle()}
                            disabled={isDisabled}
                        />
                    </Row>
                    {children}
                </Column>
                {isCompleted && <CompletedDiv>OPPNÅDD</CompletedDiv>}
            </InfoCardWrapper>
            <TDFModal
                isOpen={isOpen}
                toggle={toggle}
                shirt={headline}
                text={modalText}
                shirtColor={shirtColor}
            ></TDFModal>
        </WrapperCol>
    );
};

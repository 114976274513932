import {
    breakpointMobile,
    Column,
    Logo,
    Row,
    SecondaryButton,
} from '@gnist/design-system';
import { useNavigate } from 'react-router-dom';
import { PATH_BEGIN } from 'src/routing/routes';
import loginRedirect from 'src/utils/auth/loginRedirect';
import { ddRumPrivacySetting } from 'src/utils/datadog';
import lang from 'src/utils/lang';
import { isMobileApp } from 'src/utils/mobile';
import { styled } from 'styled-components';

const SpreadRow = styled(Row)`
    justify-content: space-between;
    align-items: center;
    padding-top: var(--moller-spacing-m);
    padding-right: var(--moller-spacing-m);
    padding-left: var(--moller-spacing-m);
    @media screen and (max-width: ${breakpointMobile}) {
        padding: var(--moller-spacing-s);
        padding-right: var(--moller-spacing-l);
    }
`;
const ButtonRow = styled(Row)`
    gap: var(--moller-spacing-s);
`;

const BackButton = styled.a`
    cursor: pointer;
`;

export const SimpleHeader = () => {
    const navigate = useNavigate();
    const isOnMobileWeb = window.innerWidth <= 480;
    return (
        <Column {...ddRumPrivacySetting('allow')}>
            <SpreadRow>
                <BackButton onClick={() => navigate(PATH_BEGIN)}>
                    <Logo
                        brand="bilhold"
                        type="wordmark"
                        size={isOnMobileWeb ? 'l' : 'xxl'}
                    />
                </BackButton>
                {!isMobileApp() && (
                    <ButtonRow>
                        <SecondaryButton onClick={() => loginRedirect()}>
                            {lang.login}
                        </SecondaryButton>
                    </ButtonRow>
                )}
            </SpreadRow>
        </Column>
    );
};

import { useQuery } from '@tanstack/react-query';
import { useFetchersContext } from 'external-apis';

const useFindTireHotelDeal = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.bilhold.fetcher
        .path('/v1/tire-hotel/vin/{Vin}')
        .method('get')
        .create();
};

export function useGetTireHotelDeal(vin: string, opts?: { enabled?: boolean }) {
    const { enabled = true } = opts ?? {};
    const getTireHotelDeal = useFindTireHotelDeal();
    return useQuery({
        queryKey: ['getTireHotelDeal', vin],
        // Need to invalidate cache after customer has visited tire-hotel fasttrack
        // enable = false with cache is initialized as success
        gcTime: 0,
        queryFn: () =>
            getTireHotelDeal({ Vin: vin }).then((x) => {
                if (x.data.length === 0) {
                    return undefined;
                }

                return x.data[0];
            }),
        // We apparently only use the first element of the response?
        // Logic copied from old fast track
        enabled: enabled && vin.length > 0,
    });
}

import { Column } from '@gnist/design-system';
import { ReactNode } from 'react';
import { ExpandableServiceDescription } from '../../../components/services/ExpandableServiceDescription';

type ServiceDescriptionProps = {
    selected: boolean;
    description: string;
    children?: ReactNode;
};
export function ServiceDescription({
    selected,
    description,
    children,
}: ServiceDescriptionProps) {
    return (
        <Column gap="xs">
            <p>
                {ExpandableServiceDescription({
                    description,
                    forceExpanded: selected,
                })}
            </p>
            {children}
        </Column>
    );
}

import {
    Heading1Text,
    Heading2Text,
    TextContainer,
} from '@gnist/design-system';
import { CenteredPageContent } from 'src/shared/CenteredPageContent';

const SaServiceDealArticle = () => {
    return (
        <CenteredPageContent showBackButton>
            <TextContainer>
                <Heading1Text>
                    Et smart valg – du har inngått serviceavtale
                </Heading1Text>
                <p>
                    Denne avtalen gir deg trygghet fordi service på bilen blir
                    gjennomført i henhold til bilfabrikkens serviceprogram for
                    din bil. Du mottar faktura månedlig, kvartalsvis eller årlig
                    og slipper å tenke på kostnader når bilen skal på service.
                </p>

                <Heading2Text>Dette får du (ved behov)</Heading2Text>
                <ul>
                    <li>Skift av motorolje (gjelder ikke elbil)</li>
                    <li>Skift av girolje (gjelder ikke elbil)</li>
                    <li>Skift av differensialolje (gjelder ikke alle biler)</li>
                    <li>
                        Skift av luftfilter (etter fabrikkens anbefaling om
                        skiftintervaller)
                    </li>
                    <li>
                        Skift av pollenfilter (etter fabrikkens anbefaling om
                        skiftintervaller)
                    </li>
                </ul>

                <Heading2Text>Dette gjør det så trygt og enkelt</Heading2Text>
                <ul>
                    <li>
                        Avtalt pris eller kjørte kilometer med kontroll på alle
                        utgifter til service uten uforutsette kostnader. Prisen
                        KPI-justeres.
                    </li>
                    <li>Alt arbeid utføres på originalt merkeverksted</li>
                    <li>
                        Kun originaldeler benyttes – med 3 års garanti på deler
                        og arbeid
                    </li>
                    <li>
                        Komplett servicehistorikk gir økt verdi når bilen skal
                        selges og et trygt eierskifte
                    </li>
                    <li>
                        Service som gjennomføres etter bilfabrikkens anvisninger
                    </li>
                    <li>24-timers mobilitetsgaranti</li>
                    <li>
                        Erstatningsbil ved verkstedbesøk kan inkluderes i
                        avtalen ved et lite pristillegg
                    </li>
                    <li>
                        Har du leasingavtale med Volkswagen Financial Services
                        samles alle utgifter i én og samme faktura
                    </li>
                    <li>
                        Frihet til å velge ethvert av våre autoriserte
                        merkeverksteder i hele Norge
                    </li>
                    <li>
                        Næringsdrivende med flere biler vil få løpende
                        oppdatering av kjøremønster, reparasjonshistorikk og
                        ståtid pr. bil. Alle utgifter samles i faktura med full
                        oversikt over alle kjøretøy. I tillegg kan hele
                        merverdiavgiften trekkes fra for næringskunder på den
                        månedlige kostnaden
                    </li>
                </ul>
                <p>
                    Ønsker du å endre serviceavtalen for bilen din? Kontakt oss
                    på e-post:{' '}
                    <a href="mailto:serviceavtale@moller.no">
                        serviceavtale@moller.no
                    </a>
                    . Ønsker du å endre en verkstedtime må du kontakte ditt
                    verksted direkte.
                </p>
            </TextContainer>
        </CenteredPageContent>
    );
};

export default SaServiceDealArticle;

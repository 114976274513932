import { Heading2Text, IconButton, Logo } from '@gnist/design-system';
import { LogoBrand } from '@gnist/design-system/foundation/logos/Logo.js';
import { Skeleton } from '@mui/material';
import { Brand, ExistingCarRelation } from 'external-apis/src/types/bilhold';
import { useNavigate } from 'react-router-dom';
import lang from 'src/utils/lang';
import { formatBrand } from 'src/utils/string/formatCarVariables';
import { styled } from 'styled-components';
import { normalizeETron } from '../car-brands/audi/audiUtils';
import { Submenu } from '../components/Submenu';
import { brandToLogoName, renderLogoSize } from './stickyHeaderUtils';

const StyledLogo = styled(Logo)`
    position: absolute;
    left: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
`;

const StyledVolkswagenLogo = styled(StyledLogo)`
    bottom: -40px;
    svg {
        background-color: var(--moller-color-background);
        color: var(--moller-color-primary);
    }
`;

const TopHeader = styled.div<{ isScrollActive: boolean; brand: LogoBrand }>`
    width: 100%;
    top: 0;
    left: 0;
    position: fixed;
    box-sizing: border-box;
    padding: var(--moller-spacing-xs) var(--moller-spacing-xs);
    padding-top: calc(var(--safe-area-top) + var(--moller-spacing-xs));
    z-index: 4;
    background-color: var(--moller-color-background);
    ${({ isScrollActive, brand }) =>
        !isScrollActive &&
        brand === 'vw' &&
        `border-bottom: solid var(--moller-color-primary)
                    var(--moller-stroke-small);`}
`;

const SpaceBetween = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 800px;
    margin: auto;
`;

export const Header = ({
    car,
    brand,
    isLoading,
    isScrollActive,
    carName,
}: {
    car?: ExistingCarRelation;
    brand?: Brand;
    isLoading: boolean;
    isScrollActive: boolean;
    carName: string | undefined;
}) => {
    const navigate = useNavigate();
    const brandLogo = brandToLogoName(brand);

    const renderHeaderContent = () => {
        if (isLoading) {
            return <Skeleton width={100} />;
        }
        if (isScrollActive) {
            return `${formatBrand(brand)} ${normalizeETron(carName)}`;
        }
        switch (brand) {
            case 'Volkswagen':
            case 'VolkswagenCommercialVehicles':
                return (
                    <StyledVolkswagenLogo
                        brand={brandLogo}
                        type="wordmark"
                        size={renderLogoSize(brandLogo)}
                    />
                );
            case 'Skoda':
            case 'Audi':
            case 'Cupra':
                return (
                    <StyledLogo
                        brand={brandLogo}
                        type="wordmark"
                        size={renderLogoSize(brandLogo)}
                    />
                );
            default:
                return formatBrand(brand);
        }
    };

    return (
        <TopHeader isScrollActive={isScrollActive} brand={brandLogo}>
            <SpaceBetween>
                <IconButton
                    icon={'arrow_backward'}
                    label={lang.back_to_main}
                    onClick={() => navigate(-1)}
                />

                <Heading2Text $as="span">{renderHeaderContent()}</Heading2Text>
                {car ? <Submenu car={car} /> : <div style={{ width: '4px' }} />}
            </SpaceBetween>
        </TopHeader>
    );
};

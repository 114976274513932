import {
    AlertBanner,
    Column,
    PrimaryButton,
    TextArea,
} from '@gnist/design-system';
import { useState } from 'react';
import { BookingViewModel } from 'src/features/appointment/api/useAppointments';
import { useNewChatThread } from 'src/utils/hooks/useMessageThreads';
import { useProfile } from 'src/utils/hooks/useProfile';
import lang from 'src/utils/lang';
import { styled } from 'styled-components';
import { partialAppointmentGuidToSafeNumberId } from '../utils/messagesUtils';

const MessageArea = styled(TextArea)`
    width: 100%;
    max-width: 500px;
`;

const StyledAlert = styled(AlertBanner)`
    max-width: 500px;
    margin-bottom: var(--moller-spacing-xs);
    box-sizing: border-box;
`;

interface Props {
    appointment: BookingViewModel;
}

export const SendMessageInNewChat = ({ appointment }: Props) => {
    const [content, setContent] = useState('');

    const { data: user } = useProfile();
    const customerName = user?.firstname + ' ' + user?.surname;

    const externalId = partialAppointmentGuidToSafeNumberId(appointment.id);

    const {
        mutate: postMessage,
        isError,
        isPending: isLoading,
    } = useNewChatThread(
        customerName,
        externalId,
        appointment.dealer.id,
        appointment.dealer.name
    );

    function handleSendMessage() {
        postMessage({
            content,
            registrationNumber: appointment.registrationNumber,
        });
    }

    return (
        <Column horizontalAlign="end">
            {isError && (
                <StyledAlert
                    type="error"
                    message={lang.postMessageErrorContactUsLink}
                />
            )}
            <MessageArea
                label="Send en melding"
                helperText={lang.provideMoreInfo}
                value={content}
                onChange={(e) => {
                    setContent(e.target.value);
                }}
                maxLength={500}
            />
            <PrimaryButton
                onClick={handleSendMessage}
                loading={{
                    isLoading: isLoading,
                    loadingText: 'Sending message',
                }}
                disabled={!content || content.trim().length === 0}
            >
                {lang.send}
            </PrimaryButton>
        </Column>
    );
};

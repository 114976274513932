import { datadogLogs } from '@datadog/browser-logs';
import {
    AlertBanner,
    Column,
    DetailText,
    InfoCard,
    Row,
} from '@gnist/design-system';
import { formatInTimeZone } from 'date-fns-tz';
import {
    DealerViewModel,
    ReservedTimeSlotViewModel,
} from 'external-apis/src/types/port';
import { useGetApiLocaleConfig } from '../../_api/apiLocale';
import { BoldText } from '../../components/BoldText';
import { BilholdViewLayout } from '../../components/bilhold/BilholdView';
import { useBookingStepAction } from '../../lib/datadog/hooks';
import { useLanguageContext } from '../../lib/languages/languageContext';
import { StandardServicesData } from '../standard-services/useStandardServices';

export function ChooseTimeslotDone({
    reservedTimeSlot,
    dealers,
    selectedServices,
}: {
    reservedTimeSlot: ReservedTimeSlotViewModel;
    dealers: DealerViewModel[];
    selectedServices: StandardServicesData['selectedServices'];
}) {
    const timeZone = useGetApiLocaleConfig().timeZone;

    const [lc, locale] = useLanguageContext();
    const { fnsLocale } = locale;

    const startTime = reservedTimeSlot.startTime;
    const estimatedFinished = reservedTimeSlot.estimatedFinished;
    const dealerNumber = reservedTimeSlot.dealerNumber;

    const dealer = dealers.find((x) => x.id === dealerNumber);

    const additionalActions = [];

    if (dealer) {
        additionalActions.push(`Reserve time slot ${dealer.name}`);
    }

    const actionContext = {
        dealerNumber: dealer?.id,
        adapterId: dealer?.adapterId,
    };

    useBookingStepAction('Reserve time slot', {
        actionContext,
        additionalActions,
    });

    if (!dealer) {
        datadogLogs.logger.error('dealer not found for reserved timeslot');
        return null;
    }

    const reservationDateString = formatInTimeZone(
        startTime,
        timeZone,
        'cccc dd. LLLL yyyy',
        { locale: fnsLocale }
    );

    const capitalizedReservationDateString =
        reservationDateString.charAt(0).toUpperCase() +
        reservationDateString.slice(1, reservationDateString.length);

    const estimatedFinishedSuffix = estimatedFinished
        ? formatInTimeZone(estimatedFinished, timeZone, 'cccc dd. LLLL HH:mm', {
              locale: fnsLocale,
          })
        : undefined;

    const estimatedFinishedString = estimatedFinishedSuffix
        ? `${lc.chooseTimeslot.estimated_finished_prefix.replace(
              '{0}',
              estimatedFinishedSuffix
          )}`
        : undefined;
    const startTimeSuffix = formatInTimeZone(startTime, timeZone, 'HH:mm', {
        locale: fnsLocale,
    });

    const startTimeString = `${lc.chooseTimeslot.start_time_prefix} ${startTimeSuffix}`;

    const isDiagnosisServiceSelected = selectedServices.some(
        (x) => x.serviceCategory === 'Diagnosis'
    );

    return (
        <BilholdViewLayout>
            <InfoCard density="compact">
                <Column gap="xs">
                    <BoldText as="div">
                        <Row
                            style={{ gap: `0 var(--moller-spacing-xs)` }}
                            flexWrap="wrap"
                        >
                            {capitalizedReservationDateString}
                            <span>{startTimeString}</span>
                        </Row>
                    </BoldText>
                    <p>
                        {lc.chooseTimeslot.pre_appointment_delivery_info_done}
                    </p>
                    {isDiagnosisServiceSelected ? (
                        <AlertBanner
                            density="compact"
                            type="info"
                            message={
                                lc.chooseTimeslot
                                    .estimated_finished_when_describe_an_issue_selected
                            }
                        ></AlertBanner>
                    ) : (
                        <DetailText>{estimatedFinishedString}</DetailText>
                    )}
                </Column>
            </InfoCard>
        </BilholdViewLayout>
    );
}

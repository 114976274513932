import { AlertBanner, Link as LinkText } from '@gnist/design-system';
import { FastTrackType } from 'booking/_api/urlSearchParameters';
import { ddRumPrivacySetting } from 'booking/components/DDRumPrivacySetting';
import { Link } from 'react-router-dom';
import { getBookingPath } from 'src/features/booking/usePathToBooking';
import { withErrorBoundary } from 'src/utils/ErrorBoundary';
import useFeatureToggle from 'src/utils/dev-tools/useFeatureToggle';
import lang from 'src/utils/lang';
import getFastTrackPath from 'src/utils/url/getFastTrackPath';
import { styled } from 'styled-components';
import { StyledOverline } from '../HomeStyledComponents';
import ActionBarCard from './ActionBarCard';

export const damageUrl = '/damage';

const StyledAlertBanner = styled(AlertBanner)`
    margin: 0 var(--moller-spacing-s);
`;

const ActionBarWrapper = styled.div`
    margin-bottom: var(--moller-spacing-m);
    margin-top: var(--moller-spacing-xl);
`;

const GridLayout = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    gap: var(--moller-spacing-s);

    // In case a screen is extremely small, we want to show only one column
    @media (max-width: 350px) {
        grid-template-columns: 1fr;
    }
`;

const fallbackMessage = (
    <>
        Vi greide dessverre ikke å vise handlinger for denne bilen. Du kan prøve
        å gå til{' '}
        <LinkText $as={Link} to="/booking">
            verkstedbestilling
        </LinkText>{' '}
        eller{' '}
        <LinkText $as={Link} to="/damage">
            skadetakst
        </LinkText>{' '}
        og taste inn registreringsnummer.
    </>
);

const fallback = (retry: () => void) => (
    <StyledAlertBanner
        type="warning"
        message={fallbackMessage as unknown as string} // Ugly hack because message must be string
        action={{ label: 'Prøv igjen', onClick: retry }}
    />
);

const ActionBarView = ({
    vin,
    licensePlate,
    isHidden,
}: {
    vin?: string;
    licensePlate?: string;
    isHidden?: boolean;
}) => {
    const searchParams = new URLSearchParams();
    const showTireChange = useFeatureToggle('ShowTireChange');

    const params = {
        vehicleIdentificationNumber: vin,
        registrationNumber: licensePlate,
    };

    const fastTrackUrl = (x: FastTrackType) =>
        getFastTrackPath(getBookingPath(params), params, x);

    if (isHidden) {
        return null;
    }

    return (
        <ActionBarWrapper>
            <StyledOverline>Bestill din neste time</StyledOverline>
            <GridLayout {...ddRumPrivacySetting('allow')}>
                <ActionBarCard
                    icon={'build'}
                    title={lang.workshopLinkTitle}
                    description={lang.workshopDescription}
                    link={`/booking?registrationNumber=${licensePlate}`}
                />
                <ActionBarCard
                    icon={'glass_damage'}
                    title={lang.glassDamageTitle}
                    description={lang.glassDamageDescription}
                    link={fastTrackUrl('glass-damage')}
                />
                <ActionBarCard
                    icon={'car_crash'}
                    title={lang.bookDamageAppraiserTitle}
                    description={lang.bookDamageAppraiserDescription}
                    link={
                        searchParams
                            ? `${damageUrl}?${searchParams}`
                            : damageUrl
                    }
                />
                {showTireChange && (
                    <ActionBarCard
                        icon={'tire'}
                        title={lang.orderTireChangeAppointmentTitle}
                        description={lang.orderTireChangeAppointmentDescription}
                        link={fastTrackUrl('tire-change')}
                    />
                )}
            </GridLayout>
        </ActionBarWrapper>
    );
};

export const ActionBar = withErrorBoundary('ActionBar', ActionBarView, {
    fallback,
});

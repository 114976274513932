export function vehicleOlderThanXYears(
    modelYear: number | null | undefined,
    years: number
) {
    if (!modelYear) {
        return undefined;
    }

    const age = new Date().getFullYear() - modelYear;
    return age > years;
}

import {
    BodyText,
    Column,
    InputFieldValidity,
    TextArea,
} from '@gnist/design-system';
import { useLanguageContext } from '../../../lib/languages/languageContext';
import { textFieldValidity } from '../../../lib/form/validationUtils';

const MAX_COMMENT_LENGTH = 1000;

type CommentFieldProps = {
    comment: string;
    setComment: (comment: string) => void;
    textFieldValidity: typeof textFieldValidity;
    requiredFieldValidity: InputFieldValidity | undefined;
};

export function CommentField({
    comment,
    setComment,
    textFieldValidity,
    requiredFieldValidity,
}: CommentFieldProps) {
    const [lc] = useLanguageContext();
    const validationErrorText =
        lc.errors.other_service_comment_validation_text_field;
    const validity =
        requiredFieldValidity?.type === 'error'
            ? textFieldValidity(comment, validationErrorText)
            : undefined;

    return (
        <Column gap="none">
            <BodyText>{lc.standardServices.commentLabel}</BodyText>
            <TextArea
                label={lc.standardServices.other_service_label}
                onChange={(e) => {
                    e.preventDefault();
                    setComment(e.target.value);
                }}
                value={comment}
                maxLength={MAX_COMMENT_LENGTH}
                counterText={counterLabel({
                    count: comment.length,
                })}
                validity={validity}
            />
        </Column>
    );
}

function counterLabel({ count = 0, max = 1000 }) {
    return `${count} / ${max}`;
}

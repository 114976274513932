import { BodyText, Icon, PrimaryButton } from '@gnist/design-system';
import { HTMLProps } from 'react';
import { styled } from 'styled-components';
import { useLanguageContext } from '../../lib/languages/languageContext';
import { DDRumPrivacySetting } from '../DDRumPrivacySetting';
import { FlexRow } from '../FlexRow';

type BilholdNextButtonProps = HTMLProps<HTMLButtonElement>;

const CustomFlexRow = styled(FlexRow)`
    align-items: center;
    gap: var(--moller-spacing-xxs);
`;

const RightAlignedFlexRow = styled(FlexRow)`
    margin-left: auto;
`;

function ConfirmSectionButton({
    onClick,
    children,
}: BilholdNextButtonProps & { children: JSX.Element[] }) {
    return (
        <DDRumPrivacySetting privacyLevel="allow">
            <RightAlignedFlexRow>
                <PrimaryButton type="submit" onClick={onClick}>
                    <CustomFlexRow>{children}</CustomFlexRow>
                </PrimaryButton>
            </RightAlignedFlexRow>
        </DDRumPrivacySetting>
    );
}

export function BilholdSkipButton({ onClick }: BilholdNextButtonProps) {
    const [lc] = useLanguageContext();

    return (
        <ConfirmSectionButton onClick={onClick}>
            <BodyText>{lc.components.button_skip}</BodyText>
            <Icon icon={'arrow_downward'} />
        </ConfirmSectionButton>
    );
}

export function BilholdNextButton({ onClick }: BilholdNextButtonProps) {
    const [lc] = useLanguageContext();

    return (
        <ConfirmSectionButton onClick={onClick}>
            <BodyText>{lc.components.button_next}</BodyText>
            <Icon icon="arrow_downward" />
        </ConfirmSectionButton>
    );
}

import { useMutation } from '@tanstack/react-query';
import { useFetchersContext } from 'external-apis';
import { HttpError } from 'external-apis/src/types/user-self-service';
import httpStatus from 'http-status';
import CountryCode from 'src/types/CountryCode';
import trimUserName from 'src/utils/auth/trimUserName';
import getCountryCode from 'src/utils/region/getCountryCode';

interface EnrollError {
    data?: HttpError;
}

const useEnrollUserFetcher = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.userSelfService.fetcher
        .path('/api/enroll-new-user')
        .method('post')
        .create();
};

export const useEnrollUser = ({
    onSuccess,
    onTooManyRequestsError,
    onGeneralError,
}: {
    onSuccess: () => void;
    onTooManyRequestsError: () => void;
    onGeneralError: () => void;
}) => {
    const enrollNewUser = useEnrollUserFetcher();
    return useMutation<unknown, EnrollError, string, unknown>({
        mutationFn: (phoneNumber: string) =>
            enrollNewUser({
                phoneNumber: trimUserName(phoneNumber),
                countryCode: getCountryCode(),
                phoneCountryCode: CountryCode.NO,
            }),
        onSuccess: () => onSuccess(),
        onError: (error) => {
            if (error.data?.httpCode === httpStatus.TOO_MANY_REQUESTS) {
                onTooManyRequestsError();
            }
            onGeneralError();
        },
    });
};

import {
    stripWhitespace,
    validation,
} from '@gnist/design-system/utilities/validation';
import { VALID_DIGITS } from '@gnist/design-system/utilities/validation/regex.js';
import { Services } from '../../../../_api/http/services';
import { useLanguageContext } from '../../../../lib/languages/languageContext';
import { StdServicesForm } from '../../useSectionForm';
import { RecommendedServiceFormState } from './recommendedServiceFormState';

export function useValidRecommendedServices(
    availableServices: Services | undefined
) {
    const [lc] = useLanguageContext();
    return validation<RecommendedServiceFormState, StdServicesForm>({
        preValidation: (state) => ({
            ...state,
            mileage: stripWhitespace(state.mileage || ''),
        }),

        steps: [
            (state: RecommendedServiceFormState) => {
                const mileage = state.mileage;
                return {
                    isValid: mileage.length > 0 || !state.open,
                    errorMessage: lc.errors.mileage_validation_required,
                };
            },
            (state: RecommendedServiceFormState) => {
                const mileage = state.mileage;
                return {
                    isValid: VALID_DIGITS.test(mileage) || !state.open,
                    errorMessage: lc.errors.mileage_validation_number,
                };
            },
            (state: RecommendedServiceFormState) => {
                const mileage = state.mileage;
                const maxLength = 9;
                return {
                    isValid: mileage.length <= maxLength,
                    errorMessage:
                        lc.errors.mileage_validation_invalid_max_length.replace(
                            '{0}',
                            maxLength.toString()
                        ),
                };
            },
            (state: RecommendedServiceFormState, allValues: StdServicesForm) =>
                hasValidRecommendedServiceJobsSelection(
                    state,
                    allValues,
                    availableServices,
                    lc.errors.recommended_service_selection_validation
                ),
        ],
        necessity: (values) => ({
            isRequired: values.recommendedService.open,
            missingMessage: lc.errors.mileage_validation_required,
        }),
        showMessageImmediately: (state) => state.mileage.length > 0,
    });
}

function hasValidRecommendedServiceJobsSelection(
    state: RecommendedServiceFormState,
    allValues: StdServicesForm,
    availableServices: Services | undefined,
    message: string
) {
    const hasSelectedRecommendedServices =
        availableServices?.Recommended?.some((x) =>
            allValues.selectedIds.includes(x.id)
        ) ?? false;
    const hasNotFetchedRecommendedServices =
        availableServices?.Recommended.length === 0;
    return {
        isValid:
            hasNotFetchedRecommendedServices ||
            hasSelectedRecommendedServices ||
            !state.open,
        errorMessage: message,
    };
}

import { breakpointTablet } from '@gnist/design-system/utilities/layout/breakpoints.js';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from 'styled-components';

const TDSHeadline = styled.h1`
    font-size: 1.5rem;
    font-weight: 700;
    letter-spacing: 0;
    margin: 0;
    @media screen and (min-width: ${breakpointTablet}) {
        font-size: 2rem;
    }
`;

const TDSThinHeadline = styled.h2`
    font-size: 1rem;
    font-weight: 300;
    @media screen and (min-width: ${breakpointTablet}) {
        font-size: 1.5rem;
    }
`;
const TDSSubtitle = styled.h2`
    font-size: 1.25rem;
    font-weight: 700;
    @media screen and (min-width: ${breakpointTablet}) {
        font-size: 1.5rem;
    }
`;
const HeadingPrizeCard = styled.h3`
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
    @media screen and (min-width: ${breakpointTablet}) {
        font-size: 1.25rem;
    }
`;

const TDFTextLarge = styled.p`
    font-size: 1rem;
    font-weight: 400;
    @media screen and (min-width: ${breakpointTablet}) {
        font-size: 1.25rem;
    }
`;

const TDFTextMedium = styled.p`
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
    @media screen and (min-width: ${breakpointTablet}) {
        font-size: 1rem;
    }
`;

const TextThin = styled.p`
    font-size: 1rem;
    font-weight: 300;
`;
const TDSTextSmall = styled.p`
    font-size: 0.875rem;
    font-weight: 400;
    @media screen and (min-width: ${breakpointTablet}) {
        font-size: 1rem;
    }
`;
const TextSmallThin = styled.p`
    font-size: 0.875rem;
    font-weight: 300;
`;
const TDSTextXSmall = styled.p`
    font-size: 0.625rem;
    @media screen and (min-width: ${breakpointTablet}) {
        font-size: 0.875rem;
    }
`;

const StyledLink = styled(RouterLink)`
    color: white;
    margin-top: 0.5rem;
    display: block;
`;
export {
    HeadingPrizeCard,
    StyledLink,
    TDFTextLarge,
    TDFTextMedium,
    TDSHeadline,
    TDSSubtitle,
    TDSTextSmall,
    TDSTextXSmall,
    TDSThinHeadline,
    TextSmallThin,
    TextThin,
};

import { checkDoneDependencies } from '../../sectionUtils';
import { ConfirmBookingProps } from '../ConfirmBookingSection';
import { ConfirmBookingWithDoneDependencies } from './ConfirmBookingWithDoneDependencies';
import { ConfirmBookingWithoutDoneDependencies } from './ConfirmBookingWithoutDoneDependencies';

export type ConfirmBookingEditProps = ConfirmBookingProps & {
    viewMode: 'edit';
};

export function ConfirmBookingEdit({
    dependencies,
    ...props
}: ConfirmBookingEditProps) {
    if (checkDoneDependencies(dependencies)) {
        return (
            <ConfirmBookingWithDoneDependencies
                dependencies={dependencies}
                {...props}
            />
        );
    }

    return (
        <ConfirmBookingWithoutDoneDependencies dependencies={dependencies} />
    );
}

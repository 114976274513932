import {
    AlertBanner,
    TextField as PlainTextField,
    PrimaryButton,
    Row,
} from '@gnist/design-system';
import { useEffect, useState } from 'react';
import { useCityFromZipCode } from 'src/features/create-new-user/utils/useCityFromZipCode';
import UserProfile from 'src/types/UserProfile';
import { ddRumPrivacySetting } from 'src/utils/datadog';
import { ONLY_NUMBERS } from 'src/utils/string/regex';
import { styled } from 'styled-components';
import lang from 'utils/lang';
import {
    checkTextFieldValidity,
    checkValidForm,
} from './utils/checkProfileError';

export type ProfileDataUpdate = Pick<
    UserProfile,
    'firstname' | 'surname' | 'addressLine' | 'postcode' | 'city' | 'email'
>;

interface UpdateProfileProps {
    setUpdatedProfileData: (data: ProfileDataUpdate) => void;
    onSubmit?: () => void;
    submitButton?: boolean;
    minimalForm?: boolean;
    profile: UserProfile;
    isUpdating?: boolean;
}

const StyledForm = styled.form`
    margin-top: var(--moller-spacing-m);
    gap: var(--moller-spacing-base);
    display: flex;
    flex-direction: column;
    max-width: 500px;
`;

const TextField = styled(PlainTextField)`
    max-width: 400px;
    width: 100%;
`;

const StyledDeleteParagraph = styled.p`
    margin-top: var(--moller-spacing-xl);
    font-size: var(--moller-size-s) !important;
    padding-left: var(--moller-spacing-xxs);
    a {
        color: var(--moller-color-on-background);
    }
`;

const DoubleInput = styled(Row)`
    gap: var(--moller-spacing-s);
    max-width: 400px;
    ${TextField} {
        min-width: 30%;
    }
`;

const PhoneTextField = styled(PlainTextField)`
    width: 100%;
    max-width: 400px;
    margin-bottom: var(--moller-spacing-s);

    input {
        // The component does something weird in disabled state, covering numbers. This solves it.
        min-width: max-content;
    }
`;

const StyledAlertBanner = styled(AlertBanner)`
    margin-bottom: var(--moller-spacing-s);
    max-width: 400px;
`;

export const UpdateProfile = ({
    setUpdatedProfileData,
    minimalForm,
    onSubmit,
    submitButton,
    profile,
    isUpdating,
}: UpdateProfileProps) => {
    const [firstname, setFirstName] = useState(profile?.firstname || '');
    const [surname, setSurname] = useState(profile?.surname || '');
    const [addressLine, setAddressLine] = useState(profile?.addressLine || '');
    const [postcode, setPostcode] = useState(profile?.postcode || '');
    const [manualCity, setCityManually] = useState(profile?.city || '');
    const [emailAddress, setEmailAddress] = useState(profile?.email || '');
    const { city, disableUserInput, errorMessage } = useCityFromZipCode(
        postcode || ''
    );
    const nonValidForm = !checkValidForm(
        firstname,
        surname,
        addressLine,
        postcode,
        city,
        emailAddress
    );

    useEffect(() => {
        setUpdatedProfileData({
            firstname: firstname.trim(),
            surname: surname.trim(),
            addressLine: addressLine.trim(),
            postcode: postcode.trim(),
            city: city.trim(),
            email: emailAddress.trim(),
        });
    }, [
        firstname,
        surname,
        addressLine,
        postcode,
        city,
        emailAddress,
        setUpdatedProfileData,
    ]);

    if (profile === null) {
        return <AlertBanner type="error" message={lang.errorTryAgainLater} />;
    }

    const isEdited =
        profile?.firstname !== firstname ||
        profile?.surname !== surname ||
        profile?.addressLine !== addressLine ||
        profile?.postcode !== postcode ||
        profile?.city !== manualCity ||
        profile?.email !== emailAddress;

    return (
        <>
            <StyledForm
                onSubmit={(e) => {
                    e.preventDefault();
                    onSubmit?.();
                }}
                {...ddRumPrivacySetting('mask-user-input')}
            >
                <TextField
                    onChange={(e) => {
                        setFirstName(e.target.value.trimStart());
                    }}
                    label={lang.first_name}
                    value={firstname}
                    validity={checkTextFieldValidity('name', firstname)}
                />
                <TextField
                    onChange={(e) => {
                        setSurname(e.target.value.trimStart());
                    }}
                    label={lang.last_name}
                    value={surname}
                    validity={checkTextFieldValidity('name', surname)}
                />
                <TextField
                    onChange={(e) => {
                        setAddressLine(e.target.value.trimStart());
                    }}
                    label={lang.address}
                    value={addressLine}
                    validity={checkTextFieldValidity('address', addressLine)}
                />
                <DoubleInput>
                    <TextField
                        type="tel"
                        onChange={(e) => {
                            const { value } = e.target;
                            if (
                                value.length <= 4 &&
                                value.match(ONLY_NUMBERS)
                            ) {
                                setPostcode(value.trimStart());
                            }
                        }}
                        validity={
                            checkTextFieldValidity('zip', postcode) ??
                            errorMessage
                                ? {
                                      type: 'error',
                                      message: lang.invalid_zip_code,
                                  }
                                : undefined
                        }
                        label={lang.zip_code}
                        value={postcode}
                    />
                    <TextField
                        onChange={(e) => setCityManually(e.target.value)}
                        label={lang.city}
                        value={disableUserInput ? city : manualCity}
                        disabled={disableUserInput}
                    />
                </DoubleInput>
                {!minimalForm && (
                    <>
                        <TextField
                            type="email"
                            onChange={(e) => {
                                setEmailAddress(e.target.value.trim());
                            }}
                            label={lang.email}
                            value={emailAddress}
                            validity={checkTextFieldValidity(
                                'email',
                                emailAddress
                            )}
                        />
                        <PhoneTextField
                            type="tel"
                            onChange={() => null}
                            label={lang.cellPhoneNumber}
                            value={profile?.phoneNumber || ''}
                            helperText={lang.editPhoneNumberInfo}
                            disabled
                        />
                    </>
                )}
                {nonValidForm && (
                    <StyledAlertBanner
                        type="info"
                        message={lang.fill_all_fields_except_middle_name}
                    />
                )}
                {submitButton && (
                    <PrimaryButton
                        type="submit"
                        loading={{
                            isLoading: isUpdating,
                            loadingText: 'Updating profile',
                        }}
                        disabled={nonValidForm || !isEdited}
                    >
                        {lang.save}
                    </PrimaryButton>
                )}
            </StyledForm>
            {!minimalForm && (
                <StyledDeleteParagraph>
                    Jeg vil <a href="/delete-account">slette min profil</a>
                </StyledDeleteParagraph>
            )}
        </>
    );
};

import {
    Heading1Text,
    PrimaryButton,
    TextContainer,
    breakpointTablet,
} from '@gnist/design-system';
import { ReactNode } from 'react';
import { styled } from 'styled-components';

interface SectionTextLayoutProps {
    title: string;
    children: ReactNode;
    cta?: { label: string; onClick: () => void };
    wideText?: boolean;
}

export const SizeContainer = styled.div<{
    $wideText?: boolean;
}>`
    button {
        margin-top: var(--moller-spacing-l);
    }
    @media screen and (min-width: ${breakpointTablet}) {
        max-width: ${({ $wideText }) => ($wideText ? `60%` : `480px`)};
    }
`;
export const SectionTextLayout = ({
    title,
    children,
    cta,
    wideText = false,
}: SectionTextLayoutProps) => {
    return (
        <SizeContainer $wideText={wideText}>
            <TextContainer>
                <Heading1Text>{title}</Heading1Text>
                {children}
                {cta ? (
                    <PrimaryButton onClick={cta.onClick}>
                        {cta.label}
                    </PrimaryButton>
                ) : null}
            </TextContainer>
        </SizeContainer>
    );
};

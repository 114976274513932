import { AdditionalInfoCustomerInterviewQuestion } from 'external-apis/src/types/port';
import {
    LanguageContextType,
    useLanguageContext,
} from '../../../lib/languages/languageContext';
import {
    BodyText,
    Column,
    InputFieldValidity,
    InputHelperText,
    RadioButton,
    TextField,
} from '@gnist/design-system';
import { StdServicesForm } from '../useSectionForm';
import { fakeSetState, fakeState } from '../../testData';
import { textFieldValidity } from '../../../lib/form/validationUtils';

type Question = AdditionalInfoCustomerInterviewQuestion['question'];
type QuestionOption =
    AdditionalInfoCustomerInterviewQuestion['options'][number];
function textForInterviewQuestion(question: Question, lc: LanguageContextType) {
    switch (question) {
        case 'WhenDidTheProblemFirstHappen':
            return {
                text: lc.standardServices.whenDidTheProblemFirstHappen,
                label: lc.standardServices.interviewWhenDidItHappenLabel,
            };
        case 'WhereIsTheProblemLocated':
            return {
                text: lc.standardServices.whereIsTheProblemLocated,
                label: lc.standardServices.interviewLocationLabel,
            };
        case 'HowOftenIsTheProblemOccuring': {
            return {
                text: lc.standardServices.HowOftenIsTheProblemStillOccuring,
            };
        }
        case 'IsTheProblemPredictable': {
            return {
                text: lc.standardServices.isTheProblemPredictable,
                label: lc.standardServices.interviewPredictableLabel,
            };
        }
        case 'IsTheProblemProvokable': {
            return {
                text: lc.standardServices.isTheProblemProvokable,
            };
        }
    }
}

function textForInterviewQuestionOption(
    option: QuestionOption,
    lc: LanguageContextType
) {
    switch (option) {
        case 'Always':
            return lc.standardServices.interviewOptionAlways;
        case 'Sometimes':
            return lc.standardServices.interviewOptionSometimes;
        case 'Yes':
            return lc.standardServices.interviewOptionYes;
        case 'No':
            return lc.standardServices.interviewOptionNo;
    }
}

type QnA = StdServicesForm['additionalInfo'][number]['interviewQuestions'];

type InterviewQuestionsProps = {
    questions: AdditionalInfoCustomerInterviewQuestion[];
    questionsAndAnswers: QnA;
    setQuestionsAndAnswers: (questionsAndAnswers: QnA) => void;
    requiredFieldValidity: InputFieldValidity | undefined;
    textFieldValidity: typeof textFieldValidity;
};

export function InterviewQuestions({
    questions,
    questionsAndAnswers,
    setQuestionsAndAnswers,
    requiredFieldValidity,
}: InterviewQuestionsProps) {
    if (!questions) return null;
    const titles = questions.map((x) => x.question);
    return questions.map(({ question, inputType, options }) => {
        return (
            <InterviewQuestion
                key={question}
                question={question}
                inputType={inputType}
                options={options}
                answer={(() => questionsAndAnswers?.[question])()}
                setAnswer={setInterviewAnswer({
                    question,
                    questions: titles,
                    questionsAndAnswers,
                    setQuestionsAndAnswers,
                })}
                textFieldValidity={textFieldValidity}
                requiredFieldValidity={requiredFieldValidity}
            />
        );
    });
}

type SetInterviewAnswer = {
    question: Question;
    questions: AdditionalInfoCustomerInterviewQuestion['question'][];
    questionsAndAnswers: QnA;
    setQuestionsAndAnswers: (questionsAndAnswers: QnA) => void;
};

function setInterviewAnswer({
    question,
    questions,
    questionsAndAnswers,
    setQuestionsAndAnswers,
}: SetInterviewAnswer) {
    const defaults: QnA = questions.reduce((acc, x) => {
        acc[x] = undefined;
        return acc;
    }, {} as NonNullable<QnA>);
    return function (answer: string) {
        setQuestionsAndAnswers({
            ...(questionsAndAnswers ?? defaults),
            [question]: answer,
        });
    };
}

if (import.meta.vitest) {
    const { expect, it, describe } = import.meta.vitest;
    describe('Set interview answers', function () {
        it.concurrent('set first answer', function () {
            const state =
                fakeState<
                    StdServicesForm['additionalInfo'][number]['interviewQuestions']
                >(undefined);
            const setState = fakeSetState(state);
            const setAnswer = setInterviewAnswer({
                question: 'WhenDidTheProblemFirstHappen',
                questions: [
                    'WhenDidTheProblemFirstHappen',
                    'WhenDidTheProblemFirstHappen',
                    'IsTheProblemProvokable',
                ],
                questionsAndAnswers: undefined,
                setQuestionsAndAnswers: setState,
            });
            const answer = 'It happened in my driveway';
            setAnswer(answer);
            expect(state.value).toEqual({
                WhenDidTheProblemFirstHappen: answer,
            });
        });
        it.concurrent('set answer when another exists', function () {
            const initState = {
                WhenDidTheProblemFirstHappen: 'On my way to work',
                WhereIsTheProblemLocated: undefined,
                IsTheProblemProvokable: undefined,
                IsTheProblemPredictable: undefined,
                HowOftenIsTheProblemOccuring: undefined,
            };
            const state =
                fakeState<
                    StdServicesForm['additionalInfo'][number]['interviewQuestions']
                >(initState);
            const setState = fakeSetState(state);
            const setAnswer = setInterviewAnswer({
                question: 'HowOftenIsTheProblemOccuring',
                questions: [],
                questionsAndAnswers: initState,
                setQuestionsAndAnswers: setState,
            });
            const answer = 'Yes';
            setAnswer(answer);
            expect(state.value).toEqual({
                ...initState,
                HowOftenIsTheProblemOccuring: answer,
            });
        });
        it.concurrent('update an existing answer', function () {
            const initState = {
                WhenDidTheProblemFirstHappen: 'On my way to work',
                WhereIsTheProblemLocated: undefined,
                IsTheProblemProvokable: undefined,
                IsTheProblemPredictable: undefined,
                HowOftenIsTheProblemOccuring: undefined,
            };
            const state =
                fakeState<
                    StdServicesForm['additionalInfo'][number]['interviewQuestions']
                >(initState);
            const setState = fakeSetState(state);
            const setAnswer = setInterviewAnswer({
                question: 'WhenDidTheProblemFirstHappen',
                questions: [],
                questionsAndAnswers: initState,
                setQuestionsAndAnswers: setState,
            });
            const answer = 'It happened in my driveway';
            setAnswer(answer);
            expect(state.value).toEqual({
                ...initState,
                WhenDidTheProblemFirstHappen: answer,
            });
        });
    });
}

type InterviewQuestionProps = {
    question: AdditionalInfoCustomerInterviewQuestion['question'];
    options: AdditionalInfoCustomerInterviewQuestion['options'] | undefined;
    inputType: AdditionalInfoCustomerInterviewQuestion['inputType'];
    setAnswer: (answer: string) => void;
    answer: string | undefined;
    requiredFieldValidity: InputFieldValidity | undefined;
    textFieldValidity: typeof textFieldValidity;
};

function InterviewQuestion({
    question,
    inputType,
    options,
    setAnswer,
    answer,
    requiredFieldValidity,
}: InterviewQuestionProps) {
    const [lc] = useLanguageContext();
    const { text, label } = textForInterviewQuestion(question, lc);
    if (inputType === 'SingleChoice' && options) {
        const validity =
            requiredFieldValidity?.type === 'error'
                ? textFieldValidity(answer, '')
                : undefined;
        const radio = options.map((x, i) => {
            const option = textForInterviewQuestionOption(x, lc);
            return (
                <RadioButton
                    key={`${i}-${x}`}
                    label={option}
                    value={option === answer}
                    onChange={() => setAnswer(option)}
                    validity={validity}
                />
            );
        });
        return (
            <Column gap="xxs">
                <BodyText>{text}</BodyText>
                {radio}
                <InputHelperText
                    id={''}
                    validity={
                        validity?.type === 'error'
                            ? {
                                  type: 'error',
                                  message: lc.errors.single_choice_validation,
                              }
                            : undefined
                    }
                />
            </Column>
        );
    }
    if (inputType === 'Text') {
        const validationErrorText =
            lc.errors.other_service_comment_validation_text_field;
        const validity =
            requiredFieldValidity?.type === 'error'
                ? textFieldValidity(answer, validationErrorText)
                : undefined;
        return (
            <Column gap="none">
                <BodyText>{text}</BodyText>
                <TextField
                    label={label ?? ''}
                    onChange={(e) => {
                        e.preventDefault();
                        setAnswer(e.target.value);
                    }}
                    value={answer ?? ''} // important to not pass undefined so that the component does not switch from uncontrolled to controlled.
                    validity={validity}
                />
            </Column>
        );
    }
    return null;
}

import { useLocalFormState } from '@gnist/design-system';
import { noValidation } from '@gnist/design-system/utilities/validation';
import React, { useEffect, useRef, useState } from 'react';
import { useGetDealers } from '../../_api/http/dealers';
import { DDRumPrivacySetting } from '../../components/DDRumPrivacySetting';
import { FlexRow } from '../../components/FlexRow';
import {
    BilholdViewHidden,
    BilholdViewLayout,
} from '../../components/bilhold/BilholdView';
import { BilholdViewTitle } from '../../components/bilhold/BilholdViewTitle';
import { useLanguageContext } from '../../lib/languages/languageContext';
import { useScrollRefIntoView } from '../../lib/util/scroll';
import { ChooseDealerResult } from '../choose-dealer/ChooseDealerSection';
import { ChooseTimeSlotResult } from '../choose-timeslot/ChooseTimeslotSection';
import { ChooseVehicleResult } from '../choose-vehicle/ChooseVehicleSection';
import { SectionState, checkDoneDependencies } from '../sectionUtils';
import { StandardServicesData } from '../standard-services/useStandardServices';
import { TransportServicesResult } from '../transport-services/TransportServicesSection';
import { AdditionalServicesDone } from './AdditionalServicesDone';
import {
    AddServicesForm,
    AdditionalServicesEdit,
} from './AdditionalServicesEdit';
import { AdditionalServicesLoader } from './AdditionalServicesLoader';

export interface AdditionalServicesData {
    selectedServices: StandardServicesData['selectedServices'];
}

export type AdditionalServicesState = SectionState<AdditionalServicesData>;
export type AdditionalServicesDependencies = {
    chooseVehicle: ChooseVehicleResult;
    transportServices: TransportServicesResult;
    chooseDealer: ChooseDealerResult;
    chooseTimeSlot: ChooseTimeSlotResult;
};

export function useAdditionalServices({
    initialState,
    dependencies,
    isFastTrack,
}: {
    initialState: AdditionalServicesState;
    dependencies: AdditionalServicesDependencies;
    isFastTrack: boolean;
}) {
    const [state, setState] = useState(initialState);
    const depsAreDone = checkDoneDependencies(dependencies);

    useEffect(() => {
        setState((s) =>
            depsAreDone
                ? s.viewMode === 'hidden'
                    ? { viewMode: 'edit' }
                    : s
                : { viewMode: 'hidden' }
        );
    }, [depsAreDone]);

    if (depsAreDone && state.viewMode === 'done') {
        const done = {
            result: {
                viewMode: state.viewMode,
                data: state.data,
            },
            props: {
                viewMode: state.viewMode,
                dependencies,
                setSectionState: setState,
                data: state.data,
            },
        };
        return done;
    }

    if (depsAreDone && state.viewMode === 'edit' && isFastTrack) {
        const edit = {
            result: {
                viewMode: 'done' as const,
                data: {
                    selectedServices: [],
                    isWaiting: false,
                },
            },
            props: {
                viewMode: 'done' as const,
                dependencies,
                data: {
                    selectedServices: [],
                    isWaiting: false,
                },
                setSectionState: setState,
            },
        };
        return edit;
    }

    if (depsAreDone && state.viewMode === 'edit') {
        const edit = {
            result: {
                viewMode: state.viewMode,
            },
            props: {
                viewMode: state.viewMode,
                dependencies,
                setSectionState: setState,
            },
        };
        return edit;
    }

    return {
        result: {
            viewMode: 'hidden' as const,
        },
        props: {
            viewMode: 'hidden' as const,
            setSectionState: setState,
        },
    };
}

type AdditionalServicesProps = ReturnType<
    typeof useAdditionalServices
>['props'] & { isFastTrack: boolean };

export type AdditionalServicesResult = ReturnType<
    typeof useAdditionalServices
>['result'];

export function AdditionalServices({
    isFastTrack,
    setSectionState,
    ...props
}: AdditionalServicesProps) {
    const [lc] = useLanguageContext();

    const sectionRef = useRef(null);
    useScrollRefIntoView(props.viewMode === 'edit', sectionRef);

    if (isFastTrack) {
        return null;
    }

    return (
        <DDRumPrivacySetting privacyLevel="allow">
            <BilholdViewLayout>
                <FlexRow ref={sectionRef}>
                    <BilholdViewTitle
                        title={lc.additionalServices.title}
                        isEditable={props.viewMode === 'done'}
                        onClick={() =>
                            setSectionState({
                                viewMode: 'edit',
                            })
                        }
                    />
                </FlexRow>
                <AdditionalServicesSection
                    {...props}
                    setSectionState={setSectionState}
                    isFastTrack
                />
            </BilholdViewLayout>
        </DDRumPrivacySetting>
    );
}

function AdditionalServicesSection(props: AdditionalServicesProps) {
    const servicesForm = useLocalFormState<AddServicesForm>(
        {
            selectedIds: [],
        },
        {
            selectedIds: noValidation(),
        }
    );

    const dealerResponse = useGetDealers();

    if (props.viewMode === 'hidden') {
        return <AdditionalServicesHidden />;
    }

    if (dealerResponse.isPending) {
        return <AdditionalServicesLoader />;
    }

    if (props.viewMode === 'edit') {
        return (
            <AdditionalServicesEdit
                reservedTimeSlot={
                    props.dependencies.chooseTimeSlot.data.reservedTimeSlot
                }
                regNr={props.dependencies.chooseVehicle.data.registrationNumber}
                selectedDealer={props.dependencies.chooseDealer.data}
                setSectionState={props.setSectionState}
                servicesForm={servicesForm}
            />
        );
    }

    return (
        <AdditionalServicesDone
            data={props.data}
            regNr={props.dependencies.chooseVehicle.data.registrationNumber}
            selectedDealer={props.dependencies.chooseDealer.data}
            dealers={dealerResponse?.data}
        />
    );
}

const AdditionalServicesHidden: React.FC = () => {
    const [lc] = useLanguageContext();
    return <BilholdViewHidden desc={lc.additionalServices.choose_timeslot} />;
};

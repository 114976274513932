import { ActionCard } from '@gnist/design-system';
import { BilholdInnerViewLayout } from '../../components/bilhold/BilholdView';
import { DelayedRender } from '../../lib/DelayedRender';
import { ShowMoreDealers } from './dealer-picker/ShowMoreDealers';

export function ChooseAffiliatedDealerLoader() {
    return (
        <DelayedRender>
            <ActionCard density="compact" isLoading />
        </DelayedRender>
    );
}

// this should not be delayed -- could probably remove this when having updated
// dealer section and place loader on <Dealer>
export function ChooseDealerLoader() {
    const card = <ActionCard density="compact" isLoading />;
    return (
        <BilholdInnerViewLayout>
            <div>
                {card}
                {card}
                {card}
                {card}
                {card}
            </div>
            <ShowMoreDealers
                showMore={() => {
                    return;
                }}
                shownDealerCount={0}
                totalDealerCount={0}
            />
        </BilholdInnerViewLayout>
    );
}

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Brand, { brandMapper } from 'src/types/Brand';
import proxyGet from 'src/utils/data-mocks/proxyGet';
import getCurrentEnvironment from 'src/utils/environment/getCurrentEnvironment';
import get from 'src/utils/http/get';
import httpDelete from 'src/utils/http/httpDelete';
import post from 'src/utils/http/post';

export interface PreferredDealer {
    dealerId: string;
    dealerName: string;
    address: string;
    zipCode: string;
    city: string;
    phoneNumber: string;
    brand: Brand;
}

export interface PreferredAffiliationDealerResponse {
    vin: string;
    preferredDealerId: string;
    preferredDealerName: string;
    dealerAddress: string;
    dealerEmail: string;
    dealerPhone: string;
    brand: Brand;
    serviceCertifications?: string[];
}

const { bilholdApiUrl } = getCurrentEnvironment();

export const usePreferredDealer = () => {
    return useQuery({
        queryKey: ['preferredDealer'],
        queryFn: () =>
            get<PreferredDealer[]>(
                `${bilholdApiUrl}/v1/preferred-dealers`
            ).then((res) => {
                return res.data.map((pd) => {
                    const mappedBrand = brandMapper(pd.brand);
                    return {
                        ...pd,
                        brand: mappedBrand,
                    };
                });
            }),
        staleTime: 1000 * 60 * 60,
    });
};

export const usePreferredAffiliationDealers = () => {
    return useQuery({
        queryKey: ['preferredAffiliationDealer'],
        queryFn: () =>
            proxyGet<PreferredAffiliationDealerResponse[]>(
                `${bilholdApiUrl}/v1/preferred-dealers/affiliation`
            ).then((res) => res.data),
    });
};

export function useEditPreferredDealer(brand: Brand | undefined) {
    const queryCache = useQueryClient();
    return useMutation({
        mutationFn: (dealerId: string) =>
            post(`${bilholdApiUrl}/v1/preferred-dealers/${brand}`, {
                dealerNumber: dealerId,
            }),

        onSuccess: () => {
            void queryCache.invalidateQueries({
                queryKey: ['preferredDealer'],
            });
            void queryCache.invalidateQueries({
                queryKey: ['preferredAffiliationDealer'],
            });
        },
    });
}

export const useDeletePreferredDealer = (brand: Brand) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: () =>
            httpDelete(`${bilholdApiUrl}/v1/preferred-dealers/${brand}`),
        onSuccess: () => {
            void queryClient.invalidateQueries({
                queryKey: ['preferredDealer'],
            });
        },
    });
};

import { Column, TextContainer } from '@gnist/design-system';
import { Link as RouterLink } from 'react-router-dom';
import {
    TDFTextMedium,
    TDSHeadline,
} from 'src/features/competition/textComponents/TDFText';
import { PATH_PRIVACY } from 'src/routing/routes';
import BackButton from 'src/shared/BackButton';
import FeedbackPrompt from 'src/shared/feedback-card/FeedbackPrompt';
import { styled } from 'styled-components';
import { CompetitionPageWrapper } from '../components/CompetitionPageWrapper';

const ListTDT = styled.ul`
    list-style-type: '>';
    padding-left: var(--moller-spacing-xxs);
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
`;
const ListItemTDT = styled.li`
    padding-inline-start: 1rem;
`;

const HeadlineTerms = styled.h2`
    font-size: 1.25rem;
    font-weight: 700;
    margin: 0 0 0 0;
    padding: 0;
`;

const InlineLink = styled(RouterLink)`
    color: white;
`;

const PATH_SKODA_OPENING_HOURS =
    'https://www.skoda-auto.no/mer-skoda/kontakt-oss';

const Page = () => (
    <Column gap={'xl'}>
        <BackButton />
        <TextContainer>
            <TDSHeadline>Personvernerklæring for Tour de Škoda</TDSHeadline>
            <TDFTextMedium>
                Harald A. Møller AS er behandlingsansvarlig for
                personopplysningene knyttet til sykkelkonkurransen Tour de
                Škoda. Opplysningene brukes til å kontakte deg, samt til å
                informere deg om fremdrift og nyheter knyttet til konkurransen.
                Opplysningene er også viktige å ha dersom vi må tilbakekalle
                premier eller sende ut informasjon pga. feil. Du har innsynsrett
                etter personvernloven og kan kreve retting, endring og sletting.
                Du kan kontakte oss ved å sende en e-post til
                personvern@moller.no.
            </TDFTextMedium>
            <TDFTextMedium>
                Denne personvernerklæringen dekker kun behandling av
                personopplysninger i forbindelse med konkurransen “Tour de
                Škoda”. Deltakelse krever at du har bruker i appen “Bilhold”, og
                personvernerklæringen for denne tjenesten finner du{' '}
                <InlineLink to={PATH_PRIVACY}>her</InlineLink>
            </TDFTextMedium>
        </TextContainer>
        <TextContainer>
            <HeadlineTerms>Personvernerklæring for Škoda</HeadlineTerms>
            <TextContainer>
                <TDFTextMedium>
                    HVA SLAGS OPPLYSNINGER SAMLER VI INN?
                </TDFTextMedium>
                <TDFTextMedium>
                    For å kunne gjennomføre konkurransen er vi avhengige av å
                    samle inn ulike typer informasjon, inkludert
                    personopplysninger. Under følger en oversikt over hvordan vi
                    samler inn personopplysningene og hvilke opplysninger dette
                    er.
                </TDFTextMedium>
            </TextContainer>
            <ListTDT>
                <ListItemTDT>
                    Opplysninger du selv oppgir til oss: <br /> Når du
                    registrerer deg som bruker i Bilhold må du oppgi en del
                    informasjon som lagres av oss, slik som navn og
                    kontaktinformasjon.
                </ListItemTDT>
                <ListItemTDT>
                    Opplysninger fra Strava: <br />
                    Vi bruker informasjon fra Strava til å holde rede på hvor
                    langt du har syklet. Vi lagrer ikke enkeltaktiviteter, men
                    vi lagrer en sum av aktiviteter innenfor tidsperioden, som
                    kilometer og høydemetre syklet.
                </ListItemTDT>
            </ListTDT>
        </TextContainer>
        <TextContainer>
            <TDFTextMedium>HVA BRUKES OPPLYSNINGENE TIL?</TDFTextMedium>
            <TDFTextMedium>
                Opplysningene brukes til å knytte dine prestasjoner i
                konkurransen til deg som person, for å sende ukentlige
                nyhetsbrev om konkurransen, og til å kunne kontakte deg dersom
                du vinner hovedpremien. Hvis du vinner andre premier bruker vi
                informasjonen du oppgir om hvor du ønsker å hente premiene til å
                distribuere utsendelsen av premieproduktene til de ulike
                bilforhandlerne. Informasjonen fra Strava kan også brukes for å
                forhindre juks.
            </TDFTextMedium>
        </TextContainer>
        <TextContainer>
            <TDFTextMedium>DELING AV PERSONOPPLYSNINGER</TDFTextMedium>
            <TDFTextMedium>
                Vi deler personopplysninger etter behov med partnere (importør,
                fabrikk og forhandlere) i verdikjeden til Škoda for å sikre
                riktig distribusjon av premier.
            </TDFTextMedium>
            <TDFTextMedium>
                Vi vil også kunne måtte utlevere opplysninger i enkelttilfeller,
                eksempelvis til offentlige myndigheter, dersom det er mistanke
                om lovbrudd.
            </TDFTextMedium>
            <TDFTextMedium>
                I forbindelse med koblingen mot Strava (Strava API) vil vi dele
                informasjon med Strava slik at de bl.a. kan drive support og for
                videreutvikling av tjenesten. Ønsker du å vite mer om hvordan
                Strava behandler dine personopplysninger henviser vi til
                personvernerklæringen på deres nettsted eller app.
            </TDFTextMedium>
        </TextContainer>
        <TextContainer>
            <TDFTextMedium>LAGRINGSTID</TDFTextMedium>
            <TDFTextMedium>
                Vi lagrer opplysninger om deg relatert til Tour de Škoda kun så
                lenge det er nødvendig for å oppnå formålet de ble innhentet
                for, eller dersom vi er pålagt å lagre opplysningene.
                Opplysningene vil slettes når de ikke lengre er nødvendige for å
                oppnå formålet, senest innen utgangen av 2024.
            </TDFTextMedium>
        </TextContainer>
        <TextContainer>
            <TDFTextMedium>DINE RETTIGHETER</TDFTextMedium>
            <TDFTextMedium>
                Kontakt oss dersom du har spørsmål knyttet til behandlingen av
                dine personopplysninger. På visse vilkår har du anledning til å
                be oss om å:
            </TDFTextMedium>
            <TDFTextMedium>
                a) Gi deg nærmere informasjon om hvordan vi behandler dine
                personopplysninger <br />
                b) Gi deg en kopi av personopplysninger som du har gitt oss
                <br />
                c) Oppdatere dine personopplysninger <br />
                d) Begrense eller stoppe behandlingen av dine opplysninger
                <br />
                e) Stoppe behandling som utelukkende er basert på en automatisk
                behandling av personopplysninger <br />
                g) Gi deg opplysninger du har gitt til oss i en strukturert,
                alminnelig anvendt og maskinlesbart format eller overføre
                dataene direkte til en annen leverandør <br />
            </TDFTextMedium>
            <TDFTextMedium>
                Der behandlingen er basert på samtykke, kan du når som helst
                trekke dette tilbake ved å gi oss (personvern@moller.no) beskjed
                om det eller selv forandre samtykket på «Mitt Bilhold» siden.
                Dersom du mener at vi behandler personopplysninger i strid med
                personopplysningsregelverket, har du rett til å klage til
                Datatilsynet.
            </TDFTextMedium>
        </TextContainer>
        <TextContainer>
            <TDFTextMedium>ENDRING AV PERSONVERNERKLÆRING</TDFTextMedium>
            <TDFTextMedium>
                Vi vil med jevne mellomrom kunne oppdatere eller endre denne
                personverninformasjonen. Ved større endringer vil vi informere
                om dette.
            </TDFTextMedium>
        </TextContainer>

        <TextContainer>
            <TDFTextMedium>KONTAKTINFORMASJON</TDFTextMedium>
            <TDFTextMedium>
                Har du ytterligere spørsmål om Škoda personverninformasjon eller
                om vår bruk av personopplysninger, ta gjerne kontakt med oss på
                skoda.kundeservice@moller.no eller personvern@moller.no. Du
                finner informasjon om kundesenteret sine åpningstider{' '}
                <InlineLink to={PATH_SKODA_OPENING_HOURS}>her</InlineLink>.
            </TDFTextMedium>
        </TextContainer>
        <FeedbackPrompt />
    </Column>
);
export const PrivacyStatementPage = () => {
    return (
        <CompetitionPageWrapper>
            <Page />
        </CompetitionPageWrapper>
    );
};

import {
    Column,
    Heading3Text,
    Icon,
    Row,
    breakpointDesktop,
} from '@gnist/design-system';
import { Link } from 'react-router-dom';
import { PATH_ADD_CAR } from 'src/routing/routes';
import lang from 'src/utils/lang';
import { styled } from 'styled-components';
import { CarLinkCard, Container } from '../HomeStyledComponents';

const StyledColumn = styled(Column)`
    padding: var(--moller-spacing-s);
    @media screen and (min-width: ${breakpointDesktop}) {
        gap: var(--moller-spacing-xl);
    }
`;

export const AddCar = () => {
    return (
        <Container>
            <CarLinkCard forwardedAs={Link} to={PATH_ADD_CAR}>
                <StyledColumn>
                    <Row gap="xxs" verticalAlign="center">
                        <Heading3Text>{lang.addCar}</Heading3Text>
                        <Icon icon="arrow_forward" />
                    </Row>
                    <p>{lang.addCarDescription}</p>
                </StyledColumn>
            </CarLinkCard>
        </Container>
    );
};

import loginRedirect from 'src/utils/auth/loginRedirect';
import { Section, SectionTextLayout } from '../components';

import ruterepatasjon from '../images/ripe.jpg';

export const DamageAppraiser = () => {
    return (
        <Section alignImageRight>
            <img src={ruterepatasjon} alt="" />
            <SectionTextLayout
                title={'Har du fått en ripe i lakken eller skade i bilruten?'}
                cta={{
                    label: 'Bestill skadetakst',
                    onClick: () => loginRedirect(),
                }}
            >
                <p>
                    Steinsprut er irriterende og kan føre til at du må bytte
                    hele frontruten. Med Bilhold-appen kan du enkelt få
                    skadetakst og bestille reparasjon av bilrute, uten å måtte
                    dra til verkstedet. Registrer skaden, last opp bilder og få
                    et uforpliktede pristilbud fra ønsket verksted - enkelt som
                    det.
                </p>
            </SectionTextLayout>
        </Section>
    );
};

import { VideosByTypeNumberResult } from '@bilhold/sanity';
import { ExistingCarRelation } from 'external-apis/src/types/bilhold';
import groq from 'groq';
import { useSanityData } from 'src/sanity/useSanityData';

export const useGetSanityVideosByTypeNumber = (
    typeNumber?: string | null,
    isEnabled: boolean = true
) => {
    const videosByTypeNumber = groq`*[_type == "video" && references(*[_type == "carType" && $typeNumber == typeNumber]._id)] | order(priority asc)`;

    return useSanityData<VideosByTypeNumberResult>(
        videosByTypeNumber,
        { typeNumber },
        isEnabled
    );
};

export function getTypeNumberForVin(
    existingCars: ExistingCarRelation[] | undefined,
    vin: string | undefined
) {
    const currentCar = existingCars?.find((c) => c.vin === vin);
    const typeNumber = currentCar?.typeNumber;

    return typeNumber;
}

export function getBrandForVin(
    existingCars: ExistingCarRelation[] | undefined,
    vin: string | undefined
) {
    const currentCar = existingCars?.find((c) => c.vin === vin);
    const brand = currentCar?.brand;

    return brand;
}

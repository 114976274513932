import {
    Heading1Text,
    DetailText,
    LeadText,
    TextContainer,
} from '@gnist/design-system';
import { isMobileApp } from 'src/utils/mobile';
import { styled } from 'styled-components';
import { TireAccordion } from './TireAccordion';
import tireChange from './tire-change.webp';
import tireHotel from './tire-hotel-modal.webp';

const FlexContainer = styled.div<{ reverseOrder: boolean }>`
    display: flex;
    flex-direction: ${({ reverseOrder }) =>
        reverseOrder ? 'column-reverse' : 'column'};
    h2 {
        margin-top: var(--moller-spacing-s) !important;
    }
`;

const StyledImg = styled.div`
    img {
        object-fit: cover;
        width: 100%;
        height: 300px;
        object-position: 80% 20%;
    }
`;

interface ModalContentProps {
    type: 'internal' | 'external' | 'standard';
    modalHeader: string;
    modalTitle: string;
    modalDescription: string[];
    modalDetail?: string;
    infoText?: string;
}

export const TireModalContent = ({
    type,
    modalHeader,
    modalTitle,
    modalDescription,
    modalDetail,
    infoText,
}: ModalContentProps) => {
    return (
        <>
            <TextContainer>
                <FlexContainer reverseOrder={isMobileApp()}>
                    <StyledImg>
                        {!isMobileApp() && (
                            <img
                                src={
                                    type === 'standard' ? tireChange : tireHotel
                                }
                                alt=""
                            />
                        )}
                    </StyledImg>
                    <Heading1Text>{modalHeader}</Heading1Text>
                </FlexContainer>
                <LeadText>{modalTitle}</LeadText>
                {modalDescription.map((paragraph) => (
                    <p key={paragraph}>{paragraph}</p>
                ))}
            </TextContainer>
            {type === 'standard' && (
                <TextContainer>
                    <h4>Fordeler med å ta hjulskift på verksted</h4>
                    <ul>
                        <li>
                            Hjulene monteres og demonteres av fagfolk, slik at
                            du kan føle deg sikker på veien.
                        </li>
                        <li>
                            Du slipper stresset med å bytte selv - og kan nyte
                            en kaffe mens du venter.
                        </li>
                        <li>
                            Kontroll av bremser for
                            slitasje/skader/funksjonalitet
                        </li>
                    </ul>
                    <p>Har du dekkhotell hos oss er hjulskift inkludert.</p>
                </TextContainer>
            )}
            {type === 'internal' && (
                <TextContainer>
                    <h4>Hva er fordelene med dekkhotell?</h4>
                    <TireAccordion />
                </TextContainer>
            )}
            {modalDetail && (
                <TextContainer>
                    <p>{modalDetail}</p>
                </TextContainer>
            )}
            {infoText && (
                <TextContainer>
                    <DetailText>{infoText}</DetailText>
                </TextContainer>
            )}
        </>
    );
};

import { useQuery } from '@tanstack/react-query';
import { useFetchersContext } from 'external-apis';
import {
    Brand,
    ExistingCarRelation,
    VehicleInfo,
} from 'external-apis/src/types/bilhold';
import { CountryCode } from 'external-apis/src/types/port';
import {
    FriendlyQueryError,
    FriendlyQueryWarning,
} from '../../lib/errors/PortError';
import {
    LanguageContextType,
    useLanguageContext,
} from '../../lib/languages/languageContext';
import { STALE_TIME } from '../../lib/query-client/config';
import { useGetApiLocaleConfig } from '../apiLocale';

const useFindVehicle = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.bilhold.fetcher
        .path('/v1/cars/regnr/{registrationNumber}')
        .method('get')
        .create();
};

export function useGetVehicle(registrationNumber: string | undefined) {
    const [lc] = useLanguageContext();
    const countryCode = useGetApiLocaleConfig().countryCode;
    const findVehicle = useFindVehicle();
    return useQuery<VehicleInfo, Error>({
        queryKey: ['registrationNumber', { registrationNumber, countryCode }],
        staleTime: STALE_TIME.day,
        gcTime: STALE_TIME.day,
        enabled: false,
        ...(!!registrationNumber && {
            enabled: true,
            queryFn: () =>
                getVehicle(registrationNumber, countryCode, lc, findVehicle),
        }),
    });
}

async function getVehicle(
    registrationNumber: string,
    countryCode: CountryCode,
    lc: LanguageContextType,
    findVehicle: ReturnType<typeof useFindVehicle>
) {
    try {
        const result = await findVehicle({
            registrationNumber,
            countryCode,
        });
        return result.data;
    } catch (e) {
        if (e instanceof findVehicle.Error) {
            const response = e.getActualType();
            if (response) {
                switch (response.status) {
                    case 404: {
                        throw new FriendlyQueryError(
                            lc.errors.carcare_car_system_404,
                            e,
                            e.status
                        );
                    }
                    case 409: {
                        throw new FriendlyQueryError(
                            lc.errors.carcare_409,
                            e,
                            e.status
                        );
                    }
                }
            }
        }
        throw e;
    }
}

const useFindRegisteredVehicle = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.bilhold.fetcher.path('/v1/cars').method('get').create();
};

export function useGetRegisteredVehicles() {
    const [lc] = useLanguageContext();
    const findRegisteredVehicles = useFindRegisteredVehicle();
    return useQuery<Vehichle[], Error>({
        queryKey: ['registeredVehicles'],
        staleTime: STALE_TIME.day,
        gcTime: STALE_TIME.day,
        retry: 1,
        queryFn: () => getRegisteredVehicles(lc, findRegisteredVehicles),
    });
}

async function getRegisteredVehicles(
    lc: LanguageContextType,
    findRegisteredVehicles: ReturnType<typeof useFindRegisteredVehicle>
) {
    try {
        const result = await findRegisteredVehicles({});
        const data = result.data.existingCars ?? [];
        return data.filter(isValidVehicle).map(toVehicle);
    } catch (e) {
        if (e instanceof findRegisteredVehicles.Error) {
            throw new FriendlyQueryWarning(
                lc.errors.carcare_registered_vehicles,
                e as Error,
                e.status
            );
        }
        throw e;
    }
}
function isValidVehicle(
    relation: ExistingCarRelation
): relation is Required<ExistingCarRelation> {
    return (
        relation.brand !== null &&
        relation.name !== null &&
        relation.vin !== null &&
        relation.licensePlate !== null
    );
}

export type Vehichle = {
    vin: string;
    licensePlate: string;
    brand: Brand;
    model: string;
};

function toVehicle(relation: Required<ExistingCarRelation>): Vehichle {
    return {
        vin: relation.vin,
        licensePlate: relation.licensePlate,
        brand: relation.brand,
        model: relation.name,
    };
}

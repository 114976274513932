import { Fetcher } from 'openapi-typescript-fetch';
import { Environment } from '../..';
import { ENVIRONMENTS, backendUrl } from '../environment';
import { paths } from '../schema/bilhold-api';
import { FetcherConfiguration } from './fetchersProvider';

const urls: { [x in (typeof ENVIRONMENTS)[number]]: string } = {
    prod: 'https://bilhold-api.prod.mollercloud.no',
    stage: 'https://bilhold-api.stage.mollercloud.no',
    test: 'https://bilhold-api.test.mollercloud.no',
    dev: 'https://bilhold-api.dev.mollercloud.no',
    local: 'https://localhost:7269',
};

export const fetcher: ReturnType<typeof Fetcher.for<paths>> =
    Fetcher.for<paths>();

export function configureBilholdFetcher({
    localEnvironmentOverride,
    environment,
    middlewares,
}: FetcherConfiguration) {
    const baseUrl = backendUrl({
        environment,
        urls,
        localEnvironmentOverride,
    });
    fetcher.configure({
        baseUrl: baseUrl,
        init: {
            headers: {
                'Content-Type': 'application/json',
            },
        },
        use: middlewares,
    });
    return {
        baseUrl,
        fetcher,
        environment,
    };
}

export function baseUrl(
    environment: Environment,
    localEnvironmentOverride?: string
) {
    return backendUrl({
        environment,
        urls,
        localEnvironmentOverride,
    });
}

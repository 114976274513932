import {
    Heading1Text,
    LoadingSpinner,
    PrimaryButton,
    TextContainer,
} from '@gnist/design-system';
import {
    AddCarRequestRelationReason,
    CarRelationType,
} from 'external-apis/src/types/bilhold';
import HtmlReactParser from 'html-react-parser';
import { useState } from 'react';
import ConfirmWrapper from 'src/features/add-car/components/confirm-wrapper/ConfirmWrapper';
import ownerReasons from 'src/features/add-car/utils/ownerReasons';
import {
    ProfileDataUpdate,
    UpdateProfile,
} from 'src/features/user-profile/user-settings/UpdateProfile';
import { useProfileUpdate } from 'src/features/user-profile/user-settings/utils/updateProfile';
import { useProfile } from 'src/utils/hooks/useProfile';
import lang, { TextKey } from 'src/utils/lang';
import getCountryCode from 'src/utils/region/getCountryCode';

interface OwnerProps {
    reasons: AddCarRequestRelationReason[];
    addCarFunction: (relationType: CarRelationType) => void;
    addCarError: string;
    setErrorMessage: (value: string) => void;
}

const Owner = ({
    reasons,
    addCarFunction,
    addCarError,
    setErrorMessage,
}: OwnerProps) => {
    const { data: profile, isPending: isProfileLoading } = useProfile();
    const [showRelationContent, setShowRelationContent] = useState(false);
    const [updatedProfileData, setUpdatedProfileData] =
        useState<ProfileDataUpdate>();

    const countryCode = getCountryCode();
    const { mutate: updateProfile, isPending: isUpdating } = useProfileUpdate(
        () => addCarFunction('Owner'),
        () => setErrorMessage('general_error_contact_support')
    );

    const handleOnClick = () => {
        if (updatedProfileData) {
            const changedUserData = {
                firstname: updatedProfileData.firstname,
                surname: updatedProfileData.surname,
                addressLine: updatedProfileData.addressLine,
                postcode: updatedProfileData.postcode,
                city: updatedProfileData.city,
                countryCode: countryCode,
                email: updatedProfileData.email,
            };
            updateProfile(changedUserData);
        } else {
            addCarFunction('Owner');
        }
    };

    const reason = ownerReasons[reasons[0]];

    return (
        <TextContainer>
            {reasons.length > 0 && (
                <>
                    <Heading1Text $as="h3">
                        {reason ? lang[reason.title] : lang.defaultReasonTitle}
                    </Heading1Text>
                    <p>
                        {reason
                            ? HtmlReactParser(lang[reason.description])
                            : lang.defaultReasonDescription}
                    </p>
                    <br />
                    {!showRelationContent && (
                        <PrimaryButton
                            onClick={() =>
                                reason?.continue && setShowRelationContent(true)
                            }
                        >
                            {lang[reason?.buttonText as TextKey]}
                        </PrimaryButton>
                    )}
                </>
            )}
            {(showRelationContent || !reasons.length) && (
                <>
                    <Heading1Text $as="h3">
                        {lang.addCarOwnerTitle}
                    </Heading1Text>
                    <p>{lang.addCarOwnerDescription}</p>
                    {isProfileLoading && <LoadingSpinner />}
                    {profile && (
                        <UpdateProfile
                            profile={profile}
                            minimalForm
                            setUpdatedProfileData={setUpdatedProfileData}
                        />
                    )}
                    <ConfirmWrapper
                        onClick={handleOnClick}
                        isLoading={isUpdating}
                        errorMessage={addCarError}
                    />
                </>
            )}
        </TextContainer>
    );
};

export default Owner;

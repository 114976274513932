import { styled } from 'styled-components';
import { tokens } from '@gnist/themes/tokens.css.js';
import { AllowedIcons, Icon } from '@gnist/design-system';
import { TimelineContent, TimelineContentProps } from './TimelineContent';

const TimelineContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0.5rem;

    &::before {
        content: '';
        position: absolute;
        width: 2px;
        height: 100%;
        background-color: ${tokens.color['on-surface']};
    }
`;

const TimelineIcon = styled(Icon)`
    color: ${tokens.color['on-tertiary-container']};
`;

const TimelineStep = styled.div`
    display: flex;
    align-items: start;
    position: relative;
    padding-bottom: 20px;
`;

const IconWrapper = styled.div`
    width: 2rem;
    height: 2rem;
    background-color: ${tokens.color['tertiary-container']};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: -1rem;
`;

const ContentWrapper = styled.div`
    width: 100%;
    padding-left: 2rem;
`;

export type TimelineIcon = Extract<AllowedIcons, 'car_crash' | 'build'>;
type TimelineProps = {
    steps: {
        icon: TimelineIcon;
        content: TimelineContentProps;
    }[];
};

export function Timeline({ steps }: TimelineProps) {
    return (
        <TimelineContainer>
            {steps.map((step, index) => (
                <TimelineStep key={index}>
                    <IconWrapper>
                        <TimelineIcon icon={step.icon} />
                    </IconWrapper>
                    <ContentWrapper>
                        <TimelineContent {...step.content} />
                    </ContentWrapper>
                </TimelineStep>
            ))}
        </TimelineContainer>
    );
}

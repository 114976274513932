import {
    AlertBanner,
    breakpointMobile,
    IconButton,
    DetailText,
    ProgressBar,
    Heading2Text,
} from '@gnist/design-system';
import { useQuery } from '@tanstack/react-query';
import { useFetchersContext } from 'external-apis';
import { ServiceAgreementType } from 'external-apis/src/types/bilhold';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
    PATH_SA_DEAL,
    PATH_SERVICE_DEAL,
    PATH_SV_DEAL,
} from 'src/routing/routes';
import { withErrorBoundary } from 'src/utils/ErrorBoundary';
import { styled } from 'styled-components';

const SVA_USED_CAR_MILEAGE = 999999;

export const InfoContainerCard = styled.div`
    background-color: var(--moller-color-surface-variant);
    color: var(--moller-color-on-surface-variant);
    border: solid var(--moller-stroke-small)
        var(--moller-color-on-surface-variant);
    padding: var(--moller-spacing-s);
    border-radius: var(--moller-radius-card);
    display: flex;
    flex-direction: column;
    ${DetailText.selector} {
        padding-bottom: var(--moller-spacing-s);
        margin: 0;
    }
    @media screen and (min-width: ${breakpointMobile}) {
        grid-column: 1 / 2 span;
    }
`;

const GridLayout = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--moller-spacing-xl);
    padding: var(--moller-spacing-s) 0;
    @media screen and (min-width: ${breakpointMobile}) {
        grid-template-columns: 1fr 1fr;
    }
`;

const ProgressBarLabel = styled.p`
    margin-top: var(--moller-spacing-xs);
`;

const useFindServiceAgreement = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.bilhold.fetcher
        .path('/v1/service-agreements/vin/{Vin}')
        .method('get')
        .create();
};

export function useGetServiceAgreement(vin: string) {
    const getSvaAgreement = useFindServiceAgreement();
    return useQuery({
        queryKey: ['getServiceAgreement', vin],
        queryFn: () =>
            getSvaAgreement({ Vin: vin }).then((x) => {
                return x.data;
            }),
    });
}

function getContent(agreementType: ServiceAgreementType | undefined) {
    if (agreementType === 'ServiceAgreement') {
        return {
            header: 'Serviceavtale',
            infoLink: `${PATH_SERVICE_DEAL}/${PATH_SA_DEAL}`,
        };
    }

    return {
        header: 'Service- og vedlikeholdsavtale',
        infoLink: `${PATH_SERVICE_DEAL}/${PATH_SV_DEAL}`,
    };
}

export const SvaServiceContainer = ({ vin }: { vin: string }) => {
    const agreementResult = useGetServiceAgreement(vin);

    if (
        agreementResult.isLoading ||
        !agreementResult.data ||
        agreementResult.data.agreementType === 'Undefined'
    ) {
        return <></>;
    }

    const agreement = agreementResult.data;
    const deal = getContent(agreement.agreementType);

    const {
        agreementMileage,
        currentMileage,
        activationDate,
        agreementExpirationDate,
        status,
        statusReason,
    } = agreement;

    const agreementStart = moment(activationDate);
    const agreementEnd = moment(agreementExpirationDate);
    const totalMonths = agreementEnd.diff(agreementStart, 'months');
    const diffToTotal = totalMonths - moment().diff(agreementStart, 'months');
    const monthsRemaining = diffToTotal >= 0 ? diffToTotal : 0;
    const MonthPercentage = (monthsRemaining / totalMonths) * 100;

    const isNewCar = agreementMileage != SVA_USED_CAR_MILEAGE;

    function getMileagePercentage() {
        if (!currentMileage || !agreementMileage) return 0;
        return (currentMileage / agreementMileage) * 100;
    }

    function getProgressMileage() {
        if (!currentMileage || !agreementMileage) return 0;
        return currentMileage <= agreementMileage
            ? currentMileage
            : agreementMileage;
    }

    const milagePercentage = getMileagePercentage();
    const progressMileage = getProgressMileage();

    const suspended = status === 'Suspended';
    const suspendedReason = statusReason?.toLowerCase();
    if (suspended) {
        return (
            <InfoContainerCard>
                <DetailText>{deal.header}</DetailText>
                <Heading2Text>
                    Din avtale er midlertidig stoppet grunnet {suspendedReason}
                </Heading2Text>
            </InfoContainerCard>
        );
    }
    return (
        <InfoContainerCard>
            <DetailText $as={'h2'}>{deal.header}</DetailText>
            <GridLayout>
                <div>
                    <ProgressBar size="large" percentage={MonthPercentage} />
                    <ProgressBarLabel>
                        {monthsRemaining} måneder gjenstår
                    </ProgressBarLabel>
                </div>
                {isNewCar ? (
                    <div>
                        <ProgressBar
                            percentage={
                                milagePercentage <= 100 ? milagePercentage : 100
                            }
                            size="large"
                        />
                        <ProgressBarLabel>
                            {progressMileage} av {agreementMileage} km
                        </ProgressBarLabel>
                    </div>
                ) : null}
            </GridLayout>
            <IconButton
                icon={'chevron_right'}
                label="Les mer om avtalen"
                showLabel="left"
                $as={Link}
                to={deal.infoLink}
                style={{ alignSelf: 'flex-end' }}
            />
        </InfoContainerCard>
    );
};

const fallback = (retry: () => void) => (
    <AlertBanner
        type="warning"
        message="Vi greide dessverre ikke å vise informasjon om din serviceavtale. Prøv igjen senere!"
        action={{ label: 'Prøv igjen', onClick: retry }}
    />
);

export const SvaService = withErrorBoundary('SvaService', SvaServiceContainer, {
    fallback,
});

import { useQuery } from '@tanstack/react-query';
import { useFetchersContext } from 'external-apis';
import { Brand, DealerModel } from 'external-apis/src/types/bilhold';
import { useMemo } from 'react';
import CountryCode from 'src/types/CountryCode';
import { SimpleCoordinates } from 'src/types/SimpleCoordinates';
import { dealerSorters } from './dealerSorter';
import { getKilometersBetweenDealerAndCustomer } from './getKilometersBetween';

export interface DealerWithDistance extends DealerModel {
    distanceFromClient?: number;
}

const useDealersFetcher = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.bilhold.fetcher.path('/v1/dealers').method('get').create();
};

export const useDealers = (
    brand: Brand | undefined,
    location: SimpleCoordinates | undefined,
    dealerGroup: string
) => {
    const getDealers = useDealersFetcher();
    const { data: dealers, isPending: isLoading } = useQuery<
        DealerModel[],
        Error
    >({
        queryKey: ['dealerSearch', CountryCode.NO],
        queryFn: () =>
            getDealers({ CountryCode: 'NOR' }).then((res) => res.data),
        staleTime: 1000 * 60 * 60,
    });

    const certificationBrand = brand == 'Cupra' ? 'Seat' : brand;

    const memoizedDealers: DealerWithDistance[] | undefined = useMemo(
        () =>
            dealers &&
            dealers
                .map(
                    (dealer) =>
                        ({
                            ...dealer,
                            distanceFromClient:
                                getKilometersBetweenDealerAndCustomer(
                                    location,
                                    dealer.coordinates
                                ),
                        } as DealerWithDistance)
                )
                .filter(
                    (dealer) =>
                        dealer.name
                            ?.toUpperCase()
                            .includes(dealerGroup.toUpperCase()) &&
                        (!certificationBrand ||
                            dealer.serviceCertifications?.includes(
                                certificationBrand
                            ))
                )
                .sort(
                    location
                        ? dealerSorters[0].sortFunction
                        : dealerSorters[1].sortFunction
                ),
        [dealerGroup, dealers, location, certificationBrand]
    );

    return { isLoading, dealers: memoizedDealers };
};

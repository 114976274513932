import { datadogRum } from '@datadog/browser-rum';
import {
    DetailText,
    InfoCard,
    PrimaryButton,
    Row,
    TextArea,
    TextButton,
} from '@gnist/design-system';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import getCurrentEnvironment from 'src/utils/environment/getCurrentEnvironment';
import post from 'src/utils/http/post';
import { styled } from 'styled-components';
import { Sentiment } from './FeedbackPrompt';

const StyledDescription = styled(DetailText)`
    margin-top: var(--moller-spacing-l);
    font-weight: 500;
    font-size: 1rem;
`;

const StyledTextArea = styled(TextArea)`
    width: 100%;
    margin-bottom: var(--moller-spacing-m);
`;

const StyledContainer = styled(InfoCard)`
    margin: var(--moller-spacing-s) 0;
    padding: var(--moller-spacing-l);
    padding-top: 0;
`;

const StyledText = styled.p`
    margin: var(--moller-spacing-xxs) 0;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
    width: 100%;

    @media (min-width: 650px) {
        width: auto;
    }
`;

const StyledTextButton = styled(TextButton)`
    width: 100%;
    @media (min-width: 650px) {
        width: auto;
    }
`;

interface SentimentInputProps {
    sentiment: Sentiment;
    setSentiment: (sentiment: Sentiment) => void;
    specifiedService?: string;
    path?: string;
    customContent?: string;
}

export const SentimentInput = ({
    sentiment,
    setSentiment,
    specifiedService,
    path,
    customContent,
}: SentimentInputProps) => {
    const location = useLocation();
    const [value, setValue] = useState('');
    const [isFeedbackSent, setIsFeedbackSent] = useState(false);

    const content = customContent
        ? customContent
        : `Fortell oss hva du savner å se og hvordan vi kan gjøre ${
              specifiedService || 'siden'
          } mer nyttig for deg.`;

    const { name, bilholdApiUrl } = getCurrentEnvironment();

    const sendFeedback = async () => {
        const env = name !== 'prod' ? `\`${name}\`` : '';

        datadogRum.addAction('bilhold-feedback-message', {
            customFeedback: value,
        });

        await post(`${bilholdApiUrl}/slack-notification`, {
            message: `*NY TILBAKEMELDING* 🎉🥳\n*Melding*:\n
${value}\n
*Sentiment*:\n
${sentiment}\n
*Siden det ble sendt fra*:\n
${path ?? location.pathname + location.search}\n
${env}
             `,
        });

        setValue('');
        setIsFeedbackSent(true);
    };

    const renderTitle = () => {
        if (sentiment === 'sentiment_dissatisfied') {
            return 'Uffda! Hva kan vi gjøre bedre?';
        } else if (sentiment === 'sentiment_neutral') {
            return 'Takk for tilbakemeldingen! Ønsker du å utdype?';
        }
        return 'Så bra! Ønsker du å utdype?';
    };

    if (isFeedbackSent) {
        return (
            <StyledContainer>
                <Row gap="base" verticalAlign="center">
                    <StyledDescription>
                        Takk for tilbakemeldingen!
                    </StyledDescription>
                </Row>
            </StyledContainer>
        );
    }

    return (
        <StyledContainer>
            <StyledDescription>{renderTitle()}</StyledDescription>
            <StyledText>{content}</StyledText>
            <StyledTextArea
                value={value}
                onChange={(e) => {
                    setValue(e.target.value.trimStart());
                }}
                label={'Gi tilbakemelding til Bilhold-teamet'}
                helperText="NB: Ikke skriv inn personlige opplysninger. Du får ikke svar på tilbakemeldingen din."
            />
            <Row gap="s" verticalAlign="center" flexWrap="wrap">
                <StyledPrimaryButton
                    disabled={value.length === 0}
                    onClick={() => void sendFeedback()}
                >
                    Send tilbakemelding
                </StyledPrimaryButton>
                <StyledTextButton onClick={() => setSentiment('')}>
                    Kanskje senere
                </StyledTextButton>
            </Row>
        </StyledContainer>
    );
};

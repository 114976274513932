import {
    AlertBanner,
    Column,
    InfoCard,
    PrimaryButton,
} from '@gnist/design-system';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PATH_COMPETITION, PATH_COMPETITION_ABOUT } from 'src/routing/routes';
import { getIsLoggedIn } from 'src/utils/auth/authenticationUtils';
import { styled } from 'styled-components';
import { AuthContext } from '../providers/AuthProvider';
import { TDFTextMedium, TDSSubtitle } from '../textComponents/TDFText';
import { ReconnectToStravaModal } from './ReconnectToStravaModal';

const TDFPrimaryButton = styled(PrimaryButton)`
    border-radius: var(--moller-border-radius-large);
    div {
        font-size: 1rem;
        font-weight: 400;
    }
`;

const WrapperCard = styled(InfoCard)`
    width: 100%;
    border: 0;
`;

const getErrorMessage = (errorCode?: string): string => {
    switch (errorCode) {
        case 'AthleteId already exists':
            return 'Denne Strava-brukeren er allerede logget inn med en annen bruker.';
        case 'User has not allowed sharing private activities':
            return 'Ved registrering må du tillate oss å hente private aktiviteter fra Strava.';
        default:
            return 'En feil skjedde. Prøv å logge inn på nytt.';
    }
};

export const StravaLoginStatus = () => {
    const isLoggedIn = getIsLoggedIn();
    const {
        hasCompetitionUser,
        isHasCompetitionUserLoading,
        hasStravaAuthenticated,
        isStravaAuthLoading,
        error,
    } = useContext(AuthContext);
    const navigate = useNavigate();

    const [isReconnectToStravaModalOpen, setIsReconnectToStravaModalOpen] =
        useState(false);

    const errorMessage = getErrorMessage(error?.response?.data?.message);

    const StravaLoginStatusContent = () => {
        if (isLoggedIn && hasCompetitionUser) {
            return (
                <>
                    <div>
                        <TDSSubtitle style={{ marginTop: 0 }}>
                            Konkurransen for i år er avsluttet
                        </TDSSubtitle>
                        <TDFTextMedium>
                            Gå til konkurransen for å se informasjon om dine
                            premier.
                        </TDFTextMedium>
                    </div>
                    <TDFPrimaryButton
                        onClick={() => {
                            hasStravaAuthenticated
                                ? navigate(PATH_COMPETITION)
                                : setIsReconnectToStravaModalOpen(true);
                        }}
                    >
                        Til konkurransen
                    </TDFPrimaryButton>
                    <ReconnectToStravaModal
                        isOpen={isReconnectToStravaModalOpen}
                        handleClose={() =>
                            setIsReconnectToStravaModalOpen(false)
                        }
                        returnPath={PATH_COMPETITION_ABOUT}
                    />
                </>
            );
        } else if (
            isLoggedIn &&
            !hasCompetitionUser &&
            !isHasCompetitionUserLoading &&
            !hasStravaAuthenticated &&
            !isStravaAuthLoading
        ) {
            return (
                <div>
                    <TDSSubtitle style={{ marginTop: 0 }}>
                        Konkurransen for i år er avsluttet
                    </TDSSubtitle>
                    <TDFTextMedium>
                        Vinneren av hovedpremien er trukket, og det ble Tore,
                        som valgte taktelt fra Thule som premie. <br />
                        Vi gratulerer!
                    </TDFTextMedium>
                </div>
            );
        } else {
            return (
                <div>
                    <TDSSubtitle style={{ marginTop: 0 }}>
                        Konkurransen for i år er avsluttet
                    </TDSSubtitle>
                    <TDFTextMedium>
                        Vinneren av hovedpremien er trukket, og det ble Tore,
                        som valgte taktelt fra Thule som premie. <br />
                        Vi gratulerer!
                    </TDFTextMedium>
                </div>
            );
        }
    };

    return (
        <WrapperCard>
            <Column gap={'xs'}>
                <StravaLoginStatusContent />
                {error && (
                    <AlertBanner
                        type="error"
                        message={errorMessage}
                    ></AlertBanner>
                )}
            </Column>
        </WrapperCard>
    );
};

import {
    ActionCard,
    Heading3Text,
    InfoCard,
    LinkCard,
    breakpointDesktop,
    breakpointTablet,
} from '@gnist/design-system';
import { styled } from 'styled-components';
import drawerSubmitEvent from '../utils/drawerSubmitEvent';

export const Container = styled.div`
    margin: var(--moller-spacing-s);
    height: 100%;
`;

export const StyledOverline: typeof Heading3Text = styled(Heading3Text)`
    display: flex;
    margin-bottom: var(--moller-spacing-xxs);
    margin-top: var(--moller-spacing-xs);
`;

export const CarLinkCard = styled(LinkCard)`
    position: relative;
    box-sizing: content-box;
    padding: var(--moller-spacing-s) var(--moller-spacing-l);
    padding-bottom: var(--moller-spacing-l);
    min-height: 118px;
    overflow: hidden;

    @media screen and (min-width: ${breakpointDesktop}) {
        padding: var(--moller-spacing-3xl) var(--moller-spacing-xl);
    }
`;

export const AppointmentActionCard = styled(InfoCard)`
    padding: var(--moller-spacing-xs);
    height: 100%;
    width: 100%;
    max-width: 800px;
    font-size: var(--moller-type-small-body-font-size);
    @media screen and (min-width: ${breakpointTablet}) {
        padding: var(--moller-spacing-m);
    }
    margin-bottom: var(--moller-spacing-xs);
`;

export const SuggestedActionCard = styled(ActionCard).attrs<{
    $clickName: string;
    $toggleDrawer: () => void;
}>(({ $clickName, $toggleDrawer }) => ({
    onClick: () => {
        drawerSubmitEvent(`click on ${$clickName}`, $toggleDrawer);
    },
    onKeyDown: (e: React.KeyboardEvent<HTMLButtonElement>) => {
        if (e.code === 'Enter') {
            drawerSubmitEvent(`click on ${$clickName}`, $toggleDrawer);
        }
    },
}))<{
    $clickName: string;
    $toggleDrawer: () => void;
}>`
    padding: var(--moller-spacing-m);
    height: 100%;
    width: 100%;
    max-width: 800px;
    border: none;
    cursor: pointer;
    font-size: var(--moller-type-small-body-font-size);
    @media screen and (min-width: ${breakpointTablet}) {
        padding: var(--moller-spacing-xl);
    }
`;

import { InfoCard } from '@gnist/design-system';
import React from 'react';
import { styled } from 'styled-components';
import { useLanguageContext } from '../../lib/languages/languageContext';
import { parseServicePrices, Price } from '../../sections/shared/prices/Price';
import {
    parseServicesPrices,
    SumPrice,
} from '../../sections/shared/prices/SumPrice';
import { StandardServicesData } from '../../sections/standard-services/useStandardServices';
import { BilholdViewLayout } from '../bilhold/BilholdView';
import { BoldText } from '../BoldText';

type ServicesDoneProps = {
    selectedServices: StandardServicesData['selectedServices'];
    selectedDealerNumber: string;
    additionalInfo?: StandardServicesData['selectedServices'];
};
export const ServiceCommentDescription = styled.div`
    padding-bottom: var(--moller-spacing-xs);
    font-size: 0.8rem;
`;

const hasComment = (
    service: StandardServicesData['selectedServices'][number]
) => service.additionalInfo?.some((x) => x.infoType === 'Comment');

export const ServicesDone: React.FC<ServicesDoneProps> = ({
    selectedServices,
    selectedDealerNumber,
}) => {
    const [lc] = useLanguageContext();

    return (
        <BilholdViewLayout>
            {selectedServices.map((service) => (
                <InfoCard
                    density="compact"
                    key={`${service.adapterId}_${service.serviceId}`}
                >
                    <BoldText>{service.name}</BoldText>
                    {hasComment(service) && (
                        <ServiceCommentDescription>
                            <>{lc.components.comment} </>
                            {service.comment}
                        </ServiceCommentDescription>
                    )}
                    <Price values={parseServicePrices(service)} />
                </InfoCard>
            ))}
            <SumPrice
                values={parseServicesPrices(
                    selectedServices,
                    selectedDealerNumber
                )}
            />
        </BilholdViewLayout>
    );
};

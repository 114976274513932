import { Column, Row } from '@gnist/design-system';
import { styled } from 'styled-components';
import { stages } from '../../utils/stages';
import { Ticket } from './Ticket';

interface StagesProps {
    currentStage: number;
    totalKm: number;
}

const ColumnWrapper = styled(Column)`
    margin-top: var(--moller-spacing-xl);
`;
const StyledText = styled.span<{ $completedStage?: boolean }>`
    color: ${(props) => (props.$completedStage ? '#78faae' : 'currentColor')};
`;

export function Stages({ currentStage, totalKm }: StagesProps) {
    // User has finished every stage.
    // currentStage only ranges from 0-20
    currentStage = currentStage === 20 ? 21 : currentStage;
    let totalDistanceToCompleteStage = 0;
    return (
        <ColumnWrapper gap={'s'}>
            {stages.map((stage) => {
                totalDistanceToCompleteStage += stage.km;

                const hasCompletedStage =
                    currentStage >= stage.stage &&
                    totalKm >= totalDistanceToCompleteStage;

                return (
                    <Row
                        horizontalAlign="spaceBetween"
                        key={stage.stage}
                        gap={'xs'}
                    >
                        <Row horizontalAlign="center">
                            {hasCompletedStage ? <Ticket /> : null}
                            <StyledText $completedStage={hasCompletedStage}>
                                {stage.start} til {stage.end}
                            </StyledText>
                        </Row>
                        <StyledText
                            $completedStage={hasCompletedStage}
                            style={{
                                textAlign: 'right',
                                minWidth: 'fit-content',
                            }}
                        >
                            {stage.km} km
                        </StyledText>
                    </Row>
                );
            })}
        </ColumnWrapper>
    );
}

import { AlertBanner, InfoCard } from '@gnist/design-system';
import { withErrorBoundary } from 'src/utils/ErrorBoundary';
import { formatDealerName } from 'src/utils/formatDealerName';
import { CardLayout } from '../components/CardLayout';
import { useGetTireHotelDeal } from '../query/useTireHotelDeal';

export const TireHotelContainer = ({
    vin,
    isSkoda,
}: {
    vin: string;
    isSkoda: boolean;
}) => {
    const { data } = useGetTireHotelDeal(vin);

    return (
        <>
            {data?.map((d) => (
                <InfoCard key={d.dealer?.name}>
                    <CardLayout
                        title={
                            d.dealer?.name
                                ? `Dine dekk er sjekket inn hos ${formatDealerName(
                                      d.dealer?.name
                                  )}`
                                : ''
                        }
                        description={'Dekkhotell'}
                        icon={!isSkoda ? 'tire' : undefined}
                    />
                </InfoCard>
            ))}
        </>
    );
};

const fallback = (retry: () => void) => (
    <AlertBanner
        type="warning"
        message="Vi greide dessverre ikke å vise innhold for ditt dekkhotell. Prøv igjen senere!"
        action={{ label: 'Prøv igjen', onClick: retry }}
    />
);

export const TireHotel = withErrorBoundary('TireHotel', TireHotelContainer, {
    fallback,
});

import { datadogRum } from '@datadog/browser-rum';
import { useState } from 'react';
import { BookingViewModel } from 'src/features/appointment/api/useAppointments';
import { formatDealerName } from 'src/utils/formatDealerName';
import { isMobileApp } from 'src/utils/mobile';
import { AppointmentActionCard } from '../../home/components/HomeStyledComponents';
import { DetailedAppointmentDrawer } from '../detailed-appointment/DetailedAppointmentDrawer';
import { AppointmentCardLayout } from './AppointmentCardContent';

type AppointmentCardProps = {
    appointment: BookingViewModel;
    isLoading: boolean;
};

export const AppointmentCard = ({
    appointment,
    isLoading,
}: AppointmentCardProps) => {
    const [campaignModalIsOpen, setCampaignModalIsOpen] = useState(false);

    if (!appointment.dealer?.name) {
        datadogRum.addError(`Dealer was null:`, appointment);
    }
    return (
        <>
            <AppointmentActionCard>
                <AppointmentCardLayout
                    dealer={formatDealerName(appointment.dealer?.name)}
                    services={
                        appointment.bookedServices?.map(
                            (service) => service.name
                        ) ?? []
                    }
                    setCampaignModalIsOpen={setCampaignModalIsOpen}
                    appointment={appointment}
                />
            </AppointmentActionCard>
            {isMobileApp() && (
                <DetailedAppointmentDrawer
                    drawerIsOpen={campaignModalIsOpen}
                    setDrawerIsOpen={setCampaignModalIsOpen}
                    appointment={appointment}
                    isLoading={isLoading}
                />
            )}
        </>
    );
};

import {
    ExistingCarRelation,
    OrderedCarRelation,
    PendingCarRelation,
} from 'external-apis/src/types/bilhold';

export type CarWithStatus =
    | CarAndStatus<CarStatusType.PENDING_CAR, PendingCarRelation>
    | CarAndStatus<CarStatusType.ORDERED_CAR, OrderedCarRelation>
    | CarAndStatus<CarStatusType.EXISTING_CAR, ExistingCarRelation>;

type CarAndStatus<TStatus extends CarStatusType, TCar> = {
    car: TCar;
    statusType: TStatus;
};

export enum CarStatusType {
    PENDING_CAR = 'PENDING_CAR',
    ORDERED_CAR = 'ORDERED_CAR',
    EXISTING_CAR = 'EXISTING_CAR',
}

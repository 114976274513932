import { InfoCard } from '@gnist/design-system';
import {
    PreferredAffiliationDealerResponse,
    usePreferredAffiliationDealers,
} from '../../home/queries/usePreferredDealer';
import { ContactInfoCard } from './ContactInfoCard';

const BrandContactInfo = () => {
    const { data: preferredDealerResponse, isPending: isLoading } =
        usePreferredAffiliationDealers();

    const allPreferredDealers: PreferredAffiliationDealerResponse[] =
        preferredDealerResponse || [];

    const uniqueBrands = Array.from(
        new Set(allPreferredDealers.map((dealer) => dealer.brand))
    );

    if (isLoading) {
        <InfoCard isLoading />;
    }

    const renderContentBasedOnBrand = (brand: string) => {
        switch (brand.toUpperCase()) {
            case 'VOLKSWAGEN':
                return (
                    <ContactInfoCard
                        title={'Volkswagen online-tjenester'}
                        description={
                            'For spørsmål vedrørende Volkswagen-appen, produkter og tjenester fra Volkswagen AG.'
                        }
                        phoneLabel={'Ring kundeservice'}
                        phoneNumber={'+4780012651'}
                        email="connect-support@volkswagen.de"
                        openHoursText="Åpent 24 timer"
                    />
                );
            case 'AUDI':
                return (
                    <ContactInfoCard
                        title={'Audi kundeservice'}
                        description={
                            'Ved spørsmål om myAudi og andre connect-tjenester som Functions on demand, Vehicle code, Audi Pay og fjernstyrte tjenester. '
                        }
                        phoneLabel={'Ring kundeservice'}
                        phoneNumber={'+4780012123'}
                        email="digitalsupport.no@audi.de"
                        openHoursText="Åpent 24 timer"
                    />
                );
            case 'SKODA':
                return (
                    <ContactInfoCard
                        title={'Škoda Connect-tjenester'}
                        description={
                            'Ved spørsmål eller behov for hjelp med Škoda Connect-tjenester eller MyŠkoda App.'
                        }
                        phoneLabel={'Ring kundeservice'}
                        phoneNumber={'+80080247247'}
                        email="support@skoda-auto.com"
                        openHoursText="Åpent 24 timer"
                    />
                );
            case 'CUPRA':
                return (
                    <ContactInfoCard
                        title={'Cupra Connect-tjenester'}
                        description={
                            'For spørsmål vedrørende Cupra-appen, produkter og tjenester fra Cupra'
                        }
                        email="cupradigitalsupport@cupraofficial.com"
                        openHoursText="Åpent 24 timer"
                    />
                );
        }
    };

    return (
        <div>
            {uniqueBrands.map((brand) => {
                return (
                    <div key={brand}>
                        {uniqueBrands.length > 0 &&
                            renderContentBasedOnBrand(brand)}
                    </div>
                );
            })}
        </div>
    );
};

export default BrandContactInfo;

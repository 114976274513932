import { Row } from '@gnist/design-system';
import { ServiceViewModel } from 'external-apis/src/types/port';
import { useLanguageContext } from '../../../lib/languages/languageContext';
import { StandardServicesData } from '../../standard-services/useStandardServices';
import { tireHotelService, tireService } from '../../testData';
import { toSelectedService } from '../toSelectedService';

type SumPriceProps = {
    values: (number | undefined | null)[];
    finalSummary?: boolean;
};

export function SumPrice({ values, finalSummary = false }: SumPriceProps) {
    const [lc] = useLanguageContext();
    const noUndefined = (x: SumPriceProps['values']) =>
        x.every((value) => typeof value === 'number');
    const bold = 'var(--moller-typeface-weight-bold)';

    if (!noUndefined(values)) {
        return (
            <Row horizontalAlign="end">
                <p style={finalSummary ? { fontWeight: bold } : {}}>
                    {finalSummary ? lc.prices.total : lc.prices.sum}
                    <span
                        style={{
                            fontWeight: 'var(--moller-typeface-weight-bold)',
                        }}
                    >
                        {lc.prices.price_calculated_at_dealer}
                    </span>
                </p>
            </Row>
        );
    }
    return (
        <Row horizontalAlign="end">
            <p style={finalSummary ? { fontWeight: bold } : {}}>
                {finalSummary ? lc.prices.total : lc.prices.sum}
                <span
                    style={{ fontWeight: 'var(--moller-typeface-weight-bold)' }}
                >
                    {`${sum(values)} ${lc.prices.currency}`}
                </span>
            </p>
        </Row>
    );
}

function sum(values: number[]) {
    if (values.length === 0) return 0;
    return Math.ceil(values.reduce((x, y) => x + y));
}

if (import.meta.vitest) {
    const { it, expect } = import.meta.vitest;
    it.concurrent('sum - with empty array', () => {
        const actual = sum([]);
        expect(actual).toBe(0);
    });
    it.concurrent('sum - with non-empty array', () => {
        const values = [200, 500, 10];
        const actual = sum(values);
        expect(actual).toBe(710);
    });
}

// we expect to rewrite this function when changing to services first flow.
export function parseServicesPrices(
    services: (
        | StandardServicesData['selectedServices'][number]
        | ServiceViewModel
    )[],
    dealerNumber: string
) {
    if (services.length < 1) return [];
    return services
        .map((x) =>
            x.dealerSpecificInformation.find(
                (y) => y.dealerNumber === dealerNumber
            )
        )
        .map((x) => x?.price?.fixedPrice);
}

if (import.meta.vitest) {
    const { it, expect } = import.meta.vitest;
    it.concurrent('parseServicesPrices - when empty list', () => {
        const actual = parseServicesPrices([], '1100');
        expect(actual).toEqual([]);
    });
    it.concurrent('parseServicesPrices - when only defined prices', () => {
        const services = [tireHotelService, tireService].map((x) =>
            toSelectedService(x)
        );
        const actual = parseServicesPrices(services, '1290');
        expect(actual.sort()).toEqual([185, 299]);
    });
    it('parseServicesPrices -  when some undefined prices', () => {
        const services = [tireHotelService, tireService].map((x) =>
            toSelectedService(x)
        );
        const actual = parseServicesPrices(services, '1100');
        expect(actual.sort()).toEqual([199, undefined]);
    });
}

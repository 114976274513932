import {
    Heading1Text,
    Heading2Text,
    TextContainer,
} from '@gnist/design-system';
import { CenteredPageContent } from 'src/shared/CenteredPageContent';

const WarrantyAudi = () => {
    return (
        <CenteredPageContent showBackButton>
            <TextContainer>
                <Heading1Text>Garantier på bilen</Heading1Text>
                <p>
                    Garantiheftet skal betraktes som et viktig verdidokument som
                    alltid skal oppbevares i bilen med følgende dokumentmappe
                    sammen med øvrige dokumenter som bl.a. serviceheftet. Heftet
                    skal være ferdig utfylt med alle relevante data fra selgende
                    forhandler og underskrevet av bilens første kjøper. Vi
                    anbefaler at heftet leses grundig slik at man er godt kjent
                    med innhold og krav enten man har kjøpt bilen ny eller
                    brukt.
                </p>

                <Heading2Text>Audi nybilgaranti</Heading2Text>
                <p>
                    Alle nye biler fra Audi leveres med 2 års nybilgaranti uten
                    kilometerbegrensning. For biler som er importert av Harald
                    A. Møller AS og registrert etter 01.01.2011 er denne
                    bilgarantien utvidet til en Norgesgaranti på 5 år/100.000
                    km, avhengig av hva som inntreffer først. Denne garantien
                    gjelder for alle mangler i materiale og produksjon. For en
                    feil eller mangel som kommer inn under denne garantien, må
                    et autorisert Audi Service-verksted kontaktes for en
                    utbedring.
                </p>
                <p>
                    Audi AG gir 2 års garanti ved kjøp av en ny Audi. Audi AG er
                    garantigiver. Denne garantien gjelder for alle mangler i
                    materiale og produksjon, og vilkårene beskrives nærmere
                    nedenfor. AUDI AG, Harald A. Møller AS eller en autorisert
                    Audi-forhandler dokumenterer i serviceheftet når garantien
                    trer i kraft og begynner å løpe.
                </p>
                <p>
                    Garantien begynner å løpe når Audi AG, Harald A. Møller AS
                    eller en autorisert Audi-forhandler overleverer den nye
                    bilen til den første kjøperen eller når den nye bilen
                    registreres første gang, avhengig av hva som inntreffer
                    først. Uavhengig av dette begynner garantien å løpe når
                    bilen er blitt utlevert, registrert eller tatt i bruk av en
                    autorisert Audi-forhandler i EØS-området og i Sveits.
                </p>
                <p>
                    Audi AG har fastsatt en vedlikeholdsplan for hver enkelt
                    modell som i detalj beskriver hva som skal utføres ved hver
                    service på de aktuelle tidspunkter. Enhver ytelse etter
                    garantien forutsetter at hver intervallservice er blitt
                    gjennomført etter anvisningene fra Audi AG. Husk –
                    serviceheftet er bilens logg hvor det fremkommer om bilen er
                    vedlikeholdt slik fabrikken foreskriver. Dokumentasjon som
                    viser detaljer om hva som er foretatt vil kunne bli
                    påkrevet. En utført serviceoppfølging vil også kunne ha stor
                    betydning den dagen bilen skal selges!
                </p>
                <p>
                    For en feil eller mangel som kommer inn under denne
                    garantien, må en autorisert Audi serviceforhandler kontaktes
                    for en utbedring.
                </p>
                <p>
                    Garantien begrenser ikke garantitakerens lovfestede
                    rettigheter ihht norsk lov (Lov om kjøp, Lov om
                    Forbrukerkjøp) overfor selger ved mangler, og heller ikke
                    eventuelle krav ihht lov om produktansvar.
                </p>
                <p>
                    Garantien dekker ikke direkte slitasjedeler, dvs. enhver
                    naturlig reduksjon av bilens funksjonalitet eller verdi som
                    følge av bruk, som ikke skyldes mangler i materiale og
                    produksjon.
                </p>
                <p>
                    Garantien gjelder ikke for leveranser fra andre leverandører
                    (påbygg, ettermonterte komponenter i og på bilen) enn Audi
                    AG, Harald A. Møller AS eller selgende forhandler og heller
                    ikke for mangler ved bilen som skyldes slike leveranser. Det
                    samme gjelder for tilbehør som ikke ble montert og/eller
                    levert av nevnte leverandører.
                </p>
                <p>
                    Dersom bilen utleveres eller registreres utenfor EØS-området
                    (som omfatter EU-landene samt Norge, Island og
                    Liechtenstein) og Sveits, kan denne garantien ikke brukes
                    som grunnlag for krav.
                </p>
                <p>
                    Det kan ikke fremsettes krav mot Harald A. Møller AS eller
                    Audi- serviceforhandler basert på denne garantien når
                    mangelen skyldes følgende forhold:
                </p>
                <ul>
                    <li>
                        Bilen er blitt reparert, vedlikeholdt eller på annen
                        måte behandlet ufagmessig av garantitakeren selv eller
                        av tredjepart som ikke er autorisert
                        Audi-serviceforhandler, eller
                    </li>
                    <li>
                        Anvisninger om drift, bruk og vedlikehold av bilen
                        (f.eks. instruksjonsbok) ikke er blitt fulgt, eller
                    </li>
                    <li>
                        Bilen har fått skader som skyldes påvirkning fra andre
                        eller ytre faktorer (f.eks. uhell, hagl, steinsprut,
                        oversvømmelse), eller
                    </li>
                    <li>
                        Det er montert deler i eller på bilen som ikke er
                        godkjent av Audi AG / Harald A. Møller AS for denne
                        bruken, eller bilen er modifisert på en måte som ikke er
                        godkjent av Volkswagen AG / Harald A. Møller AS (f.eks.
                        tuning), eller
                    </li>
                    <li>
                        Bilen er blitt behandlet unormalt eller er blitt brukt
                        på en måte som ikke er forutsatt, f.eks. deltakelse i
                        motorsportskonkurranser eller overlast, eller
                    </li>
                    <li>
                        Garantitakeren ikke har meldt fra om en mangel uten
                        ugrunnet opphold, eller
                    </li>
                    <li>
                        Garantitakeren til tross for oppfordring ikke har gjort
                        det mulig å rette mangelen omgående.
                    </li>
                </ul>
                <Heading2Text>
                    For behandling av garantikrav gjelder følgende bestemmelser
                </Heading2Text>
                <p>
                    Krav på grunnlag av denne garantien kan bare gjøres
                    gjeldende hos autoriserte Audi-serviceforhandler i
                    EØS-området og i Sveits.
                </p>
                <p>
                    Bilens servicehefte skal legges frem og må være fylt ut
                    fullstendig. Med fullstendig menes at foreskrevne servicer
                    skal være foretatt ihht AUDI AG´s krav. Dersom
                    serviceoppfølging ikke er foretatt på et merke- verksted,
                    kan det ved reklamasjonskrav overfor merkeverkstedet kreves,
                    hvor det er saklig grunn, at det fremvises tydelig og
                    fullstendig dokumentasjon for at alle aktuelle
                    servicepunkter er fulgt ihht AUDI AG´s retningslinjer. Som
                    saklig grunn regnes alle tilfeller hvor det kan foreligge en
                    eventuell årsakssammenheng mellom uhell/feil/mangel og
                    utilstrekkelig serviceoppfølging. Ved utbedringer kan AUDI
                    AG eller importøren etter eget skjønn bytte ut eller
                    reparere den delen som har mangel. Deler som skiftes ut i
                    garantisammenheng er å betrakte som AUDI AG / Harald A.
                    Møller AS´s eiendom.
                </p>
                <p>
                    For deler som er blitt montert, lakkert eller reparert i
                    forbindelse med utbedringen, kan garantitakeren gjøre
                    garantikrav gjeldende basert på Audi nybilgaranti i Norge
                    frem til utløpet av garantitiden for bilen. (Eventuell
                    videre reklamasjonsfrist må vurderes i forhold til kundenes
                    rettigheter ihht norsk lov.)
                </p>
                <p>
                    Hvis bilen ikke kan brukes på grunn av en mangel, er
                    garantitakeren forpliktet til å ta kontakt med nærmeste
                    autorisert Audi - serviceforhandler. Denne avgjør om det
                    nødvendige arbeidet skal utføres på stedet eller ved
                    serviceforhandlerens verksted. Dette påvirker ikke mulige
                    krav som garantitakeren måtte ha i henhold til Audi
                    Mobilitetsgaranti.
                </p>

                <Heading2Text>
                    I tillegg til nybilgarantien gjelder følgende garantier for
                    karosseriet av nye biler
                </Heading2Text>
                <p>
                    <b>3 års garanti mot lakkfeil</b>
                </p>
                <p>
                    <b>12 års garanti mot gjennomrusting av karosseriet</b>
                </p>
                <p>
                    Med gjennomrusting menes her at det er oppstått hull i
                    karosseriplatene som har utviklet seg innenfra (fra hulrom)
                    som ikke kan forventes at oppdages tidligere i forbindelse
                    med lakkvedlikehold ihht anbefalinger i bilens
                    instruksjonsbok.
                </p>

                <p>
                    <b>Norgesgaranti 5 år / 100.000km</b>
                </p>
                <p>
                    I direkte tilknytning til nybilgarantien nevnt under I. gir
                    Harald A. Møller AS en Norgesgaranti i Norge i 5 år for
                    biler som er importert av Harald A. Møller AS og registrert
                    etter 01.01.2011 så fremt den totale kjørelengden ikke er
                    over 100 000 km.
                </p>
                <p>
                    Garantiytelsene er de samme som er beskrevet i Audi
                    nybilgaranti punkt I.
                </p>
                <p>
                    Bortsett fra garantiens varighet gjelder alle bestemmelser i
                    Audi nybilgarantien (forutsetninger, kriterier for fravær av
                    mangler, unntak fra garantien, behandling av krav,
                    ikrafttreden og garantitidens begynnelse, gyldighetsområde
                    osv.) som er gjengitt lenger oppe, tilsvarende for denne
                    lakk- og karosserigarantien. Det er samtidig en viktig
                    forutsetning for godkjennelse av lakk og rustskader via
                    garanti at et generelt godt lakkvedlikehold skal ha vært
                    gjennomført, dvs. i tråd med informasjonen som er gitt om
                    dette i bilens instruksjonsbok.
                </p>

                <Heading2Text>Rettigheter i henhold til norsk lov</Heading2Text>
                <p>
                    Ihht Lov om kjøp (LOV-2002-06-21-34) / Lov om forbrukerkjøp
                    (LOV-2007-06-15-36) er lengste frist for reklamasjon inntil
                    2 år ved næringskjøp og inntil 5 år ved forbrukerkjøp.
                </p>
                <p>
                    Kjøperen/Forbrukeren taper sin rett til å gjøre en mangel
                    gjeldende dersom han ikke innen rimelig tid etter at han
                    oppdaget eller burde ha oppdaget den, gir selgeren melding
                    som angir hva slags mangel det gjelder. Fristen for å
                    reklamere kan aldri være kortere enn to måneder fra det
                    tidspunktet kjøper oppdaget mangelen.
                </p>
            </TextContainer>
        </CenteredPageContent>
    );
};

export default WarrantyAudi;

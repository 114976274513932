import { Brand } from 'external-apis/src/types/bilhold';
import { formatBrandName } from 'src/utils/string/formatBrandNames';
import toLowerCaseWithCapitalize from 'src/utils/string/toLowerCaseWithCapitalize';
import lang from 'utils/lang';

const formatCarName = (brand: Brand, name?: string) =>
    `${formatBrandName(brand)} ${
        toLowerCaseWithCapitalize(name) || ''
    }`.trim() ?? lang.unknown;

export default formatCarName;

import { Brand } from 'external-apis/src/types/bilhold';
import OldBrand from 'src/types/Brand';

const BrandNames = {
    [OldBrand.VW]: 'Volkswagen',
    [OldBrand.NYTTE]: 'Volkswagen',
    [OldBrand.SEAT]: 'SEAT',
    [OldBrand.CUPRA]: 'CUPRA',
    [OldBrand.SKODA]: 'Škoda',
    [OldBrand.AUDI]: 'Audi',
    [OldBrand.UNKNOWN]: 'Ukjent',
    [OldBrand.OTHER]: 'Annet',
};

export default (brand?: OldBrand): string => {
    if (!brand) {
        return '';
    }

    return BrandNames[brand] || '';
};

export const formatBrandName = (brand?: Brand) => {
    switch (brand) {
        case 'Audi':
            return 'Audi';
        case 'Seat':
            return 'SEAT';
        case 'Volkswagen':
        case 'VolkswagenCommercialVehicles':
            return 'Volkswagen';
        case 'Skoda':
            return 'Škoda';
        case 'Cupra':
            return 'CUPRA';
        case 'Other':
        default:
            return 'Ukjent';
    }
};

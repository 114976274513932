import { datadogRum } from '@datadog/browser-rum';
import { DetailText, Link } from '@gnist/design-system';
import { DealerViewModel } from 'external-apis/src/types/port';
import { GOOGLE_BASE_URI } from '../lib/constants/googleConstants';
import { DealerListSortOrder } from '../sections/choose-dealer/dealer-picker/DealerPicker';
import { DistanceToDealer } from '../sections/choose-dealer/dealer-picker/DistanceToDealer';
import { formatDealerAddress } from '../sections/choose-dealer/dealer-picker/SingleChoiceDealerList';

interface DealerAddressLinkProps {
    dealer: DealerViewModel;
    sortOrder?: DealerListSortOrder;
}

export const DealerAddressLink: React.FC<DealerAddressLinkProps> = ({
    sortOrder,
    dealer,
}) => {
    if (!(dealer.coordinates?.latitude && dealer.coordinates.longitude)) {
        return <DetailText>{formatDealerAddress(dealer)}</DetailText>;
    }
    const searchParams = new URLSearchParams({
        api: '1',
        query: `${dealer.coordinates?.latitude},${dealer.coordinates?.longitude}`,
    });
    const url = `${GOOGLE_BASE_URI}/maps/search/?${searchParams.toString()}`;
    return (
        <>
            <Link
                target={'_blank'}
                rel={'noreferrer noopener'}
                href={url}
                onClick={() => datadogRum.addAction('clickDealerAddress')}
            >
                {formatDealerAddress(dealer)}
            </Link>
            <DistanceToDealer dealer={dealer} sortOrder={sortOrder} />
        </>
    );
};

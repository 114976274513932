import { InputFieldValidity, useInputHelperText } from '@gnist/design-system';

export const standardServicesHelperTextId = `standardserviceslist-selectedIds-helperText`;

export function useStandardServiceListValidity(
    validity?: InputFieldValidity<{ errorMsgRequired: true }>
) {
    const { inputAriaProps } = useInputHelperText<{
        errorMsgRequired: true;
    }>({
        id: standardServicesHelperTextId,
        validity,
    });

    return {
        inputariaprops: inputAriaProps,
        validity: validity && { type: validity.type },
    };
}

import {
    AdditionalInfoBooking,
    BookingCreateModelQuery,
    BookingType,
    ReservedTimeSlotViewModel,
} from 'external-apis/src/types/port';
import { StandardServicesData } from '../../standard-services/useStandardServices';

type CreateBookingBody = {
    adapterId: string;
    businessOperation: ReturnType<typeof getBusinessOperation>;
    bookingId: string;
    vin: string;
    dealerNumber: string;
    startTime: string | undefined;
    bookedServices: ReturnType<typeof toBookedService>[];
    mileage: string;
    isWaiting: boolean;
    bookingType: BookingType;
};

export function createBookingBody({
    adapterId,
    bookingId,
    vin,
    bookedServices,
    businessOperation,
    mileage,
    dealerNumber,
    startTime,
    isWaiting,
    bookingType,
}: CreateBookingBody): BookingCreateModelQuery {
    return {
        adapterId,
        bookingId,
        vin,
        services: bookedServices,
        mileage: mileage === '' ? null : +mileage,
        businessOperation,
        dealerNumber,
        startTime: startTime,
        customerWaitingAtDealer: isWaiting,
        countryCode: 'NOR',
        type: bookingType,
    };
}

export function toBookedService({
    serviceId,
    comment,
    interviewAnswers,
}: StandardServicesData['selectedServices'][number]) {
    const definedInfoEntries = Object.entries({
        comment,
        interviewAnswers,
    }).filter(([_, v]) => !!v);

    return {
        serviceId,
        additionalInfo: (!definedInfoEntries.length && []) || [
            definedInfoEntries.reduce(
                (obj: NonNullable<AdditionalInfoBooking>[number], [k, v]) => ({
                    ...obj,
                    [k]: v,
                }),
                {}
            ),
        ],
    };
}

if (import.meta.vitest) {
    const { describe, it, expect } = import.meta.vitest;
    const comment = 'A service can only have one comment';
    const interviewAnswers = {
        IsTheProblemProvokable: 'yes',
        IsTheProblemPredictable: 'yes',
        WhereIsTheProblemLocated: 'yes',
        HowOftenIsTheProblemOccuring: 'yes',
        WhenDidTheProblemFirstHappen: 'yes',
    };
    describe('toBookedService', function () {
        it('assign empty additional info', function () {
            const bookedService = toBookedService({
                adapterId: 'adapter-id',
                serviceId: '1',
                name: 'test-service',
                serviceType: 'None',
                bookingType: 'None',
                dealerSpecificInformation: [],
            });
            expect(bookedService['additionalInfo']).toEqual([]);
        });
        it('assign additional info with comment', function () {
            const bookedService = toBookedService({
                adapterId: 'adapter-id',
                serviceId: '1',
                name: 'test-service',
                serviceType: 'None',
                bookingType: 'None',
                comment,
                dealerSpecificInformation: [],
            });
            expect(bookedService['additionalInfo']).toEqual([{ comment }]);
        });
        it('assign additional info with interview questions', function () {
            const bookedService = toBookedService({
                adapterId: 'adapter-id',
                serviceId: '1',
                name: 'test-service',
                serviceType: 'None',
                bookingType: 'None',
                interviewAnswers,
                dealerSpecificInformation: [],
            });
            expect(bookedService['additionalInfo']).toEqual([
                {
                    interviewAnswers,
                },
            ]);
        });
        it('assign additional info with comment and interview questions', function () {
            const bookedService = toBookedService({
                adapterId: 'adapter-id',
                serviceId: '1',
                name: 'test-service',
                serviceType: 'None',
                bookingType: 'None',
                comment,
                interviewAnswers,
                dealerSpecificInformation: [],
            });
            expect(bookedService['additionalInfo']).toEqual([
                {
                    comment,
                    interviewAnswers,
                },
            ]);
        });
    });
}

export function getBusinessOperation(
    reservedTimeslot: ReservedTimeSlotViewModel | undefined,
    selectedDealerNumbner: string,
    selectedServices: StandardServicesData['selectedServices']
) {
    if (reservedTimeslot) {
        return reservedTimeslot.businessOperation;
    }
    const selectedDealerSpecificInfo =
        selectedServices[0].dealerSpecificInformation.find(
            (d) => d.dealerNumber === selectedDealerNumbner
        );
    return selectedDealerSpecificInfo?.businessOperation ?? 'unknown';
}

import { Column, ProgressBar, Row } from '@gnist/design-system';
import React from 'react';
import { TDFCup } from 'src/features/competition/assets/TDFCup';
import { TDFShirt } from 'src/features/competition/assets/TDFShirt';
import { styled } from 'styled-components';

const ProgressBarLine = styled.p`
    font-size: 0.5rem;
    color: var(--moller-color-primary);
    line-height: 0;
`;
const ProgressHeader = styled.div`
    text-align: center;
    padding-right: 16px;
`;
const TextUnderProgress = styled.p<{ value: number; maxValue: number }>`
    font-size: 0.875rem;
    position: relative;
    left: ${(props) => (props.value / props.maxValue) * 100 * 0.92 + '%'};
    text-align: start;
`;
const TextGray = styled.p`
    color: var(--moller-palette-neutral-80);
    font-size: 0.75rem;
`;
const ColumnHeader = styled(Column)`
    width: fit-content;
    align-self: end;
`;

interface Props {
    currentValue: number;
    maxValue: number;
    progressHeader: React.JSX.Element | string;
    shirtColor: string;
    startLabel?: string;
    endLabel?: string;
    textUnderProgress?: string;
}

export const ShirtProgressBar = ({
    currentValue,
    maxValue,
    progressHeader,
    shirtColor,
    startLabel,
    endLabel,
    textUnderProgress,
}: Props) => {
    const isCompleted = currentValue >= maxValue;
    return (
        <Column gap={'xxs'}>
            <ColumnHeader horizontalAlign={'center'} verticalAlign={'end'}>
                <ProgressHeader>{progressHeader}</ProgressHeader>
            </ColumnHeader>
            <Row verticalAlign={'start'}>
                <Column style={{ width: '100%' }}>
                    <Row style={{ marginTop: '14px', marginRight: '8px' }}>
                        <div style={{ width: '100%' }}>
                            <ProgressBar
                                animateOnChange={true}
                                percentage={(currentValue / maxValue) * 100}
                                size={'small'}
                            />
                        </div>
                        <ProgressBarLine aria-hidden="true">
                            {' '}
                            |{' '}
                        </ProgressBarLine>
                    </Row>
                    {!isCompleted && (
                        <TextUnderProgress
                            value={currentValue}
                            maxValue={maxValue}
                        >
                            {Number.isInteger(currentValue)
                                ? currentValue
                                : shirtColor === 'white'
                                ? Math.floor(currentValue)
                                : currentValue.toFixed(1)}{' '}
                            {textUnderProgress}
                        </TextUnderProgress>
                    )}
                    <Row horizontalAlign={'spaceBetween'} verticalAlign={'end'}>
                        <TextGray>{startLabel}</TextGray>
                        <TextGray>{endLabel}</TextGray>
                    </Row>
                </Column>
                <Row verticalAlign={'end'} horizontalAlign={'start'}>
                    <TDFCup width="19" height="20" />
                    <TDFShirt
                        fill={shirtColor}
                        width="25"
                        height="20"
                        isElevationShirt={shirtColor === 'white'}
                    />
                </Row>
            </Row>
        </Column>
    );
};

import { Column, Row } from '@gnist/design-system';
import {
    TextSmallThin,
    TextThin,
} from 'src/features/competition/textComponents/TDFText';
import { useDealers } from 'src/features/user-profile/my-preferred-dealer/utils/useDealers';
import { useNamedQueryParams } from 'src/utils/browser/useNamedQueryParams';

interface Props {
    dealer: string | undefined;
}

export const PrizePicked = ({ dealer }: Props) => {
    const dealerGroup = useNamedQueryParams('dealerGroup')[0] || '';
    const { dealers } = useDealers(undefined, undefined, dealerGroup);
    const skodaDealer = dealers?.filter((dealer) =>
        dealer?.salesCertifications?.includes('Skoda')
    );
    return (
        <Row
            horizontalAlign={'spaceBetween'}
            verticalAlign={'center'}
            style={{ width: '100%' }}
        >
            {dealer === '0' ? (
                <TextThin>Du har valgt å ikke få en premie</TextThin>
            ) : (
                <Column gap={'m'}>
                    <TextSmallThin>
                        Premien kan hentes hos din valgte bilforhandler i
                        tidsrommet 26. august til 15. september. Hent premien
                        din hos:
                    </TextSmallThin>
                    <TextThin style={{ paddingLeft: '1rem' }}>
                        {skodaDealer &&
                            skodaDealer.find(
                                (dealers) => dealers.dealerNumber === dealer
                            )?.name}
                    </TextThin>
                </Column>
            )}
        </Row>
    );
};

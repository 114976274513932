import { datadogLogs } from '@datadog/browser-logs';
import { useMutation } from '@tanstack/react-query';
import { useFetchersContext } from 'external-apis';
import {
    BookingCreateModelQuery,
    BookingViewModel,
} from 'external-apis/src/types/port';
import { useRef, useState } from 'react';
import { FriendlyQueryError } from '../../lib/errors/PortError';
import {
    LanguageContextType,
    useLanguageContext,
} from '../../lib/languages/languageContext';
import { createGUID } from '../../sections/confirm-booking/confirm-booking-edit/createGuid';

const useCreateBookings = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.port.fetcher.path('/bookings').method('post').create();
};

const useFindBookings = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.port.fetcher.path('/bookings').method('get').create();
};
export function useConfirmBooking(
    onSuccessBooking: () => void,
    dealerMail: string | undefined
) {
    const [lc] = useLanguageContext();
    const confirmBooking = useCreateBookings();
    const idempotencyKeyRef = useRef(createGUID());
    const [hasNetworkError, setHasNetworkError] = useState(false);
    const findBookings = useFindBookings();

    return useMutation<
        BookingViewModel | undefined,
        Error,
        BookingCreateModelQuery
    >({
        mutationFn: (body: BookingCreateModelQuery) =>
            confirmBookingMutation(
                body,
                lc,
                confirmBooking,
                {
                    setHasNetworkError,
                    hasNetworkError,
                    findBookings,
                },
                idempotencyKeyRef.current,
                dealerMail
            ),
        onSuccess: () => {
            onSuccessBooking();
        },
    });
}

async function getBookings(findBookings: ReturnType<typeof useFindBookings>) {
    const result = await findBookings({});
    return result.data;
}

export class NetworkError extends Error {
    status?: number;

    constructor(message: string, status?: number) {
        super(message);
        this.status = status;
    }
}

type PartialObject = Partial<Record<string, unknown>>;
function isObject(x: unknown): x is PartialObject {
    return typeof x === 'object';
}

function isNetworkError(x: unknown) {
    return isObject(x) && hasNoHttpStatus(x);
}

function hasNoHttpStatus(e: PartialObject): e is { status: number } {
    return !e.status;
}

interface NetworkErrorHandler {
    setHasNetworkError: (hasNetworkError: boolean) => void;
    hasNetworkError: boolean;
    findBookings: ReturnType<typeof useFindBookings>;
}

async function confirmBookingMutation(
    body: BookingCreateModelQuery,
    lc: LanguageContextType,
    confirmBooking: ReturnType<typeof useCreateBookings>,
    networkErrorHandler: NetworkErrorHandler,
    idempotencyKey: string,
    dealerMail: string | undefined
) {
    try {
        const result = await confirmBooking(body, {
            headers: [['Idempotency-Key', idempotencyKey]],
        });
        return result.data;
    } catch (e) {
        if (e instanceof confirmBooking.Error) {
            const response = e.getActualType();
            datadogLogs.logger.error(
                'Confirm booking mutation failed: ',
                response
            );

            switch (response.status) {
                case 409:
                    if (
                        response.data.errorType === 'TimeslotReservationExpired'
                    ) {
                        throw new FriendlyQueryError(
                            lc.errors.port_timeslots_confirm_timeout,
                            e,
                            response.status
                        );
                    }
                    throw new FriendlyQueryError(
                        lc.errors.default_with_dealer.replace(
                            '{mail}',
                            dealerMail ?? 'kundeservice@moller.no'
                        ),
                        e,
                        response.status
                    );
                case 400:
                    if (networkErrorHandler.hasNetworkError) {
                        datadogLogs.logger.info(
                            '400 on with network error: Fetching upcoming bookings to check if booking was created'
                        );
                        const upComingBookings = await getBookings(
                            networkErrorHandler.findBookings
                        );

                        const createdBooking = upComingBookings.find(
                            (booking) => isSameBooking(booking, body)
                        );
                        if (createdBooking) {
                            datadogLogs.logger.info(
                                '400 on with network error: User had created booking. Send to booking receipt page'
                            );
                            return createdBooking;
                        } else {
                            datadogLogs.logger.info(
                                '400 on with network error: no booking found for user. Showing general error'
                            );
                        }
                    }
                    throw new FriendlyQueryError(
                        lc.errors.default_with_dealer.replace(
                            '{mail}',
                            dealerMail ?? 'kundeservice@moller.no'
                        ),
                        e,
                        response.status
                    );
                default:
                    throw new FriendlyQueryError(
                        lc.errors.default_with_dealer.replace(
                            '{mail}',
                            dealerMail ?? 'kundeservice@moller.no'
                        ),
                        e,
                        response.status
                    );
            }
        }
        if (isNetworkError(e)) {
            if (e instanceof Error) {
                datadogLogs.logger.error(
                    'Throwing retryable network error',
                    {},
                    e
                );
            }
            networkErrorHandler.setHasNetworkError(true);
            throw new NetworkError(
                lc.errors.default_with_dealer.replace(
                    '{mail}',
                    dealerMail ?? 'kundeservice@moller.no'
                ),
                e.status
            );
        }

        throw e;
    }
}

function isSameBooking(
    createdBooking: BookingViewModel,
    requestBody: BookingCreateModelQuery
) {
    return (
        createdBooking.dealer.id === requestBody.dealerNumber &&
        createdBooking.vin === requestBody.vin &&
        createdBooking.startTime === requestBody.startTime &&
        createdBooking.bookedServices?.every((y) =>
            requestBody.services.some((s) => s.serviceId == y.id)
        )
    );
}

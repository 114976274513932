export const PATH_HOME = '/home';
export const PATH_BEGIN = '/begin';
export const PATH_APP = '/app';
export const PATH_LOGIN_CALLBACK_OIDC = '/callback';
export const PATH_MESSAGES = '/messages';
export const PATH_EXPLORE = '/explore';
export const PATH_EXPLORE_SKODA = '/explore-skoda';
export const PATH_MESSAGE_THREAD = '/messages/:threadId';
export const PATH_MESSAGE_THREAD_CHAT_NEW_MESSAGE =
    '/messages/messages-contact/new/:appointmentId';
export const PATH_MESSAGE_THREAD_CHAT =
    'messages/messages-contact/thread/:threadId';
export const PATH_PRIVACY = '/privacy';
export const PATH_COOKIES = '/cookies';
export const PATH_TERMS_OF_SERVICE = '/terms-of-service';
export const PATH_SERVICES = '/services';
export const DEV_TOOLS = '/dev-tools';
export const APP_REVIEW_LOGIN = '/app-review-login';

// Profile
export const PATH_MY_PROFILE = '/user-settings';
export const PATH_PROFILE_OVERVIEW = '/profile-overview';
export const PATH_DELETE_ACCOUNT = '/delete-account';
export const PATH_CREATE_NEW_USER = '/create-new-user';
export const PATH_MY_PREFERRED_DEALER = '/my-preferred-dealer';

// Booking
export const PATH_BOOKING_ROOT = '/booking';
export const PATH_BOOKING_WITH_PATH_PARAMS =
    `${PATH_BOOKING_ROOT}/vin?/:vin?/booking?/:booking?/*` as const;
export const PATH_BOOKING_CONFIRMATION_ROOT = '/booking-confirmation';
export const PATH_BOOKING_CONFIRMATION = `${PATH_BOOKING_CONFIRMATION_ROOT}/id/:id/*`;
export const PATH_BOOKING_FAST_TRACK = '/fasttrack/:fastTrackType';
export const PATH_GLASS_DAMAGE = '/glass-damage';
export const PATH_WARRANTY = '/warranty';
export const PATH_ADD_CAR = '/add-car';
export const PATH_CAMPAIGN = '/campaign';

// Damage appraiser
export const PATH_DAMAGE = '/damage';
export const PATH_DAMAGE_ID = '/damage/id/:id';
export const PATH_DAMAGE_APPRAISER = '/damage-appraiser';
export const PATH_DAMAGE_APPRAISERS = '/damage-appraisers';
export const PATH_DAMAGE_APPRAISER_VALUATIONS = '/damage-appraiser-valuations';
export const PATH_DAMAGE_APPRAISER_VALUATION = '/damage-appraiser-valuation';
export const PATH_DAMAGE_APPRAISER_STATS = '/damage-appraiser-stats';
export const PATH_INTRODUCTION_VIDEOS = '/introduction-videos';

// My car
export const PATH_MY_CAR = '/my-car/vin/:vin';
export const PATH_SERVICE_DEAL = '/service-deal';
export const PATH_SA_DEAL = `sa`;
export const PATH_SV_DEAL = `sv`;

// Product
export const PATH_PRODUCT = '/product';
export const PATH_PRODUCT_WITH_ID = `${PATH_PRODUCT}/:carId/:articleNumber`;
export const PATH_CUSTOM_PRODUCT = `${PATH_PRODUCT}/:carId/customer-request`;
export const PATH_PRODUCT_CONFIRMATION = `${PATH_PRODUCT}/:carId/:articleNumber/confirmation`;

// My ordered car
export const PATH_MY_ORDERED_CAR = '/my-ordered-car/contract';
export const PATH_MY_ORDERED_CAR_WITH_ID = `${PATH_MY_ORDERED_CAR}/:contractId`;

// TDF Competition
export const PATH_COMPETITION_ABOUT = '/about-competition';
export const PATH_COMPETITION = '/competition';
export const PATH_COMPETITION_TERMS = '/terms-competition';
export const PATH_COMPETITION_PRIVACY_STATEMENT =
    '/privacy-statement-competition';

// Various
export const PATH_MOBILITY_GUARANTEE = '/mobility-guarantee';
export const PATH_MOBILITY_GUARANTEE_MORE_INFO =
    '/mobility-guarantee-more-info';

export const PATH_MY_APPOINTMENTS = '/my-appointments';
export const PATH_DETAILED_APPOINTMENT = '/appointment';

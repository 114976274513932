import { formatDealerName } from 'booking/lib/util/datadogFormatDealerName';
import { formatBrandName } from 'src/utils/string/formatBrandNames';
import formatLicensePlate from 'src/utils/string/formatLicensePlate';
import { formatMileage } from 'src/utils/string/formatMilage';
import { formatDate, getNumberOfRemainingDays } from 'src/utils/time/timeUtils';
import { Variables } from '../components/RichText';
import { getIfExist, getIfExistFormat } from './getIfExist';

export const getVariableText = (key: string, variables: Variables) => {
    switch (key) {
        case 'carBrand':
            return getIfExistFormat(variables.car?.brand, formatBrandName);
        case 'licensePlate':
            return getIfExistFormat(
                variables.car?.licensePlate,
                formatLicensePlate
            );
        case 'carName':
            return getIfExist(variables.car?.name);
        case 'registrationDate':
            return getIfExist(variables.car?.registrationDate);
        case 'euControlDeadline':
            return getIfExistFormat(variables.nextEuControlDate, formatDate);
        case 'euControlRemainingDays':
            if (!variables.nextEuControlDate) return '';
            return getIfExistFormat(variables.nextEuControlDate, (value) => {
                const remainingDays = getNumberOfRemainingDays(value);
                return remainingDays === 1 ? '1 dag' : `${remainingDays} dager`;
            });
        case 'recallCode':
            return getIfExist(variables.recallCode);
        case 'preferredDealerName':
            return getIfExistFormat(
                variables.preferredDealer?.preferredDealerName,
                formatDealerName
            );
        case 'carMileage':
            return getIfExistFormat(`${variables.car?.mileage}`, formatMileage);
        default:
            console.warn(
                `Could not find information variable ${key} from sanity data.`
            );
            return `**${key}**`;
    }
};

import { DetailText, TextContainer } from '@gnist/design-system';
import { useState } from 'react';
import { styled } from 'styled-components';
import { useLanguageContext } from '../../../lib/languages/languageContext';

const NpsScaleContainer = styled.div`
    width: 400px;
    max-width: 100%;
    margin: var(--moller-spacing-xxs) auto;
`;

const NpsValueButton = styled.button<{ selected: boolean }>`
    background: ${({ selected }) =>
        selected
            ? 'var(--moller-color-primary)'
            : 'var(--moller-color-secondary)'};
    color: ${({ selected }) =>
        selected
            ? 'var(--moller-color-on-primary)'
            : 'var(--moller-color-on-secondary)'};
    width: 28px;
    height: 28px;
    cursor: pointer;
    border: none;
    border-radius: 50%;
    transition: 0.15s ease all;

    &:hover {
        div {
            transform: scale(1.25);
        }
    }
`;

const FlexContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const NpsScale = ({
    submitScore,
    score,
}: {
    submitScore: (value: number) => void;
    score: number | null;
}) => {
    const [lc] = useLanguageContext();
    const scoreValues = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    const [value, setValue] = useState<number | null>(null);

    return (
        <NpsScaleContainer>
            <FlexContainer>
                {scoreValues.map((i) => (
                    <NpsValueButton
                        selected={value !== null && value >= i}
                        key={i}
                        onMouseEnter={() => setValue(i)}
                        onClick={() => submitScore(i)}
                        disabled={!!score}
                    >
                        <TextContainer>{i}</TextContainer>
                    </NpsValueButton>
                ))}
            </FlexContainer>
            <FlexContainer>
                <DetailText>
                    {lc.confirmBooking.net_promoter_score.not_likely}
                </DetailText>
                <DetailText>
                    {lc.confirmBooking.net_promoter_score.very_likely}
                </DetailText>
            </FlexContainer>
        </NpsScaleContainer>
    );
};

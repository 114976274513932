import {
    breakpointDesktop,
    breakpointMobile,
    Column,
    Icon,
    Row,
    TextContainer,
} from '@gnist/design-system';
import { styled } from 'styled-components';
import reviews from '../components/app-reviews.json';

interface Review {
    stars: number;
    source: string;
    text: string;
}

const ReviewBox = styled(Column)`
    height: fit-content;
    padding: var(--moller-spacing-xl);
    border-radius: var(--moller-radius-card);
    background-color: var(--moller-color-surface-variant);
    gap: var(--moller-spacing-xxs);
    @media screen and (max-width: ${breakpointMobile}) {
        padding: var(--moller-spacing-s);
    }
    @media screen and (min-width: ${breakpointDesktop}) {
        max-width: 500px;
    }
`;

const ReviewText = styled(TextContainer)`
    p:last-child {
        margin-bottom: 0px;
    }
`;

const Review = ({ stars, source, text }: Review) => {
    const StarArray = Array(stars).fill(1);
    return (
        <ReviewBox>
            <Row>
                {StarArray.map((x: number, i: number) => (
                    <Icon icon="star" key={x + i} />
                ))}
            </Row>
            <ReviewText>
                <p>
                    <b>{stars} stjerner</b>
                    <br />
                    {source}
                </p>
                <p>«{text}»</p>
            </ReviewText>
        </ReviewBox>
    );
};

const ReviewRow = styled.section`
    display: flex;
    max-width: 1700px;
    width: 100%;
    justify-content: space-between;
    flex-wrap: nowrap;
    gap: var(--moller-spacing-m);
    flex-direction: column;
    @media screen and (min-width: ${breakpointDesktop}) {
        flex-direction: row;
    }
    padding: var(--moller-spacing-s) 10vw;
    box-sizing: border-box;
    @media screen and (min-width: ${breakpointMobile}) {
        padding: calc(2 * var(--moller-spacing-xxl)) 10vw;
    }
    @media screen and (min-width: 1700px) {
        padding-left: 150px;
        padding-right: 150px;
    }
`;

export const AppReviews = () => {
    return (
        <ReviewRow>
            {Object.values(reviews).map((review) => (
                <Review {...review} key={review.key} />
            ))}
        </ReviewRow>
    );
};

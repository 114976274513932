import {
    ActionCard,
    Column,
    Icon,
    Row,
    Heading2Text,
    breakpointTablet,
} from '@gnist/design-system';
import { useNavigate } from 'react-router-dom';
import { PATH_COMPETITION } from 'src/routing/routes';
import { styled } from 'styled-components';
import poster from '../../home/current-actions/competition/SkodaPosterCrop.jpg';

const ImageCardLink = styled(ActionCard)`
    max-width: 100%;
    padding: 0;
    padding-bottom: var(--moller-spacing-xs);
`;

const ImageFill = styled.img`
    border-radius: var(--moller-spacing-xs) var(--moller-spacing-xs) 0 0;
    object-fit: cover;
    width: 100%;
    position: relative;
`;

const StyledSubtitle = styled(Heading2Text)`
    font-family: Moller;
    font-size: 1rem;
    font-weight: bold;
`;

const Desc = styled.p`
    font-family: Moller;
    margin-top: var(--moller-spacing-base);
    font-size: var(--moller-type-large-detail-font-size);
    line-height: var(--moller-type-large-detail-line-height);
    @media screen and (min-width: ${breakpointTablet}) {
        font-size: var(--moller-type-large-detail-font-size);
        margin-bottom: var(--moller-spacing-s);
    }
`;

const CardActionText = styled.p`
    font-size: 1rem;
    font-weight: 500;
    margin: 0;
    font-family: Moller;
`;

const ColumnAddPadding = styled(Column)`
    padding: 0 var(--moller-spacing-l);
`;

export const SkodaCompetitionCardBranded = () => {
    const navigate = useNavigate();
    return (
        <div>
            <ImageCardLink
                onClick={() => {
                    navigate(PATH_COMPETITION);
                }}
            >
                <Column gap={'s'}>
                    <ImageFill src={poster} />
                    <ColumnAddPadding gap={'xs'}>
                        <div>
                            <StyledSubtitle>Takk for i år!</StyledSubtitle>
                            <Desc>
                                Tour de Škoda er over for denne gang, og
                                vinneren av hovedpremien ble Tore, som valgte
                                taktelt fra Thule som premie. Vi gratulerer!
                                Alle som har vunnet trøyepremier må huske å
                                hente premiene mellom 26. august og 15.
                                september.
                            </Desc>
                        </div>
                        <Row
                            gap={'xs'}
                            horizontalAlign="end"
                            verticalAlign="center"
                        >
                            <CardActionText>Til konkurransen </CardActionText>
                            <Icon icon={'arrow_forward'} />
                        </Row>
                    </ColumnAddPadding>
                </Column>
            </ImageCardLink>
        </div>
    );
};

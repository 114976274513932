import { Column, IconButton, Row } from '@gnist/design-system';
import { TDFCup } from 'src/features/competition/assets/TDFCup';
import { TDFShirt } from 'src/features/competition/assets/TDFShirt';
import {
    TDFTextLarge,
    TDSTextSmall,
} from 'src/features/competition/textComponents/TDFText';
import { styled } from 'styled-components';

const ModalWrapper = styled(Column)`
    inset: 0;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    z-index: 1000;
    isolation: isolate;
    position: fixed;
`;
const GrayBackground = styled.div`
    z-index: -1;
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
`;
const DivWrapper = styled.div`
    width: 70%;
    color: black;
    height: fit-content;
    border-radius: 1rem;
    max-height: 656px;
    max-width: 600px;
    box-sizing: border-box;
    overflow: auto;
    display: flex;
    gap: var(--moller-spacing-m);
    position: fixed;
    flex-direction: column;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: var(--moller-spacing-l);
    z-index: 1100;
    background-color: #a6a6a6;
`;

const ModalDiv = styled.div`
    display: block;
    unicode-bidi: isolate;
`;

const TextWrapper = styled(Column)`
    text-align: left;
`;
interface Props {
    isOpen: boolean;
    toggle: () => void;
    shirt: string;
    text: string;
    shirtColor: string;
}

export const TDFModal = ({
    isOpen,
    toggle,
    shirt,
    text,
    shirtColor,
}: Props) => {
    return (
        <>
            {isOpen && (
                <ModalWrapper onClick={toggle}>
                    <GrayBackground />
                    <DivWrapper>
                        <Row
                            verticalAlign="center"
                            horizontalAlign="spaceBetween"
                        >
                            <TDFTextLarge>
                                Om {shirt.toLocaleLowerCase()}
                            </TDFTextLarge>
                            <IconButton icon={'close'} label={'lukk'} />
                        </Row>
                        <Row horizontalAlign="center" verticalAlign="center">
                            <TDFShirt
                                fill={shirtColor}
                                width={'36'}
                                height={'29'}
                                isElevationShirt={shirtColor === 'white'}
                            />
                            <TDFCup width={'25'} height={'29'} />
                        </Row>
                        <TextWrapper gap={'s'}>
                            <TDSTextSmall>{text}</TDSTextSmall>
                            <TDSTextSmall>
                                De første 250 som oppnår{' '}
                                {shirt.toLocaleLowerCase()} får premie!
                            </TDSTextSmall>
                        </TextWrapper>
                    </DivWrapper>
                    <ModalDiv />
                </ModalWrapper>
            )}
        </>
    );
};

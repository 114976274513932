import { FormHelpers } from '@gnist/design-system/utilities/forms/formHelpers.js';
import { StdServicesForm } from '../useSectionForm';

export function similarInfo(x: IncludesInfoId, y: IncludesInfoId) {
    return x.serviceId === y.serviceId && x.adapterId === y.adapterId;
}

export function existingInfo(x: IncludesInfoId) {
    return (y: IncludesInfoId) => similarInfo(x, y);
}

export type Info = StdServicesForm['additionalInfo'][number];
export type InputProps = FormHelpers<StdServicesForm>['inputProps'];
export type SetValue = FormHelpers<StdServicesForm>['setValue'];
export type IncludesInfoId = {
    adapterId: string;
    serviceId: string;
    [key: string]: unknown;
};

import { breakpointDesktop } from '@gnist/design-system';
import { breakpointTablet } from '@gnist/design-system/utilities/layout/breakpoints.js';
import {
    PATH_COMPETITION,
    PATH_COMPETITION_ABOUT,
    PATH_COMPETITION_PRIVACY_STATEMENT,
    PATH_COMPETITION_TERMS,
} from 'src/routing/routes';
import { themeOverride } from 'src/utils/dev-tools/devToolsUtils';
import useFeatureToggle from 'src/utils/dev-tools/useFeatureToggle';
import useCurrentPage from 'src/utils/useCurrentPage';
import { styled } from 'styled-components';
import { ChildThemeProvider } from 'theming';
import posterTerms from '../../begin/images/SkodaPosterTDT.jpg';
import { useShowCompetitionForBetaUsers } from '../hooks/useShowCompetitionForBetaUsers';
import posterAbout from '../images/SkodaPosterAbout.jpg';
import posterCompetition from '../images/SkodaPosterCompetition.jpg';
import { AuthProvider } from '../providers/AuthProvider';
import { Footer } from './Footer';

interface CompetitionWrapperProps {
    children: JSX.Element;
}

const ImageWrapper = styled.div`
    width: 100%;
    overflow: visible;
    @media only screen and (min-width: ${breakpointTablet}) {
        height: 300px;
    }
`;
const ImageCover = styled.img`
    height: 100%;
    width: 100%;
    object-fit: cover;
`;

const StyledDiv = styled.div`
    max-width: 800px;
    margin: 0 auto;
    padding: var(--moller-spacing-l);

    @media screen and (min-width: ${breakpointDesktop}) {
        padding: 0 var(--moller-spacing-l);
    }
`;

export function CompetitionPageWrapper({ children }: CompetitionWrapperProps) {
    const currentPage = useCurrentPage();

    const showCompetitionPage = useFeatureToggle('ShowCompetitionCard');
    const showForBetaUsers = useShowCompetitionForBetaUsers();

    if (!showCompetitionPage && !showForBetaUsers) {
        return <></>;
    }

    return (
        <ChildThemeProvider theme={'skoda'} overrideTheme={themeOverride}>
            <AuthProvider>
                <ImageWrapper>
                    {currentPage.url.includes(PATH_COMPETITION) && (
                        <ImageCover src={posterCompetition} />
                    )}
                    {currentPage.url.includes(PATH_COMPETITION_ABOUT) && (
                        <ImageCover src={posterAbout} />
                    )}
                    {currentPage.url.includes(PATH_COMPETITION_TERMS) && (
                        <ImageCover src={posterTerms} />
                    )}
                    {currentPage.url.includes(
                        PATH_COMPETITION_PRIVACY_STATEMENT
                    ) && <ImageCover src={posterTerms} />}
                </ImageWrapper>
                <StyledDiv>
                    {children}
                    <Footer />
                </StyledDiv>
            </AuthProvider>
        </ChildThemeProvider>
    );
}

import { Column } from '@gnist/design-system';
import { styled } from 'styled-components';

const StyledHeadline = styled.div`
    font-weight: var(--moller-type-large-heading2text-font-weight);
    font-size: 16px;
`;

const StyledContent = styled(Column)`
    padding-inline-start: var(--moller-spacing-none);
`;

const Cursive = styled.div`
    font-style: italic;
`;

const StyledUl = styled.ul`
    padding-inline-start: var(--moller-spacing-m);
    margin-top: var(--moller-spacing-s);
`;

const StyledLi = styled.li`
    margin-bottom: var(--moller-spacing-s);
`;

const StyledLink = styled.a`
    color: var(--moller-color-interaction);
`;

export const References = () => {
    return (
        <>
            <StyledContent>
                <StyledHeadline>Merk</StyledHeadline>
                <StyledUl>
                    <StyledLi>
                        <p>
                            Alt utslipp er ført som CO2-ekvivalenter. Det vil si
                            at alt av klimagassutslipp som inngår i det enkelte
                            tilfellet er omregnet til tilsvarende utslipp av
                            karbondioksid.
                        </p>
                    </StyledLi>
                    <StyledLi>
                        <p>
                            Strømforbruk er basert på variert kjøring med din
                            elbil og kan avvike fra tester.
                        </p>
                    </StyledLi>
                </StyledUl>
            </StyledContent>
            <StyledContent>
                <StyledHeadline>Kilder</StyledHeadline>
                <StyledUl>
                    <StyledLi>
                        <Cursive>
                            Beregnet CO2-faktor for strømforbruk i Norge
                        </Cursive>
                        <StyledLink href="https://www.nve.no/energi/energisystem/kraftproduksjon/hvor-kommer-stroemmen-fra/">
                            Klimadeklarasjon av Norges vassdrags- og
                            energidirektorat (2022)
                        </StyledLink>
                    </StyledLi>
                    <StyledLi>
                        <Cursive>Bilens strømforbruk</Cursive>
                        <p>
                            Dataen er hentet fra Statens Vegvesen, basert på
                            bilens tekniske data. Om vi ikke finner data
                            tilknyttet din bil viser vi et generalisert tall
                            knyttet til din bilmodell.
                        </p>
                    </StyledLi>
                    <StyledLi>
                        <Cursive>Utslipp fra mellomstor dieselbil</Cursive>
                        <StyledLink href="https://klimatsmartsemester.se/sites/default/files/metodrapport-klimatsmart-semester-version2-1.pdf">
                            Klimasmart semester - Chalmers (2021)
                        </StyledLink>
                    </StyledLi>
                    <StyledLi>
                        <Cursive>Fly Oslo - Berlin</Cursive>
                        <StyledLink href="https://klimatsmartsemester.se/sites/default/files/metodrapport-klimatsmart-semester-version2-1.pdf">
                            Klimasmart semester - Chalmers (2021)
                        </StyledLink>
                    </StyledLi>
                </StyledUl>
            </StyledContent>
        </>
    );
};

import { useLocalFormState } from '@gnist/design-system';
import { useEffect, useRef, useState } from 'react';
import { useGetDealers } from '../../_api/http/dealers';
import { DDRumPrivacySetting } from '../../components/DDRumPrivacySetting';
import { FlexRow } from '../../components/FlexRow';
import {
    BilholdViewHidden,
    BilholdViewLayout,
} from '../../components/bilhold/BilholdView';
import { BilholdViewTitle } from '../../components/bilhold/BilholdViewTitle';
import { getTransportServicesValidator } from '../../lib/form/validators';
import { useLanguageContext } from '../../lib/languages/languageContext';
import { useScrollRefIntoView } from '../../lib/util/scroll';
import { ChooseDealerResult } from '../choose-dealer/ChooseDealerSection';
import { ChooseTimeSlotResult } from '../choose-timeslot/ChooseTimeslotSection';
import { ChooseVehicleResult } from '../choose-vehicle/ChooseVehicleSection';
import { SectionState, checkDoneDependencies } from '../sectionUtils';
import { StandardServicesResult } from '../standard-services/StandardServicesSection';
import { StandardServicesData } from '../standard-services/useStandardServices';
import { TransportServicesDone } from './TransportServicesDone';
import {
    TransportServicesEdit,
    TrspServicesForm,
} from './TransportServicesEdit';
import { TransportServicesLoader } from './TransportServicesLoader';

export interface TransportServicesData {
    selectedServices: StandardServicesData['selectedServices'];
    isWaiting: boolean;
}

export type TransportServicesState = SectionState<TransportServicesData>;
export type TransportServicesDependencies = {
    chooseVehicle: ChooseVehicleResult;
    chooseTimeSlot: ChooseTimeSlotResult;
    chooseStandardServices: StandardServicesResult;
    chooseDealer: ChooseDealerResult;
};

export function useTransportServices({
    initialState,
    dependencies,
    isFastTrack,
}: {
    initialState: TransportServicesState;
    dependencies: TransportServicesDependencies;
    isFastTrack: boolean;
}) {
    const [state, setState] = useState(initialState);
    const depsAreDone = checkDoneDependencies(dependencies);

    useEffect(() => {
        setState((s) =>
            depsAreDone
                ? s.viewMode === 'hidden'
                    ? { viewMode: 'edit' }
                    : s
                : { viewMode: 'hidden' }
        );
    }, [depsAreDone]);

    if (depsAreDone && state.viewMode === 'done') {
        const done = {
            result: {
                viewMode: state.viewMode,
                data: state.data,
            },
            props: {
                viewMode: state.viewMode,
                dependencies,
                setSectionState: setState,
                data: state.data,
            },
        };
        return done;
    }

    if (depsAreDone && state.viewMode === 'edit' && isFastTrack) {
        const edit = {
            result: {
                viewMode: 'done' as const,
                data: {
                    selectedServices: [],
                    isWaiting: false,
                },
            },
            props: {
                viewMode: 'done' as const,
                dependencies,
                data: {
                    selectedServices: [],
                    isWaiting: false,
                },
                setSectionState: setState,
            },
        };
        return edit;
    }

    if (depsAreDone && state.viewMode === 'edit') {
        const edit = {
            result: {
                viewMode: state.viewMode,
            },
            props: {
                viewMode: state.viewMode,
                dependencies,
                setSectionState: setState,
            },
        };
        return edit;
    }

    return {
        result: {
            viewMode: 'hidden' as const,
        },
        props: {
            viewMode: 'hidden' as const,
            setSectionState: setState,
        },
    };
}

type TransportServicesProps = ReturnType<
    typeof useTransportServices
>['props'] & { isFastTrack: boolean };

export type TransportServicesResult = ReturnType<
    typeof useTransportServices
>['result'];

function TransportServicesSection(props: TransportServicesProps) {
    const [lc] = useLanguageContext();

    const servicesForm = useLocalFormState<TrspServicesForm>(
        {
            selectedIds: [],
        },
        {
            selectedIds: getTransportServicesValidator(
                lc.errors.waiting_service_validation
            ),
        }
    );

    const dealerResponse = useGetDealers();
    if (props.viewMode === 'hidden') {
        return <TransportServicesHidden />;
    }

    if (dealerResponse.isLoading) {
        return <TransportServicesLoader />;
    }

    if (props.viewMode === 'edit') {
        return (
            <TransportServicesEdit
                regNr={props.dependencies.chooseVehicle.data.registrationNumber}
                reservedTimeSlot={
                    props.dependencies.chooseTimeSlot.data.reservedTimeSlot
                }
                selectedDealer={props.dependencies.chooseDealer.data}
                setSectionState={props.setSectionState}
                servicesForm={servicesForm}
            />
        );
    }

    return (
        <TransportServicesDone
            dealers={dealerResponse?.data}
            data={props.data}
            regNr={props.dependencies.chooseVehicle.data.registrationNumber}
            selectedDealer={props.dependencies.chooseDealer.data}
        />
    );
}

export function TransportServices({
    isFastTrack,
    setSectionState,
    ...props
}: TransportServicesProps) {
    const [lc] = useLanguageContext();

    const sectionRef = useRef(null);
    useScrollRefIntoView(props.viewMode === 'edit', sectionRef);

    if (isFastTrack) {
        return null;
    }

    return (
        <DDRumPrivacySetting privacyLevel="allow">
            <BilholdViewLayout>
                <FlexRow ref={sectionRef}>
                    <BilholdViewTitle
                        title={lc.transportServices.title}
                        isEditable={props.viewMode === 'done'}
                        onClick={() =>
                            setSectionState({
                                viewMode: 'edit',
                            })
                        }
                    />
                </FlexRow>
                <TransportServicesSection
                    setSectionState={setSectionState}
                    isFastTrack={isFastTrack}
                    {...props}
                />
            </BilholdViewLayout>
        </DDRumPrivacySetting>
    );
}

function TransportServicesHidden() {
    const [lc] = useLanguageContext();

    return <BilholdViewHidden desc={lc.transportServices.description} />;
}

import { ServiceViewModel } from 'external-apis/src/types/port';
import { LanguageContextType } from '../../../../lib/languages/languageContext';
import { useStandardServicesForm } from '../../useSectionForm';
import { getAdditionalInfoSetter } from '../../additionalInfo/AdditionalInfoFormState';

export type Extent = 'large' | 'small';
export function setAdditionalGlassDamageInfo({
    serviceForm,
    service,
    lc,
}: {
    serviceForm: ReturnType<typeof useStandardServicesForm>;
    service: ServiceViewModel;
    lc: LanguageContextType;
}) {
    const incidentDate = serviceForm.inputProps(
        'glassDamageIncidentDate'
    ).value;
    const incidentLocation = serviceForm.inputProps(
        'glassDamageIncidentLocation'
    ).value;
    const setInfo = serviceForm.inputProps('additionalInfo').setValue;
    const currentInfo = serviceForm.inputProps('additionalInfo').value;
    const isNearEdge = serviceForm.inputProps('glassDamageNearEdge').value;
    const damageExtent = serviceForm.inputProps('glassDamageExtent').value;
    const setComment = getAdditionalInfoSetter({
        service: {
            adapterId: service.adapterId,
            serviceId: service.id,
        },
        field: 'comment',
        selectedValues: currentInfo,
        setValues: setInfo,
    });

    const comment = `${lc.standardServices.glass_damage_title}
		${glassDamageInfoText(damageExtent, isNearEdge, lc)}
		${lc.standardServices.glass_damage_when}: ${incidentDate}
		${lc.standardServices.glass_damage_where}: ${incidentLocation}`.replace(
        /\t/g,
        ''
    );
    setComment(comment);
}

function glassDamageInfoText(
    extent: Extent,
    isNearEdge: boolean,
    lc: LanguageContextType
) {
    if (extent === 'large' || isNearEdge) {
        return lc.standardServices.glass_damage_large;
    }
    return lc.standardServices.glass_damage_small;
}

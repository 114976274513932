import { IconButton } from '@gnist/design-system';
import { useNavigate } from 'react-router-dom';
import lang from 'src/utils/lang';
import { styled } from 'styled-components';

const StyledContainer = styled.div`
    margin-bottom: 8px;
`;

const BackButton = () => {
    const navigate = useNavigate();

    return (
        <StyledContainer>
            <IconButton
                icon="arrow_backward"
                label={lang.back}
                showLabel="right"
                onClick={() => navigate(-1)}
            />
        </StyledContainer>
    );
};

export default BackButton;

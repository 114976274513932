import { ExistingCarRelation } from 'external-apis/src/types/bilhold';

export default (models: string[], car: ExistingCarRelation) => {
    return models.some((model) => {
        const substring = model.split(' ');
        return substring.every((s) =>
            car?.name?.toLowerCase()?.includes(s.toLowerCase())
        );
    });
};

import { datadogLogs } from '@datadog/browser-logs';
import { ServiceViewModel } from 'external-apis/src/types/port';
import { useFlag } from 'feature-toggle';
import { difference } from 'lodash';
import { useGetRecalls } from '../../_api/http/recalls';
import { freeService, recallService } from '../testData';

const recallCodes = ['48R7', '48R8', '48S2', '93V2', '93U9', '454R'];

export function useRecall(vin: string, services: ServiceViewModel[]) {
    const recalls = useGetRecalls(vin);

    const showRecall = useFlag('show-recall');

    if (recalls.isPending) {
        return {
            isLoading: recalls.isPending,
            services: undefined,
        };
    }

    if (!showRecall || recalls.isError) {
        if (recalls.isError) {
            datadogLogs.logger.error('Error when fetching recalls');
        }
        return {
            isLoading: false,
            services: services.filter(
                (x) => !recallCodes.some((y) => x.materialNumber === y)
            ),
            showInfoBox: false,
        };
    }

    const servicesToBeFiltered = difference(
        recallCodes,
        recalls.data.map((x) => x.recallCode)
    );

    return {
        isLoading: false,
        services: services
            .filter(
                (x) => !servicesToBeFiltered.some((y) => x.materialNumber === y)
            )
            .sort(recallFirst),
        showInfoBox: shouldShowRecallInfo(recalls.data, services),
    };
}

function shouldShowRecallInfo(
    recalls: NonNullable<ReturnType<typeof useGetRecalls>['data']>,
    services: ServiceViewModel[]
) {
    if (recalls.length < 1) return false;
    const materialNumbers = services.map((x) => x.materialNumber);
    return recalls.every((r) => !materialNumbers.includes(r.recallCode));
}

if (import.meta.vitest) {
    const { describe, expect, it } = import.meta.vitest;
    describe('shouldShowRecallInfo', function () {
        it('Active recall with matching service - false', function () {
            const actual = shouldShowRecallInfo(
                [
                    {
                        vin: 'x',
                        registrationNumber: 'x',
                        recallCode: 'P335',
                    },
                ],
                [recallService]
            );
            expect(actual).toBe(false);
        });
        it('Active recall but no matching a service - true', function () {
            const actual = shouldShowRecallInfo(
                [
                    {
                        vin: 'x',
                        registrationNumber: 'x',
                        recallCode: 'P335',
                    },
                ],
                [freeService]
            );
            expect(actual).toBe(true);
        });
        it('Inactive recall - false', function () {
            const actual = shouldShowRecallInfo([], [recallService]);
            expect(actual).toBe(false);
        });
    });
}

function recallFirst(a: ServiceViewModel, b: ServiceViewModel) {
    const includesRecall = (code: string) => recallCodes.includes(code);
    if (!(a.materialNumber && b.materialNumber)) return 0;
    if (includesRecall(a.materialNumber) && includesRecall(b.materialNumber))
        return 0;
    if (includesRecall(a.materialNumber)) return 1;
    return -1;
}

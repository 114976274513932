import { Accordion, TextContainer } from '@gnist/design-system';
import { styled } from 'styled-components';

const StyledTextContainer = styled(TextContainer)`
    padding-top: 0 !important;

    > *:first-child {
        margin-top: 0 !important;
    }
`;

export const TireAccordion = () => {
    return (
        <div>
            <Accordion head="Det forlenger dekkenes levetid" withDivider>
                <StyledTextContainer>
                    <p>
                        Hos oss i Møller Bil skifter vi hjulene, og vasker både
                        dekk og felger rene for salt og søle. Godt vedlikehold
                        av dekk gjør at de eldes senere. Rene dekk forlenger
                        dekkenes levetid, og sørger for bedre gripeevne og
                        bremselengde på veien. Dekkene lagres under ideelle
                        forhold; mørkt og tørt.
                    </p>
                </StyledTextContainer>
            </Accordion>
            <Accordion head="Det er plassbesparende" withDivider>
                <StyledTextContainer>
                    <p>
                        Dekk som ikke er i bruk tar mye plass. Bor du i en
                        leilighet har du sikkert andre ting du ønsker å
                        oppbevare i boden enn dekk. På dekkhotellene oppbevares
                        både dekk og felger som ikke er i bruk - sommerdekk om
                        vinteren og vinterdekk om sommeren. Dekkene ligger trygt
                        oppbevart innendørs og dekkes av vår forsikring.
                    </p>
                </StyledTextContainer>
            </Accordion>
            <Accordion head="Hjulskift er inkludert" withDivider>
                <StyledTextContainer>
                    <p>
                        Som kunde ved et av våre dekkhotell er dekkskift
                        inkludert. Du slipper jobben med å bytte dekk - vi gjør
                        det for deg. I tillegg vasker og spyler vi vekk søle,
                        smuss og salt. Vi hjelper også med anbefalinger til
                        hvilke dekk som passer best til din bil, både når det
                        gjelder kjøreforhold og sikkerhet.
                    </p>
                </StyledTextContainer>
            </Accordion>
            <Accordion head="Dekkene er i profesjonelle hender" withDivider>
                <StyledTextContainer>
                    <p>
                        Vi utfører en grundig inspeksjon av dekkene dine, og
                        undersøker både mønsterdybde og dekkenes holdbarhetstid,
                        samt sjekker lufttrykk. Dekkene blir montert og
                        demontert av profesjonelle fagfolk, slik at du alltid
                        kan føle deg trygg på veien.
                    </p>
                </StyledTextContainer>
            </Accordion>
        </div>
    );
};

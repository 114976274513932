import {
    AlertBanner,
    LoadingSpinner,
    PrimaryButton,
    Heading2Text,
    TextContainer,
} from '@gnist/design-system';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageLayout } from 'src/features/my-car/components/PageLayout';
import { Header } from 'src/features/my-car/sticky-header/StickyHeader';
import { useGetContract } from 'src/features/my-ordered-car/http/useGetContract';
import { PATH_HOME } from 'src/routing/routes';
import RateAppOrNpsInput from 'src/shared/RateAppOrNpsInput';
import { useStickyScroll } from 'src/utils/browser/useStickyScroll';
import { themeOverride } from 'src/utils/dev-tools/devToolsUtils';
import lang from 'src/utils/lang';
import { getThemeForCarBrand } from 'src/utils/themes/selectTheme';
import { styled } from 'styled-components';
import { ChildThemeProvider } from 'theming';

const FullHeightContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 80vh;
`;

const StyledSubtitle = styled(Heading2Text)`
    margin-top: calc(var(--moller-spacing-3xl) * 2);
`;

const StyledTextContainer = styled(TextContainer)`
    max-width: 50ch;
    margin-top: var(--moller-spacing-l);
    margin-bottom: var(--moller-spacing-xl);
`;

export const PurchaseConfirmationPage = () => {
    const [isScrollActive, setIsScrollActive] = useState(false);
    const { carId } = useParams<{
        carId: string;
        articleNumber: string;
    }>();
    const navigate = useNavigate();
    const { data, isPending: isLoading, isError } = useGetContract(carId);

    const isStickyScrollActive = useStickyScroll(
        isScrollActive,
        setIsScrollActive
    );

    if (isLoading || !data) return <LoadingSpinner isFullScreen />;
    if (isError)
        return (
            <AlertBanner
                type={'error'}
                message={lang.general_error_contact_support}
            />
        );

    // Will need this later
    // const { seller, contract, orderedCarDealer } = data;

    const { carContractCar } = data;

    const handleNavigateHome = () => {
        navigate(PATH_HOME);
    };

    const theme = getThemeForCarBrand(carContractCar.brand);

    return (
        <ChildThemeProvider theme={theme} overrideTheme={themeOverride}>
            <Header
                brand={carContractCar.brand}
                isLoading={isLoading}
                carName={carContractCar.model}
                isScrollActive={isStickyScrollActive}
            />
            <PageLayout>
                <FullHeightContainer>
                    <StyledSubtitle>Takk for din bestilling!</StyledSubtitle>
                    <StyledTextContainer>
                        <p>
                            Produktet blir levert sammen med bilen din. Du kan
                            ta kontakt med forhandler om du ønsker å endre
                            bestillingen.
                        </p>
                    </StyledTextContainer>
                    <PrimaryButton onClick={handleNavigateHome}>
                        Tilbake til forsiden
                    </PrimaryButton>
                </FullHeightContainer>

                <RateAppOrNpsInput
                    showUnlessDismissed
                    trigger="Etter gjennomført produktbestilling"
                />
            </PageLayout>
        </ChildThemeProvider>
    );
};

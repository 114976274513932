import { VideosByTypeNumberResult } from '@bilhold/sanity';
import {
    breakpointTablet,
    Heading1Text,
    TextContainer,
} from '@gnist/design-system';
import { LogoBrand } from '@gnist/design-system/foundation/logos/Logo.js';
import { PageLayout } from 'src/features/my-car/components/PageLayout';
import { themeOverride } from 'src/utils/dev-tools/devToolsUtils';
import lang from 'src/utils/lang';
import { Themes } from 'src/utils/themes/selectTheme';
import { styled } from 'styled-components';
import { ChildThemeProvider } from 'theming';
import { CarVideo } from './CarVideo';

const VideoGrid = styled.div`
    margin-top: var(--moller-spacing-xxl);
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--moller-spacing-3xl);

    @media screen and (min-width: ${breakpointTablet}) {
        grid-template-columns: 1fr 1fr;
    }
`;

interface CarVideoContainerProps {
    videos?: VideosByTypeNumberResult;
    theme: Themes;
}
interface CarVideoContainerProps {
    videos?: VideosByTypeNumberResult;
    theme: Themes;
}

export const CarVideoContainer = ({
    videos,
    theme,
}: CarVideoContainerProps) => {
    return (
        <ChildThemeProvider
            theme={theme.toLocaleLowerCase() as LogoBrand}
            overrideTheme={themeOverride}
        >
            <PageLayout showBackButton>
                <Heading1Text>{lang.videos}</Heading1Text>
                {videos ? (
                    <VideoGrid>
                        {videos.map((video) => (
                            <CarVideo key={video.name} video={video} />
                        ))}
                    </VideoGrid>
                ) : (
                    <TextContainer>
                        <p>{lang.noVideos}</p>
                    </TextContainer>
                )}
            </PageLayout>
        </ChildThemeProvider>
    );
};

import { breakpointMobile, LeadText } from '@gnist/design-system';
import { styled } from 'styled-components';
import { Section } from '../components';

const Container = styled.div`
    display: flex;
    max-width: 540px;
    padding: 0 var(--moller-spacing-m);
    @media screen and (min-width: ${breakpointMobile}) {
        padding: 0 10vw;
    }
    @media screen and (min-width: 1700px) {
        padding: 0 150px;
    }
`;
export const Info = () => {
    return (
        <Section>
            <Container>
                <LeadText>
                    Ingen kjenner bilen din så godt som vi gjør. I Bilhold får
                    du tilgang til merkeverksteder og tjenester for din bil så
                    du er garantert et godt bilhold.
                </LeadText>
            </Container>
        </Section>
    );
};

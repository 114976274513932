import { Product } from 'src/features/home/utils/productType';
import ProductRequestImage from '../product-images/custom/product-request.jpg';

export const svaProduct: Product = {
    articleNumber: 'sva', // Decides where to route
    packageNumber: 'sva',
    image: ProductRequestImage,
    title: 'Serviceavtale',
    descriptionShort: 'Her kan du sende oss en forespørsel om serviceavtale.',
    descriptionLong: 'Vi kan skaffe det meste til din bil.',
    text: 'Vi kan skaffe det meste til din bil.',
    price: 0,
    carBrand: 'Other',
    carModel: ['OTHER'],
};

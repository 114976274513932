import {
    ActionCard,
    Column,
    Heading3Text,
    LinkCard,
    Modal,
} from '@gnist/design-system';
import { OrderedCarRelation } from 'external-apis/src/types/bilhold';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { CarAppStoreCard } from 'src/features/my-car/components/CarAppStoreCard';
import { CardLayout } from 'src/features/my-car/components/CardLayout';
import { useGetSanityVideosByTypeNumber } from 'src/features/my-car/videos/useGetSanityVideosByTypeNumber';
import { SwipeableDrawer } from 'src/shared/SwipeableDrawer';
import { isMobileApp } from 'src/utils/mobile';
import { styled } from 'styled-components';
import { CardLayout as CardLayoutWithImage } from '../../components/CardLayout';
import { DeliveryProcessContent } from './DeliveryProcessContent';
import checkingDocumentImage from './check-document.jpg';

const Container = styled(Column)`
    margin-top: var(--moller-spacing-s);
    margin-bottom: var(--moller-spacing-s);
`;

const StyledCardsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--moller-spacing-s);
`;

const StyledSwipeableDrawer = styled(SwipeableDrawer)`
    background-color: var(--moller-background);
    color: var(--moller-color-on-background);
`;

interface WaitingProgramTasksProps {
    car: OrderedCarRelation;
}

const WaitingProgramTasks = ({ car }: WaitingProgramTasksProps) => {
    const [showDeliveryModal, setShowDeliveryModal] = useState(false);

    const { data: sanityVideos } = useGetSanityVideosByTypeNumber(
        car.model.typeNumber
    );

    const renderDeliveryProcessModal = () => {
        return isMobileApp() ? (
            <StyledSwipeableDrawer
                isOpen={showDeliveryModal}
                setIsOpen={() => setShowDeliveryModal(false)}
                imageUrl={checkingDocumentImage}
                scrollHeader="Leveringsprosess"
            >
                <DeliveryProcessContent carBrand={car.brand} />
            </StyledSwipeableDrawer>
        ) : (
            <Modal
                isOpen={showDeliveryModal}
                handleClose={() => setShowDeliveryModal(false)}
                size="large"
                primaryAction={{
                    label: 'Lukk',
                    onClick: () => setShowDeliveryModal(false),
                }}
            >
                <DeliveryProcessContent carBrand={car.brand} />
            </Modal>
        );
    };

    return (
        <Container gap="s">
            <Heading3Text>Aktuelle oppgaver</Heading3Text>
            <StyledCardsContainer>
                <CarAppStoreCard brand={car.brand} carName={car.model.text} />
                {car.brand !== 'VolkswagenCommercialVehicles' && (
                    <ActionCard onClick={() => setShowDeliveryModal(true)}>
                        <CardLayoutWithImage
                            title="Få innsikt i leveringsprosessen"
                            body="Her kan du lese om hva som skjer fremover."
                            overlineText="Leveringsprosessen"
                            ImageSrc={checkingDocumentImage}
                        />
                    </ActionCard>
                )}
                {sanityVideos && sanityVideos?.length > 0 && (
                    <LinkCard
                        $as={Link}
                        to={`/my-ordered-car/contract/${car.contractId}/videos`}
                    >
                        <CardLayout
                            title="Bli kjent med bilen din"
                            description="Se videoer"
                        />
                    </LinkCard>
                )}
            </StyledCardsContainer>
            {renderDeliveryProcessModal()}
        </Container>
    );
};

export default WaitingProgramTasks;

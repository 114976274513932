import { useMutation } from '@tanstack/react-query';
import { useFetchersContext } from 'external-apis';
import trimUserName from 'src/utils/auth/trimUserName';

function useUserInfo() {
    const [fetchers] = useFetchersContext();
    return fetchers.userSelfService.fetcher
        .path('/api/user-info')
        .method('post')
        .create();
}

export const useCheckUserForRegistration = () => {
    const getUserInfo = useUserInfo();
    return useMutation({
        mutationFn: ({
            phoneNumber,
            otpCode,
        }: {
            phoneNumber: string;
            otpCode: string;
        }) =>
            getUserInfo({
                countryCode: 'NO',
                phoneCountryCode: 'NO',
                phoneNumber: trimUserName(phoneNumber),
                otp: otpCode,
            }),
    });
};

import { PrizeComponentBase } from 'src/features/competition/components/myPrizes/PrizeComponentBase';
import { useUpdateStreakPrizeData } from 'src/features/competition/utils/useUpdatePrizeData';
import backpack from '../../images/Backpack.png';
interface Props {
    hasWon: boolean | undefined;
    dealer: string | undefined;
}

export const GreenStreakShirtPrize = ({ hasWon, dealer }: Props) => {
    const {
        mutate: updatePrize,
        isPending: isUpdating,
        isError,
    } = useUpdateStreakPrizeData();

    return (
        <PrizeComponentBase
            hasWon={hasWon}
            dealer={dealer}
            hasNotWonText={
                'Registrer sykling hver dag i 7 dager! De første 250 som klarer den grønne trøya får premie.'
            }
            hasWonText={
                'Du registrerte sykling hver dag i 7 dager og fikk derfor den grønne trøya! Du var også blant de 250 første som klarte dette. Derfor får du premie!'
            }
            heading={'Den grønne trøya'}
            color={'var(--moller-palette-primary-70)'}
            prizeName={'Škoda sportssekk'}
            isUpdating={isUpdating}
            isError={isError}
            updatePrize={updatePrize}
            image={backpack}
        />
    );
};
